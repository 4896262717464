import { AppbarTitleWithBackAction } from "@components/Appbar";
import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { useAuth } from "@contexts/AuthContext/auth";
import { CurrencyType } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import { useServiceMutation } from "@hooks/verification/useServiceMutation";
import { AddServicesStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PricesType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { globalStyles } from "@styles/global";
import { setDefaultPricesForAvailableCurrencies } from "@utils/prices";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { AddServiceContext } from "./addService.context";

const AddPaidServiceStep2: FC<
  PropsWithChildren<
    NativeStackScreenProps<AddServicesStackParamsList, "AddPaidServiceStep2">
  >
> = ({ navigation: { pop, setOptions, popToTop } }) => {
  const { scrollContainer, gapLarge } = globalStyles;
  const [footerHeight, setFooterHeight] = useState(0);

  const {
    restOfCurrencies,
    price,
    selectedCurrency,
    serviceItemId,
    serviceName,
    serviceType,
    clientSlots,
    breakSlots,
    service,
    clearData,
  } = useContext(AddServiceContext);
  const { t } = useTranslation();
  const editionMode = !!service;
  const {
    user: { currency },
  } = useAuth();
  const dropdownStatesHidden = new Array(restOfCurrencies?.length).fill(false);
  const [prices, setPrices] = useState<PricesType[]>([]);
  const [dropdownOpenStates, setDropdownOpenStates] =
    useState<boolean[]>(dropdownStatesHidden);
  const { mutate, isMutationLoading } = useServiceMutation({
    serviceType,
    clientSlots,
    breakSlots,
    prices,
    service,
    handleProcessClose: () => {
      clearData();
      popToTop();
      pop(1);
    },
  });

  const infos = [t("T00780"), t("T00781"), t("T00782")];

  const getDefaultValue = useCallback(
    (selectedCurrency: CurrencyType): number => {
      const defaultValue: number | undefined = prices.find(
        price => price.currency === selectedCurrency,
      )?.amount;
      return defaultValue || restOfCurrencies?.[0].value[0];
    },
    [restOfCurrencies, prices],
  );

  const onChangeValue = (value: number, selectedCurrency: CurrencyType) =>
    setPrices(prevPrices => [
      ...prevPrices.filter(
        prevPrice => prevPrice.currency !== selectedCurrency,
      ),
      { amount: value, currency: selectedCurrency },
    ]);

  const onPressButton = () =>
    mutate({
      serviceType,
      clientSlots,
      breakSlots,
      serviceItem: serviceItemId,
      prices,
    });

  useEffect(() => {
    if (editionMode) {
      const prices = service.prices.map(e =>
        e.currency === selectedCurrency ? { ...e, amount: price } : e,
      );
      setPrices(prices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !service &&
      setPrices(
        setDefaultPricesForAvailableCurrencies(
          price,
          currency,
          restOfCurrencies,
        ),
      );
  }, [currency, restOfCurrencies, price, service]);

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction
          title={t(editionMode ? "T01250" : "T00250")}
          onBack={goBack}
          onClose={() => {
            clearData();
            popToTop();
            pop(1);
          }}
        />
      ),
    });
  }, [clearData, editionMode, pop, popToTop, setOptions, t]);

  const onCurrencyDropdownOpen = useCallback(
    (dropdownIndex: number) => {
      const newDropdownOpenStates = dropdownStatesHidden;
      newDropdownOpenStates[dropdownIndex] = true;
      setDropdownOpenStates(newDropdownOpenStates);
    },
    [dropdownStatesHidden],
  );

  const onCurrencyDropdownClose = useCallback(
    (dropdownIndex: number) => {
      const newDropdownOpenStates = { ...dropdownOpenStates };
      newDropdownOpenStates[dropdownIndex] = false;
      setDropdownOpenStates(newDropdownOpenStates);
    },
    [dropdownOpenStates],
  );

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          { paddingBottom: footerHeight },
        ]}>
        <View style={gapLarge}>
          <View style={gapLarge}>
            <Chip style={{ alignSelf: "flex-start" }}>{`${t(
              "T00957",
            )} 2/2`}</Chip>
            <Text variant="bodyLarge">{serviceName}</Text>
            {infos.map((e, i) => (
              <Text key={`info-${e}-${i}`} variant="bodyMedium">
                {e}
              </Text>
            ))}
            <Text variant="titleSmall">
              {t("T00783", { value: `${price} ${currency}` })}
            </Text>
          </View>
          {restOfCurrencies?.map(({ currency: selectedCurrency, value }, i) => (
            <DropdownPicker<number>
              key={`picker-${currency}-${i}`}
              items={value.map(e => ({ value: e, label: `${e}` }))}
              defaultValue={getDefaultValue(selectedCurrency)}
              onSelectItem={item => onChangeValue(item.value, selectedCurrency)}
              dropdownLabelText={selectedCurrency as TransKey}
              onOpen={() => onCurrencyDropdownOpen(i)}
              onClose={() => onCurrencyDropdownClose(i)}
              isDropdownOpen={dropdownOpenStates[i] || false}
              multiDropdownContent
              dropDownDirection="TOP"
            />
          ))}
        </View>
      </ScrollView>
      <AbsoluteBlurredFooter
        onPress={onPressButton}
        title={editionMode ? "T01052" : "T00250"}
        buttonLoading={isMutationLoading}
        buttonDisabled={isMutationLoading}
        onLayout={height => setFooterHeight(height)}
      />
    </>
  );
};

export default AddPaidServiceStep2;
