import { TrainingsAndExercisesStackParamList } from "@navigators/navigation.types";
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs";
import { FC, PropsWithChildren } from "react";
import TrainingsAndExercisesCommonContent from "../TrainingsAndExercisesCommonContent";

const TrainingsAndExercises: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<
      TrainingsAndExercisesStackParamList,
      "TrainingsAndExercises"
    >
  >
> = () => {
  return <TrainingsAndExercisesCommonContent />;
};

export default TrainingsAndExercises;
