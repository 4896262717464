import InfoTile from "@components/Tile/InfoTile";
import { useUserDetails } from "@hooks/index";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Text } from "react-native-paper";

type PromoCodeInfoTileProps = {
  shortVersion?: boolean;
};

const PromoCodeInfoTile: FC<PromoCodeInfoTileProps> = ({
  shortVersion = false,
}) => {
  const { isPatient } = useUserDetails();
  const { t } = useTranslation();
  return (
    <InfoTile
      status="success"
      content={
        shortVersion ? (
          <Text variant="titleSmall">{t("T01605")}</Text>
        ) : (
          <Text variant="bodyMedium">
            <Trans
              i18nKey={isPatient ? "T01604" : "T01609"}
              components={{
                1: (
                  <Text style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                    15 PLN less
                  </Text>
                ),
              }}
            />
          </Text>
        )
      }
    />
  );
};

export default PromoCodeInfoTile;
