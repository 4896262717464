import { ProductName } from "@globalTypes/common.types";
import { flags } from "@utils/flags";

export const useFlags = () => {
  const isGymsy = flags.PRODUCT_NAME === ProductName.GYMSY;
  return {
    flags,
    isGymsy,
  };
};
