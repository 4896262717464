import { ActionTileProps } from "@components/Tile/ActionTile";
import { InfoTileType } from "@components/Tile/types/InfoTile.types";
import { RootTabsParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";

export enum VerificationActionTileType {
  PROFILE = "profile",
  VERIFICATION_WAITING = "verificationWaiting",
  AVAILABILITY = "availability",
  SERVICE_STATIONARY = "serviceStationary",
  SERVICE_ONLINE = "serviceOnline",
  NO_ONLINE_PAYMENTS = "noOnlinePayments",
  NO_STATIONARY_PAYMENTS = "noStationaryPayments",
}

export enum VerificationInfoTileType {
  SUBSCRIPTION_GRACE_PERIOD = "subscriptionGracePeriod",
  COMPLETED = "completed",
}

type UseVerificationOptions = {
  verificationType:
    | VerificationInfoTileType
    | VerificationActionTileType
    | null;
  cb?: () => void;
};

export const useVerification = ({
  verificationType,
  cb,
}: UseVerificationOptions) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootTabsParamList>>();

  const navigateToServices = useCallback(
    () =>
      navigate("ProfileStack", {
        screen: "ProfileCompletionStack",
        initial: false,
        params: { screen: "Services" },
      }),
    [navigate],
  );

  const navigateToOnlinePayments = useCallback(
    () =>
      navigate("ProfileStack", {
        screen: "ProfileCompletionStack",
        initial: false,
        params: { screen: "OnlinePayments" },
      }),
    [navigate],
  );
  const navigateToStationaryPayments = useCallback(
    () =>
      navigate("ProfileStack", {
        screen: "ProfileCompletionStack",
        initial: false,
        params: { screen: "StationaryPayments" },
      }),
    [navigate],
  );

  const navigateToAvailabilityHours = useCallback(
    (params: { initialDate: Date }) =>
      navigate("CalendarStack", {
        screen: "AvailabilityHours",
        initial: false,
        params,
      }),
    [navigate],
  );

  const onPressHandlerForAvailabilityHours = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (cb) {
      cb();
      return setTimeout(
        () => navigateToAvailabilityHours({ initialDate: today }),
        500,
      );
    }
    navigateToAvailabilityHours({ initialDate: today });
  }, [cb, navigateToAvailabilityHours]);

  const goToProfile = useCallback(() => {
    if (cb) {
      cb();
      return setTimeout(() => navigate("ProfileStack"), 500);
    }
    return navigate("ProfileStack");
  }, [cb, navigate]);
  const goToProfileToAddService = useCallback(() => {
    if (cb) {
      cb();
      return setTimeout(navigateToServices, 500);
    }
    return navigateToServices();
  }, [cb, navigateToServices]);

  const actionTilesContent: Record<
    VerificationActionTileType,
    ActionTileProps
  > = {
    profile: {
      title: "T00990",
      onPress: goToProfile,
      buttonLabel: "T00989",
      tileColorTheme: "yellow",
    },
    verificationWaiting: {
      title: "T00991",
      onPress: goToProfile,
      buttonLabel: "T00989",
      tileColorTheme: "yellow",
    },
    availability: {
      title: "T00995",
      onPress: onPressHandlerForAvailabilityHours,
      buttonLabel: "T00998",
      tileColorTheme: "yellow",
      subtitles: ["T00230"],
    },
    serviceStationary: {
      title: "T00996",
      onPress: goToProfileToAddService,
      buttonLabel: "T00249",
      tileColorTheme: "yellow",
      subtitles: ["T00230"],
    },
    serviceOnline: {
      title: "T00997",
      onPress: goToProfileToAddService,
      buttonLabel: "T00249",
      tileColorTheme: "yellow",
      subtitles: ["T00230"],
    },
    noOnlinePayments: {
      title: "T01139",
      onPress: navigateToOnlinePayments,
      buttonLabel: "T00228",
      tileColorTheme: "yellow",
      subtitles: ["T01138"],
    },
    noStationaryPayments: {
      title: "T01581",
      onPress: navigateToStationaryPayments,
      buttonLabel: "T01580",
      tileColorTheme: "yellow",
      subtitles: ["T00229"],
    },
  };

  const infoTilesContent: Record<VerificationInfoTileType, InfoTileType> = {
    subscriptionGracePeriod: {
      content: <Text>{t("T00993")}</Text>,
      status: "warning",
    },
    completed: {
      content: <Text>{t("T00994")}</Text>,
      status: "success",
    },
  };

  return Object.values(VerificationInfoTileType).includes(
    verificationType as VerificationInfoTileType,
  )
    ? (infoTilesContent[verificationType] as InfoTileType)
    : (actionTilesContent[verificationType] as ActionTileProps);
};
