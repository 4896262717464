import { HeaderWithSearchbar } from "@components/HeaderWithSearchbar";
import { UserListItem, UserListItemMode } from "@components/ListItems";
import { FetchError } from "@components/errors";
import { GetPhysiotherapistsResponse } from "@contexts/AuthContext/user.types";
import useRecommendedServices from "@hooks/queryHooks/useRecommendedServices";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { NoResultsInfo } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/NoResultsInfo";
import { getServices } from "@services/ApiService/common";
import { searchPhysiotherapists } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing20, spacing24 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { useThrottle } from "ahooks";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import {
  ActivityIndicator,
  Divider,
  IconButton,
  List,
  Text,
} from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { defaultFilters } from "./FiltersComponents/utils";
import { GetServicesResponse } from "./filters.types";
import { queryKeysServices } from "./queryKeysFilters";
import { useKeyboard } from "@hooks/ui/useKeyboard";

type ServiceName = {
  name: string;
};

export const FindRehabilitator: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "FindRehabilitator">
  >
> = ({
  navigation: { goBack, navigate },
  route: {
    params: { setFilters },
  },
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [rehabilitatorsList, setRehabilitatorsList] = useState<
    GetPhysiotherapistsResponse[]
  >([]);
  const [servicesList, setServicesList] = useState<GetServicesResponse[]>([]);

  const { width } = useWindowDimensions();
  const keyboardHeight = useKeyboard();

  const throttledQuery = useThrottle(searchQuery, {
    leading: false,
  });
  const { scrollContainer, gapMedium, loading, flatListSeparator } =
    globalStyles;

  const onPress = useCallback(
    (id: number) =>
      navigate("PhysiotherapistProfile", {
        id,
      }),
    [navigate],
  );

  const queryEnabled = !!throttledQuery && throttledQuery.length >= 3;

  const {
    isError: isPhysioError,
    refetch: refetchPhysio,
    isLoading: isPhysioLoading,
    data: physioData,
    isSuccess: isPhysioSuccess,
  } = useQuery({
    queryFn: () => searchPhysiotherapists({ search: throttledQuery }),
    queryKey: queryKeysPhysiotherapist.search({ search: throttledQuery }),
    enabled: queryEnabled,
  });

  const {
    data: servicesData,
    isLoading: isServicesLoading,
    isError: isServicesError,
    refetch: refetchServices,
    isSuccess: isServicesSuccess,
  } = useQuery({
    queryKey: queryKeysServices.list(throttledQuery),
    queryFn: () => getServices(throttledQuery),
    enabled: queryEnabled,
  });

  const { data: recommendedServices, isLoading: isRecommendedServicesLoading } =
    useRecommendedServices();

  const isLoading = isPhysioLoading || isServicesLoading;

  const isError = isPhysioError || isServicesError;

  const refetch = async () =>
    await Promise.all([refetchPhysio(), refetchServices()]);

  const clearInput = () => {
    setRehabilitatorsList([]);
    setServicesList([]);
    setSearchQuery("");
  };

  const onChangeText = (text: string) => {
    setSearchQuery(text);
    if (text.trim().length < 3) {
      setRehabilitatorsList([]);
      setServicesList([]);
    }
  };

  const onServiceListItemPress = useCallback(
    (serviceName: string) => {
      setFilters(prev => {
        const filtersToSet = prev ? prev : defaultFilters;
        const lat = prev?.lat;
        const lng = prev?.lng;
        if (lat && lng)
          return { ...filtersToSet, serviceName: [serviceName], lat, lng };
        return {
          ...filtersToSet,
          serviceName: [serviceName],
        };
      });
      navigate("Physiotherapists");
    },
    [navigate, setFilters],
  );

  useEffect(() => {
    if (isServicesSuccess) setServicesList(servicesData);
    if (isPhysioSuccess) setRehabilitatorsList(physioData);
  }, [isPhysioSuccess, isServicesSuccess, physioData, servicesData]);

  const divider = useMemo(
    () => (
      <Divider
        bold
        style={[
          flatListSeparator,
          {
            width,
          },
        ]}
      />
    ),
    [flatListSeparator, width],
  );

  const renderServicesList = useCallback(
    (data: ServiceName[]) => (
      <View>
        {data?.map(({ name }, index) => (
          <View key={`service-${name}`}>
            <List.Item
              onPress={() => onServiceListItemPress(name)}
              title={() => <Text variant="bodyLarge">{name}</Text>}
              right={() => (
                <IconButton
                  icon="menu-right"
                  size={spacing24}
                  style={{
                    marginEnd: -spacing20,
                  }}
                />
              )}
            />
            {index < data.length - 1
              ? divider
              : rehabilitatorsList?.length
              ? divider
              : null}
          </View>
        ))}
      </View>
    ),
    [divider, onServiceListItemPress, rehabilitatorsList?.length],
  );

  const noRehabilitatorFoundWithQuery = useMemo(
    () =>
      throttledQuery?.length >= 3 &&
      !(rehabilitatorsList?.length || servicesList?.length),
    [rehabilitatorsList?.length, servicesList?.length, throttledQuery?.length],
  );

  return (
    <SafeAreaView
      style={{ flex: 1 }}
      edges={["right", "bottom", "left", "top"]}>
      <HeaderWithSearchbar
        onClear={clearInput}
        onChangeText={onChangeText}
        goBack={goBack}
        searchQuery={searchQuery}
        enableSearching
      />
      {isLoading && queryEnabled ? (
        <ActivityIndicator style={loading} />
      ) : isError ? (
        <FetchError action={refetch} />
      ) : (
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="never"
          contentContainerStyle={[
            scrollContainer,
            gapMedium,
            {
              flexGrow: 1,
              paddingBottom: noRehabilitatorFoundWithQuery ? keyboardHeight : 0,
            },
          ]}>
          {throttledQuery.length >= 3 ? (
            rehabilitatorsList?.length || servicesList?.length ? (
              <>
                {renderServicesList(servicesList as ServiceName[])}
                {rehabilitatorsList?.map(({ id }, index) => (
                  <View key={`rehabilitator-${id}-${index}`}>
                    <UserListItem
                      mode={UserListItemMode.THERAPISTS}
                      id={id}
                      onPress={onPress}
                    />
                    {index < rehabilitatorsList.length - 1 && divider}
                  </View>
                ))}
              </>
            ) : (
              <NoResultsInfo description="T00914" />
            )
          ) : isRecommendedServicesLoading ? (
            <ActivityIndicator style={loading} />
          ) : (
            renderServicesList(recommendedServices)
          )}
        </KeyboardAwareScrollView>
      )}
    </SafeAreaView>
  );
};
