import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  OptionalSteps,
  ProfileCompletionActionsSkippedByUser,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { useEffect, useState } from "react";

const getSkipFlag = async (flag: OptionalSteps) =>
  await AsyncStorage.getItem(flag);

export const setSkipFlagValue = async (
  flag: OptionalSteps,
  val: string,
  cb?: () => void,
) => {
  await AsyncStorage.setItem(flag, val);
  cb && cb();
};

const useProfileCompletionActionsSkippedByUser = () => {
  const [
    profileCompletionActionsSkippedByUser,
    setProfileCompletionActionsSkippedByUser,
  ] = useState<ProfileCompletionActionsSkippedByUser>(null);

  const getProfileCompletionActionsSkippedByUser = async () => {
    setProfileCompletionActionsSkippedByUser(null);
    const certificates = await getSkipFlag(OptionalSteps.CERTIFICATES);
    const onlinePayments = await getSkipFlag(OptionalSteps.ONLINE_PAYMENTS);
    const skippedData = {
      certificates: certificates === "true",
      onlinePayments: onlinePayments === "true",
    };
    setProfileCompletionActionsSkippedByUser(skippedData);
  };

  useEffect(() => void getProfileCompletionActionsSkippedByUser(), []);

  return {
    profileCompletionActionsSkippedByUser,
    getProfileCompletionActionsSkippedByUser,
  };
};

export default useProfileCompletionActionsSkippedByUser;
