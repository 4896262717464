import { VerificationTile } from "@components/Tile";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { CommonActions, useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getAddress, getLicence } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { RefreshControl, ScrollView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { ProfileCompletionInfo } from "../ProfileCompletionInfo";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { VerificationStatus } from "./verification.types";
import { TransKey } from "@globalTypes/i18next";
import { useFlags } from "@hooks/useFlags";
import useGetPhone from "@hooks/phoneVerification/useGetPhone";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import { useTranslation } from "react-i18next";

const AddressAndVerification: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ProfileCompletionStackParamList,
      "AddressAndVerification"
    >
  >
> = ({ navigation: { navigate, setOptions, dispatch }, route }) => {
  const { updateUser } = useAuth();
  const isFocused = useIsFocused();
  const isFromNotification = route.params?.isFromNotification;
  const {
    flags: { FEATURE_SMS_VERIFICATION_ENABLED },
  } = useFlags();

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          { name: "Home", state: { routes: [{ name: "ProfileStack" }] } },
        ],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    isFromNotification &&
      setOptions({
        header: ({ navigation }) => (
          <AppbarTitleWithDefaultBackAction
            navigation={navigation}
            title={t("T00234")}
            onClose={handleClose}
            onBack={null}
          />
        ),
      });
  }, [handleClose, isFromNotification, setOptions, t]);

  const {
    data: addressData,
    isLoading: addressLoading,
    isError: addressError,
    refetch: addressRefetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.address(),
    queryFn: getAddress,
    onSuccess: updateUser,
  });
  const {
    data: licenceData,
    isLoading: licenceLoading,
    isError: licenceError,
    refetch: licenceRefetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.licence(),
    queryFn: getLicence,
    onSuccess: updateUser,
  });

  const {
    data: phoneData,
    isLoading: phoneLoading,
    isError: phoneError,
    refetch: phoneRefetch,
  } = useGetPhone();

  useEffect(() => {
    refetchAllData().catch(err => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const refetchAllData = async () =>
    await Promise.all([
      addressRefetch(),
      licenceRefetch(),
      FEATURE_SMS_VERIFICATION_ENABLED && phoneRefetch(),
    ]);

  if (
    addressLoading ||
    licenceLoading ||
    (FEATURE_SMS_VERIFICATION_ENABLED && phoneLoading)
  )
    return <ActivityIndicator style={globalStyles.loading} />;

  if (
    addressError ||
    licenceError ||
    (FEATURE_SMS_VERIFICATION_ENABLED && phoneError)
  )
    return <FetchError action={refetchAllData} />;

  const actionEnabled =
    licenceData?.[0]?.verificationStatus === VerificationStatus.PENDING;

  return (
    <ScrollView
      refreshControl={
        <RefreshControl
          refreshing={
            addressLoading ||
            licenceLoading ||
            (FEATURE_SMS_VERIFICATION_ENABLED && phoneLoading)
          }
          onRefresh={refetchAllData}
        />
      }
      contentContainerStyle={[globalStyles.gapMedium, globalStyles.container]}>
      <ProfileCompletionInfo />
      <VerificationTile
        mode="outlined"
        title="T00140"
        content={(addressData?.[0]?.rejectionReason || "T00150") as TransKey}
        status={addressData?.[0]?.verificationStatus || null}
        onPress={() => navigate("VerifyAddress")}
        type="profile completion"
      />
      <VerificationTile
        mode="outlined"
        title="T00148"
        content={(licenceData?.[0]?.rejectionReason || "T00149") as TransKey}
        status={licenceData?.[0]?.verificationStatus || null}
        onPress={() => navigate("VerifyLicence", { actionEnabled })}
        type="profile completion"
      />
      {FEATURE_SMS_VERIFICATION_ENABLED && (
        <VerificationTile
          mode="outlined"
          title="T01508"
          content={(phoneData?.rejectionReason || "T01509") as TransKey}
          status={phoneData?.verificationStatus || null}
          type="profile completion"
          onPress={() => navigate("VerifyPhone")}
        />
      )}
    </ScrollView>
  );
};

export default AddressAndVerification;
