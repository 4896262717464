import RNconfig, { NativeConfig } from "react-native-config";
import { getRemoteConfig } from "@react-native-firebase/remote-config";
import { Platform } from "react-native";

export const initRemoteConfig = async () => {
  if (Platform.OS === "web") return;

  const remoteConfig = getRemoteConfig();
  await remoteConfig.setConfigSettings({ minimumFetchIntervalMillis: 300000 });

  const isActivated = await remoteConfig.fetchAndActivate();
  if (!isActivated)
    console.warn("Remote Config values not activated (no new values).");

  const configKeys = {
    EXPO_PUBLIC_ENV: "EXPO_PUBLIC_ENV",
    EXPO_PUBLIC_API_BASE_URL: "EXPO_PUBLIC_API_BASE_URL",
    EXPO_PUBLIC_API_FULL_URL: "EXPO_PUBLIC_API_FULL_URL",
    EXPO_PUBLIC_APPLE_SUB_TEST_MODE: "EXPO_PUBLIC_APPLE_SUB_TEST_MODE",
    EXPO_PUBLIC_CLARITY_ID: "EXPO_PUBLIC_CLARITY_ID",
    EXPO_PUBLIC_GOOGLE_MONTHLY_SUB_ID: "EXPO_PUBLIC_GOOGLE_MONTHLY_SUB_ID",
    EXPO_PUBLIC_ONESIGNAL_APP_ID: "EXPO_PUBLIC_ONESIGNAL_APP_ID",
  };

  Object.entries(configKeys).forEach(([key, remoteKey]) => {
    const value = remoteConfig.getValue(remoteKey).asString();
    if (value) config[key] = value;
  });
};

export const config: NativeConfig = {
  EXPO_PUBLIC_ENV: process.env.EXPO_PUBLIC_ENV || RNconfig?.EXPO_PUBLIC_ENV,
  EXPO_PUBLIC_API_BASE_URL:
    process.env.EXPO_PUBLIC_API_BASE_URL || RNconfig?.EXPO_PUBLIC_API_BASE_URL,
  EXPO_PUBLIC_API_FULL_URL:
    process.env.EXPO_PUBLIC_API_FULL_URL || RNconfig?.EXPO_PUBLIC_API_FULL_URL,
  EXPO_PUBLIC_ONESIGNAL_APP_ID:
    process.env.EXPO_PUBLIC_ONESIGNAL_APP_ID ||
    RNconfig?.EXPO_PUBLIC_ONESIGNAL_APP_ID,
  EXPO_PUBLIC_OPEN_SIGNAL_SAFARI_WEB_ID:
    process.env.EXPO_PUBLIC_OPEN_SIGNAL_SAFARI_WEB_ID ||
    RNconfig?.EXPO_PUBLIC_OPEN_SIGNAL_SAFARI_WEB_ID,
  EXPO_PUBLIC_APPLE_SHARED_SECRET:
    process.env.EXPO_PUBLIC_APPLE_SHARED_SECRET ||
    RNconfig?.EXPO_PUBLIC_APPLE_SHARED_SECRET,
  EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID:
    process.env.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID ||
    RNconfig?.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID,
  EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID:
    process.env.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID ||
    RNconfig?.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID,
  EXPO_PUBLIC_GOOGLE_CLOUD_API_KEY:
    process.env.EXPO_PUBLIC_GOOGLE_CLOUD_API_KEY ||
    RNconfig?.EXPO_PUBLIC_GOOGLE_CLOUD_API_KEY,
  EXPO_PUBLIC_FACEBOOK_APP_ID:
    process.env.EXPO_PUBLIC_FACEBOOK_APP_ID ||
    RNconfig?.EXPO_PUBLIC_FACEBOOK_APP_ID,
  EXPO_PUBLIC_FACEBOOK_CLIENT_TOKEN:
    process.env.EXPO_PUBLIC_FACEBOOK_CLIENT_TOKEN ||
    RNconfig?.EXPO_PUBLIC_FACEBOOK_CLIENT_TOKEN,
  EXPO_PUBLIC_IOS_APP_ID:
    process.env.EXPO_PUBLIC_IOS_APP_ID || RNconfig?.EXPO_PUBLIC_IOS_APP_ID,
  EXPO_PUBLIC_ANDROID_APP_ID:
    process.env.EXPO_PUBLIC_ANDROID_APP_ID ||
    RNconfig?.EXPO_PUBLIC_ANDROID_APP_ID,
  EXPO_PUBLIC_APPLE_SUB_TEST_MODE:
    process.env.EXPO_PUBLIC_APPLE_SUB_TEST_MODE ||
    RNconfig?.EXPO_PUBLIC_APPLE_SUB_TEST_MODE,
  EXPO_PUBLIC_CLARITY_ID:
    process.env.EXPO_PUBLIC_CLARITY_ID || RNconfig?.EXPO_PUBLIC_CLARITY_ID,
  EXPO_PUBLIC_GOOGLE_MONTHLY_SUB_ID:
    process.env.EXPO_PUBLIC_GOOGLE_MONTHLY_SUB_ID ||
    RNconfig?.EXPO_PUBLIC_GOOGLE_MONTHLY_SUB_ID,
  EXPO_PUBLIC_ENABLE_ANALYTICS:
    process.env.EXPO_PUBLIC_ENABLE_ANALYTICS ||
    RNconfig?.EXPO_PUBLIC_ENABLE_ANALYTICS,
  EXPO_PUBLIC_SENTRY_DSN:
    process.env.EXPO_PUBLIC_SENTRY_DSN || RNconfig?.EXPO_PUBLIC_SENTRY_DSN,
  EXPO_PUBLIC_FIREBASE_API_KEY:
    process.env.EXPO_PUBLIC_FIREBASE_API_KEY ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_API_KEY,
  EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN,
  EXPO_PUBLIC_FIREBASE_PROJECT_ID:
    process.env.EXPO_PUBLIC_FIREBASE_PROJECT_ID ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_PROJECT_ID,
  EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET:
    process.env.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET,
  EXPO_PUBLIC_FIREBASE_MESSAGING_ID:
    process.env.EXPO_PUBLIC_FIREBASE_MESSAGING_ID ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_MESSAGING_ID,
  EXPO_PUBLIC_FIREBASE_WEB_APP_ID:
    process.env.EXPO_PUBLIC_FIREBASE_WEB_APP_ID ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_WEB_APP_ID,
  EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID:
    process.env.EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID ||
    RNconfig?.EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID,
  EXPO_PUBLIC_DEEP_LINK_URL:
    process.env.EXPO_PUBLIC_DEEP_LINK_URL ||
    RNconfig?.EXPO_PUBLIC_DEEP_LINK_URL,
  EXPO_PUBLIC_DEV_LOGIN: RNconfig?.EXPO_PUBLIC_DEV_LOGIN,
  EXPO_PUBLIC_DEV_LOGIN_PATIENT_MAIL:
    RNconfig?.EXPO_PUBLIC_DEV_LOGIN_PATIENT_MAIL,
  EXPO_PUBLIC_DEV_LOGIN_PASS: RNconfig?.EXPO_PUBLIC_DEV_LOGIN_PASS,
  EXPO_PUBLIC_DEV_LOGIN_REHAB_MAIL: RNconfig?.EXPO_PUBLIC_DEV_LOGIN_REHAB_MAIL,
  EXPO_PUBLIC_PRODUCT_NAME:
    process.env.EXPO_PUBLIC_PRODUCT_NAME || RNconfig?.EXPO_PUBLIC_PRODUCT_NAME,
  EXPO_PUBLIC_FEATURE_APPLE_LOGIN:
    process.env.EXPO_PUBLIC_FEATURE_APPLE_LOGIN ||
    RNconfig?.EXPO_PUBLIC_FEATURE_APPLE_LOGIN,
  EXPO_PUBLIC_FEATURE_SMS_VERIFICATION_ENABLED:
    process.env.EXPO_PUBLIC_FEATURE_SMS_VERIFICATION_ENABLED ||
    RNconfig?.EXPO_PUBLIC_FEATURE_SMS_VERIFICATION_ENABLED,
  EXPO_PUBLIC_FEATURE_FREE_SERVICE_ENABLED:
    process.env.EXPO_PUBLIC_FEATURE_FREE_SERVICE_ENABLED ||
    RNconfig?.EXPO_PUBLIC_FEATURE_FREE_SERVICE_ENABLED,
  EXPO_PUBLIC_FEATURE_FACEBOOK_LOGIN:
    process.env.EXPO_PUBLIC_FEATURE_FACEBOOK_LOGIN ||
    RNconfig?.EXPO_PUBLIC_FEATURE_FACEBOOK_LOGIN,
  EXPO_PUBLIC_FEATURE_BONE_MODEL_ENABLED:
    process.env.EXPO_PUBLIC_FEATURE_BONE_MODEL_ENABLED ||
    RNconfig?.EXPO_PUBLIC_FEATURE_BONE_MODEL_ENABLED,
  EXPO_PUBLIC_FEATURE_GENERAL_MODEL_ENABLED:
    process.env.EXPO_PUBLIC_FEATURE_GENERAL_MODEL_ENABLED ||
    RNconfig?.EXPO_PUBLIC_FEATURE_GENERAL_MODEL_ENABLED,
  EXPO_PUBLIC_FEATURE_CONDITIONS_ENABLED:
    process.env.EXPO_PUBLIC_FEATURE_CONDITIONS_ENABLED ||
    RNconfig?.EXPO_PUBLIC_FEATURE_CONDITIONS_ENABLED,
  EXPO_PUBLIC_FEATURE_MEDICAL_RECORDS:
    process.env.EXPO_PUBLIC_FEATURE_MEDICAL_RECORDS ||
    RNconfig?.EXPO_PUBLIC_FEATURE_MEDICAL_RECORDS,
  EXPO_PUBLIC_FEATURE_ABOUT_APPLICATION:
    process.env.EXPO_PUBLIC_FEATURE_ABOUT_APPLICATION ||
    RNconfig?.EXPO_PUBLIC_FEATURE_ABOUT_APPLICATION,
  EXPO_PUBLIC_FEATURE_PERSONAL_NOTES:
    process.env.EXPO_PUBLIC_FEATURE_PERSONAL_NOTES ||
    RNconfig?.EXPO_PUBLIC_FEATURE_PERSONAL_NOTES,
  EXPO_PUBLIC_FEATURE_PREDICTED_PARAMETERS:
    process.env.EXPO_PUBLIC_FEATURE_PREDICTED_PARAMETERS ||
    RNconfig?.EXPO_PUBLIC_FEATURE_PREDICTED_PARAMETERS,
  EXPO_PUBLIC_FEATURE_SURVEY_ENABLED:
    process.env.EXPO_PUBLIC_FEATURE_SURVEY_ENABLED ||
    RNconfig?.EXPO_PUBLIC_FEATURE_SURVEY_ENABLED,
  EXPO_PUBLIC_FEATURE_TRAINING_SELECTION_ASSISTANT:
    process.env.EXPO_PUBLIC_FEATURE_TRAINING_SELECTION_ASSISTANT ||
    RNconfig?.EXPO_PUBLIC_FEATURE_TRAINING_SELECTION_ASSISTANT,
  EXPO_PUBLIC_FEATURE_FORUM:
    process.env.EXPO_PUBLIC_FEATURE_FORUM ||
    RNconfig?.EXPO_PUBLIC_FEATURE_FORUM,
  EXPO_PUBLIC_STRIPE_MERCHANT_ID_APPLE_PAY:
    process.env.EXPO_PUBLIC_STRIPE_MERCHANT_ID_APPLE_PAY ||
    RNconfig?.EXPO_PUBLIC_STRIPE_MERCHANT_ID_APPLE_PAY,
  EXPO_PUBLIC_STRIPE_MERCHANT_ID_GOOGLE_PAY:
    process.env.EXPO_PUBLIC_STRIPE_MERCHANT_ID_GOOGLE_PAY ||
    RNconfig?.EXPO_PUBLIC_STRIPE_MERCHANT_ID_GOOGLE_PAY,
  EXPO_PUBLIC_GOOGLE_PAY_TEST_ENV:
    process.env.EXPO_PUBLIC_GOOGLE_PAY_TEST_ENV ||
    RNconfig?.EXPO_PUBLIC_GOOGLE_PAY_TEST_ENV,
  EXPO_PUBLIC_FEATURE_GENERAL_WORKOUT_SETS:
    process.env.EXPO_PUBLIC_FEATURE_GENERAL_WORKOUT_SETS ||
    RNconfig?.EXPO_PUBLIC_FEATURE_GENERAL_WORKOUT_SETS,
};
