import { yupResolver } from "@hookform/resolvers/yup";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useMemo } from "react";
import { Control, Controller, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { ActivityIndicator, HelperText, Text } from "react-native-paper";
import { object, string } from "yup";

import { Input } from "@components/Input";
import { FetchError } from "@components/errors";

import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import InfoTile from "@components/Tile/InfoTile";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { GeocodeStatus } from "@globalTypes/geocoding.types";
import {
  useDisplayBlurredFooter,
  useInvalidateProfileDetails,
} from "@hooks/index";
import { useErrors } from "@hooks/useErrors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { getCoordinatesFromAddress } from "@services/ApiService/geocoding";
import {
  getAddress,
  getCountryOptions,
  saveAddress,
  updateAddress,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing20 } from "@styles/spacing";
import {
  INPUT_MAX_LENGTH,
  INPUT_MAX_LENGTH_50_CHARS,
  MULTILINE_INPUT_MAX_LENGTH_SHORT,
  SHORT_INPUT_MAX_LENGTH,
} from "@utils/constants";
import { formatAddressToRedirection } from "@utils/formatAddressToRedirection";
import { showAlert } from "@utils/showAlert";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { AddressType } from "./verification.types";
import { useFlags } from "@hooks/useFlags";
import { AbsoluteBlurredFooter } from "@components/Footers";

type AddressForm = Omit<AddressType, "location">;

export const VerifyAddress: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "VerifyAddress">
  >
> = ({ navigation: { goBack } }) => {
  const { t } = useTranslation();
  const { scrollContainer, loading, gapLarge, gapMedium } = globalStyles;
  const { errors, setErrorsFromResponse, clearErrors } = useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const { isGymsy } = useFlags();
  const {
    footerHeight,
    setFooterHeight,
    displayBlurredFooter,
    keyboardHeight,
  } = useDisplayBlurredFooter();

  const {
    data: countryOptions,
    isLoading: countryOptionsLoading,
    isError: countryOptionsError,
    refetch: refetchCountryOptions,
  } = useQuery({
    queryKey: queryKeysVerficiations.countryOptions(),
    queryFn: async () => {
      const { data } = await getCountryOptions();
      const { country } = data.actions.post;
      const mappedCountryOptions = country.choices.map(
        ({ value, displayName }) => ({
          value: value as string,
          label: displayName,
        }),
      );

      return { country: mappedCountryOptions };
    },
  });

  const {
    data: initialData,
    isLoading: initialDataLoading,
    isError: initialDataError,
    refetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.address(),
    queryFn: getAddress,
    onSuccess: data => {
      data[0]?.country && setValue("country", data[0]?.country);
      setValue("street", data[0]?.street);
      setValue("buildingNumber", data[0]?.buildingNumber);
      setValue("apartmentNumber", data[0]?.apartmentNumber || "");
      setValue("additionalInformation", data[0]?.additionalInformation || "");
      setValue("postalCode", data[0]?.postalCode);
      setValue("town", data[0]?.town);
      setValue("companyName", data[0]?.companyName || "");
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: AddressType) =>
      await (!initialData?.length
        ? saveAddress(data)
        : updateAddress(initialData?.[0]?.id, data)),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysVerficiations.address(),
        }),
        queryClient.invalidateQueries({
          queryKey:
            queryKeysVerficiations.verificationProgressRehabInSubscreens(),
        }),
        invalidateProfileDetails(),
      ]);
      clearAndGoBack();
    },
  });

  const schema = useMemo(() => {
    const postalCodeRegex = /^\d{2}-\d{3}$|^\d{5}$/;
    const stringRequired = string().required(t("T00014")).trim();
    const stringNotRequired = string().trim().optional();
    return object().shape({
      country: stringRequired,
      street: stringRequired.max(
        INPUT_MAX_LENGTH,
        t("T00019", {
          max: INPUT_MAX_LENGTH,
        }),
      ),
      buildingNumber: stringRequired.matches(/^\d/, t("T00020")),
      apartmentNumber: stringNotRequired,
      additionalInformation: stringNotRequired.max(
        isGymsy ? SHORT_INPUT_MAX_LENGTH : MULTILINE_INPUT_MAX_LENGTH_SHORT,
        t("T00016", {
          max: isGymsy
            ? SHORT_INPUT_MAX_LENGTH
            : MULTILINE_INPUT_MAX_LENGTH_SHORT,
        }),
      ),
      companyName: stringNotRequired,
      postalCode: stringRequired
        .max(6, t("T00022"))
        .matches(postalCodeRegex, t("T00021")),

      town: stringRequired,
    });
  }, [isGymsy, t]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors: errorsFromForm },
    clearErrors: clearFormErrors,
  } = useForm<AddressForm>({
    resolver: yupResolver(schema) as Resolver<AddressForm>,
    defaultValues: {
      country: initialData?.[0]?.country || "PL",
    },
  });

  const refetchQueries = async () =>
    await Promise.all([refetch(), refetchCountryOptions()]);

  const onSubmit = async (data: AddressForm) => {
    const onError = (reason: GeocodeStatus) => {
      const message = `${t("T00832")}, ${reason}`;
      showAlert(message);
    };

    const coords = await getCoordinatesFromAddress(
      formatAddressToRedirection(data as AddressType),
      onError,
    );

    if (!coords) return;

    const params: AddressType = {
      ...data,
      location: {
        type: "Point",
        coordinates: [coords.lng, coords.lat],
      },
    };

    mutate(params);
  };

  const clearAndGoBack = () => {
    clearErrors();
    clearFormErrors();
    goBack();
    reset();
    showProfileVerificationSnackbar(t("T01311"));
  };

  const pickerError =
    errors?.country?.[0]?.message || errorsFromForm?.country?.message;

  if (initialDataLoading || countryOptionsLoading)
    return <ActivityIndicator style={loading} />;

  if (initialDataError || countryOptionsError)
    return <FetchError action={refetchQueries} />;

  return (
    <>
      <KeyboardAwareScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          !keyboardHeight && { paddingBottom: footerHeight },
        ]}>
        <View style={gapLarge}>
          <InfoTile
            status="info"
            content={<Text variant="bodyMedium">{t("T00956")}</Text>}
          />
          <Text variant="bodyMedium">{t("T00156")}</Text>
        </View>
        <Controller
          name="country"
          key="country"
          control={control as unknown as Control}
          render={({ field: { onChange } }) => {
            return (
              <View style={{ zIndex: 1000 }}>
                {pickerError && (
                  <HelperText type="error">{pickerError}</HelperText>
                )}
                <DropdownPicker
                  onValueChange={value => onChange(value)}
                  defaultValue={initialData?.[0]?.country || "PL"}
                  dropdownLabelText="T00618"
                  items={countryOptions.country}
                  isRequired
                  searchable
                />
              </View>
            );
          }}
        />
        <View style={gapMedium}>
          <Input
            name="street"
            key="street"
            label="T00619"
            control={control as unknown as Control}
            errors={errors?.street}
            autoCapitalize="sentences"
            isRequired
          />
          <Input
            name="buildingNumber"
            key="buildingNumber"
            label="T00620"
            control={control as unknown as Control}
            errors={errors?.buildingNumber}
            keyboardType={
              Platform.OS === "ios" ? "numbers-and-punctuation" : "default"
            }
            isRequired
          />
          <Input
            name="apartmentNumber"
            key="apartmentNumber"
            label="T00621"
            control={control as unknown as Control}
            errors={errors?.apartmentNumber}
            keyboardType={
              Platform.OS === "ios" ? "numbers-and-punctuation" : "default"
            }
          />
          <View style={{ marginBottom: -spacing20 }}>
            <Input
              name="additionalInformation"
              key="additionalInformation"
              label="T00622"
              control={control as unknown as Control}
              errors={errors?.additional_information}
              multiline
              scrollEnabled={false}
              maxLength={
                isGymsy
                  ? SHORT_INPUT_MAX_LENGTH
                  : MULTILINE_INPUT_MAX_LENGTH_SHORT
              }
              autoCapitalize="sentences"
            />
          </View>
          <View style={{ marginBottom: -spacing20 }}>
            <Input
              name="companyName"
              key="companyName"
              label="T01599"
              control={control as unknown as Control}
              errors={errors?.company_name}
              maxLength={INPUT_MAX_LENGTH_50_CHARS}
              autoCapitalize="sentences"
            />
          </View>
          <Input
            name="postalCode"
            key="postalCode"
            label="T00623"
            control={control as unknown as Control}
            errors={errors?.postalCode}
            isRequired
          />

          <Input
            name="town"
            key="town"
            label="T00624"
            control={control as unknown as Control}
            errors={errors?.town}
            autoCapitalize="sentences"
            isRequired
          />
        </View>
      </KeyboardAwareScrollView>
      {displayBlurredFooter && (
        <AbsoluteBlurredFooter
          title="T00164"
          onPress={handleSubmit(onSubmit)}
          onLayout={height => setFooterHeight(height)}
          buttonLoading={isLoading}
          buttonDisabled={isLoading}
        />
      )}
    </>
  );
};

export default VerifyAddress;
