import { FC, PropsWithChildren, useCallback, useRef } from "react";
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MessageTile from "../../components/Tile/MessageTile";

import MessageTileWithReport from "@components/Tile/MessageTileWithReportWrapper";
import { useAuth } from "@contexts/AuthContext/auth";
import { spacing16 } from "@styles/spacing";
import { MessageType as MessageTileType } from "../../components/Tile/types/MessageTile.types";
import {
  ConversationMessage,
  ConversationsMessagesResponse,
} from "./Conversation/conversation.types";

type Props = {
  data: ConversationsMessagesResponse;
  style?: StyleProp<ViewStyle>;
  withReport?: boolean;
  disableExtraText?: boolean;
};

const ConversationMessages: FC<PropsWithChildren<Props>> = ({
  data,
  style,
  withReport = false,
  disableExtraText,
}) => {
  const messagesListRef = useRef<FlatList>(null);
  const {
    user: { id: userId },
  } = useAuth();

  const { container } = styles;

  const renderMessage = useCallback(
    ({
      item: { message, attachments, id, sender, createdAt, readAt },
      index,
    }: {
      item: ConversationMessage;
      index: number;
    }) => {
      let messageType: MessageTileType;
      let isReportableMessage = false;
      switch (sender) {
        case userId:
          messageType = MessageTileType.REPORTER;
          break;
        default:
          messageType = MessageTileType.INTERLOCUTOR;
          isReportableMessage = true;
          break;
      }

      const messageTileCommonProps = {
        key: `conversation-message-${id} -${index}`,
        content: message,
        attachments,
        type: messageType,
        date: createdAt,
        index,
        readAt,
        disableExtraText,
        sender,
      };

      return isReportableMessage && withReport ? (
        <MessageTileWithReport
          {...messageTileCommonProps}
          withReport={isReportableMessage && withReport}
          messageId={id}
        />
      ) : (
        <MessageTile {...messageTileCommonProps} />
      );
    },
    [disableExtraText, withReport, userId],
  );

  const messages = data?.messages;

  return (
    <View style={[container, style]}>
      <FlatList
        data={messages}
        ref={messagesListRef}
        renderItem={renderMessage}
        keyExtractor={(item: ConversationMessage, index: number) =>
          `conversation-message-key-${item.id} -${index}`
        }
        contentContainerStyle={{ paddingHorizontal: spacing16 }}
        inverted
      />
    </View>
  );
};

export default ConversationMessages;

const styles = StyleSheet.create({
  container: { flex: 1, width: "100%" },
});
