import AddressWithMapRedirect from "@components/Address/AddressWithWebviewRedirect";
import { PickerItem } from "@components/DropdownPicker/DropdownPicker";
import PhysioLanguageDropdownPicker from "@components/DropdownPicker/PhysioLanguageDropdownPicker";
import ServiceDropdownPicker, {
  ServiceDropdownPickerProps,
} from "@components/DropdownPicker/ServiceDropdownPicker";
import EmptyHoursInformation from "@components/PhysioAvailability/EmptyHoursInformation";
import PhysioAvailabilityHoursSection from "@components/PhysioAvailability/PhysioAvailabilityHoursSection";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing8 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { isANDROID } from "@utils/constants";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { Divider, SegmentedButtons } from "react-native-paper";
import { OnHourButtonPress } from "./appointment.types";

type Props = {
  physiotherapistId: number;
  serviceType: AppointmentSlots;
  onHourPress: (args: OnHourButtonPress) => void;
  hidePhysioTile?: boolean;
  onSegmentedButtonChange?: (value: AppointmentSlots) => void;
  initialServiceId?: number;
};

export const PhysiotherapistAppointmentCalendar: FC<Props> = ({
  physiotherapistId,
  serviceType: paramServiceType,
  onHourPress,
  hidePhysioTile,
  onSegmentedButtonChange,
  initialServiceId,
}) => {
  const { t } = useTranslation();
  const [serviceType, setServiceType] = useState<AppointmentSlots>(
    paramServiceType &&
      paramServiceType !== AppointmentSlots.ONLINE_OR_STATIONARY
      ? paramServiceType
      : AppointmentSlots.STATIONARY,
  );
  const [stationaryId, setStationaryId] = useState<number>(null);
  const [onlineId, setOnlineId] = useState<number>(null);
  const [selectedItem, setSelectedItem] = useState<PickerItem>(null);
  const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false);
  const [dropdownServiceOpen, setDropdownServiceOpen] = useState(false);
  const {
    colors: { tertiaryContainer },
  } = useAppTheme();

  const isStationary = serviceType === AppointmentSlots.STATIONARY;

  const serviceId = useMemo(
    () => (isStationary ? stationaryId : onlineId),
    [isStationary, onlineId, stationaryId],
  );

  const buttons = useMemo(
    () => [
      {
        value: AppointmentSlots.STATIONARY,
        label: `${t("T00484")}`,
        showSelectedCheck: true,
      },
      {
        value: AppointmentSlots.ONLINE,
        label: `${t("T00483")}`,
        showSelectedCheck: true,
        style: serviceType === AppointmentSlots.ONLINE && {
          backgroundColor: palettes.tertiary[90],
        },
      },
    ],
    [serviceType, t],
  );
  const { gapLarge, container, gapMedium } = globalStyles;

  const commonServicePickerProps: Omit<
    ServiceDropdownPickerProps,
    "selectedServiceType" | "onValueChange"
  > = {
    physioId: physiotherapistId,
    onOpen: () => {
      setDropdownLanguageOpen(false);
      setDropdownServiceOpen(true);
    },
    onClose: () => setDropdownServiceOpen(false),
    isDropdownOpen: dropdownServiceOpen,
    multiDropdownContent: true,
    changeLabelZIndex: isANDROID && dropdownLanguageOpen,
  };

  return (
    <View style={[gapLarge, container]}>
      {!hidePhysioTile && (
        <PhysiotherapistCardWithRating
          initialData={{ id: physiotherapistId }}
        />
      )}
      <ScrollView
        contentContainerStyle={[gapLarge, { paddingTop: spacing8 }]}
        showsVerticalScrollIndicator={false}>
        <View style={{ zIndex: 101 }}>
          <PhysioLanguageDropdownPicker
            physioId={physiotherapistId}
            onSelectItem={setSelectedItem}
            onOpen={() => {
              setDropdownServiceOpen(false);
              setDropdownLanguageOpen(true);
            }}
            onClose={() => setDropdownLanguageOpen(false)}
            isDropdownOpen={dropdownLanguageOpen}
            multiDropdownContent
            itemBackgroundColor={!isStationary && tertiaryContainer}
          />
        </View>
        <View style={gapMedium}>
          <SegmentedButtons
            buttons={buttons}
            value={serviceType as string}
            onValueChange={(value: AppointmentSlots) => {
              onSegmentedButtonChange
                ? onSegmentedButtonChange(value)
                : setServiceType(value);
            }}
          />
          {isStationary && (
            <AddressWithMapRedirect
              physioId={physiotherapistId}
              style={{ paddingStart: spacing12 }}
            />
          )}
        </View>
        <ServiceDropdownPicker
          onValueChange={(value: string) => setOnlineId(+value)}
          selectedServiceType="Online"
          containerStyles={isStationary && styles.hiddenDropdown}
          itemBackgroundColor={tertiaryContainer}
          defaultValue={
            initialServiceId &&
            paramServiceType === AppointmentSlots.ONLINE &&
            `${initialServiceId}`
          }
          {...commonServicePickerProps}
        />
        <ServiceDropdownPicker
          onValueChange={(value: string) => setStationaryId(+value)}
          selectedServiceType="Stationary"
          containerStyles={!isStationary && styles.hiddenDropdown}
          defaultValue={
            initialServiceId &&
            paramServiceType === AppointmentSlots.STATIONARY &&
            `${initialServiceId}`
          }
          {...commonServicePickerProps}
        />

        <Divider />
        {!serviceId ? (
          <EmptyHoursInformation />
        ) : (
          <PhysioAvailabilityHoursSection
            physiotherapistId={physiotherapistId}
            serviceId={serviceId}
            serviceType={serviceType}
            onHourPress={(dateFrom: string) =>
              onHourPress({ dateFrom, serviceType, serviceId, selectedItem })
            }
          />
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  hiddenDropdown: {
    height: 0,
    overflow: "hidden",
  },
});
