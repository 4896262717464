import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { FlatList, View } from "react-native";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ActivityIndicator } from "react-native-paper";
import { useTranslation } from "react-i18next";

import { FetchError } from "@components/errors";
import AchievementComponent from "./AchievementComponent";
import { Logo } from "@components/Logo";

import { RootStackParamList } from "@navigators/navigation.types";
import {
  getAchievements,
  getUserAchievements,
} from "@services/ApiService/users";
import { useErrors } from "@hooks/useErrors";
import { globalStyles } from "@styles/global";
import {
  queryKeysAchievements,
  queryKeysUserAchievements,
} from "./queryKeysAchievements";

export type AchievementType = {
  id: number;
  isCompleted: boolean;
};

const AchievementsScreen: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "AchievementsScreen">
  >
> = () => {
  const [error, setError] = useState(false);
  const { setErrorsFromResponse } = useErrors();
  const { t } = useTranslation();
  const { loading, container, gapLarge } = globalStyles;

  const {
    data: achievementsList,
    isLoading: achievementsLoading,
    isError: achievementsError,
    refetch: achievementsRefetch,
  } = useQuery({
    queryKey: queryKeysAchievements.list(),
    queryFn: getAchievements,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const {
    data: userAchievementsList,
    isLoading: userAchievementsListLoading,
    isError: userAchievementsListError,
    refetch: userAchievementsListRefetch,
  } = useQuery({
    queryKey: queryKeysUserAchievements.list(),
    queryFn: getUserAchievements,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const resultAchievements = achievementsList?.map(({ id }) => {
    const isCompleted = userAchievementsList?.some(
      ({ achievement }) => achievement === id,
    );

    return {
      id,
      isCompleted,
    };
  });

  const retryQueries = async () => {
    await achievementsRefetch();
    await userAchievementsListRefetch();
    setError(false);
  };

  const renderRow = useCallback(
    ({ index }: { item: AchievementType; index: number }) => {
      if (index % 3 !== 0) {
        return null;
      }

      const rowData = resultAchievements?.slice(index, index + 3);

      return (
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {rowData?.map((item, index) => (
            <AchievementComponent
              key={`achievementDetail-${item.id}-${index}`}
              data={item}
              setError={setError}
            />
          ))}
        </View>
      );
    },
    [resultAchievements],
  );

  const isSomeError = useMemo(
    () => achievementsError || userAchievementsListError || error,
    [achievementsError, userAchievementsListError, error],
  );

  if (achievementsLoading || userAchievementsListLoading)
    return <ActivityIndicator style={loading} />;
  if (isSomeError) return <FetchError action={retryQueries} />;

  return (
    <View style={[container, gapLarge]}>
      <Logo text={t("T00404")} />
      <FlatList
        data={resultAchievements}
        renderItem={renderRow}
        keyExtractor={(item, index) => `achievement-${item.id}-${index}`}
      />
    </View>
  );
};

export default AchievementsScreen;
