import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { format } from "date-fns";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Calendar, DateArrayType } from "@components/Calendar/Calendar";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { AvailabilityBottomSheetContent } from "@screens/Calendar/Availability";
import AvailabilityList from "@screens/Calendar/AvailabilityList";

import { useAuth } from "@contexts/AuthContext/auth";
import { usePhysiotherapistAvailability } from "@hooks/availability/useFutureAvailability";
import { useErrors } from "@hooks/useErrors";
import { CalendarStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { deleteAllAvailabilityHoursFromSelectedDays } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import {
  formatDateForApi,
  getDatesWithAvailability,
  getDays,
} from "@utils/date";
import { showSnackbar } from "@utils/snackbarHelper";
import { useTranslation } from "react-i18next";
import { queryKeysPhysiotherapist } from "../Appointments/queryKeysAppointments";
import AddHoursBottomSheetContent from "./Availability/AddHoursBottomSheetContent";
import { DeleteAllHoursBottomSheetContent } from "./Availability/DeleteAllHoursBottomSheetContent";

export const AvailabilityHours: FC<
  PropsWithChildren<
    NativeStackScreenProps<CalendarStackParamList, "AvailabilityHours">
  >
> = ({
  route: {
    params: { initialDate },
  },
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<DateArrayType>({
    date: initialDate,
  });
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);
  const [firstDayOfCurrentWeek, setFirstDayOfCurrentWeek] =
    useState<Date>(initialDate);
  const { date } = selectedDate;
  const {
    user: { id },
  } = useAuth();
  const { setErrorsFromResponse } = useErrors();

  const disableCalendarsModal = useCallback(
    () => setCalendarModalVisible(false),
    [],
  );

  const { container, gapLarge } = globalStyles;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { startOfWeekDate: dateFrom, endOfWeekDate: dateTo } = getDays(
    firstDayOfCurrentWeek,
  );
  const { startOfDayDate: today, endOfTwoMonthsAheadDate } = getDays(
    new Date(),
  );

  const {
    data: availabilityPerWeek,
    isError: isAvailabilityPerWeekError,
    refetch: refetchAvailabilityPerWeek,
  } = usePhysiotherapistAvailability(
    queryKeysPhysiotherapist.availabilityPerWeek({
      physiotherapistId: id,
      dateFrom,
      dateTo,
    }),
    { dateFrom, dateTo, physiotherapistId: id },
    !!(dateFrom && dateTo),
  );

  const { isLoading: isDeleteHoursLoading, mutate } = useMutation({
    mutationFn: async (dates: string[]) =>
      await deleteAllAvailabilityHoursFromSelectedDays(dates),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async ({ data: { detail: alertMessage } }: AxiosResponse) => {
      await queryClient.invalidateQueries(
        queryKeysPhysiotherapist.availability(id),
      );
      setDeleteModalVisible(false);
      alertMessage
        ? alert(alertMessage)
        : showSnackbar({
            message: t("T01519"),
          });
    },
  });
  const {
    data: availabilityForNextTwoMonthsAhead,
    isError: isAvailabilityForNextTwoMonthsAheadError,
    refetch: refetchAvailabilityForNextTwoMonthsAhead,
  } = usePhysiotherapistAvailability(
    queryKeysPhysiotherapist.availabilityForNextTwoMonthsAhead({
      physiotherapistId: id,
      dateFrom: today,
    }),
    { dateFrom, physiotherapistId: id, dateTo: endOfTwoMonthsAheadDate },
  );

  const datesWithEventsPerWeek = getDatesWithAvailability(availabilityPerWeek);
  const datesWithAvailabilityForNextTwoMonthsAhead = getDatesWithAvailability(
    availabilityForNextTwoMonthsAhead,
  );

  const shouldDisableDeleteAllHoursButton =
    !availabilityPerWeek?.availableAppointments?.find(
      e => e.date === format(date, "yyyy-MM-dd"),
    );

  const onDeleteHoursForSingleDayBtnPress = useCallback(() => {
    mutate([formatDateForApi(date)]);
  }, [date, mutate]);

  const onDeleteHoursForMultipleDaysBtnPress = useCallback(() => {
    setDeleteModalVisible(false);
    setTimeout(() => setCalendarModalVisible(true), 700);
  }, []);

  const onSubmitDeleteHoursForSelectedDays = useCallback(
    (days: DateArrayType[]) => {
      mutate(days.map(({ date }) => formatDateForApi(date)));
      disableCalendarsModal();
    },
    [mutate, disableCalendarsModal],
  );

  const refetchAllAvailability = useCallback(
    async () =>
      await Promise.all([
        refetchAvailabilityPerWeek(),
        refetchAvailabilityForNextTwoMonthsAhead(),
      ]),
    [refetchAvailabilityForNextTwoMonthsAhead, refetchAvailabilityPerWeek],
  );

  if (isAvailabilityPerWeekError || isAvailabilityForNextTwoMonthsAheadError)
    return <FetchError action={refetchAllAvailability} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "left"]}>
      <ScrollView
        style={container}
        contentContainerStyle={[gapLarge, { paddingBottom: spacing32 }]}>
        <View>
          <Calendar
            type="week"
            initialDates={[selectedDate]}
            datesWithEvents={datesWithEventsPerWeek}
            onSelectDate={setSelectedDate}
            blockPast
            yearsOption="future"
            onWeekChange={setFirstDayOfCurrentWeek}
          />
        </View>
        <AvailabilityList date={date} initialDateForHourPicker={selectedDate} />

        <PrimaryButton
          label="T00486"
          onPress={() => setDeleteModalVisible(true)}
          mode="text"
          disabled={shouldDisableDeleteAllHoursButton || isDeleteHoursLoading}
          loading={isDeleteHoursLoading}
        />
        <PrimaryButton
          label="T00480"
          onPress={() => setModalVisible(true)}
          mode="outlined"
        />
        <PrimaryButton
          label="T00902"
          onPress={() => setAddModalVisible(true)}
        />
        <BottomModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          scrollableContent>
          <AvailabilityBottomSheetContent
            initialDate={selectedDate}
            setParentModalVisible={setModalVisible}
            userId={id}
            scrollableContent
          />
        </BottomModalContainer>
        <BottomModalContainer
          modalVisible={deleteModalVisible}
          setModalVisible={setDeleteModalVisible}>
          <DeleteAllHoursBottomSheetContent
            onDeleteHoursForSingleDayBtnPress={
              onDeleteHoursForSingleDayBtnPress
            }
            onDeleteHoursForMultipleDaysBtnPress={
              onDeleteHoursForMultipleDaysBtnPress
            }
            buttonDisabled={shouldDisableDeleteAllHoursButton}
            selectedDate={date}
            loading={isDeleteHoursLoading}
          />
        </BottomModalContainer>
        <BottomModalContainer
          modalVisible={addModalVisible}
          setModalVisible={setAddModalVisible}>
          <AddHoursBottomSheetContent
            isVisible={addModalVisible}
            setVisible={setAddModalVisible}
            initialDate={selectedDate}
          />
        </BottomModalContainer>
        <BottomModalContainer
          modalVisible={calendarModalVisible}
          setModalVisible={setCalendarModalVisible}>
          <Calendar
            multiselect
            initialDates={[selectedDate]}
            datesWithEvents={datesWithAvailabilityForNextTwoMonthsAhead}
            onDismiss={disableCalendarsModal}
            onSubmit={onSubmitDeleteHoursForSelectedDays}
            blockPast
            enableOnPressOnlyWithEvents
          />
        </BottomModalContainer>
      </ScrollView>
    </SafeAreaView>
  );
};
