import { FC, PropsWithChildren, useCallback, useState } from "react";
import { PatientsListModal } from "@components/Modals/ModalComponents";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { HeaderWithSearchbar } from "@components/HeaderWithSearchbar";
import { SafeAreaView } from "react-native-safe-area-context";

type PatientsListProps = NativeStackScreenProps<RootStackParamList>;

const PatientsList: FC<PropsWithChildren<PatientsListProps>> = ({
  navigation: { navigate, goBack },
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const clearInput = () => setSearchQuery("");

  const handleTextChange = (text: string) => {
    setSearchQuery(text);
    if (text.trim().length < 3) {
      setSearchQuery("");
    }
  };

  const handleNavigate = useCallback(
    (patientId: number) => {
      navigate({
        name: "TrainingsAndExercisesModal",
        params: { patientId, resetProcess: true },
      });
    },
    [navigate],
  );
  return (
    <SafeAreaView
      style={{ flex: 1 }}
      edges={["right", "bottom", "left", "top"]}>
      <HeaderWithSearchbar
        onClear={clearInput}
        onChangeText={handleTextChange}
        goBack={goBack}
        searchQuery={searchQuery}
        enableSearching
      />
      <PatientsListModal
        searchQuery={searchQuery}
        onItemPress={handleNavigate}
      />
    </SafeAreaView>
  );
};

export default PatientsList;
