import { FC, useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { IconButton, SegmentedButtons } from "react-native-paper";

import { GenderType } from "@contexts/AuthContext/user.types";
import { globalStyles } from "@styles/global";

import {
  ModelType,
  Orientation,
} from "@components/HumanModel/humanModel.types";

import { PrimaryButton } from "@components/buttons";
import BoneModel from "@components/HumanModel/Bone";
import GeneralModel from "@components/HumanModel/General";
import { styles } from "@components/HumanModel/humanModel.styles";
import MuscleModel from "@components/HumanModel/Muscle";
import { HumanModelType } from "@globalTypes/common.types";
import { useHumanModelButtons } from "@hooks/humanModel/useHumanModelButtons";
import { useInitialParametersForHumanModel } from "@hooks/index";
import { spacing40 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { useTranslation } from "react-i18next";

const HumanModel: FC<Required<HumanModelType>> = ({
  gender,
  humanBones,
  humanMuscles,
  humanGeneral,
}) => {
  const { modelType: initialModelType, orientation: initialOrientation } =
    useInitialParametersForHumanModel({
      general: humanGeneral,
      muscle: humanMuscles,
      bone: humanBones,
    });
  const [orientation, setOrientation] =
    useState<Orientation>(initialOrientation);
  const [modelType, setModelType] = useState<ModelType>(initialModelType);
  const [selectedGender, setSelectedGender] = useState(gender);
  const { t } = useTranslation();
  const {
    colors: { secondaryContainer, outline, onSecondaryContainer },
  } = useAppTheme();

  const { modelTypeButtons, genderButtons } = useHumanModelButtons({
    humanBones,
    humanGeneral,
    humanMuscles,
  });

  useEffect(() => {
    setOrientation(initialOrientation);
    setModelType(initialModelType);
  }, [initialModelType, initialOrientation]);

  const renderModel = useCallback(
    (modelType: ModelType) => {
      const commonProps = {
        orientation,
        onChange: () => null,
        gender: selectedGender,
        readOnly: true,
      };
      switch (modelType) {
        case ModelType.MUSCLE:
          return (
            <MuscleModel defaultSelected={humanMuscles} {...commonProps} />
          );
        case ModelType.BONE:
          return <BoneModel defaultSelected={humanBones} {...commonProps} />;
        default:
          return (
            <GeneralModel defaultSelected={humanGeneral} {...commonProps} />
          );
      }
    },
    [orientation, selectedGender, humanMuscles, humanBones, humanGeneral],
  );
  const { gapLarge } = globalStyles;
  const { iconButton, container } = styles;
  return (
    <View style={[gapLarge, { position: "relative" }]}>
      <SegmentedButtons
        value={selectedGender}
        onValueChange={(g: GenderType) => setSelectedGender(g)}
        buttons={genderButtons}
      />
      {modelTypeButtons.length > 1 ? (
        <SegmentedButtons
          value={modelType}
          onValueChange={(v: ModelType) => setModelType(v)}
          buttons={modelTypeButtons}
        />
      ) : (
        <PrimaryButton
          label={`${t("T01598")} - ${humanMuscles?.length || 0}`}
          style={{
            backgroundColor: secondaryContainer,
            borderWidth: 1,
            borderColor: outline,
          }}
          textColor={onSecondaryContainer}
        />
      )}
      <View style={container}>{renderModel(modelType)}</View>
      <IconButton
        icon="cached"
        size={spacing40}
        style={iconButton}
        onPress={() =>
          setOrientation(orientation === "front" ? "back" : "front")
        }
      />
    </View>
  );
};

export default HumanModel;
