import { TransKey } from "@globalTypes/i18next";
import {
  VerificationStatus,
  VerificationStatusType,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { TransactionStatus } from "@screens/Profiles/Transactions/transaction.types";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing4, spacing8 } from "@styles/spacing";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, ViewProps } from "react-native";
import { Text } from "react-native-paper";
import {
  getStatusIconAndText,
  getStatusIconAndTextForInvitedUsers,
  getStatusIconAndTextForPatientProfileCompletion,
} from "./helpers";
import { StatusType, TrainingDayStatus } from "./status.types";

type StatusComponentProps = {
  status:
    | StatusType
    | VerificationStatus
    | TransactionStatus
    | TrainingDayStatus
    | null;
  type?: VerificationStatusType | "invitedUser";
} & ViewProps;

const StatusComponent: FC<StatusComponentProps> = ({
  status,
  type,
  ...props
}) => {
  const { icon, text } =
    type === "profile completion"
      ? getStatusIconAndTextForPatientProfileCompletion(
          status as VerificationStatus | null,
        )
      : type === "invitedUser"
      ? getStatusIconAndTextForInvitedUsers(status as VerificationStatus | null)
      : getStatusIconAndText(status);
  const { t } = useTranslation();
  const { container, notPaid } = styles;
  return (
    <View
      style={[
        container,
        globalStyles.gapSmall,
        status === StatusType.NOT_PAID && notPaid,
      ]}
      {...props}>
      {icon}
      <Text variant="bodyMedium">{t(text as TransKey)}</Text>
    </View>
  );
};

export default StatusComponent;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  notPaid: {
    backgroundColor: palettes.primary[100],
    borderRadius: spacing12,
    paddingHorizontal: spacing8,
    paddingVertical: spacing4,
  },
});
