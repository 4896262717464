import { StyleSheet } from "react-native";
import { palettes } from "../../../styles/colors";
import { theme } from "../../../styles/theme";
import { StatusInfoType } from "../../Status/status.types";
import { flags } from "@utils/flags";
import { ProductName } from "@globalTypes/common.types";

export const getIconColorByStatus = (status: StatusInfoType) => {
  if (!status) return null;
  switch (status) {
    case "info":
      return theme.colors.primary;
    case "error":
      return theme.colors.error;
    case "warning":
      return theme.colors.warning;
    case "success":
      return palettes.complete[10];
  }
};

export const getTileStyleByStatus = (status: StatusInfoType) => {
  if (!status) return null;
  switch (status) {
    case "info":
      return flags.PRODUCT_NAME === ProductName.GYMSY
        ? styles.infoStyleAlt
        : styles.infoStyle;
    case "error":
      return styles.errorStyle;
    case "warning":
      return styles.warningStyle;
    case "success":
      return styles.successStyle;
  }
};

const styles = StyleSheet.create({
  infoStyle: {
    borderColor: theme.colors.primary,
    backgroundColor: palettes.primary[90],
  },
  infoStyleAlt: {
    borderColor: theme.colors.blue,
    backgroundColor: theme.colors.infoBlue,
  },
  warningStyle: {
    borderColor: theme.colors.warning,
    backgroundColor: palettes.warning[90],
  },
  errorStyle: {
    borderColor: theme.colors.error,
    backgroundColor: palettes.error[90],
  },
  successStyle: {
    borderColor: palettes.complete[10],
    backgroundColor: palettes.complete[5],
  },
});
