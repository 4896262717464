import { useCallback, useEffect } from "react";
import useApiHealthCheck from "./useApiHealthCheck";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UPDATE_ALERT_KEY } from "./healthCheckHelpers";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { useTranslation } from "react-i18next";
import { Linking } from "react-native";
import { appDownloadUrl } from "@utils/constants";

export const updateApp = () => Linking.openURL(appDownloadUrl);

const is7DaysSinceLastAppUpdateAlert = async () => {
  const lastAlertTime = await AsyncStorage.getItem(UPDATE_ALERT_KEY);
  const currentTime = new Date().getTime();
  return (
    !lastAlertTime ||
    currentTime - new Date(lastAlertTime).getTime() > 1000 * 60 * 60 * 24 * 7
  );
};

export const useUpdateAppAlert = () => {
  const { t } = useTranslation();
  const { canBeUpdated } = useApiHealthCheck();

  const updateAppAlert = useCallback(() => {
    showAlertWithCustomButtons({
      title: `🚀 ${t("T01546")}`,
      message: t("T01544"),
      cancelButton: { text: t("T00145") },
      confirmButton: {
        text: t("T01549"),
        onPress: updateApp,
      },
    });
  }, [t]);

  const cb = useCallback(async () => {
    const showAlert = await is7DaysSinceLastAppUpdateAlert();
    if (showAlert && canBeUpdated) {
      await AsyncStorage.setItem(UPDATE_ALERT_KEY, `${new Date().getTime()}`);
      updateAppAlert();
    }
  }, [canBeUpdated, updateAppAlert]);

  useEffect(() => {
    const interval = setInterval(cb, 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, [cb]);
};
