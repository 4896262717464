import { addMonths } from "date-fns";
import { getDate } from "@utils/date";
import { useUserDetails } from "@hooks/user/useUserDetails";

export const useRewardRedeemDates = () => {
  const { createdAt } = useUserDetails();

  const receivingRewardEndDate = getDate(addMonths(createdAt, 5));
  const countingEndDate = getDate(addMonths(createdAt, 3));

  return { receivingRewardEndDate, countingEndDate };
};
