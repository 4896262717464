import { getPatientBasicData } from "@api/users";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { SearchModal } from "@components/Modals";
import { FetchError } from "@components/errors";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useAuth } from "@contexts/AuthContext/auth";
import { useInvalidateProfileDetails } from "@hooks/index";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators//navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  FiltersSearchSection,
  LanguagesModal,
} from "@screens/Appointments/FiltersComponents";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { queryKeysLanguages } from "@screens/Common/queryKeysLanguages";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { Choice } from "@services/ApiService/api.types";
import { getPhysiotherapistFullData } from "@services/ApiService/users";
import {
  deleteLanguage,
  setLanguage,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";

type LanguagesScreenType = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "Languages">,
    NativeStackScreenProps<ProfileStackParamsList, "Profile">
  >
>;

const Languages: FC<LanguagesScreenType> = ({
  navigation: { navigate, setOptions },
}) => {
  const {
    user: { id },
  } = useAuth();
  const { t } = useTranslation();
  const { isPatient, isPhysiotherapist } = useUserDetails();
  const { scrollContainer, gapLarge } = globalStyles;
  const { setErrorsFromResponse: setErrorsFromSetResponse } = useErrors();
  const { setErrorsFromResponse: setErrorsFromDeleteResponse } = useErrors();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedLanguages, setSelectedLanguages] = useState<Choice[]>([]);
  const queryClient = useQueryClient();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const dataQueryKey = () => {
    return isPhysiotherapist
      ? queryKeysPhysiotherapistProfileData.full(id)
      : queryKeysForPatientAndPhysiotherapist.basicData(id);
  };

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    isSuccess: isUserDataSuccess,
    refetch: refetchUserData,
  } = useQuery({
    queryKey: dataQueryKey(),
    queryFn: async () =>
      isPhysiotherapist
        ? await getPhysiotherapistFullData(id)
        : await getPatientBasicData(id),
  });

  const { mutate: setNewLanguage, isLoading: isSettingLoading } = useMutation({
    mutationFn: setLanguage,
    onError: ({ response }: AxiosError) => setErrorsFromSetResponse(response),
    onSuccess: async () => {
      await Promise.all([
        invalidateProfileDetails(),
        queryClient.invalidateQueries({
          queryKey: queryKeysLanguages.list(),
        }),
      ]);
      showProfileVerificationSnackbar(t("T01311"));
    },
  });

  const {
    mutate: deleteSelectedLanguage,
    isLoading: isDeleteSelectedLanguageLoading,
  } = useMutation({
    mutationFn: deleteLanguage,
    onError: ({ response }: AxiosError) =>
      setErrorsFromDeleteResponse(response),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataQueryKey(),
      });
      showProfileVerificationSnackbar(t("T01311"));
    },
  });

  useEffect(() => {
    if (isUserDataSuccess) {
      const mySelectedLanguages = userData.spokenLanguages?.map(
        ({ language, languageFull }) => {
          return { value: language, displayName: languageFull };
        },
      );
      setSelectedLanguages(mySelectedLanguages);
    }
  }, [isUserDataSuccess, userData]);

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack, popToTop, pop } }) => (
        <AppbarTitleWithBackAction
          onBack={goBack}
          onClose={() => {
            popToTop();
            pop();
          }}
          title={t("T00604")}
          filledIcon={!!userData?.spokenLanguages?.length}
        />
      ),
    });
  }, [setOptions, t, navigate, userData?.spokenLanguages?.length]);

  if (isUserDataError) return <FetchError action={refetchUserData} />;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <Text variant="bodyMedium">{t(isPatient ? "T00627" : "T00614")}</Text>
      {isPhysiotherapist && <Text variant="bodyMedium">{t("T00615")}</Text>}
      <FiltersSearchSection
        items={userData?.spokenLanguages?.map(({ languageFull, id }) => ({
          displayName: languageFull,
          value: id,
        }))}
        onDelete={item => deleteSelectedLanguage(+item)}
        onPress={() => setIsModalVisible(prevState => !prevState)}
        searchLabel="T00616"
        header="T01040"
        emptyResult="T01041"
        rowDirection
        isLoading={isUserDataLoading || isDeleteSelectedLanguageLoading}
      />
      <SearchModal<Choice>
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedItems={selectedLanguages}
        setSelectedItems={setSelectedLanguages}
        ListComponent={LanguagesModal}
        returnLabel={value =>
          t("T00635", {
            value,
          })
        }
        isLoading={isSettingLoading}
        mutatePickedItems={setNewLanguage}
        savedItems={userData?.spokenLanguages?.map(e => e.language)}
        enableSearching
        footerHeightAndroidAPI35OrHigher={150}
      />
    </ScrollView>
  );
};

export default Languages;
