import { FC, PropsWithChildren, useCallback, useState } from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Logo } from "@components/Logo";
import { ExpectItem } from "./ExpectItem";

import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { Checkbox, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { SHOULD_SHOW_WHAT_CAN_YOU_EXPECT } from "@hooks/useGetShowWhatCanYouExpectStatus";

export const WhatCanYouExpect: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "WhatCanYouExpect">
  >
> = ({ navigation: { replace } }) => {
  const [value, setValue] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);

  const { t } = useTranslation();

  const { logo, items, checkboxContainer } = styles;
  const { scrollContainer, gapMedium } = globalStyles;

  const onPress = useCallback(async () => {
    try {
      if (value)
        await AsyncStorage.setItem(SHOULD_SHOW_WHAT_CAN_YOU_EXPECT, `${value}`);
    } catch (e) {
      console.error(e);
    }

    replace("Physiotherapists");
  }, [replace, value]);

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          { paddingBottom: footerHeight },
        ]}>
        <View style={logo}>
          <Logo />
        </View>
        <View style={items}>
          <ExpectItem
            icon="calendar"
            title="T00464"
            subtitles={["T00465", "T00466"]}
          />
          <ExpectItem
            icon="hail"
            title="T00479"
            subtitles={["T00470", "T00471"]}
          />
          <ExpectItem
            icon="karate"
            title="T00467"
            subtitles={["T00468", "T00469"]}
          />
          <View style={[checkboxContainer, gapMedium]}>
            <Checkbox.Android
              onPress={() => setValue(prev => !prev)}
              status={value ? "checked" : "unchecked"}
            />
            <Text variant="labelSmall">{t("T01315")}</Text>
          </View>
        </View>
      </ScrollView>
      <AbsoluteBlurredFooter
        title="T00472"
        onPress={onPress}
        onLayout={height => setFooterHeight(height)}
        absoluteBlurredFooterButtonTestID="continueToTherapistsListButton"
      />
    </>
  );
};

const styles = StyleSheet.create({
  logo: {
    justifyContent: "center",
    marginVertical: spacing32,
  },
  items: { flex: 1, justifyContent: "center" },
  checkboxContainer: { flexDirection: "row", alignItems: "center" },
});
