import { FetchError } from "@components/errors";
import { getInvitedTherapists } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing12, spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import ExtendedFrameContent from "./ExtendedFrameContent";
import RewardsInfoTexts from "./RewardsInfoTexts";
import SmallFrameContent from "./SmallFrameContent";
import { queryKeysTherapistsInvited } from "./queryKeysRewards";
import { VerificationStatus } from "../PhysiotherapistVerification/verification.types";

type InvitedTherapistInfoFrameParams = {
  onPress: () => void;
  extendedFrame?: boolean;
};

const InvitedTherapistInfoFrame = ({
  onPress,
  extendedFrame = false,
}: InvitedTherapistInfoFrameParams) => {
  const {
    colors: { primaryContainer },
  } = useAppTheme();
  const { gapLarge } = globalStyles;
  const { infoFrame } = styles;

  const {
    data: allTherapists,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: getInvitedTherapists,
    queryKey: queryKeysTherapistsInvited.list(),
  });

  const verifiedTherapists = allTherapists?.filter(
    ({ status }) => status === VerificationStatus.VERIFIED,
  ).length;

  return (
    <View style={gapLarge}>
      <View style={[infoFrame, { backgroundColor: primaryContainer }]}>
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : extendedFrame ? (
          <ExtendedFrameContent
            allTherapists={allTherapists.length}
            verifiedTherapists={verifiedTherapists}
            onPress={onPress}
          />
        ) : (
          <SmallFrameContent
            allTherapists={allTherapists.length}
            verifiedTherapists={verifiedTherapists}
            onPress={onPress}
          />
        )}
      </View>
      <RewardsInfoTexts extendedFrame={extendedFrame} />
    </View>
  );
};

const styles = StyleSheet.create({
  infoFrame: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: spacing16,
    paddingVertical: spacing12,
    borderRadius: spacing12,
  },
});

export default InvitedTherapistInfoFrame;
