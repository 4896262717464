import { RewardsStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import InvitedTherapistInfoFrame from "./InvitedTherapistInfoFrame";

import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import InvitedUserCard from "@components/cards/InvitedUserCard";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { getInvitedTherapists, getReward } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { showSnackbar } from "@utils/snackbarHelper";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, RefreshControl, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { InvitedTherapistData } from "./inviteToTheApp.types";
import {
  queryKeysRewards,
  queryKeysTherapistsInvited,
} from "./queryKeysRewards";
import RewardsTutorialComponent from "./RewardsTutorialComponent";
import TextSection from "./TextSection";
import { useRewardRedeemDates } from "@hooks/rewards/useRewardRedeemDates";

export const Rewards: FC<
  PropsWithChildren<NativeStackScreenProps<RewardsStackParamList, "Rewards">>
> = () => {
  const { t } = useTranslation();

  const [tutorialBottomSheetVisible, setTutorialBottomSheetVisible] =
    useState(false);
  const { setErrorsFromResponse } = useErrors();
  const { gapLarge, scrollContainer, loading } = globalStyles;
  const { countingEndDate } = useRewardRedeemDates();
  const queryClient = useQueryClient();

  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: getInvitedTherapists,
    queryKey: queryKeysTherapistsInvited.list(),
  });

  const renderItem: ListRenderItem<InvitedTherapistData> = useCallback(
    ({ item: { name, status } }) => (
      <InvitedUserCard name={name} status={status} />
    ),
    [],
  );

  const ListEmptyComponent = useMemo(
    () => (
      <Text variant="bodyMedium" style={{ textAlign: "center" }}>
        {t("T00327")}
      </Text>
    ),
    [t],
  );

  const { refetch: refetchGetReward } = useQuery({
    queryKey: queryKeysRewards.all,
    queryFn: getReward,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeysRewards.rewardStatus);
      showSnackbar({ message: t("T01575") });
    }, //don't use isSuccess - its true after refetch and will show snackbar when reentering screen
    enabled: false,
    retry: false,
  });

  const handleConfirmRewardButton = useCallback(
    () => refetchGetReward(),
    [refetchGetReward],
  );

  const showAlert = useCallback(
    () =>
      showAlertWithCustomButtons({
        title: t("T01572"),
        message: t("T01573"),
        confirmButton: {
          text: t("T01574"),
          onPress: handleConfirmRewardButton,
        },
        cancelButton: {
          text: t("T00145"),
        },
      }),
    [handleConfirmRewardButton, t],
  );

  const ListHeaderComponent = useMemo(() => {
    const remindingInfo = (
      <Text variant="labelMedium">
        <Trans
          i18nKey="T01568"
          values={{ countingEndDate }}
          components={{
            1: (
              <Text variant="labelMedium" style={globalStyles.fontBolded}>
                {countingEndDate}
              </Text>
            ),
          }}
        />
      </Text>
    );

    return (
      <View style={gapLarge}>
        <InvitedTherapistInfoFrame onPress={showAlert} extendedFrame />
        <TutorialTextButton
          label="T01571"
          onPress={() => setTutorialBottomSheetVisible(true)}
        />
        <TextSection
          title="T01566"
          subtitle="T01567"
          additionalText={remindingInfo}
          style={gapLarge}
        />
      </View>
    );
  }, [countingEndDate, gapLarge, showAlert]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["right", "left"]} style={{ flex: 1 }}>
      <FlatList
        data={data}
        renderItem={renderItem}
        ListHeaderComponent={ListHeaderComponent}
        ListEmptyComponent={ListEmptyComponent}
        keyExtractor={({ name }, i) => `${name}-${i}`}
        contentContainerStyle={[scrollContainer, gapLarge]}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
      />
      <BottomModalContainer
        modalVisible={tutorialBottomSheetVisible}
        setModalVisible={setTutorialBottomSheetVisible}
        scrollableContent
        disablePanHandlers>
        <RewardsTutorialComponent
          onPress={() => setTutorialBottomSheetVisible(false)}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};
