import { AppointmentSessionTabsParamList } from "@components/Appointment/types";
import BottomSheetCommitmentInfo from "@components/BottomSheet/BottomSheetCommitmentInfo";
import CommitmentTile from "@components/Tile/CommitmentTile";
import { PhysiotherapistCardWithRating, UserCard } from "@components/cards";
import { FetchError } from "@components/errors";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { useAuth } from "@contexts/AuthContext/auth";
import { AccountType } from "@contexts/AuthContext/user.types";
import { useAppointmentData } from "@hooks/index";
import { globalStyles } from "@styles/global";
import { UseQueryResult } from "@tanstack/react-query";
import { t } from "i18next";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { RefreshControl, ScrollView, View } from "react-native";
import { ActivityIndicator, Switch, Text } from "react-native-paper";
import InfoSection from "./InfoSection";

type SessionInformationProps = {
  isCommitmentActive: boolean;
  setIsCommitmentActive: Dispatch<SetStateAction<boolean>>;
  isCommitmentLoading: boolean;
  isCommitmentError: boolean;
  refetchError: UseQueryResult["refetch"];
};

const SessionInformation: FC<
  PropsWithChildren<
    Omit<AppointmentSessionTabsParamList, "navigation" | "route"> &
      SessionInformationProps
  >
> = ({
  isCommitmentActive,
  setIsCommitmentActive,
  isCommitmentLoading,
  isCommitmentError,
  refetchError,
}) => {
  const { data: contextData, updateData } = useContext(
    AppointmentSessionContext,
  );

  const {
    user: { accountType },
  } = useAuth();
  const { scrollContainer, gapLarge, loading } = globalStyles;
  const isPatient = accountType === AccountType.PATIENT;
  const [modalVisible, setModalVisible] = useState(false);

  const { data, isLoading, isError, refetch } = useAppointmentData(
    contextData?.appointmentId,
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  const { physiotherapist, patient } = data;

  return (
    <ScrollView
      contentContainerStyle={[gapLarge, scrollContainer]}
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={refetch} />
      }>
      <View style={gapLarge}>
        {isPatient ? (
          <>
            <Text variant="titleMedium">{t("T00060")}:</Text>
            <PhysiotherapistCardWithRating
              initialData={{ id: physiotherapist }}
              onPress={() =>
                updateData({
                  displayInFloatingView: true,
                  navigateTo: "PhysiotherapistProfile",
                  navigateData: { id: physiotherapist },
                })
              }
            />
          </>
        ) : (
          <>
            {isCommitmentLoading ? (
              <ActivityIndicator style={globalStyles.loading} />
            ) : isCommitmentError ? (
              <FetchError action={refetchError} coverScreen={false} />
            ) : (
              <CommitmentTile
                title="T00370"
                content="T00371"
                action={
                  <Switch
                    value={isCommitmentActive}
                    onValueChange={() => setIsCommitmentActive(prev => !prev)}
                  />
                }
                onButtonPress={() => setModalVisible(true)}
              />
            )}
            <Text variant="titleMedium">{t("T00275")}:</Text>
            <UserCard
              initialData={{ id: patient }}
              onPress={() => {
                updateData({
                  displayInFloatingView: true,
                  navigateTo: "PatientProfile",
                  navigateData: { id: patient },
                });
              }}
              mode="outlined"
            />
          </>
        )}
      </View>
      {!data.surveyOmitted && <InfoSection {...data} />}
      <BottomSheetCommitmentInfo
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </ScrollView>
  );
};

export default SessionInformation;
