import {
  CreateTrainingStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useContext, useEffect } from "react";
import SetASchedule from "./SetASchedule";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { useTranslation } from "react-i18next";
import { CreateTrainingContext } from "../Physiotherapist/createTraining.context";

type SetAScheduleScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      CreateTrainingStackParamsList,
      "SetAIndividualTrainingSchedule"
    >,
    NativeStackScreenProps<RootStackParamList, "PatientProfile">
  >
>;

const SetAIndividualTrainingSchedule: FC<SetAScheduleScreen> = ({
  route,
  navigation: { setOptions, pop: popParent, popToTop: popToTopParent },
}) => {
  const { t } = useTranslation();
  const { patientId } = route.params;
  const { popCount } = useContext(CreateTrainingContext);
  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack, popToTop, pop } }) => (
        <AppbarTitleWithBackAction
          title={t("T00072")}
          onBack={goBack}
          onClose={() => {
            popToTop();
            pop(popCount);
          }}
        />
      ),
    });
  }, [popCount, setOptions, t]);

  return (
    <SetASchedule
      patientId={patientId}
      trainingId={route.params?.trainingId}
      navigateAfterAssignTrainingToPatient={cb => {
        popToTopParent();
        popParent(popCount);
        cb();
      }}
      isNewTraining={route.params?.isNewTraining}
      editedTraining={route.params?.editedTraining}
    />
  );
};

export default SetAIndividualTrainingSchedule;
