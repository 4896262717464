import { FileAttachment } from "@globalTypes/common.types";
import { PostServiceType } from "./services.types";

export enum VerificationStatus {
  PENDING = "P",
  REJECTED = "R",
  VERIFIED = "V",
}

export type RehabVerificationProgressResponse = {
  progressValue: number;
  licenceVerification: VerificationStatus | null;
  addressVerification: VerificationStatus | null;
  languageVerification: VerificationStatus | null;
  servicesVerification: VerificationStatus | null;
  aboutMeVerification: VerificationStatus | null;
  profilePhotoVerification: VerificationStatus | null;
  certificatesVerification: VerificationStatus | null;
  educationVerification: VerificationStatus | null;
  experienceVerification: VerificationStatus | null;
  subscriptionVerification: boolean;
  stationaryPaymentVerification: boolean;
  onlinePaymentVerification: boolean;
  phoneNumberVerification: VerificationStatus | null;
};

export type PatientProgress = {
  progressValue: number;
  profileFulfilled: VerificationStatus | null;
  patientDetails: VerificationStatus | null;
  languageVerification: VerificationStatus | null;
  profilePhotoVerification: VerificationStatus | null;
};

export type BaseVerificationType = {
  verificationStatus: VerificationStatus;
  rejectionReason: string | null;
  createdAt: string;
};

type Location = {
  type: "Point";
  coordinates: [number, number]; // [longitude,latitude]
};

export type AddressType = {
  country: string;
  street: string;
  buildingNumber: string;
  apartmentNumber?: string;
  additionalInformation?: string;
  companyName?: string;
  postalCode: string;
  town: string;
  location: Location | null;
};

export type UserAddress = AddressType &
  BaseVerificationType & {
    id: number;
  };

export type GetAttachmentType = {
  file: string;
  name: string;
};

export type LicenceBase = {
  id: number;
  licenceNumber: string;
  specialization: { id: number; name: string };
};

export type GetLicence = BaseVerificationType &
  LicenceBase & {
    attachments: FileAttachment[];
  };

export type PostLicenceWithAttachmentResponse = BaseVerificationType &
  LicenceBase & {
    attachments: number[];
  };

export type PostLicenceBase = {
  licenceNumber: string;
  specialization: string;
};

export type LicenceForm = PostLicenceBase & {
  attachments: FileAttachment[];
};

export type PostLicenceWithAttachment = PostLicenceBase & {
  attachments: number[];
};

export type Education = BaseVerificationType & {
  id: number;
  description: string;
};

export type ProfileInformationDataType = BaseVerificationType & {
  id: number;
  description: string;
};

export type ExperienceApiType = BaseVerificationType & {
  id: number;
  years: number;
  additionalExperience: AdditionalExperienceType[];
};

export type ServiceDataType = Omit<BaseVerificationType, "rejectionReason"> & {
  id: number;
  name: string;
} & PostServiceType;

export type ServiceItem = {
  id: number;
  name: string;
  specializations: number[];
  freeService?: boolean;
};

export type CertificatesDataType = BaseVerificationType & {
  id: number;
  attachments: FileAttachment[];
};
export type UpdateCertificate = { id?: number; attachments: number[] };

export type CreateCertificateForm = {
  files: (FileAttachment & { certificateId?: number })[];
};

export type LanguageDataType = BaseVerificationType & {
  id: number;
  language: string;
};

export type VerificationStatusType = "profile completion";

type PaymentMethodsDetails = { id: number; user: number; isActive: boolean };

export type OnlinePaymentMethods = {
  stationaryAppointments?: boolean;
  onlineAppointments?: boolean;
};

export type OnlinePaymentMethodsResponse = Required<OnlinePaymentMethods> &
  Pick<PaymentMethodsDetails, "id" | "user">;

export enum StationaryPaymentMethod {
  blik = "blik",
  card = "card",
  phoneTransfer = "phoneTransfer",
  cash = "cash",
}

export type StationaryPaymentMethods = {
  blik: boolean;
  card: boolean;
  phoneTransfer: boolean;
  cash: boolean;
};

export type StationaryPaymentMethodsResponse = StationaryPaymentMethods &
  PaymentMethodsDetails;

export type AdditionalExperienceType = { id: number; description: string };

export type UpdateExperienceResponse = AdditionalExperienceType & {
  experience: number;
};

export type GetPhoneVerification = BaseVerificationType & {
  phoneNumber: string;
  id: number;
  physiotherapist: string;
  updatedAt: string;
};

export type ProfileCompletionActionsSkippedByUser = {
  certificates: boolean;
  onlinePayments: boolean;
};

export enum OptionalSteps {
  CERTIFICATES = "CERTIFICATES",
  ONLINE_PAYMENTS = "ONLINE_PAYMENTS",
}
