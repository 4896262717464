import { ScrollView } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { FC, PropsWithChildren } from "react";
import { getEquipmentDetails } from "@services/ApiService/exercises";
import { useQuery } from "@tanstack/react-query";
import { queryKeysEquipments } from "./queryKeysTrainingsAndExercises";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { Image, ImageProps } from "expo-image";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { SafeAreaView } from "react-native-safe-area-context";

const EquipmentDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "EquipmentDetails">
  >
> = ({
  route: {
    params: { id },
  },
}) => {
  const { t } = useTranslation();
  const mediaStyle = useMediaStyle();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysEquipments.detail(id),
    queryFn: async () => await getEquipmentDetails(id),
  });

  const img = data?.image
    ? { uri: data?.image }
    : (require("@assets/images/default_avatar.png") as ImageProps["source"]);

  const { scrollContainer, gapLarge, loading } = globalStyles;

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["left", "bottom", "right"]}>
      <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
        <Image
          source={img}
          style={mediaStyle}
          contentFit="contain"
          placeholder={
            require("@assets/images/default_avatar.png") as ImageProps["source"]
          }
        />
        <Text variant="titleMedium">{t("T00571")}:</Text>
        <Text variant="bodyMedium">{data?.name}</Text>
        <Text variant="titleMedium">{t("T00572")}:</Text>
        <Text variant="bodyMedium">{data?.description}</Text>
      </ScrollView>
    </SafeAreaView>
  );
};

export default EquipmentDetails;
