import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MessageType } from "@screens/MessageCenter/ConversationScreen";
import ConversationMessages from "@screens/MessageCenter/ConversationMessages";
import { ConversationInput } from "@screens/MessageCenter/Conversation";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { ConversationMessage } from "@screens/MessageCenter/Conversation/conversation.types";

const schema = object().shape({
  msg: string(),
});

type SessionChatProps = {
  disabled?: boolean;
  chatMessages: ConversationMessage[];
  sendChatMessage: (msg: string) => Promise<void>;
  clearNewMessageIndicator: () => void;
  openBottomSheet?: () => void;
  autoFocus?: boolean;
  disableExtraText?: boolean;
};

const SessionChat: FC<SessionChatProps> = ({
  disabled = false,
  chatMessages = [],
  sendChatMessage = () => {},
  clearNewMessageIndicator = () => {},
  openBottomSheet,
  autoFocus,
  disableExtraText,
}) => {
  const isFocused = false;

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<MessageType>({
    resolver: yupResolver(schema),
    defaultValues: { msg: "" },
  });

  const onSubmit = async ({ msg }: MessageType) => {
    await sendChatMessage(msg);
    reset();
  };
  useEffect(() => {
    // clear unread messages indicator
    isFocused && clearNewMessageIndicator();
  }, [clearNewMessageIndicator, isFocused]);

  return (
    <>
      <ConversationMessages
        data={{
          blocked: false,
          messages: chatMessages,
        }}
        disableExtraText={disableExtraText}
      />
      <ConversationInput
        control={control}
        isSendingMessage={false}
        onPress={handleSubmit(onSubmit)}
        onPressContainer={openBottomSheet}
        isValidForm={isValid}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </>
  );
};

export default SessionChat;
