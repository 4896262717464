import { FetchError } from "@components/errors";
import { GetPhysiotherapistsResponse } from "@contexts/AuthContext/user.types";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getPhysiotherapists } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
  View,
} from "react-native";
import { ActivityIndicator, Text, TextInput } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import SearchbarButton from "@components/buttons/SearchbarButton";
import ExtendedPhysiotherapistCard from "@components/cards/PhysiotherapistCard/ExtendedPhysiotherapistCard";
import { defaultFilters } from "@screens/Appointments/FiltersComponents/utils";
import { palettes } from "@styles/colors";
import { spacing12, spacing16, spacing32 } from "@styles/spacing";
import { DISTANCE_RANGE, THERAPISTS_LIST_PAGE_SIZE } from "@utils/constants";
import { isEqual, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { queryKeysPhysiotherapist } from "./queryKeysAppointments";
import { GetPhysiotherapistParams } from "./filters.types";
import CloseResultsInfoTile from "@components/Tile/components/CloseResultsInfoTile";
import useFirstTherapistOverDistance from "@hooks/useFirstTherapistOverDistance";

const PhysiotherapistsListScreen: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "Physiotherapists">
  >
> = ({ navigation: { navigate }, route }) => {
  const resetAllFilters = route.params?.resetAllFilters;

  const [page, setPage] = useState(1);
  const [therapistsList, setTherapistsList] = useState<
    GetPhysiotherapistsResponse[]
  >([]);
  const [activeCity, setActiveCity] = useState<string>("");
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] =
    useState<GetPhysiotherapistParams | null>(null);
  const { firstTherapistOverDistanceId } = useFirstTherapistOverDistance({
    therapistsList,
    distanceRange: DISTANCE_RANGE,
  });
  const { loading, gapLarge, scrollContainer } = globalStyles;
  const { listHeaderStyle, infoTile } = styles;
  const { setErrorsFromResponse } = useErrors();
  const { isLoading, isError, data, isFetching, isFetched, refetch } = useQuery(
    {
      keepPreviousData: true,
      queryKey: queryKeysPhysiotherapist.list(
        selectedFilters ? selectedFilters : null,
        page,
      ),
      queryFn: async () =>
        getPhysiotherapists(
          selectedFilters
            ? { available: true, ...selectedFilters }
            : defaultFilters,
          page,
          THERAPISTS_LIST_PAGE_SIZE,
        ),
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    },
  );

  const isAnyFilterSelected = useMemo(() => {
    if (selectedFilters === null) return false;
    const filters = omit(selectedFilters, ["lat", "lng"]);
    return !isEqual(defaultFilters, filters);
  }, [selectedFilters]);

  useEffect(() => {
    if (isFetched) {
      setTherapistsList(prevState =>
        page === 1 ? data?.results : [...prevState, ...data.results],
      );
    }
  }, [data?.results, isFetched, page, selectedFilters]);

  useEffect(() => {
    setPage(1);
  }, [selectedFilters]);

  useEffect(() => {
    route.params?.activeCity && setActiveCity(route.params?.activeCity);
  }, [route.params?.activeCity]);

  const ListHeaderComponent = useMemo(
    () => (
      <>
        <SearchbarButton
          label="T00586"
          onPress={() =>
            navigate("FindRehabilitator", { setFilters: setSelectedFilters })
          }
        />
        <TextInput
          value={activeCity}
          mode="outlined"
          placeholder={t("T01292")}
          onPressIn={() =>
            navigate("CitySearch", { setFilters: setSelectedFilters })
          }
          left={<TextInput.Icon icon="map-marker-outline" />}
        />
        <PrimaryButton
          icon="tune"
          label={!isAnyFilterSelected ? "T00609" : "T00610"}
          onPress={() =>
            navigate("AppointmentsFilters", {
              setFilters: setSelectedFilters,
              initialFilters: selectedFilters,
            })
          }
          mode={!isAnyFilterSelected ? "outlined" : "contained"}
        />
      </>
    ),
    [activeCity, isAnyFilterSelected, navigate, selectedFilters, t],
  );

  const renderItem: ListRenderItem<GetPhysiotherapistsResponse> = useCallback(
    ({ item: { id }, index }) => {
      const isFirst = index === 0;
      return (
        <View style={gapLarge}>
          {firstTherapistOverDistanceId === id && (
            <CloseResultsInfoTile
              firstTherapistOverDistanceId={firstTherapistOverDistanceId}
              selectedFilters={selectedFilters}
              therapistsList={therapistsList}
              extraStyle={isFirst && infoTile}
              distanceRange={DISTANCE_RANGE}
            />
          )}
          <ExtendedPhysiotherapistCard
            initialData={{ id }}
            onPress={() =>
              navigate("PhysiotherapistProfile", {
                id,
                redirectToScreen: route?.params?.redirectToScreen,
                serviceType: selectedFilters?.availabilityType,
              })
            }
            style={isFirst && { marginTop: -spacing12 }}
            testID={`therapist-${index}`}
          />
        </View>
      );
    },
    [
      gapLarge,
      firstTherapistOverDistanceId,
      selectedFilters,
      therapistsList,
      infoTile,
      navigate,
      route?.params?.redirectToScreen,
    ],
  );

  const incrementPage = () => {
    if (data.next && !isFetching) {
      setPage(prev => ++prev);
    }
  };

  useEffect(() => {
    if (resetAllFilters) setSelectedFilters(null);
  }, [resetAllFilters]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["right", "bottom", "left"]} style={{ flex: 1 }}>
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
        data={therapistsList}
        renderItem={renderItem}
        keyExtractor={({ id }: GetPhysiotherapistsResponse, index) =>
          `physiotherapist-item-${id}-${index}`
        }
        contentContainerStyle={[scrollContainer, gapLarge, { paddingTop: 0 }]}
        ListEmptyComponent={
          <InfoTile
            content={<Text variant="bodyMedium">{t("T00906")}</Text>}
            status="info"
            style={infoTile}
          />
        }
        ListFooterComponent={data.next && isFetching && <ActivityIndicator />}
        onEndReached={() => incrementPage()}
        ListHeaderComponent={ListHeaderComponent}
        ListHeaderComponentStyle={listHeaderStyle}
        stickyHeaderIndices={[0]}
        stickyHeaderHiddenOnScroll
      />
    </SafeAreaView>
  );
};

export default PhysiotherapistsListScreen;

const styles = StyleSheet.create({
  listHeaderStyle: {
    backgroundColor: palettes.primary[100],
    paddingTop: spacing32,
    paddingBottom: spacing16,
    marginTop: -spacing12,
    ...globalStyles.gapLarge,
  },
  infoTile: {
    marginBottom: spacing12,
    marginTop: -spacing16,
  },
});
