type Args = {
  el: Date;
  type: string;
  currentYear: number;
  currentMonth: number;
  actualYear: number;
  actualMonth: number;
  actualDay: number;
  multiselect: boolean;
};

const shouldDateBeDisabled = ({
  el,
  type,
  currentYear,
  currentMonth,
  actualYear,
  actualMonth,
  actualDay,
  multiselect,
}: Args): boolean => {
  const elDate = el?.getDate() || null;
  const thisDayMonth = el?.getMonth();
  const thisDayYear = el?.getFullYear();
  const thisDayYearIsActualYear = thisDayYear === actualYear;
  const currentYearIsActualYear = currentYear === actualYear;

  if (multiselect)
    return (
      (currentYearIsActualYear &&
        currentMonth === actualMonth &&
        elDate < actualDay) ||
      (currentYearIsActualYear && currentMonth < actualMonth) ||
      currentYear < actualYear ||
      (currentYearIsActualYear &&
        currentMonth > actualMonth &&
        currentMonth - actualMonth > 2)
    );

  if (type === "week")
    return (
      (thisDayMonth < actualMonth && thisDayYear <= actualYear) ||
      (thisDayMonth > actualMonth + 2 && thisDayYearIsActualYear) ||
      (thisDayYear > actualYear && thisDayMonth > 1)
    );

  return false;
};

export default shouldDateBeDisabled;
