import Avatar from "@components/Avatar";
import PromoCodeInfoTile from "@screens/Appointments/PromoCodeInfoTile";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "../../../styles/global";
import StatusComponent from "../../Status/StatusComponent";
import { CardContentProps } from "./UpcomingAppointmentCard.types";

const CardContent: FC<CardContentProps> = ({
  subtitle,
  status,
  data,
  discountInfo = false,
}) => {
  const { cardContentStyle } = styles;
  const { gapLarge, gapSmall } = globalStyles;

  return (
    <View style={[cardContentStyle, gapLarge]}>
      <Avatar
        firstName={data?.firstName}
        userId={data?.id}
        image={data?.avatar}
      />
      <View style={[gapSmall, { flex: 1 }]}>
        <Text variant="titleMedium">{`${data?.firstName} ${data?.lastName}`}</Text>
        {discountInfo && <PromoCodeInfoTile shortVersion />}
        {subtitle ? <Text variant="bodyMedium">{subtitle}</Text> : null}
        {status ? <StatusComponent status={status} /> : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContentStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default CardContent;
