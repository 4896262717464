import { globalStyles } from "@styles/global";
import { FC } from "react";
import { ScrollView, ScrollViewProps } from "react-native";
import { Chip } from "react-native-paper";
import { CustomMessageTemplateType } from "./conversation.types";

type TemplateChipListProps = ScrollViewProps & {
  onCustomMessageTemplateSelect: (value: string) => void;
  customMessageTemplateData: CustomMessageTemplateType[];
};

const TemplateChipList: FC<TemplateChipListProps> = ({
  onCustomMessageTemplateSelect,
  customMessageTemplateData,
  ...props
}) => {
  return (
    <ScrollView
      horizontal
      contentContainerStyle={globalStyles.gapMedium}
      keyboardShouldPersistTaps="handled"
      showsHorizontalScrollIndicator={false}
      {...props}>
      {customMessageTemplateData?.map(({ name, author, description }, i) => (
        <Chip
          mode="outlined"
          onPress={() => onCustomMessageTemplateSelect(description)}
          key={`template-${author}-${i}`}>
          {name}
        </Chip>
      ))}
    </ScrollView>
  );
};

export default TemplateChipList;
