export const endpoints = {
  REGISTER: "auth/register/",
  LOGIN: "auth/login/",
  VALIDATE_TOKEN: "auth/validate/",
  REFRESH: "auth/refresh/",

  PHYSIOTHERAPISTS_LIST: "physiotherapists/",
  MY_PHYSIOTHERAPISTS_LIST: "my-physiotherapists/",
  PHYSIOTHERAPIST_DETAILS: (id: number) => `physiotherapists/${id}/`,
  MY_LATEST_PHYSIOTHERAPISTS: "my-physiotherapists/recent-past/",
  PHYSIOTHERAPIST_SERVICES: (id: number) => `physiotherapists/${id}/services/`,

  MY_PATIENTS_LIST: "patients/",
  MY_PATIENT_DETAILS: (id: number) => `patients/${id}/`,
  INVITED_THERAPISTS: "therapists-invited/",
  REWARD_STATUS: "eligible-for-reward/",
  REDEEM_REWARD: "redeem-reward/",

  PROFILES: "profiles/",
  PROFILE_DETAILS: (id: number) => `profiles/${id}/`,
  PROFILES_ACCEPT_TERMS: (id: number) => `profiles/${id}/accept-terms/`,

  APPOINTMENTS: "appointments/",

  APPOINTMENTS_GROUPS: "availability-groups/",
  APPOINTMENTS_AVAILABILITY: "appointments-availability/",
  APPOINTMENT_AVAILABILITY: (id: number) => `appointments-availability/${id}/`,
  FUTURE_AVAILABILITY: "appointments-availability/future-availability/",
  FUTURE_SLOTS_CHECK: "availability-any-future-slot/",
  APPOINTMENT_DETAILS: (id: number) => `appointments/${id}/`,
  APPOINTMENTS_JOIN: (id: number) => `appointments/${id}/join/`,
  APPOINTMENT_CANCEL: (id: number) => `appointments/${id}/cancel/`,
  APPOINTMENT_SURVEY: (id: number) => `appointments/${id}/survey/`,
  DELETE_CUSTOM_APPOINTMENT: (id: number) =>
    `appointments/${id}/custom-event-delete/`,
  APPOINTMENTS_AVAILABILITY_DELETE_DAYS:
    "appointments-availability/delete-days/",
  COPY_APPOINTMENTS_AVAILABILITY: "appointments-availability/copy-day/",
  APPOINTMENTS_PHYSIOTHERAPIST: "appointments-physiotherapist/",

  REHAB_VERIFICATION_PROGRESS: "rehab-verification-progress/",
  PATIENT_VERIFICATION_PROGRESS: "patient-verification-progress/",
  VERIFY_ADDRESS: "verifications/address/",
  UPDATE_ADDRESS: (id: number) => `verifications/address/${id}/`,
  VERIFY_LICENCE: "verifications/licence/",
  VERIFY_EDUCATION: "verifications/education/",
  EDUCATION_DETAILS: (id: number) => `verifications/education/${id}/`,
  ABOUT_ME: "verifications/about-me/",
  EXPERIENCE: "verifications/experience/",
  EXPERIENCE_DETAILS: (id: number) => `verifications/experience/${id}/`,
  CERTIFICATES: "verifications/certificates/",
  CERTIFICATE: (id: number) => `verifications/certificates/${id}/`,
  CERTIFICATE_FILES: "verifications/certificates/files/",
  SERVICES: "verifications/services/",
  DELETE_SERVICE: (id: number) => `verifications/services/${id}/`,
  LICENCE_POST_ATTACHMENT: "verifications/licence/files/",
  IMAGES: "verifications/image/",
  IMAGE: (id: number) => `verifications/image/${id}/`,
  IMAGE_FILES: "verifications/image/files/",
  LANGUAGES: "verifications/spoken-languages/",
  LANGUAGE: (id: number) => `verifications/spoken-languages/${id}/`,
  ADDITIONAL_EXPERIENCE: "verifications/additional-experience/",
  ADDITIONAL_EXPERIENCE_DETAILS: (id: number) =>
    `verifications/additional-experience/${id}/`,

  REPORT_TRAINING: "report-workout/",

  EXERCISES_LIST: "exercises/",
  EXERCISE: (id: number) => `exercises/${id}/`,
  EXERCISE_COPY: (id: number) => `exercises/${id}/copy/`,
  EXERCISE_STATS: (exerciseId: number) => `exercise-stats/${exerciseId}/`,
  DELETE_EXERCISE_ATTACHMENTS: (id: number) =>
    `exercise-optional-attachments/${id}/`,

  TRAININGS: "workouts/",
  TRAINING: (id: number) => `workouts/${id}/`,
  TRAINING_COPY: (id: number) => `workouts/${id}/copy/`,
  TRAINING_FINISH: (id: number) => `workouts/${id}/finish/`,

  PROPHYLACTIC_TRAININGS: "prophylactic-workouts/",
  PROPHYLACTIC_TRAINING_ASSIGN: (id: number) =>
    `prophylactic-workouts/${id}/assign/`,
  PROPHYLACTIC_TRAINING: (id: number) => `prophylactic-workouts/${id}/`,
  WORKOUT_SET_CATEGORIES: "prophylactic-workout-set-categories/",
  WORKOUT_SET_CATEGORY: (id: number) =>
    `prophylactic-workout-set-categories/${id}/`,
  WORKOUT_CATEGORY_SETS: "/prophylactic-workout-sets/",
  WORKOUT_CATEGORY_SET: (id: number) => `prophylactic-workout-sets/${id}/`,
  ASSIGN_WORKOUT_SET: (id: number) => `prophylactic-workout-sets/${id}/assign/`,

  MEDICAL_RECORDS: "medical-records/",
  MEDICAL_RECORDS_FILES: "medical-records/files/",
  MEDICAL_RECORD_AS_PHYSIO: "medical-record-as-physio/",
  MEDICAL_RECORD: (id: number) => `medical-records/${id}/`,
  SHARE_MEDICAL_RECORDS: "share-medical-records/",
  SHARE_MEDICAL_RECORD: (id: number) => `share-medical-records/${id}/`,

  USER_DETAILS: "user-details/",
  USER_DETAILS_UPDATE: (id: number) => `user-details/${id}/`,

  TRAINING_DAYS: "days/",
  TRAINING_DAY: (id: number) => `days/${id}/`,
  TRAINING_CLOSEST_DAY: "days/closest-day/",

  SPECIAL_TRAININGS: "special-training/",
  SPECIAL_TRAINING: (id: number) => `special-training/${id}/`,

  CONVERSATIONS: "conversations/",
  CONVERSATION: (id: number) => `conversations/${id}/messages/`,
  CONVERSATION_MARK_AS_READ: (conv_id: number) => `conversations/${conv_id}/`,
  CONVERSATION_ATTACHMENTS: (conv_id: number) =>
    `conversations/${conv_id}/attachments/`,
  ALL_UNREAD_MESSAGES: "conversations/unread-messages-count/",

  REVIEWS: "review-physiotherapist/",
  REVIEW_DETAILS: (id: number) => `review-physiotherapist/${id}/`,
  REVIEW_CHECK: (id: number) =>
    `review-physiotherapist/checkreview/?physiotherapist_id=${id}`,
  REPORT_REVIEW: "report-review/",

  POST_REVIEW_RESPONSE: "review-response/",
  REVIEW_RESPONSE: (id: number) => `review-response/${id}/`,
  REPORT_REVIEW_RESPONSE: "report-review-response/",

  ACHIEVEMENTS: "achievements/",
  ACHIEVEMENT: (id: number) => `achievements/${id}/`,
  USER_ACHIEVEMENTS: "user-achievements/",

  NOTES: "notes/",
  NOTE_DETAILS: (id: number) => `notes/${id}/`,

  HELP_CENTER_SUBMISSION: "help-center-submission/",

  SERIES: (id: number) => `series/${id}/`,

  PHYSIOTHERAPIST_SETTINGS: "settings/physiotherapist-settings/",
  PHYSIOTHERAPIST_SETTINGS_CHANGE: (id: number) =>
    `settings/physiotherapist-settings/${id}/`,

  SESSION_TRAININGS: "session-workout/",
  SESSION_TRAINING: (id: number) => `session-workout/${id}/`,
  BLACKLIST: "blacklist/",
  REMOVE_FROM_BLACKLIST: "blacklist/unblock/",

  EQUIPMENT_LIST: "equipment/",
  EQUIPMENT_DETAILS: (id: number) => `equipment/${id}/`,

  CONDITIONS_LIST: "conditions/",

  REPORT_PHYSIOTHERAPIST: "report-physiotherapist/",
  REPORT_PATIENT: "report-patient/",
  ALL_SERVICES: "services/",
  SERVICES_FOR_SPECIALIZATION: "service-items/",
  RECOMMENDED_SERVICES: "recommended-services/",

  EARNINGS_STATS_LIST: "earnings-stats-list/",
  EARNINGS_STATS_MONTH_CHART: "earnings-stats-chart-list/",
  EARNINGS_STATS_YEAR_CHART: "earnings-stats-year-chart/",

  APPOINTMENT_STATS_YEAR_CHART: "appointment-stats-year-chart/",
  APPOINTMENT_STATS_MONTH_CHART: "appointment-stats-chart-list/",
  APPOINTMENT_STATS_LIST: "appointment-stats-list/",

  OCCUPANCY_STATS_CHART: "occupancy-stats-chart-list/",
  OCCUPANCY_STATS_LIST: "occupancy-stats-list/",
  OCCUPANCY_STATS_YEAR_CHART: "occupancy-stats-year-chart-list/",

  EXPORT_REHABILITATION_STATISTICS: "export-rehabilitation-statistics/",
  NOTIFICATION: (id: number) => `notification/${id}/`,

  CATEGORIES: "categories/",
  CATEGORY_DETAILS: (id: number) => `categories/${id}/`,
  CATEGORIES_SUBJECTS: (id: number) => `categories/${id}/subjects/`,
  CATEGORY_SUBJECT_DETAILS: (catID: number, subID: number) =>
    `categories/${catID}/subjects/${subID}/`,

  APPLE_SIGNIN: "auth/social/apple/",
  GOOGLE_SIGNIN: "auth/social/google/",
  FACEBOOK_SIGNIN: "auth/social/facebook/",
  RESEND_CONFIRMATION_LINK: "auth/resend-confirmation-link/",

  PREDICTED_PARAMETERS: "predicted-parameters/",

  AVAILABLE_COUNTRIES: "available-countries/",

  CURRENCY_AMOUNT_OPTIONS: "currency-amount-options/",
  RATE_OUR_APP: "rate-our-app/",

  WORKOUT_CATEGORIES: "workout-categories/",
  WORKOUT_CATEGORY: (id: number) => `workout-categories/${id}/`,

  GET_FIXME_USER: "get-fixme-user/",

  TRANSACTIONS_HISTORY: "appointment-transaction-history/",

  SET_EMAIL: "auth/set-email/",
  FORUM_TOPICS: "forum-topics/",
  FORUM_TOPIC: (id: number) => `forum-topics/${id}/`,
  FORUM_POSTS: "forum-posts/",
  FORUM_POST: (id: number) => `forum-posts/${id}/`,
  FORUM_POST_FILES: "forum-posts/files/",

  REPORT_CONTENT: "report-content/",

  SPECIALIZATIONS: "specializations/",

  SUB_APPLE: "subscription-apple/",
  SUB_GOOGLE: "subscription-google/",
  DEFER_SUB_GOOGLE: "subscription-google/defer/",

  SUBSCRIPTION_APPLE_STATUS: "subscription-apple-status/",
  SUBSCRIPTION_GOOGLE_STATUS: "subscription-google-status/",
  INTELLIGENT_TRAINING_ASSISTANT: "intelligent-training-selection-assistant/",

  ONLINE_PAYMENT_METHODS: "physiotherapist-online-payments/",
  ONLINE_PAYMENT_METHOD_UPDATE: (id: number) =>
    `physiotherapist-online-payments/${id}/`,
  STATIONARY_PAYMENT_METHODS: "physiotherapist-stationary-payments/",
  STATIONARY_PAYMENT_METHOD_UPDATE: (id: number) =>
    `physiotherapist-stationary-payments/${id}/`,

  INVITATION_CODE: "invitation-code/",
  REDEEM_CODE: "redeem-code/",

  NOTIFICATION_MAIN_LIST: "notification-settings/main/",
  NOTIFICATION_MAIN_PATCH: (id: number) => `notification-settings/main/${id}/`,
  NOTIFICATIONS_APPOINTMENTS: "notification-settings/appointments/",
  NOTIFICATIONS_APPOINTMENTS_PATCH: (id: number) =>
    `notification-settings/appointments/${id}/`,
  NOTIFICATIONS_WORKOUTS: "notification-settings/workouts/",
  NOTIFICATIONS_WORKOUTS_PATCH: (id: number) =>
    `notification-settings/workouts/${id}/`,

  NOTIFICATIONS_MESSAGES: "notification-settings/messages/",
  NOTIFICATIONS_MESSAGES_PATCH: (id: number) =>
    `notification-settings/messages/${id}/`,

  FORUM_BLACKLIST: "forum-blacklist/",

  VERIFY_PHONE: "verifications/phone-number/",
  VERIFY_PHONE_CODE_CHECK: "verifications/phone-number/code-check/",

  HEALTH_CHECK: "api-healthcheck/",

  CUSTOM_MESSAGE_TEMPLATES: "custom-message-templates/",
  CUSTOM_MESSAGE_TEMPLATES_DETAILS: (id: number) =>
    `custom-message-templates/${id}/`,
  CHECK_PROMO_CODE: "check-promo-code/",
};
