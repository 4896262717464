import { AppbarTitleWithBackAction } from "@components/Appbar";
import ServiceTypeButtonAndSlotsPicker from "@components/BottomSheetContents/ServiceTypeButtonAndSlotsPicker";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import SlotsDropdownPicker from "@components/DropdownPicker/SlotsDropdownPicker";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { createSlots } from "@components/Status/helpers";
import InfoTile from "@components/Tile/InfoTile";
import { yupResolver } from "@hookform/resolvers/yup";
import { useServiceMutation } from "@hooks/verification/useServiceMutation";
import { AddServicesStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing100, spacing16 } from "@styles/spacing";
import { BREAK_SLOTS_COUNT, isANDROID } from "@utils/constants";
import { freeServicesPrices } from "@utils/prices";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import { object, string } from "yup";
import BreakAfterServiceTutorialBottomSheet from "../BreakAfterServiceTutorialBottomSheet";
import { ServiceFormType } from "./AddPaidServiceStep1";
import { AddServiceContext } from "./addService.context";

const AddFreeService: FC<
  PropsWithChildren<
    NativeStackScreenProps<AddServicesStackParamsList, "AddFreeService">
  >
> = ({ navigation: { pop, setOptions, popToTop } }) => {
  const {
    serviceItemId,
    serviceName,
    service = null,
    clearData,
  } = useContext(AddServiceContext);
  const [slotsDropdownOpen, setSlotsDropdownOpen] = useState(false);
  const [breakSlotsDropdownOpen, setBreakSlotsDropdownOpen] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<number>(
    service?.clientSlots - 1 || 0,
  );
  const [selectedBreakSlots, setSelectedBreakSlots] = useState<number>(
    service?.breakSlots || 0,
  );
  const [serviceFormData, setServiceFormData] = useState<ServiceFormType>(null);
  const [tutorialBottomSheetVisible, setTutorialBottomSheetVisible] =
    useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const { t } = useTranslation();
  const { gapLarge, scrollContainer } = globalStyles;
  const breakSlots = createSlots(BREAK_SLOTS_COUNT, true);
  const editionMode = !!service;

  const schema = useMemo(
    () =>
      object().shape({
        serviceType: string().required(t("T00028")),
      }),
    [t],
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction
          title={t(editionMode ? "T01250" : "T00250")}
          onBack={goBack}
          onClose={() => {
            clearData();
            !editionMode && popToTop();
            pop(1);
          }}
        />
      ),
    });
  }, [clearData, editionMode, pop, popToTop, setOptions, t]);

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isMutationLoading } = useServiceMutation({
    serviceType: serviceFormData?.serviceType,
    prices: freeServicesPrices,
    service,
    selectedSlots,
    handleProcessClose: () => {
      clearData();
      !editionMode && popToTop();
      pop(1);
    },
  });

  const onPressButton = (data: ServiceFormType) => {
    mutate({
      ...data,
      serviceItem: serviceItemId,
      clientSlots: selectedSlots + 1,
      breakSlots: selectedBreakSlots,
      prices: freeServicesPrices,
    });
    setServiceFormData(data);
  };

  const onSlotsDropdownOpen = useCallback(() => {
    setSlotsDropdownOpen && setSlotsDropdownOpen(false);
    setBreakSlotsDropdownOpen(true);
  }, []);

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          {
            paddingBottom: footerHeight,
            paddingTop:
              slotsDropdownOpen && !isANDROID && !editionMode
                ? spacing100
                : spacing16,
          },
        ]}>
        <View style={gapLarge}>
          <Text variant="bodyLarge">{serviceName}</Text>
          <ServiceTypeButtonAndSlotsPicker
            control={control}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            slotsDropdownOpen={slotsDropdownOpen}
            setSlotsDropdownOpen={setSlotsDropdownOpen}
            watch={watch}
            serviceType={service?.serviceType}
          />
          <SlotsDropdownPicker
            slots={breakSlots}
            defaultValue={selectedBreakSlots}
            setSelectedSlots={setSelectedBreakSlots}
            dropdownLabel="T01584"
            onOpen={onSlotsDropdownOpen}
            onClose={() => setBreakSlotsDropdownOpen(false)}
            isDropdownOpen={breakSlotsDropdownOpen}
          />
          <View style={{ marginTop: -spacing16 }}>
            <TutorialTextButton
              label="T01585"
              onPress={() => setTutorialBottomSheetVisible(true)}
              buttonInView
            />
          </View>
          <DropdownPicker<number>
            items={[{ label: t("T01521"), value: 0 }]}
            defaultValue={0}
            dropdownLabelText="T00545"
            disabled
          />
          <InfoTile status="info" content={<Text>{t("T01520")}</Text>} />
        </View>
        <BreakAfterServiceTutorialBottomSheet
          modalVisible={tutorialBottomSheetVisible}
          setModalVisible={setTutorialBottomSheetVisible}
        />
      </ScrollView>
      <AbsoluteBlurredFooter
        onPress={handleSubmit(onPressButton)}
        title="T00250"
        onLayout={height => setFooterHeight(height)}
        buttonLoading={isMutationLoading}
        buttonDisabled={isMutationLoading}
      />
    </>
  );
};

export default AddFreeService;
