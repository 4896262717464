import DropdownPicker, {
  OnPickerValueChange,
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { FetchError } from "@components/errors";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { getSpecializations } from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { ActivityIndicator, HelperText, Text } from "react-native-paper";
import InfoTile from "../Tile/InfoTile";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "@globalTypes/i18next";
import { spacing16 } from "@styles/spacing";
import { useFlags } from "@hooks/useFlags";

type SpecializationDropDownPickerProps = {
  onValueChange: OnPickerValueChange;
  defaultValue?: string;
  placeholder?: TranslationKey;
  errorMessage?: string;
  disabled?: boolean;
  isVerified?: boolean;
};

export type SpecializationItemType = {
  id: number;
  name: string;
  requirements: string;
};

const SpecializationDropDownPicker: FC<SpecializationDropDownPickerProps> = ({
  onValueChange,
  errorMessage,
  isVerified,
  ...props
}) => {
  const [items, setItems] = useState<PickerItem[]>(null);
  const [currentItem, setCurrentItem] = useState<string>(null);
  const { isGymsy } = useFlags();

  const { t } = useTranslation();

  const { isError, isLoading, refetch, data } = useQuery({
    queryKey: queryKeysVerficiations.specializationOptions(),
    queryFn: getSpecializations,
  });

  useEffect(() => {
    if (!isError && !isLoading) {
      const items: PickerItem[] = data.map(({ name, id }) => ({
        label: name,
        value: `${id}`,
      }));
      setItems(items);
    }
  }, [data, isError, isLoading]);
  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  const requirements = data.filter(({ id }) => id === +currentItem)[0]
    ?.requirements;

  return (
    items?.length > 0 && (
      <>
        <DropdownPicker
          dropdownLabelText="T00549"
          items={items}
          onValueChange={value => {
            setCurrentItem(value);
            onValueChange(value);
          }}
          hasError={!!errorMessage}
          isRequired
          {...props}
        />
        {errorMessage && (
          <HelperText style={{ marginTop: -spacing16 }} type="error">
            {errorMessage}
          </HelperText>
        )}
        {!isGymsy && currentItem && !isVerified && !!requirements && (
          <InfoTile
            status="info"
            content={
              <Text variant="bodyMedium">
                {`${t("T00923")}: \n${requirements}`}
              </Text>
            }
          />
        )}
      </>
    )
  );
};

export default SpecializationDropDownPicker;
