import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

import { FetchError } from "@components/errors";
import { ConversationHeader, DisableMessages } from "./Conversation";
import ConversationMessages from "./ConversationMessages";
import UpcomingConsultations from "./UpcomingConsultations";

import { useUnreadMessages } from "@hooks/queryHooks/useUnreadMessages";
import { useErrors } from "@hooks/useErrors";
import { useAssistant } from "@hooks/user/useAssistant";
import { useKeyboard } from "@hooks/ui/useKeyboard";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { useIsFocused } from "@react-navigation/native";
import {
  getConversationMessages,
  getConversations,
  markMessagesAsRead,
} from "@services/ApiService/conversations";
import { globalStyles } from "@styles/global";
import { spacing16, spacing8 } from "@styles/spacing";
import { isIOS } from "@utils/constants";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";
import ConversationMessagesFooter from "./ConversationMessagesFooter";
import {
  queryKeysConversations,
  queryKeysUnreadMessages,
} from "./queryKeysMessageCenter";
import AddAttachmentBottomSheet from "@components/Files/AddAttachmentBottomSheet";
import { useResources } from "@hooks/media/useResources/useResources";
import { postFile } from "@services/ApiService/common";
import { endpoints } from "@services/ApiService/endpoints";
import { FileAttachment } from "@globalTypes/common.types";
import { showAlert } from "@utils/showAlert";

export type MessageType = {
  msg?: string;
};

const ConversationScreen: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "ConversationScreen">
  >
> = ({
  route: {
    params: { interlocutorId },
  },
  navigation: { navigate },
}) => {
  const { isAssistantId } = useAssistant(interlocutorId);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [addAttachmentBottomSheetVisible, setAddAttachmentBottomSheetVisible] =
    useState(false);
  const keyboardHeight = useKeyboard(isIOS);
  const { t } = useTranslation();
  const { setErrorsFromResponse: setMarkMessagesAsReadErrorsFromResponse } =
    useErrors();
  const queryClient = useQueryClient();

  const { isPhysiotherapist } = useUserDetails();
  const { headerContainer } = styles;
  const { gapMedium, loading } = globalStyles;
  const isFocused = useIsFocused();
  const { allUnreadMessages } = useUnreadMessages(interlocutorId);

  const {
    pickFilesFromDevice,
    pickedFiles,
    isLoading: isPickedFileLoading,
    removeFile,
  } = useResources({});

  const {
    data: conversationsList,
    isLoading: conversationsListLoading,
    isError: conversationsListError,
    refetch: refetchConversationsList,
  } = useQuery({
    queryKey: queryKeysConversations.list(),
    queryFn: getConversations,
  });

  const { mutate: postAttachmentFile, isLoading: isPostFileLoading } =
    useMutation({
      mutationFn: postFile,
      onError: ({ response }: AxiosError<FileAttachment>) =>
        showAlert(response.data.file[0]),
      onSuccess: async () => await refetchConversationData(),
    });

  const filteredConversations =
    conversationsList?.length &&
    conversationsList.filter(
      ({ interlocutor }) => interlocutor == interlocutorId,
    );
  const convId = filteredConversations?.length && filteredConversations[0].id;

  const {
    data: conversationData,
    isLoading: conversationLoading,
    isError: conversationError,
    refetch: refetchConversationData,
  } = useQuery({
    queryKey: queryKeysConversations.details(convId),
    queryFn: async () => await getConversationMessages(convId),
    enabled: !!filteredConversations?.length,
    refetchInterval: isFocused && 5000,
  });

  const { mutate: markMessagesInConvAsRead } = useMutation({
    mutationFn: async () => await markMessagesAsRead(convId),
    onError: ({ response }: AxiosError) =>
      setMarkMessagesAsReadErrorsFromResponse(response),
    onSuccess: async () =>
      await Promise.all([
        queryClient.invalidateQueries(queryKeysUnreadMessages.list()),
        queryClient.invalidateQueries(
          queryKeysUnreadMessages.details(interlocutorId),
        ),
      ]),
  });

  useEffect(() => {
    if (pickedFiles?.length) {
      const { name, uri } = pickedFiles[0];
      setAddAttachmentBottomSheetVisible(false);
      postAttachmentFile({
        file: { uri, name },
        endpoint: endpoints.CONVERSATION_ATTACHMENTS(convId),
      });
      removeFile(uri);
    }
  }, [convId, pickedFiles, postAttachmentFile, removeFile]);

  useEffect(() => {
    if (allUnreadMessages && convId) {
      markMessagesInConvAsRead();
    }
  }, [allUnreadMessages, convId, markMessagesInConvAsRead]);

  const onPressPatient = () =>
    navigate("PatientProfile", {
      id: interlocutorId,
    });

  const onPressPhysiotherapist = () =>
    navigate("PhysiotherapistProfile", {
      id: interlocutorId,
    });

  const navigateToAppointmentDetails = (id: number) =>
    navigate("AppointmentDetails", {
      id,
    });

  return (
    <Host>
      <SafeAreaView edges={["bottom"]} style={{ flex: 1 }}>
        <View
          style={[
            { flex: 1 },
            containerHeight > 0 &&
              keyboardHeight > 0 && {
                height: containerHeight - keyboardHeight,
                flex: 0,
              },
            { paddingBottom: footerHeight || 0 },
          ]}
          onLayout={({ nativeEvent }) => {
            if (containerHeight === 0) {
              setContainerHeight(nativeEvent.layout.height);
            }
          }}>
          <View
            style={[
              headerContainer,
              gapMedium,
              keyboardHeight > 0 && { display: "none" },
            ]}>
            <ConversationHeader
              interlocutorId={interlocutorId}
              onPressPatient={!isAssistantId && onPressPatient}
              onPressPhysiotherapist={!isAssistantId && onPressPhysiotherapist}
            />
            {!isAssistantId && (
              <DisableMessages disabledUserId={interlocutorId} />
            )}
            <UpcomingConsultations
              interlocutorId={interlocutorId}
              nav={navigateToAppointmentDetails}
            />
            {isAssistantId && <Text variant="bodyMedium">{t("T00863")}</Text>}
            <Divider bold />
          </View>
          {conversationsListLoading || conversationLoading ? (
            <ActivityIndicator style={loading} />
          ) : conversationsListError || conversationError ? (
            <View style={globalStyles.container}>
              <FetchError
                action={refetchConversationsList}
                coverScreen={false}
              />
            </View>
          ) : (
            <>
              <ConversationMessages data={conversationData} withReport />
              {(isPickedFileLoading || isPostFileLoading) && (
                <ActivityIndicator style={{ marginBottom: spacing8 }} />
              )}
            </>
          )}
          <ConversationMessagesFooter
            setFooterHeight={setFooterHeight}
            convId={convId}
            isAssistantId={isAssistantId}
            isPhysiotherapist={isPhysiotherapist}
            isBlocked={conversationData?.blocked}
            disabledUserId={interlocutorId}
            handleAddFilesIcon={() => setAddAttachmentBottomSheetVisible(true)}
          />
        </View>
        <AddAttachmentBottomSheet
          visible={addAttachmentBottomSheetVisible}
          setVisible={setAddAttachmentBottomSheetVisible}
          pickFilesFromDevice={pickFilesFromDevice}
        />
      </SafeAreaView>
    </Host>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    paddingHorizontal: spacing16,
    paddingTop: spacing16,
    paddingBottom: spacing8,
  },
});

export default ConversationScreen;
