/* eslint-disable react-hooks/rules-of-hooks */
import { AppbarTitleWithBackAction } from "@components/Appbar";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AbortFillSurveyBottomSheet } from "@components/BottomSheetContents";
import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { RadioButtonsGroup } from "@components/FormElements";
import { Input } from "@components/Input";
import PainTileWithController from "@components/Tile/PainTileWithController";
import { booleanButtons } from "@frontendData/boolean_buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDisplayBlurredFooter, useElementHeight } from "@hooks/index";
import { ScheduleAppointmentStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import {
  MAX_PAIN_LEVEL,
  MIN_PAIN_LEVEL,
  MULTILINE_INPUT_MAX_LENGTH,
} from "@utils/constants";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Text } from "react-native-paper";
import { boolean, number, object, string } from "yup";
import { AppointmentContext } from "../appointment.context";
import { Appointment } from "../appointment.types";
import SurveyHeader from "./SurveyHeader";
import { useFlags } from "@hooks/useFlags";

type InjuryDescriptionType = Pick<
  Appointment,
  | "injuryDescription"
  | "painLevel"
  | "previouslyTreated"
  | "disfunctionDescription"
> & { hasDisfunction: string; processingCheckbox: boolean };

const InjuryDescription: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ScheduleAppointmentStackParamsList,
      "InjuryDescription"
    >
  >
> = ({ navigation: { navigate, setOptions, pop }, route: { params } }) => {
  const { t } = useTranslation();
  const { scrollContainer, gapLarge, gapSmall } = globalStyles;
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const { onElementLayout: onInputLayout, elementHeight: inputHeight } =
    useElementHeight();
  const { onElementLayout: onContainerLayout, elementHeight: containerHeight } =
    useElementHeight();
  const [injuryDescriptionScrollY, setInjuryDescriptionScrollY] = useState(0);
  const [processingCheckboxScrollY, setProcessingCheckboxScrollY] = useState(0);

  const {
    footerHeight,
    setFooterHeight,
    displayBlurredFooter,
    keyboardHeight,
  } = useDisplayBlurredFooter();

  const scrollViewRef = useRef<KeyboardAwareScrollView>(null);
  const scrollToY = (y: number) => scrollViewRef.current.scrollToPosition(0, y);

  const {
    data: {
      injuryDescription,
      painLevel,
      previouslyTreated,
      disfunctionDescription,
    },
    updateData,
  } = useContext(AppointmentContext);
  const {
    flags: { FEATURE_MEDICAL_RECORDS },
  } = useFlags();

  const schema = useMemo(
    () =>
      object().shape({
        injuryDescription: string()
          .required(t("T00014"))
          .max(MULTILINE_INPUT_MAX_LENGTH)
          .trim(),
        painLevel: number()
          .required(t("T00014"))
          .typeError(t("T00035"))
          .default(MIN_PAIN_LEVEL)
          .min(
            MIN_PAIN_LEVEL,
            t("T00033", {
              value: MIN_PAIN_LEVEL,
            }),
          )
          .max(
            MAX_PAIN_LEVEL,
            t("T00034", {
              value: MAX_PAIN_LEVEL,
            }),
          ),
        disfunctionDescription: string().when("hasDisfunction", {
          is: "Yes",
          then: schema =>
            schema
              .test("disfunctionDescription", t("T00027"), value => !!value)
              .required(t("T00014"))
              .max(MULTILINE_INPUT_MAX_LENGTH)
              .trim(),
        }),
        processingCheckbox: boolean()
          .oneOf([true], t("T00014"))
          .required(t("T00014")),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<InjuryDescriptionType>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      injuryDescription,
      painLevel,
      previouslyTreated: previouslyTreated || "No",
      hasDisfunction: disfunctionDescription?.length > 0 ? "Yes" : "No",
      disfunctionDescription,
    },
  });

  const patientWithDisfunction = watch("hasDisfunction") === "Yes";

  const saveAndContinue = ({
    injuryDescription,
    painLevel,
    previouslyTreated,
    disfunctionDescription,
  }: InjuryDescriptionType) => {
    updateData({
      injuryDescription,
      painLevel,
      previouslyTreated,
      disfunctionDescription,
    });

    FEATURE_MEDICAL_RECORDS
      ? navigate("MedicalRecordsToAppointment", { ...params })
      : navigate("ScheduledAppointmentSummary");
  };

  useEffect(() => {
    if (!patientWithDisfunction) {
      setValue("disfunctionDescription", "");
    }
  }, [patientWithDisfunction, setValue]);

  useEffect(() => {
    params?.fillInSurveyProcess &&
      setOptions({
        header: ({ navigation: { goBack } }) => (
          <AppbarTitleWithBackAction
            title={t("T00285")}
            onBack={goBack}
            onClose={() => setModalVisible(true)}
          />
        ),
      });
  }, [params, setOptions, t]);

  const handlePatientWithDisfunctionChange = useCallback(
    () => scrollViewRef.current.scrollToPosition(0, containerHeight, true),
    [containerHeight],
  );

  useEffect(() => {
    if (patientWithDisfunction) handlePatientWithDisfunctionChange();
  }, [handlePatientWithDisfunctionChange, patientWithDisfunction]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      if (errors?.injuryDescription?.message) {
        return scrollToY(injuryDescriptionScrollY);
      }
      if (errors?.processingCheckbox?.message) {
        return scrollToY(processingCheckboxScrollY);
      }
    }
  }, [errors, injuryDescriptionScrollY, processingCheckboxScrollY]);

  return (
    <>
      <KeyboardAwareScrollView
        ref={scrollViewRef}
        contentContainerStyle={[
          scrollContainer,
          !keyboardHeight && { paddingBottom: footerHeight },
        ]}
        scrollEnabled={scrollEnabled}
        extraHeight={inputHeight + footerHeight}>
        <View style={gapLarge} onLayout={onContainerLayout}>
          <View
            style={gapSmall}
            onLayout={e => setInjuryDescriptionScrollY(e.nativeEvent.layout.y)}>
            <SurveyHeader
              step="2"
              onSkip={() => {
                navigate("ScheduledAppointmentSummary", {
                  surveyOmitted: true,
                });
              }}
              disableSkip={params?.fillInSurveyProcess}
            />
            <Text variant="titleMedium">{t("T00299")}:</Text>
            <Input
              name="injuryDescription"
              label="T00285"
              control={control as unknown as Control}
              multiline
              maxLength={MULTILINE_INPUT_MAX_LENGTH}
              onLayout={onInputLayout}
            />
            <PainTileWithController
              name="painLevel"
              control={control as unknown as Control}
              setScrollEnabled={setScrollEnabled}
            />
          </View>
          <Controller
            control={control}
            name="previouslyTreated"
            render={({ field: { onChange, value } }) => (
              <RadioButtonsGroup
                errorMessage={errors?.previouslyTreated?.message}
                onChange={onChange}
                value={value}
                data={booleanButtons}
                title="T00573"
                withColon={false}
              />
            )}
          />
          <Controller
            control={control}
            name="hasDisfunction"
            render={({ field: { onChange, value } }) => (
              <RadioButtonsGroup
                errorMessage={errors?.hasDisfunction?.message}
                onChange={onChange}
                value={value}
                data={booleanButtons}
                title="T00579"
                withColon={false}
              />
            )}
          />
          {patientWithDisfunction ? (
            <Input
              name="disfunctionDescription"
              label="T00580"
              control={control as unknown as Control}
              multiline
              maxLength={MULTILINE_INPUT_MAX_LENGTH}
              defaultValue={disfunctionDescription || null}
              onLayout={onInputLayout}
            />
          ) : null}
          <View
            onLayout={e =>
              setProcessingCheckboxScrollY(e.nativeEvent.layout.y)
            }>
            <CheckboxSimpleText
              name="processingCheckbox"
              control={control as never as Control}
              text="T01317"
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      {displayBlurredFooter && (
        <AbsoluteBlurredFooter
          onPress={handleSubmit(saveAndContinue)}
          title="T00472"
          onLayout={height => setFooterHeight(height)}
        />
      )}
      {params?.fillInSurveyProcess && (
        <BottomModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}>
          <AbortFillSurveyBottomSheet
            onContinue={() => setModalVisible(false)}
            onAbort={() => {
              pop();
              pop();
            }}
          />
        </BottomModalContainer>
      )}
    </>
  );
};

export default InjuryDescription;
