import { AbsoluteBlurredFooter } from "@components/Footers";
import HumanModelWithController from "@components/HumanModel/HumanModelWithController";
import { useAuth } from "@contexts/AuthContext/auth";
import { GenderType } from "@contexts/AuthContext/user.types";
import { HumanModelType } from "@globalTypes/common.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHumanModelSchemas } from "@hooks/humanModel/useHumanModelSchemas";
import { ScheduleAppointmentStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Control, FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import { AppointmentContext } from "../appointment.context";
import SurveyHeader from "./SurveyHeader";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AbortFillSurveyBottomSheet } from "@components/BottomSheetContents";

const PlaceOfInjury: FC<
  PropsWithChildren<
    NativeStackScreenProps<ScheduleAppointmentStackParamsList, "PlaceOfInjury">
  >
> = ({ navigation: { navigate, pop, setOptions }, route: { params } }) => {
  const {
    user: { gender },
  } = useAuth();
  const [footerHeight, setFooterHeight] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [humanModelScrollY, setHumanModelScrollY] = useState(0);

  const { data, updateData } = useContext(AppointmentContext);

  const { scrollContainer } = globalStyles;
  const { humanModelSchema } = useHumanModelSchemas();
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(humanModelSchema),
    defaultValues: {
      gender: data?.gender || gender,
      humanGeneral: data?.humanGeneral,
      humanBones: data?.humanBones,
      humanMuscles: data?.humanMuscles,
    },
  });
  const humanBonesField = watch("humanBones");
  const humanMusclesField = watch("humanMuscles");
  const humanGeneralField = watch("humanGeneral");
  const { t } = useTranslation();

  useEffect(() => {
    setValue("gender", gender);
  }, [gender, setValue]);

  useEffect(() => {
    params?.fillInSurveyProcess &&
      setOptions({
        header: ({ navigation: { goBack } }) => (
          <AppbarTitleWithBackAction
            title={t("T00280")}
            onBack={goBack}
            onClose={() => setModalVisible(true)}
          />
        ),
      });
  }, [params, setOptions, t]);

  const scrollRef = useRef<ScrollView>(null);
  const scrollToY = (y: number) =>
    scrollRef?.current?.scrollTo({ x: 0, y, animated: true });

  useEffect(() => {
    if (Object.keys(errors).length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (errors?.[""]?.message) {
        return scrollToY(humanModelScrollY);
      }
    }
  }, [errors, humanModelScrollY]);

  const onSubmit = (data: HumanModelType) => {
    updateData(data);
    navigate("InjuryDescription", { ...params });
  };

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          { paddingBottom: footerHeight },
        ]}
        ref={scrollRef}>
        <SurveyHeader
          disableSkip={params?.fillInSurveyProcess}
          onSkip={() => {
            navigate("ScheduledAppointmentSummary", { surveyOmitted: true });
          }}
          step="1"
        />
        <View onLayout={e => setHumanModelScrollY(e.nativeEvent.layout.y)}>
          <Text variant="titleMedium">{t("T00294")}:</Text>
          <HumanModelWithController
            gender={watch("gender") as GenderType}
            onGenderChange={(value: GenderType) => setValue("gender", value)}
            humanBones={humanBonesField}
            humanMuscles={humanMusclesField}
            humanGeneral={humanGeneralField}
            control={control as unknown as Control}
            errors={errors as { [x: string]: FieldError[] }}
          />
        </View>
      </ScrollView>
      <AbsoluteBlurredFooter
        onPress={handleSubmit(onSubmit)}
        title="T00472"
        onLayout={height => setFooterHeight(height)}
      />
      {params?.fillInSurveyProcess && (
        <BottomModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}>
          <AbortFillSurveyBottomSheet
            onContinue={() => setModalVisible(false)}
            onAbort={() => pop()}
          />
        </BottomModalContainer>
      )}
    </>
  );
};

export default PlaceOfInjury;
