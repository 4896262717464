import { PrimaryButton } from "@components/buttons";
import SpecializationDropDownPicker from "@components/DropdownPicker/SpecializationDropdownPicker";
import { FetchError } from "@components/errors";
import PermissionModal from "@components/Modals/PermissionModal";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { PermissionType } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvalidateProfileDetails } from "@hooks/index";
import { useCheckNotifications } from "@hooks/notifications/useCheckNotifications";
import { useNotificationMainSettings } from "@hooks/notifications/useNotificationMainSettings";
import { useErrors } from "@hooks/useErrors";
import {
  confirmSpecialization,
  getLicence,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { object, string } from "yup";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import {
  PostLicenceBase,
  PostLicenceWithAttachment,
  VerificationStatus,
} from "./verification.types";
import { VerifyLicenceScreenProps } from "./VerifyLicence";
import InfoTile from "@components/Tile/InfoTile";

type SpecializationForm = Pick<PostLicenceBase, "specialization">;

export const ConfirmSpecialization: FC<VerifyLicenceScreenProps> = ({
  navigation: { navigate, getState },
}) => {
  const { t } = useTranslation();
  const { errors, setErrorsFromResponse, clearErrors } = useErrors();
  const queryClient = useQueryClient();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const {
    data: initialData,
    isLoading: initialDataLoading,
    isError: initialDataError,
    refetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.licence(),
    queryFn: getLicence,
    onSuccess: data => {
      setValue("specialization", `${data[0]?.specialization?.id}` || "");
    },
  });

  const { data } = useNotificationMainSettings();

  const [
    showNotificationPermissionModalVisible,
    setShowNotificationPermissionModalVisible,
  ] = useState(false);

  const isVerified =
    initialData?.[0]?.verificationStatus === VerificationStatus.VERIFIED;

  const schema = useMemo(() => {
    const stringSchema = string().required(t("T00014")).trim();
    return object().shape({
      specialization: stringSchema,
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors: clearFormErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      specialization: `${initialData?.[0]?.specialization?.id}`,
    },
  });

  const handleGoBackToPrevScreen = useCallback(() => {
    const firstRouteName = getState().routes?.[0]?.name;
    if (firstRouteName === "VerifyLicence") return navigate("Profile");
    return navigate("AddressAndVerification");
  }, [getState, navigate]);

  const handleClearAndGoBack = useCallback(() => {
    clearErrors();
    clearFormErrors();
    handleGoBackToPrevScreen();
    reset();
    showProfileVerificationSnackbar(t("T01311"));
  }, [clearErrors, clearFormErrors, handleGoBackToPrevScreen, reset, t]);

  const handlePermissionsGranted = useCallback(
    (withGoBackAction = true) => {
      setShowNotificationPermissionModalVisible(false);
      withGoBackAction && handleClearAndGoBack();
    },
    [handleClearAndGoBack],
  );

  const { handleCheckNotifications, isNotificationGranted } =
    useCheckNotifications({
      mainNotification: data,
      onPermissionsGranted: handlePermissionsGranted,
    });

  const onSubmit = ({ specialization }: SpecializationForm) => {
    if (!specialization || specialization === "undefined") {
      setError("specialization", { message: t("T00014") });
      return;
    }
    mutate({ specialization } as PostLicenceWithAttachment);
  };

  const onUpdateLicenceSuccess = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries(queryKeysVerficiations.licence()),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      ),
    ]);
    isNotificationGranted
      ? handleClearAndGoBack()
      : setShowNotificationPermissionModalVisible(true);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: confirmSpecialization,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onUpdateLicenceSuccess,
  });

  if (initialDataLoading)
    return <ActivityIndicator style={globalStyles.loading} />;
  if (initialDataError) return <FetchError action={refetch} />;

  return (
    <View style={[globalStyles.container, globalStyles.gapLarge]}>
      {initialData?.[0]?.rejectionReason && (
        <InfoTile
          status="warning"
          content={
            <Text variant="bodyMedium">
              {initialData?.[0]?.rejectionReason}
            </Text>
          }
        />
      )}
      <Text variant="bodyMedium" style={{ marginBottom: spacing8 }}>
        {t("T00550")}
      </Text>
      <Controller
        name="specialization"
        key="specialization"
        control={control}
        render={({ field, fieldState: { error } }) => {
          const hasSpecialization = !!initialData?.[0]?.specialization?.id;
          const { id, name } =
            (hasSpecialization && initialData[0].specialization) || {};
          return (
            <SpecializationDropDownPicker
              isVerified={isVerified}
              onValueChange={value => field.onChange(value)}
              defaultValue={hasSpecialization && `${id}`}
              placeholder={!hasSpecialization ? "T00919" : (name as TransKey)}
              errorMessage={
                errors?.specialization?.[0]?.message || (error && t("T00014"))
              }
              disabled={isVerified}
            />
          );
        }}
      />
      <PrimaryButton
        loading={isLoading}
        label="T00612"
        onPress={handleSubmit(onSubmit)}
        disabled={isVerified}
      />
      <PermissionModal
        isModalVisible={showNotificationPermissionModalVisible}
        setIsModalVisible={setShowNotificationPermissionModalVisible}
        onPressConfirm={handleCheckNotifications}
        type={PermissionType.notifications}
        onPressNotNowCallback={handleClearAndGoBack}
      />
    </View>
  );
};

export default ConfirmSpecialization;
