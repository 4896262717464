import { SvgAssignWorkoutToPatient } from "@assets/svg/tutorials";
import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import ActiveAndInactiveTrainingsList from "@components/Training/ActiveAndInactiveTrainingsList";
import { PrimaryButton } from "@components/buttons";
import { UserCard } from "@components/cards";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren, useState } from "react";
import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

const RehabilitationTrainings: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "RehabilitationTrainings">
  >
> = ({ route, navigation: { navigate } }) => {
  const { patientId } = route.params;
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <ActiveAndInactiveTrainingsList
        ListHeaderComponent={
          <View style={globalStyles.gapMedium}>
            <UserCard initialData={{ id: patientId }} mode="outlined" flat />
            <PrimaryButton
              label="T00191"
              onPress={() =>
                navigate("TrainingsAndExercisesModal", { patientId })
              }
            />
            <TutorialTextButton
              label="T00670"
              onPress={() => setModalVisible(true)}
            />
          </View>
        }
        patientId={patientId}
        isAssistant={false}
      />

      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <BottomTutorialComponent
          title="T00671"
          subtitles={["T00672"]}
          content={[{ svgComponent: () => <SvgAssignWorkoutToPatient /> }]}
          onPress={() => setModalVisible(false)}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default RehabilitationTrainings;
