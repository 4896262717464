import { ProductName } from "@globalTypes/common.types";
import { flags } from "@utils/flags";
import * as React from "react";
import Svg, { ClipPath, Defs, G, Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) =>
  flags.PRODUCT_NAME === ProductName.FIXME ? (
    <Svg width={24} height={24} fill="none" {...props}>
      <Path
        fill="#4666FF"
        d="M20.169 0H3.832A3.832 3.832 0 0 0 0 3.832v16.337A3.831 3.831 0 0 0 3.832 24h16.337A3.831 3.831 0 0 0 24 20.169V3.832A3.831 3.831 0 0 0 20.169 0Z"
      />
      <Path
        fill="#fff"
        d="M9.883 4.6c0-1.136.849-1.91 2.044-1.91 1.196 0 2.001.774 2.001 1.91 0 1.134-.827 1.885-2 1.885-1.175 0-2.045-.798-2.045-1.886Z"
      />
      <Path
        fill="#fff"
        d="m15.693 15.001-1.737-2.186 5.286-6.524h-3.72l-3.457 4.446L8.607 6.29h-3.87l5.305 6.596-5.392 6.717h3.718l3.567-4.567 1.705 2.212L15.693 15ZM17.53 17.313h-3.84l1.767 2.29h3.893l-1.82-2.29Z"
      />
    </Svg>
  ) : (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M20.169 0H3.832A3.832 3.832 0 000 3.832v16.337A3.831 3.831 0 003.832 24h16.337A3.831 3.831 0 0024 20.169V3.832A3.831 3.831 0 0020.169 0z"
        fill="#FDC400"
      />
      <G clipPath="url(#clip0_887_1653)">
        <Path
          d="M16.872 10.771l.988-1.552a3.697 3.697 0 00.124-3.81 3.7 3.7 0 00-3.281-1.94H9.275a3.687 3.687 0 00-3.293 1.968 3.693 3.693 0 00.17 3.834l.973 1.476a6.044 6.044 0 008.43 8.471 6.045 6.045 0 001.318-8.447h-.001zM7.568 7.213c0-.945.765-1.71 1.708-1.712h5.428a1.71 1.71 0 011.442 2.628l-.764 1.2a6.018 6.018 0 00-3.392-1.04 6.03 6.03 0 00-3.374 1.027L7.848 8.15a1.708 1.708 0 01-.28-.938z"
          fill="#fff"
        />
        <Path
          d="M14.666 16.516l.003-.003.027-.029a3.4 3.4 0 00.742-2.452H12.13v1.041h1.8l-.083.167a2.056 2.056 0 01-1.855 1.14 2.05 2.05 0 01-.84-.179 2.042 2.042 0 01-1.051-2.63 2.04 2.04 0 011.018-1.075c.279-.136.58-.206.895-.206.504 0 .982.182 1.355.514l.986-.986a3.428 3.428 0 00-2.342-.922 3.431 3.431 0 00-3.434 3.436 3.44 3.44 0 003.435 3.435 3.427 3.427 0 002.65-1.25l.004-.002h-.002z"
          fill="#FDC400"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_887_1653">
          <Path
            fill="#fff"
            transform="translate(5.526 3.468)"
            d="M0 0H12.9278V16.9131H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
export default SvgComponent;
