import { ProgressCircle } from "@components/ProgressCircle/ProgressCircle";
import MultiColorText from "@components/Text/MultiColorText";
import VerificationActionTile from "@components/Tile/VerificationActionTile";
import { FetchError } from "@components/errors";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing16 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { getDayName, getFormattedDayWithMonth } from "@utils/date";
import { isToday } from "date-fns";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { NextAppointmentsSection } from "./NextAppointmentsSection";
import { BaseAppointment } from "@screens/Appointments/appointment.types";
import { useAuth } from "@contexts/AuthContext/auth";

type Props = {
  onPressAppointment: (id: number) => void;
  data: BaseAppointment[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  today: Date;
};

export const WelcomeSection: FC<Props> = ({
  onPressAppointment,
  data,
  isLoading,
  isError,
  refetch,
  today,
}) => {
  const { t } = useTranslation();
  const {
    user: { language },
  } = useAuth();
  const { gapMedium, gapLarge } = globalStyles;
  const {
    leftBoxContainer,
    container,
    leftBoxSubContainer,
    rightBoxContainer,
    rightBoxSubContainer,
    rightBoxCountSection,
    leftText,
  } = styles;

  const confirmedAppointments = data?.filter(
    ({ paymentType, paymentSucceeded }) =>
      paymentType === "Online" ? paymentSucceeded : true,
  );
  const todayConfirmedAppointments = confirmedAppointments?.filter(
    ({ dateTo }) => isToday(new Date(dateTo)),
  );

  const completedTodayAppointments = todayConfirmedAppointments?.filter(
    ({ dateTo }) => new Date(dateTo).getTime() < new Date().getTime(),
  );

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  const completedTodayAppointmentsLength = completedTodayAppointments.length;
  const allTodayAppointmentsLength = todayConfirmedAppointments.length;

  const actualPercent =
    !!completedTodayAppointmentsLength &&
    !!allTodayAppointmentsLength &&
    (completedTodayAppointmentsLength / allTodayAppointmentsLength) * 100;

  const prevPercent =
    !!completedTodayAppointmentsLength &&
    !!allTodayAppointmentsLength &&
    ((completedTodayAppointmentsLength - 1) / allTodayAppointmentsLength) * 100;

  return (
    <View style={gapLarge}>
      <View style={[container, gapMedium]}>
        <View style={leftBoxContainer}>
          <View style={leftBoxSubContainer}>
            <Text variant="titleMedium" style={leftText}>
              {getDayName(today, language)}
            </Text>
            <Text variant="headlineLarge" style={leftText}>
              {getFormattedDayWithMonth(today)}
            </Text>
          </View>
        </View>
        <View style={rightBoxContainer}>
          <View style={rightBoxSubContainer}>
            <Text variant="titleMedium" style={{ color: palettes.primary[0] }}>
              {t("T01000")}
            </Text>
            <View style={[rightBoxCountSection, gapLarge]}>
              <MultiColorText
                leftText={`${completedTodayAppointments?.length}`}
                rightText={`/${todayConfirmedAppointments?.length}`}
                variant="headlineLarge"
                rightTextProps={{
                  variant: "headlineLarge",
                  style: { color: palettes.secondary[60] },
                }}
              />
              <ProgressCircle
                percent={actualPercent}
                initialValue={
                  completedTodayAppointmentsLength === 0 ? 0 : prevPercent
                }
              />
            </View>
          </View>
        </View>
      </View>
      <VerificationActionTile />
      <NextAppointmentsSection
        data={confirmedAppointments}
        onPressAppointment={onPressAppointment}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftBoxContainer: {
    backgroundColor: palettes.primary[40],
    padding: spacing16,
    borderRadius: spacing12,
    alignItems: "center",
    height: "100%",
    flex: 0,
    flexGrow: isWeb ? 1 : 0,
    flexShrink: 1,
  },
  leftBoxSubContainer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  rightBoxContainer: {
    borderColor: palettes.primary[40],
    borderWidth: 1,
    padding: spacing16,
    borderRadius: spacing12,
    height: "100%",
    flexGrow: isWeb ? 2 : 1,
    flexShrink: 1,
  },
  rightBoxSubContainer: {
    alignItems: "flex-start",
    justifyContent: "center",
    flex: 1,
  },
  rightBoxCountSection: { flexDirection: "row", alignItems: "center" },
  leftText: { color: palettes.primary[100] },
});
