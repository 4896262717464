import { View } from "react-native";
import { Text } from "react-native-paper";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Input } from "@components/Input";
import { Control, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../buttons/PrimaryButton";
import { globalStyles } from "@styles/global";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteEducation,
  modifyEducation,
} from "@services/ApiService/verifications";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { Education } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useInvalidateProfileDetails } from "@hooks/index";
import { useFlags } from "@hooks/useFlags";

type Props = {
  education: Education;
  setEducation: Dispatch<SetStateAction<Education>>;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

type EducationFormType = { education: string };

export const EditEducationBottomSheetContent: FC<Props> = ({
  education,
  setModalVisible,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setErrorsFromResponse } = useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const { isGymsy } = useFlags();

  const schema = object().shape({
    education: string().required(t("T00014")).trim(),
  });

  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    setValue("education", education.description);
  }, [education, setValue]);

  const invalidateEducationList = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries({
        queryKey: queryKeysVerficiations.education(),
      }),
    ]);
    setModalVisible(false);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const {
    mutate: deleteSelectedEducation,
    isLoading: deleteSelectedEducationLoading,
  } = useMutation({
    mutationFn: deleteEducation,
    onSuccess: invalidateEducationList,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const {
    mutate: modifySelectedEducation,
    isLoading: modificationSelectedEducationLoading,
  } = useMutation({
    mutationFn: (description: string) =>
      modifyEducation(education.id, description),
    onSuccess: async () => {
      await invalidateEducationList();
      reset();
      setValue("education", "");
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const onSubmit = ({ education }: EducationFormType) =>
    modifySelectedEducation(education);

  return (
    <View style={globalStyles.gapLarge}>
      <Text variant="bodyLarge">{t("T00842")}</Text>
      <Input
        name="education"
        label={isGymsy ? "T00488" : "T00269"}
        control={control as unknown as Control}
        multiline
        isInBottomSheet
      />
      <PrimaryButton
        loading={deleteSelectedEducationLoading}
        label="T00840"
        mode="outlined"
        onPress={() => deleteSelectedEducation(education.id)}
      />
      <PrimaryButton
        loading={modificationSelectedEducationLoading}
        label="T00841"
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};
