import { getPhysiotherapistBasicData } from "@api/users";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { useQuery } from "@tanstack/react-query";
import { inactiveUserAlert } from "@utils/showAlert";
import { GestureResponderEvent } from "react-native";
import { Card } from "../index";
import { PhysiotherapistCardProps } from "./PhysiotherapistCard.types";

const PhysiotherapistCard = ({
  initialData: { id },
  onPress,
  redirectsToProfile = true,
  ...props
}: PhysiotherapistCardProps) => {
  const { data, isFetched, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(id),
    queryFn: async () => await getPhysiotherapistBasicData(id),
  });

  const handleOnPress = (e: GestureResponderEvent) => {
    if (!data?.isActive && redirectsToProfile) return inactiveUserAlert();
    return onPress(e);
  };
  return (
    <Card
      initialData={{
        id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        avatar: data?.avatar,
      }}
      subtitle={
        data?.licence?.specialization?.name &&
        data?.licence?.specialization?.name
      }
      mode="outlined"
      isFetched={isFetched}
      isError={isError}
      refetch={refetch}
      onPress={!isError && onPress && handleOnPress}
      flat={!onPress}
      {...props}
    />
  );
};

export default PhysiotherapistCard;
