import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import { CreateAndEditTrainingType } from "../training.types";

type CreateTrainingContextProps = {
  exercises: CtxExerciseType[];
  addExercise: (exercise: CtxExerciseType) => void;
  editExerciseSeries: (
    exercise: CtxExerciseType,
    exerciseIndex: number,
  ) => void;
  removeExercise: (exerciseIndex: number) => void;
  setExercises: Dispatch<SetStateAction<CtxExerciseType[]>>;
  patientId: number;
  setPatientId: Dispatch<SetStateAction<number>>;
  training: CreateAndEditTrainingType;
  setTraining: Dispatch<SetStateAction<CreateAndEditTrainingType>>;
  popCount: number;
  setPopCount: Dispatch<SetStateAction<number>>;
};

export const CreateTrainingContext = createContext<CreateTrainingContextProps>(
  {} as CreateTrainingContextProps,
);

export const CreateTrainingContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [exercises, setExercises] = useState<CtxExerciseType[]>([]);
  const [patientId, setPatientId] = useState<number | undefined>();
  const [training, setTraining] = useState<CreateAndEditTrainingType>({
    name: "",
    description: "",
    exercises: [],
  });
  const [popCount, setPopCount] = useState<number | undefined>();

  const addExercise = (exercise: CtxExerciseType) =>
    setExercises(prevState => [...prevState, exercise]);

  const editExerciseSeries = (
    exercise: CtxExerciseType,
    exerciseIndex: number,
  ) => {
    setExercises(prevState => {
      const updatedExercises = [...prevState];
      updatedExercises[exerciseIndex] = exercise;
      return updatedExercises;
    });
  };

  const removeExercise = (exerciseIndex: number) =>
    setExercises(prevState =>
      prevState.filter((_, index) => index !== exerciseIndex),
    );

  return (
    <CreateTrainingContext.Provider
      value={{
        exercises,
        addExercise,
        editExerciseSeries,
        removeExercise,
        setExercises,
        patientId,
        setPatientId,
        training,
        setTraining,
        popCount,
        setPopCount,
      }}>
      {children}
    </CreateTrainingContext.Provider>
  );
};
