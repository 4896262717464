import WebViewModal from "@components/Modals/WebViewModal";
import { getSnackbarText } from "@components/Snackbar/utils";
import VerificationTileWithProgress from "@components/Tile/VerificationTileWithProgress";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { FetchError } from "@components/errors";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { useAuth } from "@contexts/AuthContext/auth";
import { ProfileStatus } from "@contexts/AuthContext/user.types";
import { TransKey } from "@globalTypes/i18next";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useAppVersion } from "@hooks/useAppVersion";
import {
  ProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ProfileCompletionSection } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/ProfileCompletionSection";
import { getRehabVerificationProgress } from "@services/ApiService/verifications";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { isANDROID, isWeb } from "@utils/constants";
import { getWebViewData, ModalType } from "@utils/getWebViewData";
import { handleReviewApp } from "@utils/handleReviewApp";
import { myProfileDevSections } from "@utils/index";
import { showAlert, showAlertWithCustomButtons } from "@utils/showAlert";
import { showSnackbar } from "@utils/snackbarHelper";
import Lottie, { AnimationObject } from "lottie-react-native";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Linking, RefreshControl, ScrollView, View } from "react-native";
import { hideMessage } from "react-native-flash-message";
import { ActivityIndicator, Text } from "react-native-paper";
import { HelpCenterIssue } from "./CommonProfile/HelpCenter/helpCenter.types";
import ProfileSection, {
  ProfileSectionProps,
} from "./CommonProfile/ProfileSection";
import { queryKeysVerficiations } from "./PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { RehabVerificationProgressResponse } from "./PhysiotherapistVerification/verification.types";
import { useFlags } from "@hooks/useFlags";
import { BounceWrapper } from "@components/BounceWrapper";

const CONFETTI_ANIMATION_SIZE = 150;

const MyProfilePhysiotherapist: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ProfileStackParamsList & RootStackParamList,
      "Profile"
    >
  >
> = ({ navigation: { navigate } }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const {
    signOut,
    user: { id, profileStatus },
  } = useAuth();
  const {
    data: { sessionEnabled },
  } = useContext(AppointmentSessionContext);
  const isFocused = useIsFocused();
  const isVerified = profileStatus === ProfileStatus.Verified;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [verificationTileShouldBounce, setVerificationTileShouldBounce] =
    useState(false);
  const [snackbarText, setSnackbarText] = useState<TransKey>();
  const [modalProps, setModalProps] = useState({ uri: "", title: "" });
  const appVersion = useAppVersion();
  const {
    flags: {
      FEATURE_MEDICAL_RECORDS,
      FEATURE_ABOUT_APPLICATION,
      FEATURE_FORUM,
    },
  } = useFlags();

  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: getRehabVerificationProgress,
    queryKey: queryKeysVerficiations.verificationProgress(),
  });

  const [verificationProgress, setVerificationProgress] =
    useState<RehabVerificationProgressResponse>(data);

  const getOffsetValue = useCallback(() => {
    if (isWeb) return -width / 7;
    return -2 * CONFETTI_ANIMATION_SIZE - (isANDROID ? 40 : 30);
  }, [width]);

  const refetchAndSetNewData = useCallback(async () => {
    await refetch();
    if (
      verificationProgress?.progressValue !== null &&
      data?.progressValue > verificationProgress?.progressValue
    ) {
      setSnackbarText(getSnackbarText());
      snackbarText &&
        showSnackbar({
          message: t(snackbarText),
          rightIcon: "close",
          onPressRightIcon: () => {
            hideMessage();
            setSnackbarText(undefined);
          },
          onHide: () => setSnackbarText(undefined),
          renderBeforeContent: () => (
            <Lottie
              source={
                require("@assets/animations/snackbars/ConfettiAnimation.json") as AnimationObject
              }
              autoPlay
              loop={false}
              style={[
                {
                  width,
                  height: isWeb ? 300 : width,
                  position: "absolute",
                  left: isWeb
                    ? getOffsetValue() + CONFETTI_ANIMATION_SIZE / 2
                    : -40,
                },
                isWeb
                  ? { bottom: getOffsetValue() - CONFETTI_ANIMATION_SIZE }
                  : { top: getOffsetValue() },
              ]}
            />
          ),
        });
    }

    setVerificationProgress(data);
  }, [
    data,
    getOffsetValue,
    refetch,
    snackbarText,
    t,
    verificationProgress?.progressValue,
    width,
  ]);

  useEffect(() => {
    if (isFocused && data?.progressValue < 80) {
      setVerificationTileShouldBounce(true);
    } else setVerificationTileShouldBounce(false);
  }, [isFocused, data?.progressValue]);

  useEffect(() => {
    if (isFocused) void refetchAndSetNewData();
  }, [isFocused, refetchAndSetNewData]);

  const unverifiedAccountAlert = () => showAlert(t("T00885"));

  const handleListItemPress = (onPress: () => void) => {
    if (!isVerified) return unverifiedAccountAlert();
    onPress();
  };

  const openModal = async (type: ModalType) => {
    const { uri, title } = getWebViewData(type);

    setModalProps(() => {
      return {
        uri,
        title,
      };
    });

    if (isWeb) {
      await Linking.openURL(uri);
    } else {
      setIsModalVisible(true);
    }
  };

  const onPressSignout = () => {
    return sessionEnabled ? alert(t("T01313")) : signOut();
  };

  const profileSections: ProfileSectionProps["data"][] = [
    {
      title: "T00388",
      items: [
        {
          name: "T01322",
          onPress: () => navigate("RewardsStack"),
          icon: "wallet-giftcard",
          style: { borderWidth: 1, borderColor: palettes.primary[70] },
          description: "T01323",
        },
        {
          name: "T01324",
          onPress: () =>
            navigate("EnterInvitationOrPromotionCode", {
              screenType: "invitationCode",
            }),
          icon: "hand-wave-outline",
        },
        {
          name: "T01325",
          onPress: () =>
            isWeb
              ? showAlertWithCustomButtons({
                  title: t("T01326"),
                  message: "",
                  confirmButton: {
                    text: t("T00163"),
                  },
                })
              : navigate("EnterInvitationOrPromotionCode", {
                  screenType: "promotionCode",
                }),
          icon: "tag-outline",
        },
        {
          name: "T00416",
          onPress: () => navigate("MyRatings"),
          icon: "star-outline",
        },
        {
          name: "T00080",
          onPress: () => navigate("MedicalRecords"),
          icon: "folder-outline",
          hidden: !FEATURE_MEDICAL_RECORDS,
        },
      ],
    },
    {
      title: "T00710",
      items: [
        {
          name: "T00789",
          onPress: () => navigate("TransactionHistoryStack"),
          icon: "cash-multiple",
        },
        {
          name: "T00904",
          onPress: () => navigate("RehabStatistics"),
          icon: "chart-bar-stacked",
        },
      ],
    },
    {
      title: "T00389",
      items: [
        {
          name: "T00088",
          onPress: () => navigate("HelpCenterStack"),
          icon: "lifebuoy",
        },
        {
          name: "T00094",
          onPress: () => openModal(ModalType.privacy),
          icon: "text-box-outline",
        },
        {
          name: "T00093",
          onPress: () => openModal(ModalType.terms),
          icon: "text-box-outline",
        },
        {
          name: "T00109",
          onPress: () => navigate("AreYouPhysiotherapist"),
          icon: "cellphone-information",
        },
        {
          name: "T00110",
          onPress: () => openModal(ModalType.about_the_application_project),
          icon: "information-outline",
          hidden: !FEATURE_ABOUT_APPLICATION,
        },
        {
          name: "T00112",
          onPress: () => handleListItemPress(() => navigate("ForumStack")),
          icon: "forum-outline",
          hidden: !FEATURE_FORUM,
        },
      ],
    },
    {
      title: "T00002",
      items: [
        {
          name: "T01454",
          onPress: () => navigate("PushNotificationsSettingsStack"),
          icon: "bell-outline",
        },
        {
          name: "T00100",
          onPress: () => navigate("ApplicationSettings"),
          icon: "cog-outline",
        },
        {
          name: "T00905",
          onPress: handleReviewApp,
          icon: "thumb-up-outline",
        },
        {
          name: "T01468",
          onPress: () =>
            navigate("HelpCenterStack", {
              screen: "ContactForm",
              params: { defaultIssue: HelpCenterIssue.AccountRemoval },
            }),
          icon: "delete-outline",
        },
        {
          name: "T00045",
          onPress: onPressSignout,
          icon: "logout",
        },
      ],
    },
  ];

  if (__DEV__)
    profileSections.push({
      title: "DEV Section" as TransKey,
      items: myProfileDevSections(navigate),
    });

  const { gapLarge, gapMedium, scrollContainer } = globalStyles;

  return (
    <>
      <ScrollView
        contentContainerStyle={[gapLarge, scrollContainer]}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }>
        <PhysiotherapistCardWithRating initialData={{ id }} />
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : (
          <View style={gapMedium}>
            {data?.progressValue !== 100 && (
              <ProfileCompletionSection data={data} />
            )}
            <BounceWrapper shouldBounce={verificationTileShouldBounce}>
              <VerificationTileWithProgress
                mode="outlined"
                onPress={() => navigate("ProfileCompletionStack")}
              />
            </BounceWrapper>
          </View>
        )}
        {profileSections.map((e, i) => (
          <ProfileSection
            key={`physioProfileSection-${e.title}-${i}`}
            data={e}
          />
        ))}
        <Text>{`${t("T00121")}: ${appVersion}`}</Text>
        <WebViewModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          title={modalProps.title}
          uri={modalProps.uri}
          type="web"
          modalAnimation="slide"
        />
      </ScrollView>
    </>
  );
};

export default MyProfilePhysiotherapist;
