/* eslint-disable react-hooks/exhaustive-deps */
import {
  SvgCamera,
  SvgCameraDisabled,
  SvgFullscreenOff,
  SvgFullscreenOn,
  SvgMic,
  SvgMicMuted,
} from "@assets/svg";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { PrimaryButton } from "@components/buttons";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useErrors } from "@hooks/useErrors";
import {
  createCommitment,
  deleteCommitment,
  getSpecialTrainingCommitments,
} from "@services/ApiService/trainings";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import {
  spacing16,
  spacing24,
  spacing32,
  spacing40,
  spacing8,
} from "@styles/spacing";
import { theme } from "@styles/theme";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isIOS, isWeb } from "@utils/constants";
import { AxiosError } from "axios";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Animated,
  AppState,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import {
  State as GestureState,
  HandlerStateChangeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
} from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";
import { useZoomCall } from "../../../zoom/screens/call-screen";
import { queryKeysSpecialTrainings } from "../../TrainingsAndExercises/queryKeysTrainingsAndExercises";
import SessionChat from "./SessionChat";
import SessionInformation from "./SessionInformation/SessionInformation";
import SharedRecords from "./SharedRecords";
import { MicAndCamBlockedType } from "@components/Appointment/types";
import { useCheckAndRequestMicAndCamPermissions } from "@hooks/permissions/useCheckAndRequestMicAndCamPermissions";
import { useFlags } from "@hooks/useFlags";

export const AppointmentSession = () => {
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerSize, setContainerSize] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });
  const [isFullscreen, setFullscreen] = useState(false);
  const [isCommitmentActive, setIsCommitmentActive] = useState(false);
  const [buttonAndIconContainerHeight, setButtonAndIconContainerHeight] =
    useState<number>(0);
  const [microphoneBlocked, setMicrophoneBlocked] = useState(false);
  const [cameraBlocked, setCameraBlocked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const { width, height } = useWindowDimensions();
  const {
    data: contextData,
    updateData,
    setIsTabsVisible,
  } = useContext(AppointmentSessionContext);
  const appState = useRef(AppState.currentState);
  const { bottom } = useSafeAreaInsets();
  const {
    updateMicAndCamIconsState,
    displayMicCamOrBothBlockedAlert,
    requestMicAndCamPermissions,
  } = useCheckAndRequestMicAndCamPermissions();
  const {
    flags: { FEATURE_MEDICAL_RECORDS },
  } = useFlags();

  const {
    buttonAndIconContainer,
    iconsContainer,
    chatContainer,
    floatingViewContainer,
    floatingTouchStyle,
    interlocutorCameraView,
    myCameraView,
    bottomSheetHeaderStyle,
  } = styles;

  const zoomFloatingViewSizes = {
    width: width / 3.5,
    height: width / 3.5,
  };

  const {
    context,
    sendChatMessage,
    chatMessages,
    onPressLeave,
    isMuted,
    onPressAudio,
    isVideoOn,
    onPressVideo,
    clearHasNewMessage,
    newMessagesCount,
    renderInterlocutorCameraView,
    renderMyCameraView,
  } = useZoomCall({
    sessionName: `${contextData?.appointmentId}`,
    token: contextData?.sessionToken,
  });

  const { top } = useSafeAreaInsets();

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "first", title: t("T00147") },
    {
      key: "second",
      title: newMessagesCount
        ? t("T00154") + ` (${newMessagesCount})`
        : t("T00154"),
    },
  ]);

  useEffect(() => {
    if (FEATURE_MEDICAL_RECORDS) {
      routes.push({ key: "third", title: t("T00155") });
    }
  }, []);

  const pan = useRef(new Animated.ValueXY()).current;
  const panStyle: {
    transform: (
      | { translateX: Animated.AnimatedDiffClamp<number | string> }
      | {
          translateY: Animated.AnimatedDiffClamp<number | string>;
        }
    )[];
  } = useMemo(
    () => ({
      transform: [
        {
          translateX: Animated.diffClamp(
            pan.x,
            0,
            width - containerSize.width - spacing32,
          ),
        },
        {
          translateY: Animated.diffClamp(
            pan.y,
            0,
            height - containerSize.height - 5 * top,
          ),
        },
      ],
    }),
    [
      containerSize.height,
      containerSize.width,
      height,
      pan.x,
      pan.y,
      top,
      width,
    ],
  );

  const handleBlockedMicAndCam = ({
    isMicBlocked,
    isCameraBlocked,
  }: MicAndCamBlockedType) => {
    setMicrophoneBlocked(isMicBlocked);
    setCameraBlocked(isCameraBlocked);
    updateData({ cameraBlocked: isCameraBlocked });
  };

  useEffect(() => {
    const subscription = AppState.addEventListener(
      "change",
      async nextAppState => {
        if (appState.current === "background" && nextAppState === "active") {
          await updateMicAndCamIconsState(handleBlockedMicAndCam);
        }
        appState.current = nextAppState;
      },
    );

    return () => {
      subscription.remove();
    };
  }, []);

  const onPanGestureEvent = Animated.event(
    [
      {
        nativeEvent: {
          translationX: pan.x,
          translationY: pan.y,
        },
      },
    ],
    { useNativeDriver: false },
  );

  const onPanHandlerStateChange = useCallback(
    (event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>) => {
      if (event.nativeEvent.state === GestureState.END) pan.extractOffset();
    },
    [pan],
  );

  const messages = useMemo(() => {
    const tempMessages = [...chatMessages];
    return tempMessages.reverse();
  }, [chatMessages]);

  const FirstRoute = useCallback(
    () => (
      <SessionInformation
        isCommitmentActive={isCommitmentActive}
        setIsCommitmentActive={setIsCommitmentActive}
        isCommitmentLoading={isLoading}
        isCommitmentError={isError}
        refetchError={refetch}
      />
    ),
    [isCommitmentActive, setIsCommitmentActive],
  );

  const SecondRoute = useCallback(
    () => (
      <View style={[chatContainer, { paddingBottom: bottom / 2 }]}>
        <SessionChat
          chatMessages={messages}
          sendChatMessage={sendChatMessage}
          clearNewMessageIndicator={clearHasNewMessage}
          openBottomSheet={() => setModalVisible(true)}
          disableExtraText
        />
      </View>
    ),
    [messages],
  );

  const ThirdRoute = useCallback(() => {
    if (!FEATURE_MEDICAL_RECORDS) return null;
    return <SharedRecords appointmentId={contextData?.appointmentId} />;
  }, []);

  const renderScene = useMemo(
    () =>
      SceneMap(
        FEATURE_MEDICAL_RECORDS
          ? {
              first: FirstRoute,
              second: SecondRoute,
              third: ThirdRoute,
            }
          : {
              first: FirstRoute,
              second: SecondRoute,
            },
      ),
    [chatMessages, isCommitmentActive, setIsCommitmentActive],
  );

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysSpecialTrainings.commitmentsToAppointmentList(
      contextData?.appointmentId,
    ),
    queryFn: async () =>
      await getSpecialTrainingCommitments(contextData?.appointmentId),
    onSuccess: data => setIsCommitmentActive(!!data.length),
  });

  const { mutate, isLoading: isMutationLoading } = useMutation({
    mutationFn: async () => {
      if (data?.length === 0 && isCommitmentActive)
        await createCommitment(contextData?.appointmentId);
      if (data?.length > 0 && !isCommitmentActive)
        await deleteCommitment(data[0].id);
    },
    onSuccess: async () =>
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysSpecialTrainings.detail(
            contextData?.appointmentId,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysSpecialTrainings.list(),
        }),
      ]),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  useEffect(
    () =>
      updateData({
        containerSizes: {
          height: containerSize.height,
          width: containerSize.width,
        },
      }),
    [containerSize, updateData],
  );

  const updatePermissions = async () => {
    if (isIOS) {
      await requestMicAndCamPermissions(
        undefined,
        handleBlockedMicAndCam,
        false,
      );
    }
    await updateMicAndCamIconsState(handleBlockedMicAndCam);
  };

  useEffect(() => {
    void updatePermissions();
  }, []);

  const handleFinishAndGoBack = useCallback(
    (force = false) =>
      onPressLeave(force, () => {
        mutate();
        updateData({ sessionEnabled: false });
      }),
    [mutate, onPressLeave, updateData],
  );

  const renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: palettes.primary[40] }}
      style={{ backgroundColor: theme.colors.surface }}
      renderLabel={({ route }) => (
        <Text style={{ color: palettes.primary[0] }} variant="titleSmall">
          {route.title}
        </Text>
      )}
    />
  );

  const renderBottomSheetHeader = useCallback(
    (cb: () => void) => (
      <View style={bottomSheetHeaderStyle}>
        <PrimaryButton
          mode="text"
          label="T01178"
          onPress={cb}
          icon="chevron-down"
        />
      </View>
    ),
    [],
  );

  if (contextData.displayInFloatingView) {
    return (
      <PanGestureHandler
        onGestureEvent={onPanGestureEvent}
        onHandlerStateChange={onPanHandlerStateChange}>
        <Animated.View
          style={[floatingViewContainer, panStyle]}
          onLayout={({ nativeEvent }) =>
            setContainerSize({
              height: nativeEvent.layout.height,
              width: nativeEvent.layout.width,
            })
          }>
          {isWeb && <View style={{ marginTop: spacing32 }}>{context}</View>}
          {!isWeb && renderMyCameraView && (
            <View style={[zoomFloatingViewSizes, myCameraView]}>
              {renderMyCameraView()}
            </View>
          )}
          {!isWeb && renderInterlocutorCameraView && (
            <View style={[zoomFloatingViewSizes, interlocutorCameraView]}>
              {renderInterlocutorCameraView()}
            </View>
          )}
          <TouchableOpacity
            onPress={() => updateData({ displayInFloatingView: false })}
            activeOpacity={0}
            style={floatingTouchStyle}
          />
        </Animated.View>
      </PanGestureHandler>
    );
  }

  return (
    <>
      <SafeAreaView
        edges={["right", "left"]}
        key={`appointment-session-container-${
          contextData.displayInFloatingView ? "floating" : "fullscreen"
        }`}
        style={{
          flex: 1,
          maxHeight: height - (isWeb ? buttonAndIconContainerHeight : 0),
          backgroundColor: isFullscreen ? palettes.neutralVariant[30] : null,
        }}
        onLayout={({ nativeEvent }) => {
          if (containerHeight === 0) {
            setContainerHeight(nativeEvent.layout.height);
          }
          setContainerSize({
            height,
            width,
          });
        }}>
        <View
          style={[
            {
              flex: 1,
            },
            // I know it looks stupid, but it fixes layout failure on Android, don't delete it
            !isIOS && { borderColor: "black", borderWidth: 2 },
          ]}>
          {!contextData.displayInFloatingView && context}
          <View
            style={buttonAndIconContainer}
            onLayout={({ nativeEvent }) =>
              setButtonAndIconContainerHeight(nativeEvent.layout.height)
            }>
            <PrimaryButton
              label="T00153"
              onPress={() => handleFinishAndGoBack()}
              loading={isMutationLoading}
              disabled={isMutationLoading}
              style={{ minWidth: "33%" }}
            />
            <View style={iconsContainer}>
              <TouchableOpacity
                onPress={async () => {
                  if (microphoneBlocked) {
                    displayMicCamOrBothBlockedAlert(false, false);
                  } else {
                    await onPressAudio();
                  }
                }}>
                {isMuted || microphoneBlocked ? (
                  <SvgMicMuted
                    iconColor={
                      microphoneBlocked
                        ? palettes.error[40]
                        : palettes.primary[100]
                    }
                  />
                ) : (
                  <SvgMic />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  if (cameraBlocked) {
                    displayMicCamOrBothBlockedAlert(true, false);
                  } else {
                    await onPressVideo();
                  }
                }}>
                {isVideoOn && !cameraBlocked ? (
                  <SvgCamera />
                ) : (
                  <SvgCameraDisabled
                    iconColor={
                      cameraBlocked ? palettes.error[40] : palettes.primary[100]
                    }
                  />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => updateData({ displayInFloatingView: true })}
                style={{
                  width: spacing24,
                  height: spacing24,
                }}>
                <SvgFullscreenOff style={{ margin: 5 }} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  width: spacing24,
                  height: spacing24,
                }}
                onPress={() => {
                  setFullscreen(v => !v);
                  isWeb && setIsTabsVisible && setIsTabsVisible(v => !v);
                }}>
                <SvgFullscreenOn style={{ margin: 5 }} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={{ flex: isFullscreen ? 1 : 0 }}>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{ width }}
            style={{ backgroundColor: palettes.primary[100] }}
            renderTabBar={renderTabBar}
          />
        </View>
      </SafeAreaView>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        disablePanHandlers
        renderHeader={renderBottomSheetHeader}>
        <View
          style={{
            height: height / 2.25,
            alignItems: "center",
          }}>
          <SessionChat
            chatMessages={messages}
            sendChatMessage={sendChatMessage}
            clearNewMessageIndicator={clearHasNewMessage}
            autoFocus
            disableExtraText
          />
        </View>
      </BottomModalContainer>
    </>
  );
};

const styles = StyleSheet.create({
  interlocutorCameraView: {
    backgroundColor: palettes.primary[100],
    borderTopEndRadius: spacing16,
    borderBottomEndRadius: spacing16,
  },
  myCameraView: {
    backgroundColor: palettes.neutral[25],
    borderTopStartRadius: spacing16,
    borderBottomStartRadius: spacing16,
  },
  iconsContainer: {
    ...globalStyles.gapLarge,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: spacing16,
  },
  buttonAndIconContainer: {
    backgroundColor: palettes.neutralVariant[30],
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: spacing16,
    paddingVertical: spacing8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tabContainer: {
    minHeight: "43%",
    maxHeight: "43%",
    height: "43%",
  },
  fullScreenTabContainer: {
    minHeight: "100%",
    maxHeight: "100%",
    height: "100%",
    backgroundColor: theme.colors.surface,
    paddingTop: isIOS ? spacing40 : 0,
  },
  chatContainer: {
    flex: 1,
  },
  floatingViewContainer: {
    position: "absolute",
    flexDirection: "row",
    shadowColor: palettes.primary[0],
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: isWeb ? 0 : 0.29,
    shadowRadius: 4.65,
    elevation: 7,
  },
  floatingTouchStyle: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  bottomSheetHeaderStyle: {
    width: "100%",
    alignItems: "center",
  },
});

export default AppointmentSession;
