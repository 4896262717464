import {
  assignProphylacticTraining,
  assignTrainingToPatient,
  getLibraryTraining,
  getSpecialTrainingCommitmentsForSpecificPatient,
  updateSpecialTraining,
} from "@api/trainings";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Calendar, DateArrayType } from "@components/Calendar";
import { AbsoluteBlurredFooter } from "@components/Footers";
import Snackbar from "@components/Snackbar/Snackbar";
import { TrainingTile } from "@components/Tile";
import { GeneralTrainingTile } from "@components/Tile/training/GeneralTrainingTile";
import TrainingTileWithoutTrainingId from "@components/Tile/training/TrainingTileWithoutTrainingId";
import { PrimaryButton } from "@components/buttons";
import { UserCard } from "@components/cards";
import { FetchError, GeneralError } from "@components/errors";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import SetFrequencyModalContent from "@screens/TrainingsAndExercises/SetASchedule/SetFrequencyModalContent";
import { globalStyles } from "@styles/global";
import { spacing24, spacing8 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getDate, getFormattedDayWithMonth } from "@utils/date";
import { getDatesFromPeriodByFrequency } from "@utils/index";
import { removeNullFields } from "@utils/objectHelpers";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert, ScrollView, View } from "react-native";
import { ActivityIndicator, HelperText, Text } from "react-native-paper";
import { Host } from "react-native-portalize";
import { object, string } from "yup";
import { CreateTrainingContext } from "../Physiotherapist/createTraining.context";
import {
  queryKeysIndividualTrainings,
  queryKeysProphylacticTrainings,
  queryKeysSpecialTrainings,
  queryKeysTraining,
} from "../queryKeysTrainingsAndExercises";
import {
  AssignProphylacticParams,
  AssignTrainingsToPatientData,
  CreateAndEditTrainingType,
  TrainingDayDate,
} from "../training.types";
import { useFrequencyOptions } from "@hooks/useFrequencyValues";
import { formatExercisesForApi } from "../Physiotherapist/CreateAndEditTraining";
import { TransKey } from "@globalTypes/i18next";
import { hideMessage } from "react-native-flash-message";
import { showSnackbar } from "@utils/snackbarHelper";

type SetTrainingScheduleForm = {
  dateFrom: string;
  dateTo: string;
  frequency: number;
};

type SetAScheduleProps = {
  isProphylactic?: boolean;
  patientId: number;
  trainingId?: number;
  isNewTraining?: boolean;
  editedTraining?: boolean;
  navigateAfterAssignProphylactic?: () => void;
  onCloseAfterAssignProphylactic?: () => void;
  navigateAfterAssignTrainingToPatient?: (cb?: () => void) => void;
};

export type DateInputs = "dateFrom" | "dateTo";

const SetASchedule = ({
  isProphylactic = false,
  patientId,
  trainingId,
  isNewTraining,
  editedTraining,
  navigateAfterAssignProphylactic,
  onCloseAfterAssignProphylactic,
  navigateAfterAssignTrainingToPatient,
}: SetAScheduleProps) => {
  const { gapLarge, gapMedium, gapSmall, loading, scrollContainer } =
    globalStyles;
  const { training } = useContext(CreateTrainingContext);
  const isNewOrEditedTraining = isNewTraining || editedTraining;
  const [showFormSchedule, setShowFormSchedule] = useState(true);
  const [days, setDays] = useState<TrainingDayDate[]>([]);
  const [frequencyModalVisible, setFrequencyModalVisible] = useState(false);
  const [selectDaysModalVisible, setSelectDaysModalVisible] = useState(false);
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(
    isNewOrEditedTraining || false,
  );

  const [selectedDays, setSelectedDays] = useState<DateArrayType[]>([
    { date: new Date() },
  ]);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const {
    colors: { error },
  } = useAppTheme();
  const [dateInput, setDateInput] = useState<DateInputs>();

  const { t } = useTranslation();
  const { errors, generalError, setErrorsFromResponse } = useErrors({
    showModalOnGeneralError: false,
  });
  const frequencyOptions = useFrequencyOptions();
  const hideSnackbar = () => setSnackbarVisible(false);

  const schema = useMemo(() => {
    const fieldIsRequired = t("T00014");
    const dateRules = string().required(fieldIsRequired);

    return object().shape({
      dateFrom: dateRules,
      dateTo: dateRules,
    });
  }, [t]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors: errorsFromForm },
    setValue,
    trigger,
    setFocus,
  } = useForm<Partial<SetTrainingScheduleForm>>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      frequency: frequencyOptions[0].value,
    } as SetTrainingScheduleForm,
  });

  const queryClient = useQueryClient();

  const {
    data: commitmentData,
    refetch: refetchCommitment,
    isError: isRefetchCommitmentError,
    isLoading: isRefetchCommitmentLoading,
  } = useQuery({
    queryKey:
      queryKeysSpecialTrainings.commitmentsListForSpecificPatient(patientId),
    queryFn: async () =>
      await getSpecialTrainingCommitmentsForSpecificPatient(patientId),
    enabled: false,
  });
  const showTrainingAssignedSnackbar = useCallback(() => {
    showSnackbar({
      message: t("T01543"),
      rightIcon: "close",
      onPressRightIcon: hideMessage,
    });
  }, [t]);

  const assignTrainingToPatientSuccessHandler = async () => {
    !isProphylactic && (await refetchCommitment());
    if (commitmentData?.length) {
      mutateSpecialTraining(commitmentData[0].id);
    }
    await queryClient.invalidateQueries({
      queryKey: queryKeysIndividualTrainings.assignedToPatientList(patientId),
    });
    navigateAfterAssignTrainingToPatient(showTrainingAssignedSnackbar);
    reset();
  };

  const updateSpecialTrainingSuccessHandler = async () =>
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey:
          queryKeysSpecialTrainings.commitmentsListForSpecificPatient(
            patientId,
          ),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysSpecialTrainings.list(),
      }),
    ]);

  const assignProphylacticSuccessHandler = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysIndividualTrainings.activeList(true),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysProphylacticTrainings.list(),
      }),
    ]);

    Alert.alert(t("T01035"), "", [
      {
        text: t("T00540"),
        onPress: onCloseAfterAssignProphylactic,
      },
      {
        text: t("T01036"),
        onPress: navigateAfterAssignProphylactic,
      },
    ]);
  }, [
    navigateAfterAssignProphylactic,
    onCloseAfterAssignProphylactic,
    queryClient,
    t,
  ]);

  const {
    mutate: mutateSpecialTraining,
    isLoading: isUpdateCommitmentLoading,
  } = useMutation({
    mutationFn: updateSpecialTraining,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: updateSpecialTrainingSuccessHandler,
  });

  const isNotProphylacticAndHasTrainingId = !isProphylactic && !!trainingId;
  const {
    data,
    isLoading: isGettingLibraryTrainingLoading,
    isError,
    refetch,
  } = useQuery({
    enabled: isNotProphylacticAndHasTrainingId,
    queryKey: queryKeysTraining.detail(trainingId, {
      fullData: true,
    }),
    queryFn: async () =>
      await getLibraryTraining<CreateAndEditTrainingType>(trainingId, true),
  });

  const {
    mutate: assignIndividualTrainingToPatient,
    isLoading: isAssignIndividualTrainingLoading,
  } = useMutation({
    mutationFn: assignTrainingToPatient,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: assignTrainingToPatientSuccessHandler,
  });

  const { mutate: assignProphylactic, isLoading: isAssignProphylacticLoading } =
    useMutation({
      mutationFn: async (params: AssignProphylacticParams) =>
        await assignProphylacticTraining(trainingId, params),
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: assignProphylacticSuccessHandler,
    });

  const onFrequencySubmit = ({
    frequency,
    dateFrom,
    dateTo,
  }: SetTrainingScheduleForm) => {
    const dates = getDatesFromPeriodByFrequency({
      frequency,
      dateFrom,
      dateTo,
    });
    setDays(dates);
    handleCloseFrequencyModal();
  };
  const handleCloseFrequencyModal = useCallback(() => {
    setFrequencyModalVisible(false);
    if (showFormSchedule && isValid) {
      setShowFormSchedule(false);
    }
  }, [showFormSchedule, isValid]);

  const handleAssignTraining = () => {
    if (isProphylactic) return assignProphylactic({ days });

    const exercises = trainingId ? [...data.exercises] : training.exercises;
    exercises.forEach(e => removeNullFields(e));

    const exercisesFormattedForApi = formatExercisesForApi(exercises);

    const params: AssignTrainingsToPatientData = {
      patient: patientId.toString(),
      isLibrary: false,
      days,
      name: trainingId ? data?.name : training.name,
      exercises: exercisesFormattedForApi,
    };

    if (data?.description || training.description) {
      params.description = data?.description || training.description;
    }

    assignIndividualTrainingToPatient(params);
  };

  const disableExecutionDaysModal = () => {
    setSelectDaysModalVisible(false);
  };

  const onSubmitSelectExecutionDays = (dates: DateArrayType[]) => {
    setDays(
      dates
        .sort(
          (prev, next) =>
            new Date(prev.date).getTime() - new Date(next.date).getTime(),
        )
        .map(({ date }) => ({
          executionDate: format(new Date(date), "yyyy-MM-dd"),
        })),
    );
    setSelectedDays(dates);
    disableExecutionDaysModal();
  };

  const onSelectDate = useCallback(
    async (data: DateArrayType, dontClose?: boolean) => {
      if (dontClose) return;
      const date = format(data.date, "yyyy-MM-dd");
      setCalendarModalVisible(false);
      setTimeout(() => setFrequencyModalVisible(true), 1000);
      setValue(dateInput, date);
      await trigger(dateInput);
      setSelectedDays([data]);
    },
    [setValue, dateInput, trigger],
  );

  const onInputPress = (input: DateInputs) => {
    setFocus(input);
    setDateInput(input);
    setFrequencyModalVisible(false);
    setTimeout(() => setCalendarModalVisible(true), 700);
  };
  const refetchQueries = async () =>
    await Promise.all([refetch(), !isProphylactic && refetchCommitment()]);

  const pickerError =
    (errorsFromForm?.frequency?.message && (
      <HelperText type="error">{errorsFromForm?.frequency?.message}</HelperText>
    )) ||
    errors?.frequency?.map(({ message }, index) => (
      <HelperText type="error" key={index}>
        {message}
      </HelperText>
    ));

  const isAssignTrainingLoading = useMemo(
    () => isAssignIndividualTrainingLoading || isUpdateCommitmentLoading,
    [isAssignIndividualTrainingLoading, isUpdateCommitmentLoading],
  );

  const isMutationLoading = useMemo(
    () =>
      isProphylactic ? isAssignProphylacticLoading : isAssignTrainingLoading,
    [isAssignTrainingLoading, isAssignProphylacticLoading, isProphylactic],
  );

  if (
    !isProphylactic &&
    ((isNotProphylacticAndHasTrainingId && isGettingLibraryTrainingLoading) ||
      isRefetchCommitmentLoading)
  )
    return <ActivityIndicator style={loading} />;

  if (isError || isRefetchCommitmentError)
    return <FetchError action={refetchQueries} />;

  return (
    <Host>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          { paddingBottom: footerHeight },
        ]}>
        <View style={gapLarge}>
          <View style={gapMedium}>
            {!isProphylactic && (
              <UserCard initialData={{ id: patientId }} mode="outlined" flat />
            )}
            {trainingId ? (
              isProphylactic ? (
                <GeneralTrainingTile id={trainingId} />
              ) : (
                <TrainingTile trainingId={trainingId} />
              )
            ) : (
              <TrainingTileWithoutTrainingId data={training} />
            )}
          </View>
          <View style={gapMedium}>
            {days.length < 1 ? (
              <Text variant="bodyMedium">
                {t(isProphylactic ? "T01028" : "T00257")}
              </Text>
            ) : (
              <>
                <View style={gapSmall}>
                  <Text variant="titleMedium">{t("T00251")}:</Text>
                  <Text variant="bodyMedium">
                    {getDate(days[0]?.executionDate)}
                  </Text>
                </View>
                <View style={gapSmall}>
                  <Text variant="titleMedium">{t("T00252")}:</Text>
                  <Text variant="bodyMedium">
                    {getDate(days[days.length - 1]?.executionDate)}
                  </Text>
                </View>
                <View style={gapSmall}>
                  <Text variant="titleMedium">{t("T00260")}:</Text>
                  <Text variant="bodyMedium">{days.length}</Text>
                </View>
                <View style={gapSmall}>
                  <Text variant="titleMedium">{t("T00261")}:</Text>
                  <Text variant="bodyMedium">
                    {days
                      .map(({ executionDate }) =>
                        getFormattedDayWithMonth(new Date(executionDate)),
                      )
                      .join(", ")}
                  </Text>
                </View>
              </>
            )}
            {generalError ? <GeneralError error={generalError} /> : null}
            {errors?.executionDates?.map(({ message }) => (
              <Text variant="bodySmall" key={message} style={{ color: error }}>
                {message}
              </Text>
            ))}
          </View>
          <View
            style={[
              gapMedium,
              {
                marginTop: spacing8,
              },
            ]}>
            <PrimaryButton
              mode="outlined"
              label="T00846"
              onPress={() => setSelectDaysModalVisible(true)}
            />
            <Text variant="bodyMedium" style={{ alignSelf: "center" }}>
              {`${t("T00847")}`.toUpperCase()}
            </Text>
            <PrimaryButton
              mode="outlined"
              label="T00254"
              onPress={() => setFrequencyModalVisible(true)}
            />
          </View>
        </View>
      </ScrollView>
      <BottomModalContainer
        modalVisible={selectDaysModalVisible}
        setModalVisible={setSelectDaysModalVisible}>
        <View>
          <Calendar
            multiselect
            initialDates={days?.map(({ executionDate }) => ({
              date: new Date(executionDate),
            }))}
            onDismiss={disableExecutionDaysModal}
            onSubmit={onSubmitSelectExecutionDays}
            blockPast
          />
        </View>
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={frequencyModalVisible}
        setModalVisible={setFrequencyModalVisible}>
        <SetFrequencyModalContent
          control={control as unknown as Control}
          pickerError={pickerError}
          disabled={!isValid}
          onPress={handleSubmit(onFrequencySubmit)}
          dateFromError={errors?.["dateFrom"]}
          dateToError={errors?.["dateTo"]}
          onInputPress={onInputPress}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={calendarModalVisible}
        setModalVisible={setCalendarModalVisible}>
        <View style={[gapLarge, { paddingVertical: spacing24 }]}>
          <Calendar
            initialDates={selectedDays}
            onSelectDate={onSelectDate}
            blockPast
            yearsOption="future"
          />
        </View>
      </BottomModalContainer>
      {isNewOrEditedTraining && (
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text={
            (isNewTraining && "T01071") ||
            ((editedTraining && "T01072") as TransKey)
          }
        />
      )}
      <AbsoluteBlurredFooter
        title={isProphylactic ? "T00834" : "T00151"}
        onPress={handleAssignTraining}
        onLayout={height => setFooterHeight(height)}
        buttonLoading={isMutationLoading}
        buttonDisabled={!days.length || isMutationLoading}
      />
    </Host>
  );
};

export default SetASchedule;
