import { CurrencyType } from "@globalTypes/common.types";
import {
  AdditionalExperienceType,
  LicenceBase,
  ServiceDataType,
  UserAddress,
  VerificationStatus,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { ProfileUpdateForm } from "@screens/Profiles/ProfileUpdateScreen";

export enum ProfileStatus {
  Banned = 0,
  EmailUnconfirmed = 2,
  New = 5,
  Fulfilled = 10,
  Verified = 20,
}

export type GoogleSubscriptionStatusType =
  | "SUBSCRIPTION_STATE_ACTIVE"
  | "SUBSCRIPTION_STATE_CANCELED"
  | "SUBSCRIPTION_STATE_EXPIRED"
  | "SUBSCRIPTION_STATE_IN_GRACE_PERIOD"
  | "SUBSCRIPTION_STATE_ON_HOLD"
  | "SUBSCRIPTION_STATE_PAUSED"
  | "SUBSCRIPTION_STATE_PENDING"
  | "SUBSCRIPTION_STATE_UNSPECIFIED";

export type UserLocales = {
  language: string;
  timezone: string;
  currency: CurrencyType;
};

type StripeTypes = {
  stripeAccountId: string;
  stripeAccountRegisterUrl: string;
  stripeActive: boolean;
  stripeDashboardUrl?: string;
};

type SubscriptionData = {
  subscriptionExpires: string;
  subscriptionActive: boolean;
};

export type AppleSubscriptionStatus = {
  subscriptionStatus: AppleSubscriptionStatusCodes;
};

export enum AppleSubscriptionStatusCodes {
  AUTO_RENEWABLE_SUBSCRIPTION_ACTIVE = 1,
  AUTO_RENEWABLE_SUBSCRIPTION_EXPIRED = 2,
  AUTO_RENEWABLE_SUBSCRIPTION_IN_BILLING_RETRY_PERIOD = 3,
  AUTO_RENEWABLE_SUBSCRIPTION_IN_BILLING_GRACE_PERIOD = 4,
  AUTO_RENEWABLE_SUBSCRIPTION_REVOKED = 5,
  AUTO_RENEWABLE_SUBSCRIPTION_CANCELED = 6,
}

export type GoogleSubscriptionStatus = {
  subscriptionStatus: GoogleSubscriptionStatusType;
};

export type User = UserLocales &
  StripeTypes &
  SubscriptionData & {
    id: number;
    email: string;
    avatar?: string;
    firstName: string;
    lastName: string;
    gender: GenderType;
    accountType: AccountType | null;
    birthday: string | null;
    isActive: boolean;
    isStaff: boolean;
    isSuperuser: boolean;
    profileStatus: ProfileStatus;
    profileStatusName: keyof typeof ProfileStatus;
    language: string;
    termsAccepted: boolean;
    loginType: LoginType;
    invitationCode: string;
    createdAt: string;
  };

export type Profile = {
  email: string;
  firstName: string;
  lastName: string;
  gender: GenderType;
  accountType: AccountType | null;
  birthday: string | null;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  profileStatus: ProfileStatus;
  profileStatusName: keyof typeof ProfileStatus;
} & UserLocales;

export type PublicPhysiotherapistProfile = {
  id: number;
  firstName: string;
  lastName: string;
  licence: LicenceBase;
  avatar?: string;
  isActive: boolean;
  address?: Pick<UserAddress, "companyName">;
};

type Certificate = {
  attachment: string;
  name: string;
  attachmentThumbnail?: string | null;
};

export type Language = {
  id: number;
  language: string;
  languageFull: string;
};

export type PublicPhysiotherapistFullProfile = PublicPhysiotherapistProfile & {
  aboutMe: string | null;
  address: UserAddress;
  education: string[] | null;
  experience: {
    id: number;
    years: number;
    additionalExperience: AdditionalExperienceType[];
  } | null;
  certificates: Certificate[] | null;
  services: ServiceDataType[];
  avgRating: number;
  ratingsCount: number;
  spokenLanguages: Language[];
  isCompany: boolean;
};

export type GetPhysiotherapistsResponse = {
  id: number;
  distance: string | null;
};

export type PublicUserProfile = {
  id: number;
  firstName?: string;
  lastName?: string;
  gender: GenderType | null;
  age: number | null;
  height?: number | null;
  weight?: number | null;
  avatar: string | null;
  isActive: boolean;
  spokenLanguages: Language[];
};

export enum GenderType {
  MALE = "M",
  FEMALE = "F",
  OTHER = "O",
}

export enum AccountType {
  PATIENT = "P",
  THERAPIST = "T",
}

export type UserTokensFromApi = {
  access: string;
  refresh: string;
};
type TokenType = "access" | "refresh";

export type JWTToken = UserLocales &
  StripeTypes &
  SubscriptionData & {
    id: number;
    token_type: TokenType;
    exp: number;
    iat: number;
    jti: string;
    user_id: number;
    email: string;
    first_name: string;
    last_name: string;
    gender: GenderType | null;
    account_type: AccountType | null;
    birthday: string | null;
    is_active: boolean;
    is_staff: boolean;
    is_superuser: boolean;
    profile_status: ProfileStatus;
    profile_status_name: keyof typeof ProfileStatus;
    terms_accepted: boolean;
  };

export type UserSignUpForm = {
  email: string;
  password: string;
  passwordConfirmation: string;
  privacyCheckbox: boolean;
};

export type UserAuthenticationData = {
  email: string;
  password: string;
};

export type OnlyIdProfile = {
  id: number;
};

export type UserReport = {
  recipient: number;
  description: string;
};

export type UserReportResponse = UserReport & {
  id: number;
  verificationStatus: VerificationStatus;
  rejectionReason: string;
  createAt: string;
};

export type ProfileUpdateType = Omit<UserLocales, "currency"> &
  Omit<ProfileUpdateForm, "offersServicesAs"> & {
    timezone: string;
    isCompany?: boolean;
  };

export type LocaleHeaders = {
  Language?: string;
  Timezone?: string;
  Currency?: string;
};

export enum LoginType {
  NORMAL = "normal",
  APPLE = "apple",
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

export type SignInWithApple = (
  identityToken: string,
  firstName?: string,
  lastName?: string,
) => Promise<void>;
