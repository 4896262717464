import { CalendarProps, DateArrayType } from "@components/Calendar";
import { RadioButtonsGroup } from "@components/FormElements/RadioButtonsGroup";
import { TimePicker } from "@components/Picker";
import { PrimaryButton } from "@components/buttons";
import { useAuth } from "@contexts/AuthContext/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import NestedCalendarModal from "@screens/Calendar/Availability/NestedCalendarModal";
import {
  deletePhysiotherapistAvailabilityGroup,
  setAvailabilityHoursRangeForSelectedDate,
} from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing16, spacing24, spacing8 } from "@styles/spacing";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isANDROID, isIOS } from "@utils/constants";
import {
  getDateObject,
  getDays,
  getMinAvailableTime,
  getTime,
  roundTimeToMatchSlot,
} from "@utils/date";
import { showAlert, showAlertWithCustomButtons } from "@utils/showAlert";
import { AxiosError } from "axios";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { object, string } from "yup";
import {
  AvailabilityHoursRangeFormType,
  CalendarDates,
  HoursConfirmType,
  PostAvailabilityHoursRangeErrorType,
} from "../availability.types";
import { isNewDateInPrevDates } from "../calendarUtils";
import { showSnackbar } from "@utils/snackbarHelper";
import { hideMessage } from "react-native-flash-message";
import { usePhysiotherapistAvailability } from "@hooks/availability/useFutureAvailability";
import { FetchError } from "@components/errors";
import { useLocale } from "@hooks/useLocale";
import { visitTypes } from "@screens/Appointments/filters.types";
import { setHourAndMinute } from "@screens/Appointments/FiltersComponents/utils";
import { AvailabilityGroup } from "@components/PhysioAvailability/PhysioAvailability.types";
import { getMinAndMaxHoursForPicker } from "./availabilityHelpers";

type AddHoursBottomSheetContentProps = Pick<CalendarDates, "initialDate"> & {
  isVisible?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  groupDetails?: AvailabilityGroup;
};

const AddHoursBottomSheetContent: FC<AddHoursBottomSheetContentProps> = ({
  initialDate,
  isVisible,
  setVisible,
  groupDetails,
}) => {
  const { t } = useTranslation();
  const wrongTimeAlert = () => alert(t("T00959"));

  const { date } = getMinAvailableTime(initialDate.date);

  const { maxDateTo, minDateFrom } = getMinAndMaxHoursForPicker({
    alertFn: wrongTimeAlert,
    dateToCompare: date,
    groupDetails,
  });
  const slotsToDelete = groupDetails?.hours.map(({ id }) => id);

  const [hourFrom, setHourFrom] = useState(minDateFrom);
  const [hourTo, setHourTo] = useState(maxDateTo);
  const [displayHourFromModal, setDisplayHourFromModal] = useState(!isANDROID);
  const [displayHourToModal, setDisplayHourToModal] = useState(!isANDROID);
  const [displayHourFromWebModal, setDisplayHourFromWebModal] = useState(false);
  const [displayHourToWebModal, setDisplayHourToWebModal] = useState(false);
  const [nestedCalendarModalVisible, setNestedCalendarModalVisible] =
    useState(false);
  const [pickedDates, setPickedDates] = useState([initialDate]);
  const { locale } = useLocale();

  const { setErrorsFromResponse, clearErrors } = useErrors();
  const queryClient = useQueryClient();
  const {
    user: { id },
  } = useAuth();

  const { startOfPrevMonthDate: dateFrom, endOfTwoMonthsAheadDate: dateTo } =
    getDays(initialDate.date);

  const {
    data: physioAvailability,
    isLoading,
    isError,
    refetch,
  } = usePhysiotherapistAvailability(
    queryKeysPhysiotherapist.availability(id),
    { dateFrom, dateTo, physiotherapistId: id },
  );

  const { timeItemContainer, inputsContainer } = styles;

  const schema = useMemo(
    () =>
      object().shape({
        serviceType: string().required(t("T00028")),
      }),
    [t],
  );

  const {
    control,
    formState: { errors: errorsFromForm, isValid },
    handleSubmit,
    reset,
    clearErrors: clearFormErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isVisible) {
      clearFormErrors();
      clearErrors();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const { isLoading: isSetHoursLoading, mutate } = useMutation({
    mutationFn: setAvailabilityHoursRangeForSelectedDate,
    onError: ({
      response,
    }: AxiosError<PostAvailabilityHoursRangeErrorType>) => {
      setErrorsFromResponse(response);
      response.data.dates && showAlert(response.data.dates);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeysPhysiotherapist.availability(id),
      );
      setNestedCalendarModalVisible(false);
      setVisible(false);
      showSnackbar({
        message: t(groupDetails ? "T01591" : "T01308"),
        rightIcon: "close",
        onPressRightIcon: () => hideMessage(),
      });
      reset();
    },
  });

  const { mutate: deleteHoursGroup, isLoading: isDeleteHoursGroupLoading } =
    useMutation({
      mutationFn: () => deletePhysiotherapistAvailabilityGroup(slotsToDelete),
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const onChangeTime = (selectedDate: Date, direction: string) => {
    direction === "from" ? setHourFrom(selectedDate) : setHourTo(selectedDate);
    if (isANDROID) {
      direction === "from"
        ? setDisplayHourFromModal(false)
        : setDisplayHourToModal(false);
    }
  };

  const onSubmit = useCallback(
    (
      { serviceType }: AvailabilityHoursRangeFormType,
      pickedDates: DateArrayType[],
    ) => {
      if (hourFrom.getTime() > hourTo.getTime()) {
        alert(t("T00958"));
      } else {
        const dates = pickedDates.map(({ date }) => {
          const tempDate = getDateObject(date);
          tempDate.setHours(0, 0, 0, 0);
          return tempDate;
        });

        const availabilityPayload = {
          hourFrom: getTime(hourFrom, locale),
          hourTo: getTime(hourTo, locale),
          serviceType,
          dates,
        };
        if (groupDetails) {
          deleteHoursGroup(undefined, {
            onSuccess: () => mutate(availabilityPayload),
          });
        } else {
          mutate(availabilityPayload);
        }
      }
    },
    [deleteHoursGroup, groupDetails, hourFrom, hourTo, locale, mutate, t],
  );

  const handleHourFromPress = () =>
    !isIOS ? setDisplayHourFromWebModal(true) : setDisplayHourFromModal(true);

  const handleHourToPress = () =>
    !isIOS ? setDisplayHourToWebModal(true) : setDisplayHourToModal(true);

  const hourFromTimePickerProps = {
    visible: displayHourFromModal,
    visibleWebModal: displayHourFromWebModal,
    onDismiss: () => setDisplayHourFromWebModal(false),
    onConfirm: ({ hours, minutes }: HoursConfirmType) => {
      const { roundedHours, roundedMinutes } = roundTimeToMatchSlot(
        hours,
        minutes,
      );
      onChangeTime(
        setHourAndMinute(roundedHours, roundedMinutes, date),
        "from",
      );
      !isIOS && setDisplayHourFromWebModal(false);
    },
    hours: hourFrom.getHours(),
    minutes: hourFrom.getMinutes(),
    value: hourFrom,
    onChange: (_, selectedDate: Date) => onChangeTime(selectedDate, "from"),
    onPress: handleHourFromPress,
  };

  const hourToTimePickerProps = {
    value: hourTo,
    onChange: (_, selectedDate: Date) => onChangeTime(selectedDate, "to"),
    visibleWebModal: displayHourToWebModal,
    visible: displayHourToModal,
    onDismiss: () => setDisplayHourToWebModal(false),
    onConfirm: ({ hours, minutes }: HoursConfirmType) => {
      const { roundedHours, roundedMinutes } = roundTimeToMatchSlot(
        hours,
        minutes,
      );
      onChangeTime(setHourAndMinute(roundedHours, roundedMinutes, date), "to");
      !isIOS && setDisplayHourToWebModal(false);
    },
    hours: hourTo.getHours(),
    minutes: hourTo.getMinutes(),
    onPress: handleHourToPress,
  };

  const confirmSelection: CalendarProps["onSubmit"] = async dates => {
    setPickedDates(dates);
    await handleSubmit(data =>
      onSubmit(data as AvailabilityHoursRangeFormType, dates),
    )();
  };

  const checkIfInitialDateHasOtherAvailability = useCallback(
    (formData: AvailabilityHoursRangeFormType) => {
      const existingDates = physioAvailability.availableAppointments.map(
        appointment => appointment.date,
      );
      const showModifyDayAlert = isNewDateInPrevDates({
        prevDates: existingDates,
        newDates: pickedDates,
      });
      if (showModifyDayAlert) {
        return showAlertWithCustomButtons({
          title: t("T01166"),
          message: t("T01214"),
          cancelButton: { text: t("T00145") },
          confirmButton: {
            text: t("T01168"),
            onPress: handleSubmit(data =>
              onSubmit(data as AvailabilityHoursRangeFormType, pickedDates),
            ) as () => void,
          },
        });
      }
      onSubmit(formData, pickedDates);
    },
    [
      handleSubmit,
      onSubmit,
      physioAvailability?.availableAppointments,
      pickedDates,
      t,
    ],
  );
  const pickedOtherThanInitialDate =
    pickedDates.length > 1 && pickedDates[0] !== initialDate;

  const isMutatingWithPickedDates = useMemo(
    () =>
      (isSetHoursLoading && pickedOtherThanInitialDate) ||
      isDeleteHoursGroupLoading,
    [pickedOtherThanInitialDate, isSetHoursLoading, isDeleteHoursGroupLoading],
  );
  const isMutatingWithInitialDate = useMemo(
    () =>
      (isSetHoursLoading && !pickedOtherThanInitialDate) ||
      isDeleteHoursGroupLoading,
    [pickedOtherThanInitialDate, isSetHoursLoading, isDeleteHoursGroupLoading],
  );

  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View>
      <Controller
        control={control}
        name="serviceType"
        defaultValue={groupDetails?.serviceType}
        render={({ field: { onChange, value } }) => (
          <RadioButtonsGroup
            onChange={onChange}
            value={value}
            errorMessage={errorsFromForm?.serviceType?.message}
            data={visitTypes}
            title="T00494"
            containerStyles={{ paddingVertical: spacing24 }}
            radioContainerStyle={globalStyles.gapLarge}
          />
        )}
      />
      <View style={inputsContainer}>
        <View style={timeItemContainer}>
          <Text variant="bodyLarge">{t("T00498")}</Text>
          <TimePicker {...hourFromTimePickerProps} />
        </View>
        <View style={timeItemContainer}>
          <Text variant="bodyLarge">{t("T00499")}</Text>
          <TimePicker {...hourToTimePickerProps} />
        </View>
      </View>
      {!groupDetails && (
        <PrimaryButton
          onPress={() => setNestedCalendarModalVisible(true)}
          label="T01165"
          style={{ marginBottom: spacing16 }}
          mode="outlined"
          loading={isMutatingWithPickedDates}
          disabled={!isValid || isMutatingWithInitialDate || isLoading}
        />
      )}
      <PrimaryButton
        onPress={handleSubmit(checkIfInitialDateHasOtherAvailability)}
        label="T00903"
        style={{ marginBottom: spacing8 }}
        loading={isMutatingWithInitialDate}
        disabled={
          isMutatingWithPickedDates || isMutatingWithInitialDate || isLoading
        }
      />
      <NestedCalendarModal
        visible={nestedCalendarModalVisible}
        onDismiss={() => setNestedCalendarModalVisible(false)}
        onConfirmSelection={confirmSelection}
        data={physioAvailability}
        initialDate={initialDate}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  radioButtonContaier: {
    flexDirection: "row",
    alignItems: "center",
  },
  inputsContainer: {
    flexDirection: "column",
    paddingBottom: spacing16,
    paddingHorizontal: spacing16,
    gap: spacing16,
  },
  timeItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default AddHoursBottomSheetContent;
