import { getPhysiotherapistServices } from "@api/appointments";
import DropdownPicker, {
  MultiDropdownsType,
  OnPickerValueChange,
} from "@components/DropdownPicker/DropdownPicker";
import { FetchError } from "@components/errors";
import { useUserDetails } from "@hooks/index";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { useQuery } from "@tanstack/react-query";
import { SERVICE_SLOT_TIME } from "@utils/constants";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator } from "react-native-paper";

export type ServiceDropdownPickerProps = {
  physioId: number;
  onValueChange: OnPickerValueChange;
  selectedServiceType: ServiceType;
  defaultValue?: string;
  containerStyles?: StyleProp<ViewStyle>;
  itemBackgroundColor?: string;
} & MultiDropdownsType;

const ServiceDropdownPicker: FC<ServiceDropdownPickerProps> = props => {
  const {
    physioId,
    selectedServiceType,
    changeLabelZIndex,
    itemBackgroundColor,
  } = props;
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.services(
      physioId,
      selectedServiceType,
    ),
    queryFn: async () =>
      await getPhysiotherapistServices(physioId, {
        serviceType: selectedServiceType,
        isArchived: false,
      }),
    select: data => {
      return data?.map(({ serviceItem, id, clientSlots, breakSlots }) => ({
        label: `${serviceItem.name} - ${t("T01579", {
          value: clientSlots * SERVICE_SLOT_TIME,
        })} ${
          isPhysiotherapist && breakSlots > 0
            ? t("T01592", {
                value: breakSlots * SERVICE_SLOT_TIME,
              })
            : ""
        }`,
        value: `${id}`,
      }));
    },
  });

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return data.length ? (
    <DropdownPicker
      dropdownLabelText="T00608"
      dropdownLabelContainerStyle={changeLabelZIndex && { zIndex: 1 }}
      items={data}
      itemBackgroundColor={itemBackgroundColor}
      applyOuterContainerZIndex
      {...props}
    />
  ) : null;
};

export default ServiceDropdownPicker;
