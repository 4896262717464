import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import {
  OptionalSteps,
  ProfileCompletionActionsSkippedByUser,
  RehabVerificationProgressResponse,
  VerificationStatus,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { TransKey } from "@globalTypes/i18next";
import { flags } from "./flags";
import { ProductName } from "@globalTypes/common.types";
import { setSkipFlagValue } from "@hooks/verification/useProfileCompletionActionsSkippedByUser";

type ProfileCompletionSectionProps = {
  title: TransKey;
  subtitles: TransKey[];
  onPress: () => void;
  buttonLabel: TransKey;
  additionalInfo?: TransKey;
  skipActionButtonLabel?: TransKey;
  onSkipActionPress?: () => void;
};

export const getSubtitleForProfileCompletionTile = (
  progressValue: number,
): TransKey | undefined => {
  switch (true) {
    case progressValue === 0:
      return "T00199";
    case progressValue > 0 && progressValue < 40:
      return "T00200";
    case progressValue >= 40 && progressValue < 70:
      return "T00201";
    case progressValue >= 70 && progressValue < 100:
      return "T00202";
    case progressValue === 100:
      return "T00203";
    default:
      return undefined;
  }
};

export const getSubtitleForProfileCompletionTileForPatient = (
  progressValue: number,
): TransKey => {
  if (progressValue < 100) {
    return "T01053";
  }
  return "T00203";
};

export const getDataForProfileCompletionActionTile = (
  data: RehabVerificationProgressResponse,
  navigateTo: (screen: keyof ProfileCompletionStackParamList) => void,
  skippedActionTiles: ProfileCompletionActionsSkippedByUser,
  onPressSkipCb: () => void,
): ProfileCompletionSectionProps => {
  const isGymsy = flags.PRODUCT_NAME === ProductName.GYMSY;
  const checkVerification = (
    verification: VerificationStatus | null | boolean,
  ): boolean => {
    if (
      typeof verification === "boolean" ||
      verification === VerificationStatus.PENDING
    )
      return verification === false;
    return (
      verification === VerificationStatus.REJECTED || verification === null
    );
  };

  switch (true) {
    case checkVerification(data.licenceVerification): {
      const subtitles: TransKey[] = isGymsy ? ["T00229"] : ["T00231", "T00207"];
      return {
        title: "T00206",
        subtitles,
        buttonLabel: "T00205",
        onPress: () => navigateTo("VerifyLicence"),
      };
    }
    case checkVerification(data.addressVerification):
      return {
        title: "T00208",
        subtitles: ["T00229"],
        buttonLabel: "T00209",
        onPress: () => navigateTo("VerifyAddress"),
      };
    case flags.FEATURE_SMS_VERIFICATION_ENABLED &&
      checkVerification(data.phoneNumberVerification):
      return {
        title: "T01524",
        subtitles: ["T00229"],
        buttonLabel: "T01525",
        onPress: () => navigateTo("VerifyPhone"),
      };
    case checkVerification(data.languageVerification):
      return {
        title: "T00210",
        subtitles: ["T00229"],
        buttonLabel: "T00616",
        onPress: () => navigateTo("Languages"),
      };
    case checkVerification(data.servicesVerification):
      return {
        title: "T00211",
        subtitles: ["T00231"],
        buttonLabel: "T00249",
        onPress: () => navigateTo("Services"),
      };
    case checkVerification(
      data.onlinePaymentVerification || data.stationaryPaymentVerification,
    ):
      return {
        title: "T00212",
        subtitles: ["T00230"],
        buttonLabel: "T00213",
        onPress: () => navigateTo("Payments"),
      };
    case checkVerification(data.subscriptionVerification):
      return {
        title: "T00214",
        subtitles: ["T00229"],
        buttonLabel: "T00215",
        onPress: () => navigateTo("Subscription"),
        additionalInfo: "T01596",
      };
    case checkVerification(data.aboutMeVerification):
      return {
        title: "T00216",
        subtitles: ["T00230"],
        buttonLabel: "T00217",
        onPress: () => navigateTo("AboutMe"),
      };
    case checkVerification(data.profilePhotoVerification):
      return {
        title: "T00218",
        subtitles: ["T00229", "T00219"],
        buttonLabel: "T00220",
        onPress: () => navigateTo("AddPhoto"),
      };
    case checkVerification(data.experienceVerification):
      return {
        title: "T00221",
        subtitles: ["T00229"],
        buttonLabel: "T00222",
        onPress: () => navigateTo("Experience"),
      };
    case checkVerification(data.educationVerification):
      return {
        title: "T00223",
        subtitles: ["T00230"],
        buttonLabel: "T00272",
        onPress: () => navigateTo("Education"),
      };
    case !skippedActionTiles?.certificates &&
      checkVerification(data.certificatesVerification):
      return {
        title: "T00224",
        subtitles: ["T00230", "T00225"],
        buttonLabel: "T00226",
        onPress: () => navigateTo("Certificates"),
        skipActionButtonLabel: "T01603",
        onSkipActionPress: () =>
          setSkipFlagValue(OptionalSteps.CERTIFICATES, "true", onPressSkipCb),
      };
    case !skippedActionTiles?.onlinePayments &&
      checkVerification(data.onlinePaymentVerification):
      return {
        title: "T00227",
        subtitles: ["T01138"],
        buttonLabel: "T00228",
        onPress: () => navigateTo("OnlinePayments"),
        skipActionButtonLabel: "T01603",
        onSkipActionPress: () =>
          setSkipFlagValue(
            OptionalSteps.ONLINE_PAYMENTS,
            "true",
            onPressSkipCb,
          ),
      };
    default:
      break;
  }
};
