import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { VerifyLicenceConfirmationBottomSheet } from "@components/BottomSheetContents/VerifyLicenceConfirmationBottomSheet";
import { PrimaryButton } from "@components/buttons";
import PrivacyAndTermsAgreement from "@components/Checkboxes/PrivacyAndTermsAgreement";
import ProcessingDataAgreement from "@components/Checkboxes/ProcessingDataAgreement";
import { FetchError, GeneralError } from "@components/errors";
import AddAttachmentBottomSheet from "@components/Files/AddAttachmentBottomSheet";
import PermissionModal from "@components/Modals/PermissionModal";
import Snackbar from "@components/Snackbar/Snackbar";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import InfoTile from "@components/Tile/InfoTile";
import { PermissionType } from "@globalTypes/common.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvalidateProfileDetails } from "@hooks/index";
import { imageAndPdfTypes } from "@hooks/media/useResources/constants";
import { useResources } from "@hooks/media/useResources/useResources";
import { useCheckNotifications } from "@hooks/notifications/useCheckNotifications";
import { useNotificationMainSettings } from "@hooks/notifications/useNotificationMainSettings";
import { useErrors } from "@hooks/useErrors";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { postFile } from "@services/ApiService/common";
import { endpoints } from "@services/ApiService/endpoints";
import { getLicence, updateLicence } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing2 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { config } from "@utils/config";
import { isWeb } from "@utils/constants";
import { AxiosError } from "axios";
import { MediaTypeOptions } from "expo-image-picker";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Control, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Linking, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ActivityIndicator, Text } from "react-native-paper";
import { Host } from "react-native-portalize";
import { boolean, object, string } from "yup";
import { LicenceAttachments, LicenceNumberAndSpecialization } from "./Licence";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { LicenceForm, VerificationStatus } from "./verification.types";

const maxNumberOfFiles = 5;

export type LicenceFormType = LicenceForm & {
  privacyCheckbox?: boolean;
  processingDataCheckbox?: boolean;
};

export type VerifyLicenceScreenProps = CompositeScreenProps<
  NativeStackScreenProps<ProfileCompletionStackParamList, "VerifyLicence">,
  NativeStackScreenProps<ProfileStackParamsList, "Profile">
>;

export const VerifyLicence: FC<VerifyLicenceScreenProps> = ({
  navigation: { navigate, getState },
  route,
}) => {
  const actionEnabled = route.params?.actionEnabled;
  const [modalVisible, setModalVisible] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [addAttachmentBottomSheetVisible, setAddAttachmentBottomSheetVisible] =
    useState(false);

  const [
    showNotificationPermissionModalVisible,
    setShowNotificationPermissionModalVisible,
  ] = useState(false);

  const { t } = useTranslation();
  const { errors, setErrorsFromResponse, generalError, clearErrors } =
    useErrors();
  const queryClient = useQueryClient();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const { loading, scrollContainer, gapLarge } = globalStyles;

  const {
    pickFilesFromDevice,
    clearFiles,
    removeFile,
    pickedFiles,
    maxNumberOfFilesAlert,
  } = useResources({
    multiselect: true,
    maxNumberOfFiles,
    supportedFileTypes: imageAndPdfTypes,
    mediaTypes: MediaTypeOptions.Images,
  });

  const { data } = useNotificationMainSettings();

  const hideSnackbar = () => setSnackbarVisible(false);

  const {
    data: initialData,
    isLoading: initialDataLoading,
    isError: initialDataError,
    isSuccess: initialDataSuccess,
    refetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.licence(),
    queryFn: getLicence,
    onSuccess: data => {
      setValue("licenceNumber", data[0]?.licenceNumber);
      setValue("specialization", `${data[0]?.specialization?.id}` || "");
    },
  });

  const isVerified =
    initialData?.[0]?.verificationStatus === VerificationStatus.VERIFIED;
  const isRejected =
    initialData?.[0]?.verificationStatus === VerificationStatus.REJECTED;
  const isPending =
    initialData?.[0]?.verificationStatus === VerificationStatus.PENDING;

  const handleGoBackToPrevScreen = useCallback(() => {
    const firstRouteName = getState().routes?.[0]?.name;
    if (firstRouteName === "VerifyLicence") return navigate("Profile");
    return navigate("AddressAndVerification");
  }, [getState, navigate]);

  const { mutate: addAttachment, isLoading: isAddAttachmentLoading } =
    useMutation({
      mutationFn: postFile,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: ({ id, name, file }) => {
        if (fields.length >= maxNumberOfFiles) return;
        append({ name, id, file });
      },
    });

  const checkboxRules = boolean()
    .oneOf([true], t("T00014"))
    .required(t("T00014"));

  const schema = useMemo(() => {
    const stringSchema = string().required(t("T00014")).trim();
    return object().shape({
      licenceNumber: stringSchema,
      specialization: stringSchema.notOneOf(["undefined"], t("T00014")),
      privacyCheckbox: checkboxRules,
      processingDataCheckbox: checkboxRules,
    });
  }, [checkboxRules, t]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors: clearFormErrors,
  } = useForm<LicenceFormType>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      licenceNumber:
        initialDataSuccess &&
        initialData?.[0]?.verificationStatus !== VerificationStatus.REJECTED
          ? initialData?.[0]?.licenceNumber
          : "",
      specialization:
        initialDataSuccess && initialData?.[0]?.specialization
          ? `${initialData?.[0].specialization.id}`
          : "",
      attachments:
        initialDataSuccess && !isRejected && initialData?.[0]?.attachments,
    },
  });

  const handleClearAndGoBack = useCallback(() => {
    setModalVisible(false);
    clearErrors();
    clearFormErrors();
    handleGoBackToPrevScreen();
    reset();
    showProfileVerificationSnackbar(t("T01311"));
  }, [clearErrors, clearFormErrors, handleGoBackToPrevScreen, reset, t]);

  const handlePermissionsGranted = useCallback(
    (withGoBackAction = true) => {
      setShowNotificationPermissionModalVisible(false);
      withGoBackAction && handleClearAndGoBack();
    },
    [handleClearAndGoBack],
  );

  const { handleCheckNotifications, isNotificationGranted } =
    useCheckNotifications({
      mainNotification: data,
      onPermissionsGranted: handlePermissionsGranted,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attachments",
  });

  useEffect(() => {
    if (pickedFiles?.length) {
      setAddAttachmentBottomSheetVisible(false);
      const howManyCanBeAdded = maxNumberOfFiles - fields.length;
      pickedFiles.length > howManyCanBeAdded && maxNumberOfFilesAlert();
      pickedFiles.forEach(({ name, uri }, index) => {
        if (index >= howManyCanBeAdded) {
          removeFile(uri);
        } else {
          addAttachment({
            file: { name, uri },
            endpoint: endpoints.LICENCE_POST_ATTACHMENT,
          });
          removeFile(uri);
        }
      });
    }
  }, [
    addAttachment,
    fields.length,
    maxNumberOfFilesAlert,
    pickedFiles,
    removeFile,
  ]);

  const onUpdateLicenceSuccess = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries(queryKeysVerficiations.licence()),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      ),
    ]);
    isNotificationGranted || isWeb
      ? handleClearAndGoBack()
      : setShowNotificationPermissionModalVisible(true);
  };

  const {
    mutate: updateLicenceWithAttachments,
    isLoading: isUpdateLicenceLoading,
  } = useMutation({
    mutationFn: updateLicence,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onUpdateLicenceSuccess,
  });

  const onSubmit = ({
    licenceNumber,
    specialization,
    attachments: files,
  }: LicenceForm) => {
    const attachments = files?.map(({ id }) => id);
    !fields.length
      ? setSnackbarVisible(true)
      : updateLicenceWithAttachments({
          licenceNumber,
          specialization,
          attachments,
        });
  };

  const disableButton = useMemo(
    () =>
      isUpdateLicenceLoading ||
      isAddAttachmentLoading ||
      (initialData.length > 0 &&
        initialData?.[0]?.verificationStatus !== VerificationStatus.REJECTED),
    [initialData, isAddAttachmentLoading, isUpdateLicenceLoading],
  );

  if (initialDataLoading) return <ActivityIndicator style={loading} />;
  if (initialDataError) return <FetchError action={refetch} />;

  return (
    <Host>
      <KeyboardAwareScrollView
        contentContainerStyle={[scrollContainer, gapLarge]}>
        {initialData?.[0]?.rejectionReason && (
          <InfoTile
            status="warning"
            content={
              <Text variant="bodyMedium">
                {initialData?.[0]?.rejectionReason}
              </Text>
            }
          />
        )}
        {generalError && <GeneralError error={generalError} />}
        <LicenceNumberAndSpecialization
          errors={errors}
          control={control}
          initialData={initialData}
          disableButton={disableButton}
          actionEnabled={actionEnabled}
          onPress={async () => {
            if (isWeb) {
              await Linking.openURL(
                `${config.EXPO_PUBLIC_API_BASE_URL}/specialization-requirements/`,
              );
            } else {
              navigate("SpecializationRequirements");
            }
          }}
        />
        <LicenceAttachments
          clearErrors={clearErrors}
          errors={errors}
          onSelectFileButtonPress={() =>
            setAddAttachmentBottomSheetVisible(true)
          }
          clearDocuments={clearFiles}
          removeDocument={remove}
          documents={fields}
          isVerified={isVerified}
          isRejected={isRejected}
          isPending={isPending}
          actionEnabled={actionEnabled}
        />
        {!isVerified && (
          <View style={{ paddingRight: spacing2 }}>
            <PrivacyAndTermsAgreement
              name="privacyCheckbox"
              control={control as unknown as Control}
              actionEnabled={actionEnabled}
            />
            <ProcessingDataAgreement
              name="processingDataCheckbox"
              control={control as unknown as Control}
              actionEnabled={actionEnabled}
            />
          </View>
        )}
        {!isVerified && !actionEnabled && (
          <PrimaryButton
            loading={isAddAttachmentLoading || isUpdateLicenceLoading}
            disabled={disableButton}
            label="T00612"
            onPress={handleSubmit(onSubmit)}
          />
        )}
      </KeyboardAwareScrollView>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <VerifyLicenceConfirmationBottomSheet onPress={handleClearAndGoBack} />
      </BottomModalContainer>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={hideSnackbar}
        onIconPress={hideSnackbar}
        text="T00613"
      />
      <AddAttachmentBottomSheet
        visible={addAttachmentBottomSheetVisible}
        setVisible={setAddAttachmentBottomSheetVisible}
        pickFilesFromDevice={pickFilesFromDevice}
      />
      <PermissionModal
        isModalVisible={showNotificationPermissionModalVisible}
        setIsModalVisible={setShowNotificationPermissionModalVisible}
        onPressConfirm={handleCheckNotifications}
        type={PermissionType.notifications}
        onPressNotNowCallback={handleClearAndGoBack}
      />
    </Host>
  );
};

export default VerifyLicence;
