import { queryKeysSubscription } from "@components/Subscriptions/queryKeysSubscription";
import {
  AppleSubscriptionStatusCodes,
  GoogleSubscriptionStatusType,
} from "@contexts/AuthContext/user.types";
import {
  getSubscriptionAppleStatus,
  getSubscriptionAppleStatusOptions,
  getSubscriptionGoogleStatus,
  getSubscriptionGoogleStatusOptions,
} from "@services/ApiService/subscriptions";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

type AppleSubscriptionOptionsKeys = "1" | "2" | "3" | "4" | "5" | "6";

type GoogleSubscriptionOptionsKeys = GoogleSubscriptionStatusType;

export const useSubscriptionStatus = () => {
  const {
    data: appleSubscriptionStatus,
    isLoading: isAppleSubscriptionStatusLoading,
    isError: isAppleSubscriptionStatusError,
    refetch: refetchAppleSubscriptionStatus,
  } = useQuery({
    queryKey: queryKeysSubscription.appleStatus(),
    queryFn: getSubscriptionAppleStatus,
  });

  const {
    data: appleSubscriptionStatusOptions,
    isLoading: isAppleSubscriptionStatusOptionsLoading,
    isError: isAppleSubscriptionStatusOptionsError,
    refetch: refetchAppleSubscriptionStatusOptions,
  } = useQuery({
    queryKey: queryKeysSubscription.appleOptions(),
    queryFn: getSubscriptionAppleStatusOptions,
  });

  const {
    data: googleSubscriptionStatus,
    isLoading: isGoogleSubscriptionStatusLoading,
    isError: isGoogleSubscriptionStatusError,
    refetch: refetchGoogleSubscriptionStatus,
  } = useQuery({
    queryKey: queryKeysSubscription.googleStatus(),
    queryFn: getSubscriptionGoogleStatus,
  });

  const {
    data: googleSubscriptionStatusOptions,
    isLoading: isGoogleSubscriptionStatusOptionsLoading,
    isError: isGoogleSubscriptionStatusOptionsError,
    refetch: refetchGoogleSubscriptionStatusOptions,
  } = useQuery({
    queryKey: queryKeysSubscription.googleOptions(),
    queryFn: getSubscriptionGoogleStatusOptions,
  });

  const keyForAppleBillingGracePeriod =
    appleSubscriptionStatusOptions?.data &&
    Object.keys(appleSubscriptionStatusOptions.data).find(
      (key: AppleSubscriptionOptionsKeys) =>
        key ===
        AppleSubscriptionStatusCodes.AUTO_RENEWABLE_SUBSCRIPTION_IN_BILLING_GRACE_PERIOD.toString(),
    );
  const keyForGoogleBillingGracePeriod =
    googleSubscriptionStatusOptions?.data &&
    Object.keys(googleSubscriptionStatusOptions.data).find(
      (key: GoogleSubscriptionOptionsKeys) =>
        key === "SUBSCRIPTION_STATE_IN_GRACE_PERIOD",
    );

  const refetchAllSubscriptionStatusData = () =>
    Promise.all([
      refetchAppleSubscriptionStatus(),
      refetchAppleSubscriptionStatusOptions(),
      refetchGoogleSubscriptionStatus(),
      refetchGoogleSubscriptionStatusOptions(),
    ]);

  const isAnySubscriptionStatusLoading = useMemo(
    () =>
      isAppleSubscriptionStatusLoading ||
      isAppleSubscriptionStatusOptionsLoading ||
      isGoogleSubscriptionStatusLoading ||
      isGoogleSubscriptionStatusOptionsLoading,
    [
      isAppleSubscriptionStatusLoading,
      isAppleSubscriptionStatusOptionsLoading,
      isGoogleSubscriptionStatusLoading,
      isGoogleSubscriptionStatusOptionsLoading,
    ],
  );

  const isAnySubscriptionStatusError = useMemo(
    () =>
      isAppleSubscriptionStatusError ||
      isAppleSubscriptionStatusOptionsError ||
      isGoogleSubscriptionStatusError ||
      isGoogleSubscriptionStatusOptionsError,
    [
      isAppleSubscriptionStatusError,
      isAppleSubscriptionStatusOptionsError,
      isGoogleSubscriptionStatusError,
      isGoogleSubscriptionStatusOptionsError,
    ],
  );

  return {
    keyForAppleBillingGracePeriod,
    keyForGoogleBillingGracePeriod,
    appleSubscriptionStatus,
    googleSubscriptionStatus,
    isAnySubscriptionStatusError,
    isAnySubscriptionStatusLoading,
    refetchAllSubscriptionStatusData,
  };
};
