import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { restartApp } from "@utils/restart";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Image, ImageProps } from "expo-image";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { FC } from "react";

type ErrorCompomentProps = {
  buttonBackgroundColor?: string;
  hideAllText?: boolean;
};

export const ErrorComponent: FC<ErrorCompomentProps> = ({
  buttonBackgroundColor,
  hideAllText,
}) => {
  const { gapLarge, gapMedium } = globalStyles;
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const screenWidth = 0.75 * width - spacing32;
  const imageHeight = 0.75 * screenWidth;

  const ImageComponent = (
    <Image
      source={require("@assets/images/error.png") as ImageProps["source"]}
      style={{ width: screenWidth, height: imageHeight, alignSelf: "center" }}
    />
  );

  if (hideAllText) return ImageComponent;

  return (
    <View style={[gapLarge, { justifyContent: "flex-end" }]}>
      {ImageComponent}
      <View style={[gapMedium, { alignItems: "center" }]}>
        <Text variant="titleMedium">{t("T00676")}</Text>
        <Text variant="bodyMedium">{t("T00677")}</Text>
      </View>
      <PrimaryButton
        label="T00165"
        onPress={restartApp}
        style={
          buttonBackgroundColor && {
            backgroundColor: buttonBackgroundColor,
          }
        }
      />
    </View>
  );
};

export default ErrorComponent;
