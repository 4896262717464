import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";

import { PrimaryButton } from "@components/buttons";

import { CustomCarousel } from "@components/Slider/CustomCarousel";
import { physioSliderData } from "@frontendData/physio_slider";
import { useUserDetails } from "@hooks/index";
import { RootStackParamList } from "@navigators/navigation.types";
import { spacing16, spacing24 } from "@styles/spacing";
import { globalStyles } from "@styles/global";

const AreYouPhysiotherapist: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "AreYouPhysiotherapist">
  >
> = ({ navigation: { goBack } }) => {
  const { isPhysiotherapist } = useUserDetails();

  const sliderData = isPhysiotherapist
    ? physioSliderData.slice(1)
    : physioSliderData;

  return (
    <CustomCarousel
      data={sliderData}
      withAppLogo
      carouselContainerStyle={[
        globalStyles.container,
        { marginBottom: spacing24 },
      ]}>
      <PrimaryButton
        label="T00165"
        onPress={goBack}
        style={{ width: "100%", marginTop: spacing16 }}
      />
    </CustomCarousel>
  );
};

export default AreYouPhysiotherapist;
