import { TransKey, TranslationKey } from "@globalTypes/i18next";
import { bottomSheetBgColor } from "@styles/colors";
import { fontConfig } from "@styles/fontConfig";
import {
  spacing12,
  spacing16,
  spacing24,
  spacing4,
  spacing8,
} from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { isANDROID } from "@utils/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import DropDownPicker, {
  DropDownDirectionType,
  RenderListItemPropsInterface,
} from "react-native-dropdown-picker";
import { HelperText, IconButton, Text } from "react-native-paper";

type GenericValueType = string | number | boolean;

export type PickerItem<T extends GenericValueType = string> = {
  label: string;
  value: T;
};
export type OnPickerValueChange<T extends GenericValueType = string> = (
  value: T,
) => void;

export type MultiDropdownsType = {
  onOpen?: () => void;
  onClose?: () => void;
  isDropdownOpen?: boolean;
  multiDropdownContent?: boolean;
  changeLabelZIndex?: boolean;
};

type DropdownPickerProps<T extends GenericValueType = string> = {
  items: PickerItem<T>[];
  dropdownLabelText?: TransKey;
  dropdownLabelContainerStyle?: StyleProp<ViewStyle>;
  onValueChange?: OnPickerValueChange<T>;
  isRequired?: boolean;
  defaultValue?: T;
  placeholder?: TranslationKey;
  disabled?: boolean;
  onSelectItem?: (item: PickerItem<T>) => void;
  containerZIndex?: number;
  hasError?: boolean;
  searchable?: boolean;
  containerStyles?: StyleProp<ViewStyle>;
  dropDownDirection?: DropDownDirectionType;
  isInBottomSheet?: boolean;
  itemBackgroundColor?: string;
  applyOuterContainerZIndex?: boolean;
} & MultiDropdownsType;

const DropdownPicker = <T extends GenericValueType = string>({
  items,
  dropdownLabelText,
  dropdownLabelContainerStyle,
  onValueChange,
  isRequired,
  defaultValue,
  placeholder,
  disabled,
  onSelectItem,
  containerZIndex,
  hasError,
  searchable,
  containerStyles,
  dropDownDirection,
  isInBottomSheet = false,
  isDropdownOpen,
  multiDropdownContent,
  onClose,
  itemBackgroundColor,
  applyOuterContainerZIndex = !isANDROID,
  ...props
}: DropdownPickerProps<T>) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isDropdownOpen || false);
  const [value, setValue] = useState<T>();
  const {
    colors: {
      outline,
      error,
      primary,
      primaryContainer,
      white,
      onSurfaceDisabled,
      onSurface,
    },
  } = useAppTheme();
  const openCondition = multiDropdownContent ? isDropdownOpen : open;

  useEffect(() => {
    setValue(defaultValue || (!placeholder ? items[0].value : null));
  }, [defaultValue, items, placeholder]);

  const borderColor = useMemo(
    () =>
      disabled
        ? onSurfaceDisabled
        : hasError
        ? error
        : openCondition
        ? itemBackgroundColor
          ? itemBackgroundColor
          : primary
        : outline,
    [
      disabled,
      error,
      hasError,
      itemBackgroundColor,
      openCondition,
      outline,
      primary,
      onSurfaceDisabled,
    ],
  );

  const fontColor = useMemo(
    () => (disabled ? onSurfaceDisabled : hasError ? error : onSurface),
    [disabled, error, hasError, onSurface, onSurfaceDisabled],
  );
  const dropdownDirectionTOP = dropDownDirection === "TOP";

  const backgroundColor = isInBottomSheet ? bottomSheetBgColor : white;
  const hideLabelText = dropdownDirectionTOP && open;

  const renderListItem = useCallback(
    (props: RenderListItemPropsInterface<T>) => {
      const isLast = props.value === items[items.length - 1].value;
      return (
        <TouchableOpacity
          {...props}
          style={[
            styles.listItemContainerStyle,
            isLast && !dropdownDirectionTOP && styles.lastListItemStyle,
            {
              backgroundColor: props.isSelected
                ? itemBackgroundColor
                  ? itemBackgroundColor
                  : primaryContainer
                : backgroundColor,
            },
          ]}
          onPress={() => {
            setValue(props.value);
            onSelectItem && onSelectItem(props.item as PickerItem<T>);
            setOpen(false);
            onClose && onClose();
          }}>
          <Text variant="bodyLarge" numberOfLines={3}>
            {props.label}
          </Text>
        </TouchableOpacity>
      );
    },
    [
      backgroundColor,
      dropdownDirectionTOP,
      itemBackgroundColor,
      items,
      onClose,
      onSelectItem,
      primaryContainer,
    ],
  );

  return (
    <View
      style={[
        applyOuterContainerZIndex && { zIndex: containerZIndex || 100 },
        containerStyles,
      ]}>
      <View
        style={[
          {
            alignItems: "flex-start",
            zIndex: 9998,
          },
          dropdownLabelContainerStyle,
        ]}>
        {dropdownLabelText && !hideLabelText && !openCondition && (
          <HelperText
            padding="none"
            style={[styles.helperText, { backgroundColor, color: fontColor }]}
            type={hasError ? "error" : "info"}>
            {`${t(dropdownLabelText)}${isRequired ? "*" : ""}`}
          </HelperText>
        )}
      </View>
      <DropDownPicker<T>
        style={{
          borderColor,
          zIndex: openCondition ? 7000 : 100,
          backgroundColor,
          borderRadius: 3,
          paddingTop: spacing4,
          borderWidth: openCondition ? 2 : 1,
          borderTopWidth: openCondition && !dropdownDirectionTOP ? 2 : 1,
          borderBottomWidth: dropdownDirectionTOP && openCondition ? 2 : 1,
        }}
        open={openCondition}
        value={value}
        items={items}
        setOpen={setOpen}
        setValue={setValue}
        onChangeValue={onValueChange}
        listMode="SCROLLVIEW"
        dropDownContainerStyle={[
          isANDROID && { zIndex: 9999 },
          {
            borderColor,
            backgroundColor,
            borderWidth: 2,
            borderBottomWidth: dropdownDirectionTOP ? 1 : 2,
            borderTopWidth: dropdownDirectionTOP ? 2 : 1,
            borderRadius: spacing4,
          },
        ]}
        textStyle={{
          ...fontConfig.bodyLarge,
          paddingStart: openCondition ? spacing12 - 1 : spacing12,
          color: fontColor,
        }}
        showTickIcon={false}
        ArrowDownIconComponent={() => (
          <IconButton
            icon="chevron-down"
            size={32}
            style={{ margin: 0 }}
            iconColor={disabled ? onSurfaceDisabled : outline}
          />
        )}
        ArrowUpIconComponent={() => (
          <IconButton
            icon="chevron-up"
            size={32}
            style={{ margin: 0 }}
            iconColor={disabled ? onSurfaceDisabled : outline}
          />
        )}
        placeholder={t(placeholder)}
        disabled={disabled}
        searchable={searchable}
        searchContainerStyle={styles.searchContainer}
        searchTextInputStyle={{ borderWidth: 0 }}
        dropDownDirection={dropDownDirection}
        renderListItem={renderListItem}
        onClose={onClose}
        searchPlaceholder={t("T00588")}
        {...props}
      />
    </View>
  );
};

export default DropdownPicker;

const styles = StyleSheet.create({
  helperText: {
    position: "absolute",
    top: -spacing16,
    left: spacing16,
    paddingHorizontal: spacing8,
    paddingBottom: 0,
  },
  searchContainer: {
    padding: 0,
    paddingBottom: spacing8,
    paddingStart: spacing12,
  },
  listItemContainerStyle: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: spacing24,
    paddingVertical: spacing4,
  },
  lastListItemStyle: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
});
