import { PhysiotherapistAvailabilityCard } from "@components/cards/PhysiotherapistCard";
import UpcomingAppointmentCard from "@components/cards/UpcomingAppointmentCard";
import { FetchError } from "@components/errors";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { getPhysiotherapistAvailabilityGroups } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { FlashListItemType } from "./PhysiotherapistCalendar";
import { useAuth } from "@contexts/AuthContext/auth";
import { CustomAppointmentCard } from "@components/cards";
import { getDays } from "@utils/date";

type Props = {
  item: FlashListItemType;
  navToAvailability?: (initialDate?: Date) => void;
  navToAppointment: (id: number) => void;
  navToCustomAppointment: (id: number) => void;
};

const today = new Date();

export const PhysioCalendarItem: FC<Props> = ({
  item,
  navToAppointment,
  navToAvailability,
  navToCustomAppointment,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    user: { id },
  } = useAuth();

  const selectedDate = useMemo(
    () =>
      parse(
        typeof item === "string"
          ? item.split(" - ")[0]
          : item.date.split(" - ")[0],
        "dd.MM.yyyy",
        today,
      ),
    [item],
  );

  const { startOfDayDate, endOfDayDate } = useMemo(
    () => getDays(selectedDate),
    [selectedDate],
  );

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapist.availabilityGroupsForSelectedDay({
      physioId: id,
      dateFrom: startOfDayDate,
      dateTo: endOfDayDate,
    }),
    queryFn: () =>
      getPhysiotherapistAvailabilityGroups({
        dateFrom: startOfDayDate,
        dateTo: endOfDayDate,
      }),
    select: data => {
      const currentSelectedDate = format(selectedDate, "yyyy-MM-dd");
      return data[currentSelectedDate];
    },
  });

  const renderAvailability = useCallback(() => {
    return (
      data?.length > 0 && (
        <PhysiotherapistAvailabilityCard
          date={selectedDate}
          data={data}
          navToAvailability={navToAvailability}
          {...props}
        />
      )
    );
  }, [data, navToAvailability, props, selectedDate]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return typeof item === "object" ? (
    <View style={[globalStyles.gapMedium, { marginBottom: spacing16 }]}>
      <Text variant="titleMedium">{item.date}</Text>
      {renderAvailability()}
      {item.data.map((element, i) =>
        element?.name ? (
          <CustomAppointmentCard
            key={`custom-appointment-${element.id}-${i}`}
            upcomingAppointmentData={element}
            onPress={() => navToCustomAppointment(element.id)}
          />
        ) : (
          <UpcomingAppointmentCard
            key={`upcoming-appointment-${element.id}-${i}`}
            upcomingAppointmentData={element}
            onPress={() => navToAppointment(element.id)}
          />
        ),
      )}
    </View>
  ) : (
    <>
      <View style={{ marginBottom: spacing16 }}>
        <View style={globalStyles.gapMedium}>
          <Text variant="titleMedium">{item}</Text>
          {renderAvailability() || (
            <Text variant="bodyMedium">{t("T00177")}</Text>
          )}
        </View>
      </View>
    </>
  );
};
