import { FetchError } from "@components/errors";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { UserListItem, UserListItemMode } from "@components/ListItems";
import AbsoluteLoader from "@components/Loader/AbsoluteLoader";
import { OnlyIdProfile } from "@contexts/AuthContext/user.types";
import { useInvitation } from "@hooks/promoCodes/useInvitation";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useErrors } from "@hooks/useErrors";
import { useAssistant } from "@hooks/user/useAssistant";
import { queryKeysPatient } from "@screens/Common/queryKeysMyPatients";
import { NoResultsInfo } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/NoResultsInfo";
import { getMyPatients } from "@services/ApiService/users";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16, spacing56, spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { isANDROID } from "@utils/constants";
import { AxiosError } from "axios";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, StyleSheet } from "react-native";
import { Divider, Text } from "react-native-paper";

export type PatientsListModalProps = {
  searchQuery: string;
  onItemPress?: (id: number) => void;
};

export const PatientsListModal: FC<PatientsListModalProps> = ({
  searchQuery,
  onItemPress,
}) => {
  const { flatListSeparator, gapMedium, scrollContainer } = globalStyles;

  const [footerHeight, setFooterHeight] = useState(0);
  const { setErrorsFromResponse } = useErrors();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const { assistantId } = useAssistant();
  const { share } = useInvitation();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPatient.myListSearch(searchQuery),
    queryFn: async () => await getMyPatients(searchQuery),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    select: data => data.filter(e => e.id !== assistantId),
  });

  const renderItem: ListRenderItem<OnlyIdProfile> = useCallback(
    ({ item: { id } }) => (
      <UserListItem
        id={id}
        onPress={() => onItemPress(id)}
        mode={UserListItemMode.PATIENTS}
        hideRightIcon
      />
    ),
    [onItemPress],
  );

  if (isError) return <FetchError action={refetch} />;

  return (
    <>
      <FlatList
        data={data}
        keyExtractor={({ id }, index) => `patient-item-${id}-${index}`}
        contentContainerStyle={[
          gapMedium,
          scrollContainer,
          {
            paddingTop: spacing8,
            paddingBottom: !data?.length && !isANDROID ? 0 : footerHeight,
            flexGrow: 1,
          },
        ]}
        renderItem={renderItem}
        ListEmptyComponent={
          searchQuery ? (
            <NoResultsInfo />
          ) : !data?.length ? (
            <Text variant="bodyMedium">{t("T00636")}</Text>
          ) : null
        }
        ItemSeparatorComponent={() => (
          <Divider
            bold
            style={[flatListSeparator, { width, marginTop: spacing8 }]}
          />
        )}
      />
      <AbsoluteBlurredFooter
        title="T01388"
        onPress={share}
        topText={
          <Text variant="labelSmall" style={styles.footerTopText}>
            {t("T01417")}
          </Text>
        }
        onLayout={height => setFooterHeight(height)}
      />
      {isLoading && <AbsoluteLoader />}
    </>
  );
};

const styles = StyleSheet.create({
  footerTopText: {
    marginHorizontal: spacing56,
    marginTop: spacing16,
    textAlign: "center",
    color: palettes.primary[40],
  },
});
