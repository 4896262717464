import { SvgAvailabilityHoursWithBreakExample } from "@assets/svg/tutorials";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AbsoluteBlurredFooter } from "@components/Footers";
import InfoTile from "@components/Tile/InfoTile";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useHeaderHeight } from "@react-navigation/elements";
import { globalStyles } from "@styles/global";
import { spacing12, spacing8 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";

type BreakAfterServiceTutorialBottomSheetProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
};

const BreakAfterServiceTutorialBottomSheet: FC<
  BreakAfterServiceTutorialBottomSheetProps
> = ({ modalVisible, setModalVisible }) => {
  const { t } = useTranslation();
  const { gapLarge, gapMedium, scrollContainer, fontBolded } = globalStyles;
  const { height } = useWindowDimensions();
  const headerHeight = useHeaderHeight();

  const FirstExample = () => (
    <View style={[gapMedium, { paddingBottom: spacing12 }]}>
      <Text variant="bodyMedium">{t("T01586")}</Text>
      <Text variant="bodyMedium">{t("T01587")}</Text>
      <InfoTile
        status="info"
        content={
          <Text variant="bodyMedium">
            <Trans
              i18nKey="T01588"
              components={{
                1: (
                  <Text variant="bodyMedium" style={fontBolded}>
                    Example:
                  </Text>
                ),
              }}
            />
          </Text>
        }
      />
    </View>
  );

  const SecondExample = () => (
    <View style={gapMedium}>
      <Text variant="bodyMedium">
        <Trans
          i18nKey="T01589"
          components={{
            1: (
              <Text variant="bodyMedium" style={fontBolded}>
                Example:
              </Text>
            ),
          }}
        />
      </Text>
      <InfoTile
        status="info"
        content={
          <Text variant="bodyMedium">
            <Trans
              i18nKey="T01590"
              components={{
                1: (
                  <Text variant="bodyMedium" style={fontBolded}>
                    Example:
                  </Text>
                ),
              }}
            />
          </Text>
        }
      />
      <SvgAvailabilityHoursWithBreakExample />
    </View>
  );
  const [footerHeight, setFooterHeight] = useState(0);

  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      scrollableContent
      disablePanHandlers>
      <>
        <ScrollView
          style={[
            gapLarge,
            {
              height:
                height -
                headerHeight -
                (isWeb ? footerHeight : footerHeight / 2),
            },
          ]}
          contentContainerStyle={[
            isWeb
              ? { paddingBottom: footerHeight }
              : { paddingBottom: footerHeight / 2 },
            scrollContainer,
          ]}>
          <View style={[gapMedium, { paddingBottom: spacing8 }]}>
            <Text variant="bodyLarge">{t("T01585")}</Text>
            <FirstExample />
            <SecondExample />
          </View>
        </ScrollView>
        <AbsoluteBlurredFooter
          onPress={() => setModalVisible(false)}
          title="T00394"
          onLayout={height => setFooterHeight(height)}
        />
      </>
    </BottomModalContainer>
  );
};

export default BreakAfterServiceTutorialBottomSheet;
