import { SvgRightArrowFilled } from "@assets/svg";

import { FetchError } from "@components/errors";
import { HeaderWithSearchbar } from "@components/HeaderWithSearchbar";
import { useKeyboard } from "@hooks/ui/useKeyboard";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useFlags } from "@hooks/useFlags";
import { AddServicesStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysServices } from "@screens/Common/queryKeysServices";
import { NoResultsInfo } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/NoResultsInfo";
import { CurrencyAmountResponse } from "@services/ApiService/api.types";
import { getCurrencyAmount } from "@services/ApiService/common";
import { getServicesForSpecialization } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing16, spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { isANDROID } from "@utils/constants";
import { useThrottle } from "ahooks";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View } from "react-native";
import { ActivityIndicator, Divider, List, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { queryKeysVerficiations } from "../queryKeysPhysiotherapistVerification";
import { ServiceItem } from "../verification.types";
import { AddServiceContext } from "./addService.context";

const AddServices: FC<
  PropsWithChildren<
    NativeStackScreenProps<AddServicesStackParamsList, "AddServices">
  >
> = ({ navigation: { goBack, navigate } }) => {
  const { specializationId, setData } = useContext(AddServiceContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [services, setServices] = useState<ServiceItem[]>([]);
  const keyboardHeight = useKeyboard();

  const {
    flags: { FEATURE_FREE_SERVICE_ENABLED },
  } = useFlags();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const throttledQuery = useThrottle(searchQuery, {
    leading: false,
  });

  const {
    data,
    isLoading: isLoadingOptions,
    isError: isErrorOptions,
    refetch: refetchOptions,
    isSuccess,
  } = useQuery({
    queryKey: queryKeysServices.list({ specializationId }),
    queryFn: async () =>
      await getServicesForSpecialization({ specializationId }),
    enabled: !!specializationId,
  });

  useEffect(() => {
    isSuccess && setServices(data);
  }, [isSuccess, data]);

  useEffect(() => {
    setServices(() =>
      data?.filter(({ name }) =>
        name.toLowerCase().includes(throttledQuery.toLowerCase()),
      ),
    );
  }, [data, throttledQuery]);

  const {
    data: currencyAmount,
    isLoading: currencyAmountLoading,
    isError: currencyAmountError,
    refetch: refetchCurrencyAmount,
  } = useQuery({
    queryFn: getCurrencyAmount,
    queryKey: queryKeysVerficiations.currencyOptions(),
  });

  const openAddServiceForm = useCallback(
    (
      serviceName: string,
      serviceItemId: number,
      freeService?: boolean,
      currencyAmount?: CurrencyAmountResponse,
    ) => {
      if (freeService) {
        setData({ serviceItemId, serviceName });
        navigate("AddFreeService");
      } else {
        setData({ serviceItemId, serviceName, currencyAmount });
        navigate("AddPaidServiceStep1");
      }
    },
    [navigate, setData],
  );

  const renderItem = useCallback(
    ({
      item: { name, id, freeService },
      index,
    }: {
      item: ServiceItem;
      index: number;
    }) => {
      const isLastItem = index === services?.length - 1;
      if (freeService && !FEATURE_FREE_SERVICE_ENABLED) return;
      return (
        <View>
          <List.Item
            title={
              <Text variant="bodyLarge" style={styles.title}>
                {name}
              </Text>
            }
            right={() => (
              <View style={styles.svg}>
                <SvgRightArrowFilled />
              </View>
            )}
            onPress={() =>
              openAddServiceForm(name, id, freeService, currencyAmount)
            }
            titleNumberOfLines={0}
          />
          {!isLastItem && (
            <Divider bold style={[globalStyles.flatListSeparator, { width }]} />
          )}
        </View>
      );
    },
    [
      FEATURE_FREE_SERVICE_ENABLED,
      currencyAmount,
      openAddServiceForm,
      services?.length,
      width,
    ],
  );

  const onChangeText = (text: string) => setSearchQuery(text);

  const refetchAll = async () =>
    await Promise.all([refetchOptions(), refetchCurrencyAmount()]);

  if (isLoadingOptions || currencyAmountLoading)
    return <ActivityIndicator style={globalStyles.loading} />;

  if (isErrorOptions || currencyAmountError)
    return <FetchError action={refetchAll} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["left", "top", "right"]}>
      <HeaderWithSearchbar
        goBack={goBack}
        onChangeText={onChangeText}
        searchQuery={searchQuery}
        enableSearching
        onClear={() => setServices(() => data)}
      />
      <FlatList
        contentContainerStyle={[
          globalStyles.gapMedium,
          globalStyles.scrollContainer,
          {
            paddingTop: spacing8,
            paddingBottom:
              searchQuery && keyboardHeight && !isANDROID ? keyboardHeight : 0,
            flexGrow: 1,
          },
        ]}
        data={services}
        renderItem={renderItem}
        ListEmptyComponent={
          searchQuery ? (
            <NoResultsInfo />
          ) : !data.length ? (
            <Text variant="bodyMedium">{t("T00636")}</Text>
          ) : null
        }
        keyExtractor={item => `service-item-${item.id}-${item?.name}`}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  title: {
    marginRight: spacing16,
  },
  svg: { justifyContent: "center" },
});

export default AddServices;
