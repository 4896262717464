import { AppbarTitleWithBackAction } from "@components/Appbar";
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { RewardsStackParamList } from "./navigation.types";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import InviteToTheApp from "@screens/Profiles/MyProfile/InviteToTheApp";
import { Rewards } from "@screens/Profiles/MyProfile/InviteToTheApp/Rewards";

const Stack = createNativeStackNavigator<RewardsStackParamList>();

const RewardsStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<RewardsStackParamList, "InviteToTheApp">
  >
> = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="InviteToTheApp"
        component={InviteToTheApp}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction onClose={goBack} title={t("T01322")} />
          ),
        }}
      />
      <Stack.Screen
        name="Rewards"
        component={Rewards}
        options={{
          header: ({ navigation }) => (
            <AppbarTitleWithDefaultBackAction
              title={t("T01551")}
              navigation={navigation}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default RewardsStackNavigator;
