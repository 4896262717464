import { BlurView } from "@components/BlurView";
import { TextButton } from "@components/Button";
import { PrimaryButton } from "@components/buttons";
import { useLogInvitationEvents } from "@hooks/analytics/useLogInvitationEvents";
import { useInvitation } from "@hooks/index";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { isANDROID, isIOS } from "@utils/constants";
import { FC, useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { InviteToTheAppBlurredFooterProps } from "./inviteToTheApp.types";

const InviteToTheAppBlurredFooter: FC<InviteToTheAppBlurredFooterProps> = ({
  onLayout,
  intensity,
  onRightTextButtonPress,
}) => {
  const [buttonHeight, setButtonHeight] = useState<number>(null);
  const { share, copyToClipboard, invitationCode } = useInvitation();

  const { handleShareCodeEvent, handleShowQrCodeEvent } =
    useLogInvitationEvents();

  const { gapMedium } = globalStyles;
  const {
    blurredBackground,
    container,
    leftTextButtonStyle,
    upperContainer,
    textButtonStyle,
  } = styles;

  const handleShare = useCallback(async () => {
    await share();
    await handleShareCodeEvent();
  }, [handleShareCodeEvent, share]);

  const handleRighTextButtonPress = useCallback(async () => {
    onRightTextButtonPress();
    await handleShowQrCodeEvent();
  }, [handleShowQrCodeEvent, onRightTextButtonPress]);

  return (
    <BlurView
      onLayout={({ nativeEvent }) =>
        onLayout && onLayout(nativeEvent.layout.height)
      }
      style={[
        blurredBackground,
        isANDROID && { backgroundColor: palettes.primary[100] },
      ]}
      intensity={intensity ? intensity : isIOS ? 50 : 100}
      tint="light">
      <View style={[container, gapMedium]}>
        <View style={upperContainer}>
          <TextButton
            title={invitationCode}
            onPress={copyToClipboard}
            containerStyle={[leftTextButtonStyle, { height: buttonHeight }]}
            textStyle={textButtonStyle}
          />
          <TextButton
            title="T01387"
            onPress={handleRighTextButtonPress}
            containerStyle={{ width: "50%", height: buttonHeight }}
            textStyle={textButtonStyle}
          />
        </View>
        <PrimaryButton
          label="T01388"
          onLayout={({ nativeEvent }) =>
            setButtonHeight(nativeEvent.layout.height)
          }
          onPress={handleShare}
        />
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  blurredBackground: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  container: {
    padding: spacing16,
    marginBottom: spacing16,
  },
  upperContainer: {
    flexDirection: "row",
  },
  leftTextButtonStyle: {
    width: "50%",
    borderColor: palettes.primary[40],
    borderWidth: 1,
    borderRadius: 0,
    borderStyle: "dashed",
    paddingRight: 0,
  },
  textButtonStyle: {
    textAlign: "center",
  },
});

export default InviteToTheAppBlurredFooter;
