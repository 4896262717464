import Avatar from "@components/Avatar";
import { FetchError } from "@components/errors";
import { PublicPhysiotherapistProfile } from "@contexts/AuthContext/user.types";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import {
  getPatientBasicData,
  getPhysiotherapistBasicData,
} from "@services/ApiService/users";
import { spacing16, spacing24, spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { AVATAR_SIZE } from "@utils/constants";
import { FC } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import {
  ActivityIndicator,
  IconButton,
  Card as RNPCard,
  Text,
} from "react-native-paper";

export enum UserListItemMode {
  PATIENTS = "patients",
  THERAPISTS = "therapists",
}

type Props = {
  id: number;
  onPress: (id: number) => void;
  mode: UserListItemMode;
  hideRightIcon?: boolean;
};

export const UserListItem: FC<Props> = ({
  id,
  onPress,
  mode,
  hideRightIcon,
}) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(id),
    queryFn: async () => {
      return mode === UserListItemMode.PATIENTS
        ? await getPatientBasicData(id)
        : await getPhysiotherapistBasicData(id);
    },
  });

  const title = (
    <Text variant="titleMedium">{`${data?.firstName} ${data?.lastName}`}</Text>
  );

  const physioData = data as PublicPhysiotherapistProfile;
  const subtitle = (
    <Text variant="bodyMedium">
      {physioData?.licence?.specialization?.name}
    </Text>
  );

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <TouchableOpacity onPress={() => onPress(id)}>
      <RNPCard.Title
        title={data ? title : null}
        titleNumberOfLines={0}
        subtitle={physioData?.licence?.specialization ? subtitle : null}
        left={() => (
          <Avatar
            firstName={data?.firstName}
            userId={data?.id}
            image={data?.avatar}
          />
        )}
        leftStyle={styles.leftIconStyle}
        style={{ paddingLeft: 0 }}
        right={() =>
          hideRightIcon ? null : (
            <IconButton icon="menu-right" size={spacing24} />
          )
        }
      />
      {physioData?.address?.companyName && (
        <Text
          variant="bodyMedium"
          style={{
            marginLeft: AVATAR_SIZE + spacing16,
            marginBottom: spacing8,
          }}>
          {physioData?.address?.companyName}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  leftIconStyle: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    marginRight: spacing16,
  },
});
