import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import { useErrors } from "@hooks/useErrors";
import CreateOrEditTemplateBottomSheet from "@screens/MessageCenter/Conversation/CreateOrEditTemplateBottomSheet";
import { queryKeysConversations } from "@screens/MessageCenter/queryKeysMessageCenter";
import { deleteCustomMessageTemplate } from "@services/ApiService/conversations";
import { theme } from "@styles/theme";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, useCallback, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { Card as RNPCard, Text } from "react-native-paper";
import { CustomMessageTemplateTileProps } from "./types/Tile.types";

const CustomMessageTemplateTile: FC<CustomMessageTemplateTileProps> = ({
  mode,
  elevation,
  messageTemplate,
  ...props
}) => {
  const [
    messageTemplateOptionsBottomSheetVisible,
    setMssageTemplateOptionsBottomSheetVisible,
  ] = useState(false);

  const [
    createOrEditTemplateBottomSheetVisible,
    setCreateOrEditTemplateBottomSheetVisible,
  ] = useState(false);

  const queryClient = useQueryClient();

  const { setErrorsFromResponse } = useErrors();

  const onDeleteSuccess = useCallback(async () => {
    setMssageTemplateOptionsBottomSheetVisible(false);
    await queryClient.invalidateQueries(queryKeysConversations.templatesList());
  }, [queryClient]);

  const { mutate: deleteTemplate, isLoading: isDeleteTemplateLoading } =
    useMutation({
      mutationFn: deleteCustomMessageTemplate,
      onSuccess: onDeleteSuccess,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00146",
        onPress: () => deleteTemplate(messageTemplate.id),
        loading: isDeleteTemplateLoading,
        mode: "outlined",
      },
      {
        label: "T00335",
        onPress: () => {
          setMssageTemplateOptionsBottomSheetVisible(false);
          setTimeout(() => {
            setCreateOrEditTemplateBottomSheetVisible(true);
          }, 500);
        },
        loading: false,
      },
    ],
    [deleteTemplate, isDeleteTemplateLoading, messageTemplate.id],
  );

  const handleTilePress = () =>
    setMssageTemplateOptionsBottomSheetVisible(true);

  const { borderWidth, borderColor, elevatedBackground } = styles;

  return (
    <>
      <RNPCard
        {...props}
        elevation={elevation as never}
        mode={mode}
        style={[
          (mode === "contained" || "elevated") && borderWidth,
          borderColor,
          mode === "elevated" && elevatedBackground,
        ]}
        onPress={handleTilePress}>
        <RNPCard.Content>
          <Text variant="titleMedium">{messageTemplate?.name}</Text>
          <Text variant="bodyMedium">{messageTemplate?.description}</Text>
        </RNPCard.Content>
      </RNPCard>
      <BottomModalWithButton
        modalVisible={messageTemplateOptionsBottomSheetVisible}
        setModalVisible={setMssageTemplateOptionsBottomSheetVisible}
        buttons={buttons}
      />
      <CreateOrEditTemplateBottomSheet
        bottomSheetVisible={createOrEditTemplateBottomSheetVisible}
        setBottomSheetVisible={setCreateOrEditTemplateBottomSheetVisible}
        customMessageTemplateData={messageTemplate}
      />
    </>
  );
};

export default CustomMessageTemplateTile;

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
  },
  borderWidth: {
    borderWidth: 0.5,
  },

  elevatedBackground: {
    backgroundColor: theme.colors.elevatedCardBackground,
  },
});
