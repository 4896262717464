import { SvgSearch } from "@assets/svg";
import { PrimaryButton } from "@components/buttons";
import { TransKey } from "@globalTypes/i18next";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

type Props = {
  clearAllFilters?: () => void;
  anyFilterActive?: boolean;
  description?: TransKey;
};

export const NoResultsInfo: FC<Props> = ({
  clearAllFilters,
  anyFilterActive,
  description,
}) => {
  const { gapLarge, gapMedium } = globalStyles;
  const { t } = useTranslation();

  return (
    <View style={[gapLarge, styles.container]}>
      <SvgSearch />
      <View style={gapMedium}>
        <Text variant="titleMedium" style={{ textAlign: "center" }}>
          {t("T01344")}
        </Text>
        <Text variant="bodyMedium" style={{ textAlign: "center" }}>
          {t(description ? description : "T01345")}
        </Text>
      </View>
      {anyFilterActive && clearAllFilters && (
        <PrimaryButton label="T00565" onPress={clearAllFilters} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
});
