import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useState } from "react";
import { StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";

import { RootStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { spacing24 } from "@styles/spacing";
import { theme } from "@styles/theme";

import CreateOrEditTemplateBottomSheet from "./CreateOrEditTemplateBottomSheet";
import CustomMessageTemplatesList from "./CustomMessageTemplatesList";

type CustomMessageTemplatesProps = PropsWithChildren<
  NativeStackScreenProps<RootStackParamList, "CustomMessageTemplates">
>;

const CustomMessageTemplates: FC<CustomMessageTemplatesProps> = () => {
  const [
    createOrEditTemplateBottomSheetVisible,
    setCreateOrEditTemplateBottomSheetVisible,
  ] = useState(false);

  return (
    <View style={globalStyles.container}>
      <CustomMessageTemplatesList />
      <FAB
        icon="plus"
        onPress={() => setCreateOrEditTemplateBottomSheetVisible(true)}
        style={styles.fab}
        color={theme.colors.white}
      />
      <CreateOrEditTemplateBottomSheet
        bottomSheetVisible={createOrEditTemplateBottomSheetVisible}
        setBottomSheetVisible={setCreateOrEditTemplateBottomSheetVisible}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  fab: {
    position: "absolute",
    bottom: spacing24,
    right: spacing24,
    backgroundColor: theme.colors.primary,
  },
});

export default CustomMessageTemplates;
