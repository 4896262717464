import { AppbarTitleWithBackAction } from "@components/Appbar";
import ServiceTypeButtonAndSlotsPicker from "@components/BottomSheetContents/ServiceTypeButtonAndSlotsPicker";

import TutorialTextButton from "@components/Button/TutorialTextButton";
import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import SlotsDropdownPicker from "@components/DropdownPicker/SlotsDropdownPicker";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { createSlots } from "@components/Status/helpers";
import { useAuth } from "@contexts/AuthContext/auth";
import { TransKey } from "@globalTypes/i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddServicesStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  PricesType,
  ServiceType,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { globalStyles } from "@styles/global";
import { spacing16, spacing56, spacing8 } from "@styles/spacing";
import { BREAK_SLOTS_COUNT, isANDROID } from "@utils/constants";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { object, string } from "yup";
import BreakAfterServiceTutorialBottomSheet from "../BreakAfterServiceTutorialBottomSheet";
import { AddServiceContext } from "./addService.context";

export type ServiceFormType = {
  serviceType: ServiceType;
};

const AddPaidServiceStep1: FC<
  PropsWithChildren<
    NativeStackScreenProps<AddServicesStackParamsList, "AddPaidServiceStep1">
  >
> = ({ navigation: { navigate, setOptions, pop, popToTop } }) => {
  const [tutorialBottomSheetVisible, setTutorialBottomSheetVisible] =
    useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const breakSlots = createSlots(BREAK_SLOTS_COUNT, true);
  const {
    currencyAmount: currAmount,
    service = null,
    serviceItemId,
    serviceName,
    specializationId,
    setData,
    clearData,
  } = useContext(AddServiceContext);

  const {
    user: { currency },
  } = useAuth();
  const { t } = useTranslation();
  const { scrollContainer, gapLarge } = globalStyles;
  const editionMode = !!service;
  const currencyAmount = currAmount?.choices.filter(
    e => e.currency === currency,
  );
  const baseCurrency = currencyAmount?.[0];
  const restOfCurrencies = currAmount?.choices.filter(
    e => e.currency !== currency,
  );

  const [selectedCurrencyAmount, setSelectedCurrencyAmount] = useState<number>(
    getPriceInBaseCurrency(service?.prices) || baseCurrency?.value[0],
  );
  const [slotsDropdownOpen, setSlotsDropdownOpen] = useState(false);
  const [breakSlotsDropdownOpen, setBreakSlotsDropdownOpen] = useState(false);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<number>(
    service?.clientSlots - 1 || 0,
  );
  const [selectedBreakSlots, setSelectedBreakSlots] = useState<number>(
    service?.breakSlots || 0,
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction
          title={t(editionMode ? "T01250" : "T00250")}
          onBack={() => {
            clearData();
            setData({ specializationId });
            goBack();
          }}
          onClose={() => {
            clearData();
            !editionMode && popToTop();
            pop(1);
          }}
        />
      ),
    });
  }, [
    clearData,
    editionMode,
    pop,
    popToTop,
    setData,
    setOptions,
    specializationId,
    t,
  ]);

  const schema = useMemo(
    () =>
      object().shape({
        serviceType: string().required(t("T00028")),
      }),
    [t],
  );

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const restOfStep1Data = useMemo(
    () => ({
      serviceName: service?.serviceItem.name || serviceName,
      clientSlots: selectedSlots + 1,
      breakSlots: selectedBreakSlots,
      price: selectedCurrencyAmount,
      selectedCurrency: baseCurrency?.currency,
      restOfCurrencies,
      serviceItemId: service?.serviceItem.id || serviceItemId,
      service,
    }),
    [
      baseCurrency?.currency,
      restOfCurrencies,
      selectedBreakSlots,
      selectedCurrencyAmount,
      selectedSlots,
      service,
      serviceItemId,
      serviceName,
    ],
  );

  const onCurrencyDropdownOpen = useCallback(() => {
    setSlotsDropdownOpen && setSlotsDropdownOpen(false);
    setBreakSlotsDropdownOpen && setBreakSlotsDropdownOpen(false);
    setCurrencyDropdownOpen(true);
  }, []);

  const onBreakSlotsDropdownOpen = useCallback(() => {
    setCurrencyDropdownOpen && setCurrencyDropdownOpen(false);
    setSlotsDropdownOpen && setSlotsDropdownOpen(false);
    setBreakSlotsDropdownOpen(true);
  }, []);

  const goToStep2 = (validFormData: ServiceFormType) => {
    setData({ ...validFormData, ...restOfStep1Data });
    navigate("AddPaidServiceStep2");
  };

  function getPriceInBaseCurrency(prices: PricesType[]) {
    if (!prices?.length) return null;
    return prices.find(e => e.currency === currency).amount;
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          {
            paddingBottom: footerHeight,
            paddingTop:
              slotsDropdownOpen && !isANDROID && !editionMode
                ? spacing56
                : spacing16,
          },
        ]}>
        <View style={gapLarge}>
          <Chip style={{ alignSelf: "flex-start" }}>{`${t(
            "T00957",
          )} 1/2`}</Chip>
          <Text variant="bodyLarge">{restOfStep1Data.serviceName}</Text>
          {service?.serviceType && (
            <Text variant="bodyMedium">{t("T01179")}</Text>
          )}
          <ServiceTypeButtonAndSlotsPicker
            control={control}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            slotsDropdownOpen={slotsDropdownOpen}
            setSlotsDropdownOpen={setSlotsDropdownOpen}
            setCurrencyDropdownOpen={setCurrencyDropdownOpen}
            setBreakSlotsDropdownOpen={setBreakSlotsDropdownOpen}
            watch={watch}
            serviceType={service?.serviceType}
          />
          <SlotsDropdownPicker
            slots={breakSlots}
            defaultValue={selectedBreakSlots}
            setSelectedSlots={setSelectedBreakSlots}
            dropdownLabel="T01584"
            onOpen={onBreakSlotsDropdownOpen}
            onClose={() => setBreakSlotsDropdownOpen(false)}
            isDropdownOpen={breakSlotsDropdownOpen}
          />
          <View style={{ marginTop: -spacing16 }}>
            <TutorialTextButton
              label="T01585"
              onPress={() => setTutorialBottomSheetVisible(true)}
              buttonInView
            />
          </View>
          <DropdownPicker<number>
            items={baseCurrency?.value.map(e => ({ value: e, label: `${e}` }))}
            defaultValue={selectedCurrencyAmount}
            onSelectItem={({ value }) => setSelectedCurrencyAmount(value)}
            containerStyles={{ marginBottom: spacing8 }}
            dropdownLabelText={
              `${t("T00784")} ${baseCurrency?.currency}` as TransKey
            }
            onOpen={onCurrencyDropdownOpen}
            onClose={() => setCurrencyDropdownOpen(false)}
            isDropdownOpen={currencyDropdownOpen}
            multiDropdownContent
            dropDownDirection="TOP"
          />
        </View>
        <BreakAfterServiceTutorialBottomSheet
          modalVisible={tutorialBottomSheetVisible}
          setModalVisible={setTutorialBottomSheetVisible}
        />
      </ScrollView>
      <AbsoluteBlurredFooter
        onPress={handleSubmit(goToStep2)}
        title="T00472"
        onLayout={height => setFooterHeight(height)}
      />
    </>
  );
};

export default AddPaidServiceStep1;
