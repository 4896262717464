import StatusComponent from "@components/Status/StatusComponent";
import { VerificationStatus } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { globalStyles } from "@styles/global";
import { useAppTheme } from "@styles/theme";
import { FC } from "react";
import { View } from "react-native";
import { Card, CardProps, Text } from "react-native-paper";

type InvitedUserCardProps = {
  name: string;
  status: VerificationStatus;
} & Omit<CardProps, "children">;

export const InvitedUserCard: FC<InvitedUserCardProps> = ({
  mode = "outlined",
  elevation,
  name,
  status,
  ...props
}) => {
  const {
    colors: { outlineVariant, surface },
  } = useAppTheme();

  return (
    <Card
      {...props}
      elevation={elevation as never}
      mode={mode}
      style={[
        mode === "outlined" && {
          backgroundColor: surface,
          borderColor: outlineVariant,
        },
      ]}>
      <Card.Content>
        <View style={globalStyles.gapSmall}>
          <Text variant="titleMedium">{`${name}.`}</Text>
          <StatusComponent status={status} type="invitedUser" />
        </View>
      </Card.Content>
    </Card>
  );
};

export default InvitedUserCard;
