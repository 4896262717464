import { FetchError } from "@components/errors";
import { CheckboxListItem } from "@components/ListItems/CheckboxListItem";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { EquipmentType } from "@screens/TrainingsAndExercises/exercise.types";
import { NoResultsInfo } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/NoResultsInfo";
import { queryKeysEquipments } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getEquipmentList } from "@services/ApiService/exercises";
import { FlashList } from "@shopify/flash-list";
import { globalStyles } from "@styles/global";
import { spacing32, spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, StyleProp, View, ViewStyle } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

export type ServicesModalProps = {
  searchQuery: string;
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
  selectedItems: number[];
  style?: StyleProp<ViewStyle>;
};

const defaultAvatar =
  require("@assets/images/default_avatar.png") as ImageSourcePropType;

export const EquipmentModal: FC<ServicesModalProps> = ({
  searchQuery,
  setSelectedItems,
  selectedItems,
  style,
}) => {
  const [itemsList, setItemsList] = useState<EquipmentType[]>([]);
  const { width } = useWindowDimensions();
  const { loading, gapMedium, scrollContainer, flatListSeparator } =
    globalStyles;
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysEquipments.list(),
    queryFn: getEquipmentList,
  });

  const filteredItemsList = useMemo(
    () =>
      data?.filter(({ name }) =>
        name.toLocaleLowerCase().includes(searchQuery?.toLowerCase()),
      ),
    [data, searchQuery],
  );

  useEffect(() => {
    if (searchQuery.length > 0) {
      setItemsList(filteredItemsList);
    } else {
      setItemsList(data);
    }
  }, [data, filteredItemsList, searchQuery]);

  const onPressItem = useCallback(
    (item: number) =>
      setSelectedItems(prevState => {
        if (!prevState) return [item];
        return prevState.includes(item)
          ? prevState.filter(e => e !== item)
          : [...prevState, item];
      }),
    [setSelectedItems],
  );

  const renderItem = useCallback(
    ({
      item: { name, id, image },
      extraData,
    }: {
      item: EquipmentType;
      extraData?: number[];
    }) => {
      const img = image ? { uri: image } : defaultAvatar;

      return (
        <CheckboxListItem
          title={name}
          img={img}
          placeholder={defaultAvatar}
          checkboxProps={{
            status: extraData?.includes(id) ? "checked" : "unchecked",
          }}
          onPress={() => onPressItem(id)}
          style={{ minHeight: 70 }}
        />
      );
    },
    [onPressItem],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={[{ flex: 1 }, gapMedium, selectedItems?.length && style]}>
      <FlashList
        data={itemsList}
        extraData={selectedItems}
        estimatedItemSize={70}
        keyExtractor={({ id }) => `eq-${id}`}
        contentContainerStyle={scrollContainer}
        renderItem={renderItem}
        ListEmptyComponent={
          searchQuery ? (
            <View style={{ marginTop: spacing32 }}>
              <NoResultsInfo />
            </View>
          ) : !data.length ? (
            <Text variant="bodyMedium">{t("T00636")}</Text>
          ) : null
        }
        ItemSeparatorComponent={() => (
          <Divider
            bold
            style={[flatListSeparator, { width, marginTop: spacing8 }]}
          />
        )}
      />
    </View>
  );
};
