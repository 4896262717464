import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";
import InfoTile from "@components/Tile/InfoTile";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { FC } from "react";
import { PrimaryButton } from "@components/buttons";
import { View } from "react-native";
import { globalStyles } from "@styles/global";

type ServicesTutorialBottomSheetProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  specializationName: string;
};

const ServicesTutorialBottomSheet: FC<ServicesTutorialBottomSheetProps> = ({
  specializationName,
  modalVisible,
  setModalVisible,
}) => {
  const { t } = useTranslation();
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <View style={globalStyles.gapLarge}>
        <Text variant="bodyLarge">{t("T01446")}</Text>
        <Text variant="bodyMedium">{t("T00242")}</Text>
        <Text variant="bodyMedium">{t("T01583")}</Text>
        <InfoTile
          status="info"
          content={
            <>
              <Text variant="bodyMedium">{t("T01180")}</Text>
              {specializationName && (
                <Text
                  style={{
                    fontWeight: "bold",
                  }}>{`(${specializationName})`}</Text>
              )}
            </>
          }
        />
        <PrimaryButton label="T00394" onPress={() => setModalVisible(false)} />
      </View>
    </BottomModalContainer>
  );
};

export default ServicesTutorialBottomSheet;
