import { createLibraryTraining, getLibraryTraining } from "@api/trainings";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import {
  CreateTrainingStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import { AppbarTitleWithBackAction } from "@components/Appbar";
import { Input } from "@components/Input";
import { PrimaryButton, SearchbarButton } from "@components/buttons";
import { FetchError, GeneralError } from "@components/errors";
import { SafeAreaView } from "react-native-safe-area-context";
import { object, string } from "yup";
import {
  CreateAndEditTrainingType,
  CreateAndEditTrainingTypeResponse,
} from "../training.types";

import { CreateTrainingContext } from "@screens/TrainingsAndExercises/Physiotherapist/createTraining.context";
import {
  queryKeysLibraryTrainings,
  queryKeysTraining,
} from "../queryKeysTrainingsAndExercises";

import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import ExerciseWithSeriesTile from "@components/Tile/exercise/ExerciseWithSeriesTile";
import { CompositeScreenProps } from "@react-navigation/native";
import {
  MAX_THREAD_DESCRIPTION_INPUT_LENGTH,
  TITLE_INPUT_MAX_LENGTH,
} from "@utils/constants";
import { removeNullFields } from "@utils/objectHelpers";
import { CtxExerciseType } from "../exercise.types";
import { TransKey } from "@globalTypes/i18next";

export const formatExercisesForApi = (exercises: CtxExerciseType[]) =>
  exercises.map(e => ({
    ...e,
    exerciseVideoId: e.exerciseVideo,
    exerciseImageId: e.exerciseImage,
    instructionExerciseVideoId: e.instructionExerciseVideo,
  }));

const CreateAndEditTraining: FC<
  PropsWithChildren<
    CompositeScreenProps<
      NativeStackScreenProps<
        CreateTrainingStackParamsList,
        "CreateAndEditTraining",
        "SearchExercises"
      >,
      NativeStackScreenProps<Pick<RootStackParamList, "ExerciseDetails">>
    >
  >
> = ({ navigation: { navigate, setOptions, popToTop, setParams }, route }) => {
  const {
    exercises,
    removeExercise,
    setExercises,
    setPatientId,
    training,
    setTraining,
    setPopCount,
  } = useContext(CreateTrainingContext);
  const trainingId = training.trainingId || route.params?.id;
  const patientId = route.params?.patientId;
  const popCount = route.params?.popCount;
  const editionMode = !!trainingId;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { loading, scrollContainer, gapMedium } = globalStyles;

  const [title, setTitle] = useState<string>();
  const [exerciseId, setExerciseId] = useState<number>(null);
  const [exerciseIndex, setExerciseIndex] = useState<number>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [savingOptionsModalVisible, setSavingOptionsModalVisible] =
    useState(false);

  useEffect(() => {
    popCount && setPopCount(popCount);
  }, [popCount, setPopCount]);

  const noExercises = !exercises.length;
  const deleteExerciseAndClose = useCallback(
    (exerciseIndex: number) => {
      removeExercise(exerciseIndex);
      setModalVisible(false);
    },
    [removeExercise],
  );

  const showExerciseDetails = useCallback(
    (id: number) => {
      setModalVisible(false);
      setTimeout(() => navigate("ExerciseDetails", { id }), 500);
    },
    [navigate],
  );

  const editSeriesParameters = useCallback(
    (exerciseIndex: number, exerciseId: number) => {
      setModalVisible(false);
      navigate("EditParameters", { exerciseIndex, exerciseId });
    },
    [navigate],
  );

  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00303",
        onPress: () => deleteExerciseAndClose(exerciseIndex),
        mode: "outlined",
        loading: false,
      },
      {
        label: "T00304",
        onPress: () => showExerciseDetails(exerciseId),
        mode: "outlined",
        loading: false,
      },
      {
        label: "T00305",
        onPress: () => editSeriesParameters(exerciseIndex, exerciseId),
        loading: false,
      },
    ],
    [
      deleteExerciseAndClose,
      editSeriesParameters,
      exerciseId,
      exerciseIndex,
      showExerciseDetails,
    ],
  );

  useEffect(() => {
    setExercises([]);
  }, [setExercises]);

  useEffect(() => {
    setPatientId(patientId);
  }, [setPatientId, patientId]);

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack, pop } }) => (
        <AppbarTitleWithBackAction
          title={t(editionMode ? "T00069" : "T00068")}
          onClose={patientId ? () => pop(popCount) : goBack}
          onBack={goBack}
        />
      ),
    });
  }, [editionMode, patientId, popCount, setOptions, t]);

  const schema = useMemo(
    () =>
      object().shape({
        name: string().required(t("T00014")).trim(),
        description: string().trim().max(MAX_THREAD_DESCRIPTION_INPUT_LENGTH),
      }),
    [t],
  );

  const { errors, setErrorsFromResponse, generalError } = useErrors();

  const refreshAndGoBack = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysLibraryTrainings.list(),
    });
    setExercises([]);
    popToTop();
    reset();
  };

  const refreshLibraryTrainingsListAndGoSetASchedule = async (
    data: CreateAndEditTrainingTypeResponse,
    patientId: number,
  ) => {
    const isNewTraining = savingOptionsModalVisible && !changesInEditionMode;
    const editedTraining = savingOptionsModalVisible && changesInEditionMode;
    setTraining({
      ...data,
    });
    await queryClient.invalidateQueries({
      queryKey: queryKeysLibraryTrainings.list(),
    });
    setSavingOptionsModalVisible(false);
    setTimeout(
      () =>
        navigate("SetAIndividualTrainingSchedule", {
          patientId,
          trainingId: data.id,
          isNewTraining,
          editedTraining,
        }),
      500,
    );
  };

  const {
    data,
    isLoading: isGettingData,
    isError: isGetDataError,
    refetch: getDataRefetch,
  } = useQuery({
    queryKey: queryKeysTraining.detail(trainingId, { fullData: true }),
    queryFn: async () =>
      await getLibraryTraining<CreateAndEditTrainingType>(trainingId, true),
    onSuccess: data => {
      const { exercises, name } = data;
      const desc = data?.description || "";
      setExercises(exercises);
      setTraining({ exercises, name, description: desc });
      setValue("name", name);
      setValue("description", desc);
    },
    enabled: editionMode,
  });

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  const currentName = watch("name");
  const currentDescription = watch("description");

  const isFormChanged = useCallback(() => {
    const exercisesDifferences = exercises?.some(({ id, series }) => {
      const matchingExercise = data?.exercises.find(
        exercise => exercise.id === id,
      );

      if (!matchingExercise) {
        return true;
      }

      return series.some(({ id: seriesId, value }) => {
        const matchingSeries = matchingExercise.series.find(
          series => series.id === seriesId,
        );

        return !matchingSeries || matchingSeries.value !== value;
      });
    });
    return (
      currentName !== data?.name ||
      currentDescription !== data?.description ||
      exercisesDifferences
    );
  }, [
    exercises,
    currentName,
    data?.name,
    data?.description,
    data?.exercises,
    currentDescription,
  ]);

  const { mutate: createTraining, isLoading: isCreatingTraining } = useMutation(
    {
      mutationFn: createLibraryTraining,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: patientId
        ? async ({
            data,
          }: AxiosResponse<CreateAndEditTrainingTypeResponse>) => {
            await refreshLibraryTrainingsListAndGoSetASchedule(data, patientId);
            setParams({ id: data.id });
          }
        : refreshAndGoBack,
    },
  );

  const changesInEditionMode = editionMode && isFormChanged();

  const saveData = useCallback(
    ({ name, description }: CreateAndEditTrainingType) => {
      const exercisesFormattedForApi = formatExercisesForApi(exercises);
      const params: CreateAndEditTrainingType = {
        name: changesInEditionMode ? currentName : name,
        exercises: exercisesFormattedForApi,
        isLibrary: true,
      };

      const desc = changesInEditionMode ? currentDescription : description;
      if (desc) params.description = desc;

      if (editionMode) params.replaceId = trainingId;
      params.exercises.forEach(e => removeNullFields(e));
      setTraining(params);
      patientId && (!trainingId || changesInEditionMode)
        ? setSavingOptionsModalVisible(true)
        : createTraining(params);
    },
    [
      changesInEditionMode,
      createTraining,
      currentDescription,
      currentName,
      editionMode,
      exercises,
      patientId,
      setTraining,
      trainingId,
    ],
  );

  const continueWithoutSavingTrainingToLibrary = useCallback(() => {
    setSavingOptionsModalVisible(false);
    setTimeout(
      () => navigate("SetAIndividualTrainingSchedule", { patientId }),
      500,
    );
  }, [navigate, patientId]);

  useEffect(() => {
    if (changesInEditionMode) {
      setValue("name", currentName);
      setValue("description", currentDescription);
    }
  }, [
    changesInEditionMode,
    currentDescription,
    currentName,
    exercises,
    setValue,
  ]);

  const savingButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: changesInEditionMode ? "T01075" : "T01054",
        onPress: () => createTraining(training),
        mode: "outlined",
        loading: false,
      },
      {
        label: "T01055",
        onPress: continueWithoutSavingTrainingToLibrary,
        mode: "contained",
        loading: false,
      },
    ],
    [
      changesInEditionMode,
      continueWithoutSavingTrainingToLibrary,
      createTraining,
      training,
    ],
  );

  const openModal = useCallback((name: string, id: number, index: number) => {
    setTitle(name);
    setExerciseId(id);
    setExerciseIndex(index);
    setModalVisible(true);
  }, []);

  if (editionMode && isGettingData)
    return <ActivityIndicator style={loading} />;
  if (isGetDataError) return <FetchError action={getDataRefetch} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <ScrollView contentContainerStyle={scrollContainer}>
        {generalError ? <GeneralError error={generalError} /> : null}
        <View style={gapMedium}>
          <Input
            label="T00178"
            name="name"
            key="name"
            control={control as unknown as Control}
            autoCapitalize="sentences"
            errors={errors?.["name"]}
            maxLength={TITLE_INPUT_MAX_LENGTH}
          />
          <Input
            label="T01595"
            name="description"
            key="description"
            control={control as unknown as Control}
            autoCapitalize="sentences"
            errors={errors?.["description"]}
            multiline
            maxLength={MAX_THREAD_DESCRIPTION_INPUT_LENGTH}
          />
        </View>
        <View style={gapMedium}>
          <Text variant="titleMedium">{t("T00169")}:</Text>
          {noExercises ? (
            <Text variant="bodyMedium">{t("T00188")}</Text>
          ) : (
            exercises.map(({ id, name, series }, index) => (
              <ExerciseWithSeriesTile
                key={`exercise-${id}-${index}`}
                exerciseId={id}
                onPress={() => openModal(name, id, index)}
                appliedSeries={series}
              />
            ))
          )}
          <SearchbarButton
            onPress={() => navigate("SearchExercises")}
            label="T00187"
          />
          <PrimaryButton
            disabled={isCreatingTraining || noExercises}
            loading={isCreatingTraining}
            label={patientId ? "T00472" : "T00180"}
            onPress={handleSubmit(saveData)}
            style={{ marginTop: spacing8 }}
          />
        </View>
        <BottomModalWithButton
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          title={title as TransKey}
          buttons={buttons}
        />
        <BottomModalWithButton
          modalVisible={savingOptionsModalVisible}
          setModalVisible={setSavingOptionsModalVisible}
          buttons={savingButtons}
          title={changesInEditionMode ? "T01073" : "T01056"}
          subtitles={[changesInEditionMode ? "T01074" : "T01057"]}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default CreateAndEditTraining;
