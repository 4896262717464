import { FetchError } from "@components/errors";
import CustomMessageTemplateTile from "@components/Tile/CustomMessageTemplateTile";
import { getCustomMessageTemplates } from "@services/ApiService/conversations";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { RefreshControl, ScrollView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { queryKeysConversations } from "../queryKeysMessageCenter";

const CustomMessageTemplatesList = () => {
  const { isLoading, data, isError, refetch } = useQuery({
    queryFn: getCustomMessageTemplates,
    queryKey: queryKeysConversations.templatesList(),
  });
  const { gapLarge, loading } = globalStyles;
  return (
    <ScrollView
      contentContainerStyle={gapLarge}
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={refetch} />
      }>
      {isLoading ? (
        <ActivityIndicator style={loading} />
      ) : isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : (
        data?.map(e => (
          <CustomMessageTemplateTile
            key={e.id}
            messageTemplate={e}
            mode="outlined"
          />
        ))
      )}
    </ScrollView>
  );
};

export default CustomMessageTemplatesList;
