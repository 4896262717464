import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import { CreateTrainingStackParamsList } from "./navigation.types";

import CreateAndEditTraining from "@screens/TrainingsAndExercises/Physiotherapist/CreateAndEditTraining";
import EditParameters from "@screens/TrainingsAndExercises/Physiotherapist/EditParameters";
import { CreateTrainingContextProvider } from "@screens/TrainingsAndExercises/Physiotherapist/createTraining.context";
import SetAIndividualTrainingSchedule from "@screens/TrainingsAndExercises/SetASchedule/SetAIndividualTrainingSchedule";
import SearchExercises from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/SearchExercises";

const Stack = createNativeStackNavigator<CreateTrainingStackParamsList>();

const CreateTrainingStackNavigator: FC = () => {
  const { t } = useTranslation();
  return (
    <CreateTrainingContextProvider>
      <Stack.Navigator
        screenOptions={{
          headerBackTitleVisible: false,
        }}>
        <Stack.Screen
          name="CreateAndEditTraining"
          component={CreateAndEditTraining}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00068")} />,
          }}
        />
        <Stack.Screen
          name="EditParameters"
          component={EditParameters}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00097")} />,
          }}
        />
        <Stack.Screen
          name="SearchExercises"
          component={SearchExercises}
          options={{
            headerShown: false,
            headerBackTitleVisible: false,
          }}
        />
        <Stack.Screen
          name="SetAIndividualTrainingSchedule"
          component={SetAIndividualTrainingSchedule}
          options={{
            header: ({ navigation: { goBack, popToTop, pop } }) => (
              <AppbarTitleWithBackAction
                title={t("T00072")}
                onBack={goBack}
                onClose={() => {
                  popToTop();
                  pop(3);
                }}
              />
            ),
          }}
        />
      </Stack.Navigator>
    </CreateTrainingContextProvider>
  );
};

export default CreateTrainingStackNavigator;
