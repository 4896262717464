import { ButtonBasic } from "@components/Button/Button.types";
import { PrimaryButton } from "@components/buttons";
import { spacing24 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC } from "react";
import { Icon } from "react-native-paper";

const AppleLogin: FC<ButtonBasic> = props => {
  const {
    colors: { scrim, white },
  } = useAppTheme();
  return (
    <PrimaryButton
      icon={() => <Icon source="apple" size={spacing24} color={white} />}
      buttonColor={scrim}
      textColor={white}
      labelStyle={{ fontFamily: "SF-Pro-Display-Medium" }}
      {...props}
    />
  );
};

export default AppleLogin;
