import { RootStackParamList } from "@navigators/navigation.types";
import { FC, PropsWithChildren, useEffect } from "react";
import TrainingsAndExercisesCommonContent from "./TrainingsAndExercisesCommonContent";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { useTranslation } from "react-i18next";

const TrainingsAndExercisesModal: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "TrainingsAndExercisesModal">
  >
> = ({ route, navigation: { setOptions } }) => {
  const { patientId } = (route || {}).params;
  const resetProcess = route.params?.resetProcess;
  const { t } = useTranslation();

  useEffect(() => {
    resetProcess &&
      setOptions({
        header: ({ navigation: { pop } }) => (
          <AppbarTitleWithBackAction
            title={t("T00076")}
            onClose={() => pop(2)}
          />
        ),
      });
  }, [patientId, resetProcess, setOptions, t]);

  return (
    <TrainingsAndExercisesCommonContent
      patientId={patientId}
      resetProcess={resetProcess}
    />
  );
};

export default TrainingsAndExercisesModal;
