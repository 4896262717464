import { AvailabilityGroup } from "@components/PhysioAvailability/PhysioAvailability.types";
import { getMaxAvailableTime } from "@utils/date";

type GetMinAndMaxHoursForPickerParams = {
  groupDetails: AvailabilityGroup;
  dateToCompare: Date;
  alertFn: () => void;
};

export const getMinAndMaxHoursForPicker = ({
  alertFn,
  dateToCompare,
  groupDetails,
}: GetMinAndMaxHoursForPickerParams) => {
  let minDateFrom = dateToCompare;
  let maxDateTo = getMaxAvailableTime(dateToCompare, alertFn);

  if (groupDetails) {
    if (
      new Date(groupDetails.hours[0].dateFrom).getHours() >
      dateToCompare.getHours()
    ) {
      minDateFrom = new Date(groupDetails.hours[0].dateFrom);
    }
    maxDateTo = new Date(
      groupDetails.hours[groupDetails.hours.length - 1].dateTo,
    );
  }

  return { maxDateTo, minDateFrom };
};
