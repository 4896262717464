import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

import { Input } from "@components/Input";
import { Logo } from "@components/Logo";
import { PrimaryButton } from "@components/buttons";

import MultiColorText from "@components/Text/MultiColorText";
import { useAuth } from "@contexts/AuthContext/auth";
import { UserAuthenticationData } from "@contexts/AuthContext/user.types";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";

import GeneralError from "@components/errors/GeneralError";
import DevLogin from "@screens/DEV/DevLogin";
import { config } from "@utils/config";
import { isWeb } from "@utils/constants";
import { Settings } from "react-native-fbsdk-next";
import { SocialSection } from "@components/index";
import { RedirectScreens } from "@components/social/types";

type GoogleSigninType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@react-native-google-signin/google-signin").GoogleSignin;

let GoogleSignin: GoogleSigninType;

if (!isWeb) {
  GoogleSignin = (
    require("@react-native-google-signin/google-signin") as typeof import("@react-native-google-signin/google-signin")
  ).GoogleSignin;
}

export const SignInScreen: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "SignIn">>
> = ({ navigation: { navigate } }) => {
  const { safeAreaViewStyle, container, logoContainer, gap, switchToSignUp } =
    styles;
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const { setErrorsFromResponse, errors, generalError } = useErrors();

  useEffect(() => {
    if (!isWeb) {
      GoogleSignin.configure({
        webClientId: config.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID,
        iosClientId: config.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID,
      });
      Settings.initializeSDK();
      Settings.setAppID(config.EXPO_PUBLIC_FACEBOOK_APP_ID);
      Settings.setClientToken(config.EXPO_PUBLIC_FACEBOOK_CLIENT_TOKEN);
    }
  }, []);

  const { control, handleSubmit, reset } =
    useForm<Partial<UserAuthenticationData>>();
  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: signIn,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const onSubmit = (data: UserAuthenticationData) => {
    mutate(data);
    if (isSuccess) reset();
  };

  const changePassword = async () => {
    const url = `${config.EXPO_PUBLIC_API_BASE_URL}/accounts/password/reset/`;

    if (isWeb) {
      await Linking.openURL(url);
    } else {
      navigate("ForgotPasswordWebView", {
        uri: url,
      });
    }
  };

  return (
    <SafeAreaView style={safeAreaViewStyle}>
      <KeyboardAwareScrollView contentContainerStyle={container}>
        <View style={logoContainer} testID="appLogo">
          <Logo />
        </View>
        <View style={globalStyles.gapLarge}>
          <View style={gap}>
            <Text variant="titleLarge">{t("T00044")}</Text>
            {generalError && <GeneralError error={generalError} />}
          </View>
          {__DEV__ && !isWeb && config.EXPO_PUBLIC_DEV_LOGIN && <DevLogin />}
          <View>
            <Input
              name="email"
              label="T00004"
              control={control as unknown as Control<FieldValues>}
              keyboardType="email-address"
              errors={errors?.email}
              testID="signInInputEmail"
            />
            <Input
              name="password"
              label="T00007"
              secureTextEntry
              control={control as unknown as Control<FieldValues>}
              errors={errors?.password}
              testID="signInInputPassword"
            />
          </View>
          <PrimaryButton
            loading={isLoading}
            disabled={isLoading}
            label="T00044"
            style={gap}
            onPress={handleSubmit(onSubmit)}
            testID="signInButton"
          />
          <PrimaryButton mode="text" label="T00642" onPress={changePassword} />
          <Divider bold />
          <SocialSection
            title={t("T00741")}
            redirectFrom={RedirectScreens.SignIn}
          />
          <TouchableOpacity
            onPress={() => navigate("SignUp")}
            style={switchToSignUp}>
            <MultiColorText leftText={t("T00538")} rightText={t("T00043")} />
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    marginBottom: spacing16,
  },
  gap: {
    marginTop: spacing16,
  },
  safeAreaViewStyle: {
    flex: 1,
  },
  container: { padding: spacing16 },
  switchToSignUp: {
    width: "100%",
    alignItems: "center",
  },
});

export default SignInScreen;
