import { FetchError } from "@components/errors";
import MedicalRecordListItem from "@components/ListItems/MedicalRecordListItem";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { MedicalRecordsSimpleGet } from "@screens/Profiles/MyProfile/MedicalRecords/MedicalRecords.types";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import { NoResultsInfo } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/NoResultsInfo";
import { getMedicalRecords } from "@services/ApiService/medicalRecords";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

export type MedicalRecordsModalProps = {
  searchQuery: string;
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
  selectedItems: number[];
  style?: StyleProp<ViewStyle>;
};

export const MedicalRecordsModal: FC<MedicalRecordsModalProps> = ({
  searchQuery,
  setSelectedItems,
  selectedItems,
  style,
}) => {
  const [medicalRecordsList, setMedicalRecordsList] = useState<
    MedicalRecordsSimpleGet[]
  >([]);
  const { width } = useWindowDimensions();
  const { loading, gapMedium, scrollContainer, flatListSeparator } =
    globalStyles;
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysMedicalRecord.createdByMeList(),
    queryFn: async () => await getMedicalRecords(),
  });

  const filteredMedicalRecordsList = useMemo(
    () => data?.filter(({ id }) => id.toString().includes(searchQuery)),
    [data, searchQuery],
  );

  useEffect(() => {
    if (searchQuery.length > 0) {
      setMedicalRecordsList(filteredMedicalRecordsList);
    } else {
      setMedicalRecordsList(data);
    }
  }, [data, filteredMedicalRecordsList, searchQuery]);

  const onPressItem = useCallback(
    (id: number) =>
      setSelectedItems(prevState =>
        prevState.includes(id)
          ? prevState.filter(e => e !== id)
          : [...prevState, id],
      ),
    [setSelectedItems],
  );

  const renderItem = useCallback(
    ({ item: { id } }: { item: MedicalRecordsSimpleGet }) => (
      <MedicalRecordListItem
        id={id}
        checkboxProps={{
          status: selectedItems.includes(id) ? "checked" : "unchecked",
        }}
        onPress={() => onPressItem(id)}
      />
    ),
    [onPressItem, selectedItems],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <FlatList
      data={medicalRecordsList}
      keyExtractor={({ id }, index) => `record-${id}-${index}`}
      contentContainerStyle={[
        gapMedium,
        scrollContainer,
        { paddingTop: spacing8, flexGrow: 1 },
        style,
      ]}
      renderItem={renderItem}
      ListEmptyComponent={
        searchQuery ? (
          <NoResultsInfo />
        ) : !data.length ? (
          <Text variant="bodyMedium">{t("T00636")}</Text>
        ) : null
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ItemSeparatorComponent={
        <Divider
          bold
          style={[
            flatListSeparator,
            { width, marginTop: spacing8 * 2, marginBottom: spacing8 },
          ]}
        />
      }
    />
  );
};
