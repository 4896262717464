import { CreateCustomAppointment } from "@components/Appointment";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { useAuth } from "@contexts/AuthContext/auth";
import { AccountType, ProfileStatus } from "@contexts/AuthContext/user.types";
import useApiHealthCheck from "@hooks/healthCheck/useApiHealthCheck";
import { useUpdateAppAlert } from "@hooks/healthCheck/useUpdateAppAlert";
import { useAppStateChange } from "@hooks/useAppStateChange";
import { useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  AppointmentsFilters,
  CitySearch,
  FindRehabilitator,
  ServiceAndDate,
} from "@screens/Appointments";
import { CustomAppointmentDetails } from "@screens/Appointments/AppointmentDetails/CustomAppointmentDetails";
import { queryKeysSessions } from "@screens/Appointments/queryKeysAppointments";
import { ForgotPasswordWebView } from "@screens/Common";
import AcceptTerms from "@screens/Common/AcceptTerms";
import EnterInvitationOrPromotionCode from "@screens/Common/EnterInvitationOrPromotionCode";
import LoadingScreen from "@screens/Common/LoadingScreen";
import MaintenanceScreen from "@screens/Common/MaintenanceScreen";
import MapWebView from "@screens/Common/MapWebView";
import { PreviewScreen } from "@screens/Common/PreviewScreen";
import WelcomeToProduct from "@screens/Home/WelcomeToProduct";
import ConversationScreen from "@screens/MessageCenter/ConversationScreen";
import { WhatCanYouExpect } from "@screens/MessageCenter/WhatCanYouExpect";
import EmailConfirmation from "@screens/Profiles/EmailConfirmation";
import { AboutTheApplication } from "@screens/Profiles/MyProfile";
import AreYouPhysiotherapist from "@screens/Profiles/MyProfile/AreYouPhysiotherapist";
import { PatientToAssignMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords";
import MyRatings from "@screens/Profiles/MyProfile/MyRatings";
import { PersonalNotes } from "@screens/Profiles/MyProfile/PersonalNotes";
import CreateAndEditNote from "@screens/Profiles/MyProfile/PersonalNotes/CreateAndEditNote";
import ApplicationSettings from "@screens/Profiles/MyProfile/Settings/ApplicationSettings";
import PatientProfile from "@screens/Profiles/PatientProfile";
import RehabilitationTrainings from "@screens/Profiles/PatientProfileOptions/RehabilitationTrainings";
import PhysiotherapistInformationDetails from "@screens/Profiles/Physiotherapist/PhysiotherapistInformationDetails";
import EquipmentDetails from "@screens/TrainingsAndExercises/EquipmentDetails";
import ExerciseDetails from "@screens/TrainingsAndExercises/ExerciseDetails";
import GeneralWorkoutCategories from "@screens/TrainingsAndExercises/Patient/GeneralWorkoutCategories";
import GeneralWorkoutCategory from "@screens/TrainingsAndExercises/Patient/GeneralWorkoutCategory";
import GeneralWorkoutDetails from "@screens/TrainingsAndExercises/Patient/GeneralWorkoutDetails";
import { GeneralWorkoutSetDetails } from "@screens/TrainingsAndExercises/Patient/GeneralWorkoutSetDetails";
import { GeneralWorkoutSets } from "@screens/TrainingsAndExercises/Patient/GeneralWorkoutSets";
import PatientsList from "@screens/TrainingsAndExercises/Physiotherapist/PatientsList";
import SetAProphylacticSchedule from "@screens/TrainingsAndExercises/SetASchedule/SetAProphylacticSchedule";
import TrainingsAndExercisesModal from "@screens/TrainingsAndExercises/TrainingsAndExercisesModal";
import { useQueryClient } from "@tanstack/react-query";
import { storageManager } from "@utils/storageManager";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import AppointmentDetails from "../screens/Appointments/AppointmentDetails";
import PhysiotherapistsListScreen from "../screens/Appointments/PhysiotherapistsListScreen";
import AchievementsScreen from "../screens/Profiles/MyProfile/Achievements/Achievements";
import CreateAndEditRecord from "../screens/Profiles/MyProfile/MedicalRecords/CreateAndEditRecord";
import MedicalRecords from "../screens/Profiles/MyProfile/MedicalRecords/MedicalRecords";
import PhysioListForRecordSharing from "../screens/Profiles/MyProfile/MedicalRecords/PhysioListForRecordSharing";
import RecordPreview from "../screens/Profiles/MyProfile/MedicalRecords/RecordPreview";
import ShareRecord from "../screens/Profiles/MyProfile/MedicalRecords/ShareRecord";
import PhysiotherapistEvaluation from "../screens/Profiles/Physiotherapist/PhysiotherapistEvaluation";
import PhysiotherapistProfile from "../screens/Profiles/Physiotherapist/PhysiotherapistProfile";
import ProfileUpdateScreen from "../screens/Profiles/ProfileUpdateScreen";
import AssignedTrainingDetails from "../screens/TrainingsAndExercises/AssignedTrainingDetails";
import CreateAndEditExercise from "../screens/TrainingsAndExercises/Physiotherapist/CreateAndEditExercise";
import LibraryTrainingDetails from "../screens/TrainingsAndExercises/Physiotherapist/LibraryTrainingDetails";
import TrainingPlayer from "../screens/TrainingsAndExercises/TrainingPlayer";
import InitialScreen from "../screens/Unregistered/InitialScreen";
import SignInScreen from "../screens/Unregistered/SignInScreen";
import SignUpScreen from "../screens/Unregistered/SignUpScreen";
import { BottomTabNavigator } from "./";
import CreateTrainingStackNavigator from "./CreateTrainingStackNavigator";
import PushNotificationsSettingsStackNavigator from "./PushNotificationsSettingsStackNavigator";
import RehabilitationStatisticsStackNavigator from "./RehabilitationStatisticsStackNavigator";
import ScheduleAppointmentStackNavigator from "./ScheduleAppointmentStack";
import TrainingSelectionAssistantStackNavigator from "./TrainingSelectionAssistantStackNavigator";
import { RootStackParamList } from "./navigation.types";
import { useFlags } from "@hooks/useFlags";
import CustomMessageTemplates from "@screens/MessageCenter/Conversation/CustomMessageTemplates";

const Stack = createNativeStackNavigator<RootStackParamList>();

const MainStackNavigator: FC = () => {
  const { user } = useAuth();
  const { navigate } = useNavigation<{
    navigate: (screen: string, params: unknown) => void;
  }>();
  const { data, updateData } = useContext(AppointmentSessionContext);
  const { t } = useTranslation();
  const isPatient = user?.accountType === AccountType.PATIENT;
  const isPhysiotherapist = user?.accountType === AccountType.THERAPIST;
  const isTermsAccepted = !!user?.termsAccepted;
  const queryClient = useQueryClient();
  const [hasRefreshToken, setHasRefreshToken] = useState(true);
  const {
    flags: {
      FEATURE_MEDICAL_RECORDS,
      FEATURE_PERSONAL_NOTES,
      FEATURE_TRAINING_SELECTION_ASSISTANT,
    },
  } = useFlags();

  useEffect(() => {
    void storageManager
      .getItem("refreshToken")
      .then(token => setHasRefreshToken(!!token));
  }, [user]);

  useEffect(() => {
    data.navigateTo && navigate(data?.navigateTo, data?.navigateData);
    updateData({ navigateTo: null, navigateData: null });
  }, [data.navigateTo, navigate, updateData, data?.navigateData]);

  useUpdateAppAlert();

  const { refetch } = useApiHealthCheck();

  useAppStateChange(refetch);

  return (
    <Stack.Navigator>
      {!user ? (
        hasRefreshToken ? (
          <Stack.Group
            screenOptions={{
              headerShown: false,
            }}>
            <Stack.Screen name="Loading" component={LoadingScreen} />
          </Stack.Group>
        ) : (
          <Stack.Group
            screenOptions={{
              headerShown: false,
            }}>
            <Stack.Screen name="Initial" component={InitialScreen} />
            <Stack.Screen name="SignIn" component={SignInScreen} />
            <Stack.Screen name="SignUp" component={SignUpScreen} />
          </Stack.Group>
        )
      ) : (
        <>
          <Stack.Group screenOptions={{ headerShown: false }}>
            {isTermsAccepted ? (
              user?.profileStatus === ProfileStatus.EmailUnconfirmed ? (
                <Stack.Screen
                  name="EmailConfirmation"
                  component={EmailConfirmation}
                />
              ) : user?.profileStatus === ProfileStatus.New ? (
                <Stack.Screen
                  name="ProfileUpdate"
                  component={ProfileUpdateScreen}
                />
              ) : (
                <Stack.Screen name="Home" component={BottomTabNavigator} />
              )
            ) : (
              <Stack.Screen name="AcceptTerms" component={AcceptTerms} />
            )}
          </Stack.Group>
          <Stack.Group
            screenOptions={{
              presentation: "card",
              animation: "slide_from_bottom",
            }}>
            <Stack.Screen
              name="AppointmentDetails"
              component={AppointmentDetails}
              options={() => ({
                headerTitle: t("T00123"),
                header: ({ navigation: { goBack } }) => {
                  const onClose = async () => {
                    await queryClient.cancelQueries({
                      queryKey: queryKeysSessions.all,
                    });
                    goBack();
                  };
                  return (
                    <AppbarTitleWithBackAction
                      title={t("T00123")}
                      onClose={onClose}
                    />
                  );
                },
              })}
            />
            <Stack.Screen
              name="AssignedTrainingDetails"
              component={AssignedTrainingDetails}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00194")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="ExerciseDetails"
              component={ExerciseDetails}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00077")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            {FEATURE_MEDICAL_RECORDS && (
              <>
                <Stack.Screen
                  name="MedicalRecords"
                  component={MedicalRecords}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00080")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="CreateAndEditRecord"
                  component={CreateAndEditRecord}
                />
                <Stack.Screen
                  name="PatientToAssignMedicalRecord"
                  component={PatientToAssignMedicalRecord}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="RecordPreview"
                  component={RecordPreview}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00084")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="ShareRecord"
                  component={ShareRecord}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00085")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="PhysioListForRecordSharing"
                  component={PhysioListForRecordSharing}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t(isPatient ? "T00331" : "T00332")}
                        onBack={goBack}
                      />
                    ),
                  }}
                />
              </>
            )}
            <Stack.Screen
              name="AchievementsScreen"
              component={AchievementsScreen}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00092")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            {FEATURE_PERSONAL_NOTES && (
              <Stack.Screen
                name="PersonalNotes"
                component={PersonalNotes}
                options={{
                  header: ({ navigation: { goBack } }) => (
                    <AppbarTitleWithBackAction
                      onClose={goBack}
                      title={t("T00091")}
                    />
                  ),
                }}
              />
            )}
            <Stack.Screen
              name="CreateAndEditNote"
              component={CreateAndEditNote}
            />
            <Stack.Screen
              name="AreYouPhysiotherapist"
              component={AreYouPhysiotherapist}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t(isPhysiotherapist ? "T00109" : "T00090")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="AboutTheApplication"
              component={AboutTheApplication}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00109")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="MyRatings"
              component={MyRatings}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00098")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="WhatCanYouExpect"
              component={WhatCanYouExpect}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00099")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="ApplicationSettings"
              component={ApplicationSettings}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    onClose={goBack}
                    title={t("T00100")}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="EquipmentDetails"
              component={EquipmentDetails}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    onClose={goBack}
                    title={t("T00105")}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="ConversationScreen"
              component={ConversationScreen}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00362")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="PhysiotherapistInformationDetails"
              component={PhysiotherapistInformationDetails}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00258")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="ServiceAndDate"
              component={ServiceAndDate}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T01353")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="EnterInvitationOrPromotionCode"
              component={EnterInvitationOrPromotionCode}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t(isPatient ? "T01354" : "T01324")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="WelcomeToProduct"
              component={WelcomeToProduct}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T01355")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="PushNotificationsSettingsStack"
              component={PushNotificationsSettingsStackNavigator}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="RehabilitationStatisticsStack"
              component={RehabilitationStatisticsStackNavigator}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="RehabilitationTrainings"
              component={RehabilitationTrainings}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    title={t("T00189")}
                    onClose={goBack}
                  />
                ),
              }}
            />
            {isPatient && (
              <>
                <Stack.Screen
                  name="Physiotherapists"
                  component={PhysiotherapistsListScreen}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00064")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="FindRehabilitator"
                  component={FindRehabilitator}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="CitySearch"
                  component={CitySearch}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="AppointmentsFilters"
                  component={AppointmentsFilters}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00102")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="PhysiotherapistProfile"
                  component={PhysiotherapistProfile}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00065")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="PhysiotherapistEvaluation"
                  component={PhysiotherapistEvaluation}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T01356")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="ScheduleAppointmentStack"
                  component={ScheduleAppointmentStackNavigator}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="TrainingPlayer"
                  component={TrainingPlayer}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="GeneralWorkoutCategory"
                  component={GeneralWorkoutCategory}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00073")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="GeneralWorkoutSets"
                  component={GeneralWorkoutSets}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T01081")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="GeneralWorkoutSetDetails"
                  component={GeneralWorkoutSetDetails}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T01060")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="GeneralWorkoutCategories"
                  component={GeneralWorkoutCategories}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00087")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="SetAProphylacticSchedule"
                  component={SetAProphylacticSchedule}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00072")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                {FEATURE_TRAINING_SELECTION_ASSISTANT && (
                  <Stack.Screen
                    name="TrainingSelectionAssistantStack"
                    component={TrainingSelectionAssistantStackNavigator}
                    options={{
                      header: () => null,
                    }}
                  />
                )}
                <Stack.Screen
                  name="GeneralWorkoutDetails"
                  component={GeneralWorkoutDetails}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00073")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
              </>
            )}
            {isPhysiotherapist && (
              <>
                <Stack.Screen
                  name="PatientProfile"
                  component={PatientProfile}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00190")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="CreateTrainingStack"
                  component={CreateTrainingStackNavigator}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="TrainingsAndExercisesModal"
                  component={TrainingsAndExercisesModal}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00076")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="PatientsList"
                  component={PatientsList}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="CreateAndEditExercise"
                  component={CreateAndEditExercise}
                />
                <Stack.Screen
                  name="LibraryTrainingDetails"
                  component={LibraryTrainingDetails}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T00073")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="CreateCustomAppointment"
                  component={CreateCustomAppointment}
                  options={{
                    header: ({ navigation: { goBack } }) => (
                      <AppbarTitleWithBackAction
                        title={t("T01357")}
                        onClose={goBack}
                      />
                    ),
                  }}
                />
                <Stack.Screen
                  name="CustomAppointmentDetails"
                  component={CustomAppointmentDetails}
                  options={() => ({
                    header: ({ navigation: { goBack } }) => {
                      return (
                        <AppbarTitleWithBackAction
                          title={t("T00123")}
                          onClose={goBack}
                        />
                      );
                    },
                  })}
                />
                <Stack.Screen
                  name="CustomMessageTemplates"
                  component={CustomMessageTemplates}
                  options={() => ({
                    header: ({ navigation: { goBack } }) => {
                      return (
                        <AppbarTitleWithBackAction
                          title={t("T01600")}
                          onClose={goBack}
                        />
                      );
                    },
                  })}
                />
              </>
            )}
          </Stack.Group>
        </>
      )}
      <Stack.Group
        screenOptions={{
          presentation: "card",
          animation: "slide_from_bottom",
        }}>
        <Stack.Screen
          name="ForgotPasswordWebView"
          component={ForgotPasswordWebView}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction title={t("T00106")} onClose={goBack} />
            ),
          }}
        />
        <Stack.Screen
          name="MapWebView"
          component={MapWebView}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction title={t("T00139")} onClose={goBack} />
            ),
          }}
        />
        <Stack.Screen
          name="PreviewScreen"
          component={PreviewScreen}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction title="" onClose={goBack} />
            ),
          }}
        />
      </Stack.Group>
      <Stack.Screen
        name="Maintenance"
        component={MaintenanceScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default MainStackNavigator;
