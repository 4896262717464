import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { formatDateForApi } from "@utils/date";
import { addDays, addMinutes, isToday } from "date-fns";
import { usePhysiotherapistAvailability } from "./useFutureAvailability";
import { AvailableAppointments } from "@components/PhysioAvailability/PhysioAvailability.types";
import { useEffect } from "react";
import { isDateInRange } from "@components/PhysioAvailability/helpers";
import { useUserDetails } from "../user/useUserDetails";
import { roundUpToNearest15MinutesWith1SecondBackOffset } from "@utils/dateRoundUp";

type Props = {
  serviceId: number;
  physiotherapistId: number;
  activeSectionFirstDay: Date;
  onSuccess?: (index: number, appointments: AvailableAppointments) => void;
  range: number;
  fetchFutureSectionNumber: number;
  currentSectionIndex: number;
};

const getParams = (
  {
    serviceId,
    physiotherapistId,
    activeSectionFirstDay = new Date(),
    fetchFutureSectionNumber,
  }: Partial<Props>,
  isPatient: boolean,
) => {
  const addDaysToDateFrom = fetchFutureSectionNumber * 3;
  let dateFrom: string;
  if (isToday(activeSectionFirstDay) && !fetchFutureSectionNumber) {
    const now = new Date();
    if (isPatient) {
      dateFrom = formatDateForApi(
        addMinutes(addDays(now, addDaysToDateFrom), 30),
        "iso",
      );
      dateFrom = roundUpToNearest15MinutesWith1SecondBackOffset(dateFrom);
    } else dateFrom = formatDateForApi(addDays(now, addDaysToDateFrom), "iso");
  } else {
    const tempDate = addDays(activeSectionFirstDay, addDaysToDateFrom);
    tempDate.setHours(0, 0, 0, 0);
    dateFrom = formatDateForApi(tempDate, "iso");
  }

  const tempDate = addDays(activeSectionFirstDay, addDaysToDateFrom + 3);
  tempDate.setHours(0, 0, 0, 0);
  const dateTo = formatDateForApi(tempDate, "iso");

  return {
    serviceId,
    dateFrom,
    dateTo,
    physiotherapistId,
  };
};

export const useThreeDayCalendarSection = ({
  onSuccess,
  range,
  currentSectionIndex,
  ...rest
}: Props) => {
  const { isPatient } = useUserDetails();
  const sectionParams = getParams(rest, isPatient);
  const isEnabled =
    !!rest.activeSectionFirstDay &&
    isDateInRange(
      addDays(rest.activeSectionFirstDay, rest.fetchFutureSectionNumber * 3),
      range,
    );

  const { data, isError, isLoading, isSuccess, refetch } =
    usePhysiotherapistAvailability(
      queryKeysPhysiotherapist.availabilityPerThreeDays(sectionParams),
      sectionParams,
      isEnabled,
    );

  useEffect(() => {
    if (isSuccess && onSuccess)
      onSuccess(
        currentSectionIndex + rest.fetchFutureSectionNumber,
        data.availableAppointments,
      );
  }, [
    data?.availableAppointments,
    isSuccess,
    onSuccess,
    currentSectionIndex,
    rest.fetchFutureSectionNumber,
  ]);

  return {
    isError,
    isLoading,
    refetch,
    isSuccess,
  };
};
