import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { useAppointmentData } from "@hooks/index";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import InfoSection from "@screens/Appointments/AppointmentSession/SessionInformation/InfoSection";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { AppointmentDetailsTabsParamList } from ".";

export const Survey: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<AppointmentDetailsTabsParamList, "Survey">
  >
> = ({ route }) => {
  const { appointmentId } = route.params;

  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const { loading, scrollContainer, gapLarge } = globalStyles;

  const { data, isLoading, isError, refetch } =
    useAppointmentData(appointmentId);

  const renderSurveyInfo = useCallback(() => {
    if (isPhysiotherapist) {
      return (
        <InfoTile
          status="info"
          content={
            <View>
              <Text>{t("T01240")}</Text>
              {new Date(data.dateTo) > new Date() && <Text>{t("T01241")}</Text>}
            </View>
          }
        />
      );
    } else {
      const appointmentFinished = new Date(data.clientDateTo) < new Date();

      return (
        <View style={gapLarge}>
          <InfoTile
            status="info"
            content={
              <Text variant="bodyMedium">
                {t(data.cancelled ? "T01288" : "T01260")}
              </Text>
            }
          />
          {!appointmentFinished && !data.cancelled && (
            <PrimaryButton
              label="T01261"
              onPress={() =>
                navigate("ScheduleAppointmentStack", {
                  screen: "PlaceOfInjury",
                  params: {
                    fillInSurveyProcess: true,
                    appointmentId,
                    physiotherapistId: data.physiotherapist,
                    dateFrom: data.dateFrom,
                  },
                })
              }
            />
          )}
        </View>
      );
    }
  }, [data, isPhysiotherapist, t, gapLarge, navigate, appointmentId]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <ScrollView
      contentContainerStyle={[scrollContainer, gapLarge]}
      refreshControl={
        <RefreshControl onRefresh={refetch} refreshing={isLoading} />
      }>
      <Text variant="titleLarge">{t("T00318")}</Text>
      <Divider />
      {data.surveyOmitted ? renderSurveyInfo() : <InfoSection {...data} />}
    </ScrollView>
  );
};
