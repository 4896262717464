import { useState } from "react";
import { View, ViewStyle } from "react-native";

import { CalendarEdit } from "./CalendarEdit";
import { CalendarPicker } from "./CalendarPicker";

import { spacing16, spacing8 } from "@styles/spacing";
import { YearsOption } from "@components/Calendar/helpers";
import { isWeb, webScreenWidth } from "@utils/constants";
import { calendarBackgroundColor } from "@styles/colors";

export type DateArrayType = {
  date: Date;
  eventType?: string;
};

export type CalendarProps = {
  initialDates?: DateArrayType[];
  onSelectDate?: (data: DateArrayType, dontClose?: boolean) => void;
  multiselect?: boolean;
  onDismiss?: () => void;
  onSubmit?: (dates: DateArrayType[]) => void;
  datesWithEvents?: DateArrayType[];
  type?: "week" | "month";
  limitRange?: boolean;
  blockPast?: boolean;
  yearsOption?: YearsOption;
  onChangeMonth?: (date: Date) => void;
  onWeekChange?: (week: Date) => void;
  disableOnPress?: boolean;
  containerStyle?: ViewStyle;
  disableModeChange?: boolean;
  editMode?: boolean;
  enableOnPressOnlyWithEvents?: boolean;
};

const today = new Date();
today.setHours(0, 0, 0, 0);

export const Calendar = ({
  initialDates,
  onSelectDate,
  yearsOption = "both",
  onChangeMonth,
  containerStyle,
  editMode,
  onWeekChange,
  ...props
}: CalendarProps) => {
  const [currentDate, setCurrentDate] = useState<DateArrayType>({
    date: initialDates?.length ? initialDates[0].date : today,
  });
  const [editCalendarMode, setEditCalendarMode] = useState<boolean>(
    editMode || false,
  );
  const [selectedDays, setSelectedDays] = useState<DateArrayType[]>(
    initialDates?.length
      ? initialDates.map(e => {
          const tempDate = new Date(e.date);
          tempDate.setHours(0, 0, 0, 0);
          return { ...e, date: tempDate };
        })
      : [],
  );
  const changeCalendarMode = () => setEditCalendarMode(!editCalendarMode);

  return (
    <View style={[isWeb && { alignItems: "center" }]}>
      <View
        style={[
          {
            paddingHorizontal: spacing8,
            backgroundColor: calendarBackgroundColor,
            borderRadius: spacing16,
          },
          containerStyle,
          isWeb && { width: webScreenWidth / 2 },
        ]}>
        {!editCalendarMode ? (
          <CalendarPicker
            changeCalendarMode={changeCalendarMode}
            currentDate={currentDate}
            initialDates={initialDates}
            setCurrentDate={setCurrentDate}
            onSelectDate={onSelectDate}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            onChangeMonth={onChangeMonth}
            onWeekChange={onWeekChange}
            {...props}
          />
        ) : (
          <CalendarEdit
            changeCalendarMode={changeCalendarMode}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            yearsOption={yearsOption}
            onChangeMonth={onChangeMonth}
            onSelectDate={onSelectDate}
          />
        )}
      </View>
    </View>
  );
};
