import { CardProps } from "react-native-paper";
import { CardElevationType } from "../../cards/Card.types";
import { ConversationMessageAttachment } from "@screens/MessageCenter/Conversation/conversation.types";

export enum MessageType {
  REPORTER = "reporter",
  INTERLOCUTOR = "interlocutor",
}

export type MessageTileType = {
  content: string;
  attachments?: ConversationMessageAttachment[];
  type: MessageType;
  date: string;
  elevation?: CardElevationType;
  index: number;
  readAt: string | null;
  disableExtraText?: boolean;
  sender: number;
} & Omit<CardProps, "children">;

export type MessageTileWithReportType = MessageTileType & {
  withReport: boolean;
  messageId: number;
};
