import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, ScrollView } from "react-native";
import { Text } from "react-native-paper";

import ProfileSection, {
  ProfileSectionProps,
} from "./CommonProfile/ProfileSection";

import WebViewModal from "@components/Modals/WebViewModal";
import VerificationTileWithProgress from "@components/Tile/VerificationTileWithProgress";
import { UserCard } from "@components/cards";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { useAuth } from "@contexts/AuthContext/auth";
import { TransKey } from "@globalTypes/i18next";
import { useAppVersion } from "@hooks/useAppVersion";
import { useErrors } from "@hooks/useErrors";
import {
  ProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { rateApp } from "@services/ApiService/common";
import { globalStyles } from "@styles/global";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isWeb } from "@utils/constants";
import { getWebViewData, ModalType } from "@utils/getWebViewData";
import { handleReviewApp } from "@utils/handleReviewApp";
import { myProfileDevSections } from "@utils/index";
import { AxiosError } from "axios";
import {
  queryKeysAchievements,
  queryKeysUserAchievements,
} from "./Achievements/queryKeysAchievements";
import { HelpCenterIssue } from "./CommonProfile/HelpCenter/helpCenter.types";
import { useFlags } from "@hooks/useFlags";

const MyProfilePatient: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ProfileStackParamsList & RootStackParamList,
      "Profile"
    >
  >
> = ({ navigation: { navigate } }) => {
  const { t } = useTranslation();
  const {
    signOut,
    user: { id },
  } = useAuth();
  const {
    data: { sessionEnabled },
  } = useContext(AppointmentSessionContext);
  const queryClient = useQueryClient();
  const { setErrorsFromResponse } = useErrors();

  const appVersion = useAppVersion();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState({ uri: "", title: "" });
  const {
    flags: {
      FEATURE_MEDICAL_RECORDS,
      FEATURE_PERSONAL_NOTES,
      FEATURE_ABOUT_APPLICATION,
    },
  } = useFlags();

  const openModal = async (type: ModalType) => {
    const { uri, title } = getWebViewData(type);

    setModalProps(() => {
      return {
        uri,
        title,
      };
    });

    if (isWeb) {
      await Linking.openURL(uri);
    } else {
      setIsModalVisible(true);
    }
  };

  const { mutate } = useMutation({
    mutationFn: rateApp,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysAchievements.list(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysUserAchievements.list(),
        }),
      ]);
    },
    onError: ({ response }: AxiosError) => {
      setErrorsFromResponse(response);
    },
  });

  const onPressRateApp = async () => {
    await handleReviewApp();
    mutate();
  };

  const onPressSignout = () => {
    return sessionEnabled ? alert(t("T01313")) : signOut();
  };

  const profileSections: ProfileSectionProps["data"][] = [
    {
      title: "T00388",
      items: [
        {
          name: "T00080",
          onPress: () => navigate("MedicalRecords"),
          icon: "folder-outline",
          hidden: !FEATURE_MEDICAL_RECORDS,
        },
        {
          name: "T00504",
          onPress: () =>
            navigate("RehabilitationStatisticsStack", {
              screen: "RehabilitationStatistics",
            }),
          icon: "equalizer",
        },
        {
          name: "T00092",
          onPress: () => navigate("AchievementsScreen"),
          icon: "star-outline",
        },
        {
          name: "T00091",
          onPress: () => navigate("PersonalNotes"),
          icon: "clipboard-edit-outline",
          hidden: !FEATURE_PERSONAL_NOTES,
        },
      ],
    },
    {
      title: "T00710",
      items: [
        {
          name: "T00789",
          onPress: () => navigate("TransactionHistoryStack"),
          icon: "cash-multiple",
        },
      ],
    },
    {
      title: "T00389",
      items: [
        {
          name: "T00088",
          onPress: () => navigate("HelpCenterStack"),
          icon: "lifebuoy",
        },
        {
          name: "T00094",
          onPress: () => openModal(ModalType.privacy),
          icon: "text-box-outline",
        },
        {
          name: "T00093",
          onPress: () => openModal(ModalType.terms),
          icon: "text-box-outline",
        },
        {
          name: "T00090",
          onPress: () => navigate("AreYouPhysiotherapist"),
          icon: "hail",
        },
        {
          name: "T00109",
          onPress: () => navigate("AboutTheApplication"),
          icon: "cellphone-information",
        },
        {
          name: "T00110",
          onPress: () => openModal(ModalType.about_the_application_project),
          icon: "information-outline",
          hidden: !FEATURE_ABOUT_APPLICATION,
        },
      ],
    },
    {
      title: "T00002",
      items: [
        {
          name: "T01454",
          onPress: () => navigate("PushNotificationsSettingsStack"),
          icon: "bell-outline",
        },
        {
          name: "T00100",
          onPress: () => navigate("ApplicationSettings"),
          icon: "cog-outline",
        },
        {
          name: "T00905",
          onPress: onPressRateApp,
          icon: "thumb-up-outline",
        },
        {
          name: "T01468",
          onPress: () =>
            navigate("HelpCenterStack", {
              screen: "ContactForm",
              params: { defaultIssue: HelpCenterIssue.AccountRemoval },
            }),
          icon: "delete-outline",
        },
        { name: "T00045", onPress: onPressSignout, icon: "logout" },
      ],
    },
  ];

  if (__DEV__)
    profileSections.push({
      title: "DEV Section" as TransKey,
      items: myProfileDevSections(navigate),
    });

  return (
    <ScrollView
      contentContainerStyle={[
        globalStyles.gapLarge,
        globalStyles.scrollContainer,
      ]}>
      <UserCard initialData={{ id }} mode="outlined" />
      <VerificationTileWithProgress
        onPress={() => navigate("ProfileCompletionStack")}
      />
      {profileSections.map((e, i) => (
        <ProfileSection
          key={`patientProfileSection-${e.title}-${i}`}
          data={e}
        />
      ))}
      <Text>{`${t("T00121")}: ${appVersion}`}</Text>
      <WebViewModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={modalProps.title}
        uri={modalProps.uri}
        type="web"
        modalAnimation="slide"
      />
    </ScrollView>
  );
};

export default MyProfilePatient;
