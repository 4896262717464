import { AbsoluteBlurredFooter } from "@components/Footers";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";

const RewardsTutorialComponent = ({ onPress }: { onPress?: () => void }) => {
  const { t } = useTranslation();
  const { gapLarge } = globalStyles;
  const { height } = useWindowDimensions();
  const [footerHeight, setFooterHeight] = useState<number>(0);

  return (
    <>
      <ScrollView
        style={{ maxHeight: height * 0.55 }}
        contentContainerStyle={[
          gapLarge,
          {
            paddingBottom: footerHeight,
            paddingHorizontal: spacing16,
          },
        ]}>
        <Text variant="labelLarge">{t("T01576")}:</Text>
        <Text variant="bodySmall">{t("T01577")}</Text>
      </ScrollView>
      <AbsoluteBlurredFooter
        onPress={onPress}
        title="T00394"
        onLayout={height => setFooterHeight(height)}
      />
    </>
  );
};

export default RewardsTutorialComponent;
