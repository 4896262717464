import {
  DETAILS,
  ENDED_APPOINTMENT,
  LIST,
  MY,
  RECENT_LIST,
  SELECTED_DAY,
} from "@utils/constants";
import {
  GetFutureAvailabilityParams,
  PhysiotherapistAvailabilityParams,
} from "@components/PhysioAvailability/PhysioAvailability.types";
import { GetPhysiotherapistParams } from "./filters.types";

const keys = {
  physiotherapists: "physiotherapists",
  availability: "availability",
  appointments: "appointments",
  session: "session",
  muscles: "muscles",
  statistics: "statistics",
  paymentIntent: "paymentIntent",
  today: "today",
  future: "future",
  threeDays: "threeDays",
  week: "week",
  month: "month",
  futureSlots: "futureSlots",
  nextTwoMonthsAhead: "nextTwoMonthsAhead",
  promoCodeUsed: "promoCodeUsed",
};

export const queryKeysAppointments = {
  all: [keys.appointments] as const,
  list: () => [...queryKeysAppointments.all, LIST] as const,
  listWithDateFilter: (dateFrom: string) => [
    ...queryKeysAppointments.list(),
    dateFrom,
  ],
  listTodayAppointments: () =>
    [...queryKeysAppointments.list(), keys.today] as const,
  listAppointmentsWithInterlocutor: (
    interlocutorId: number,
    nearestCount: 1 | 2,
  ) => [...queryKeysAppointments.list(), interlocutorId, nearestCount] as const,
  detail: (appointmentId: number) =>
    [...queryKeysAppointments.all, DETAILS, appointmentId] as const,
  payment: (appointmentId: number) => [
    queryKeysAppointments.detail(appointmentId),
    keys.paymentIntent,
  ],
  promoCodeUsed: (physioId: number) => [
    ...queryKeysAppointments.all,
    keys.promoCodeUsed,
    physioId,
  ],
};

export const queryKeysPhysiotherapist = {
  all: [keys.physiotherapists] as const,
  list: (filters?: GetPhysiotherapistParams, page?: number) =>
    [...queryKeysPhysiotherapist.all, LIST, page, filters] as const,
  search: ({ search }: Pick<GetPhysiotherapistParams, "search">) =>
    [...queryKeysPhysiotherapist.list(), search] as const,
  myList: () => [...queryKeysPhysiotherapist.list(), MY] as const,
  myListEndedAppointment: () =>
    [...queryKeysPhysiotherapist.list(), MY, ENDED_APPOINTMENT] as const,
  availability: (physioId: number) =>
    [...queryKeysPhysiotherapist.all, keys.availability, physioId] as const,
  availabilityAnyFutureSlots: ({
    physioId,
    dateFrom,
  }: {
    physioId: number;
    dateFrom: string;
  }) =>
    [
      ...queryKeysPhysiotherapist.availability(physioId),
      keys.futureSlots,
      dateFrom,
    ] as const,
  availabilityPerThreeDays: ({
    physiotherapistId,
    ...rest
  }: PhysiotherapistAvailabilityParams) =>
    [
      ...queryKeysPhysiotherapist.availability(physiotherapistId),
      rest,
      keys.threeDays,
    ] as const,
  availabilityPerWeek: ({
    physiotherapistId,
    ...rest
  }: PhysiotherapistAvailabilityParams) =>
    [
      ...queryKeysPhysiotherapist.availability(physiotherapistId),
      rest,
      keys.week,
    ] as const,
  availabilityPerMonth: ({
    physiotherapistId,
    ...rest
  }: PhysiotherapistAvailabilityParams) =>
    [
      ...queryKeysPhysiotherapist.availability(physiotherapistId),
      rest,
      keys.month,
    ] as const,
  availabilityGroups: () =>
    [...queryKeysPhysiotherapist.all, keys.availability, LIST] as const,
  availabilityGroupsForSelectedDay: ({
    physioId,
    dateFrom,
    dateTo,
  }: {
    physioId: number;
    dateFrom: string;
    dateTo: string;
  }) =>
    [
      ...queryKeysPhysiotherapist.availability(physioId),
      SELECTED_DAY,
      dateFrom,
      dateTo,
    ] as const,
  availabilityForService: (physioId: number, serviceId: number) =>
    [...queryKeysPhysiotherapist.availability(physioId), serviceId] as const,
  myRehabilitants: () =>
    [...queryKeysPhysiotherapist.all, RECENT_LIST] as const,
  futureAvailability: ({
    physiotherapistId,
    ...rest
  }: GetFutureAvailabilityParams) =>
    [
      ...queryKeysPhysiotherapist.availability(physiotherapistId),
      rest,
      keys.future,
    ] as const,
  futureAvailabilityForNextThreeDays: ({
    physiotherapistId,
    ...rest
  }: GetFutureAvailabilityParams) =>
    [
      ...queryKeysPhysiotherapist.availability(physiotherapistId),
      rest,
      keys.future,
      keys.threeDays,
    ] as const,
  availabilityForNextTwoMonthsAhead: ({
    physiotherapistId,
    ...rest
  }: GetFutureAvailabilityParams) =>
    [
      ...queryKeysPhysiotherapist.availability(physiotherapistId),
      rest,
      keys.future,
      keys.nextTwoMonthsAhead,
    ] as const,
};

export const queryKeysSessions = {
  all: [keys.session] as const,
  sessionDetails: (id: number) =>
    [...queryKeysAppointments.all, DETAILS, keys.session, id] as const,
};

export const queryKeysAppointmentsStatistics = {
  all: [keys.appointments, keys.statistics] as const,
};
