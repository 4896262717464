import DropdownPicker, {
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { TransKey } from "@globalTypes/i18next";
import { Dispatch, SetStateAction } from "react";
import { isANDROID } from "@utils/constants";

const SlotsDropdownPicker = ({
  slots,
  defaultValue,
  setSelectedSlots,
  dropdownLabel,
  onOpen,
  onClose,
  isDropdownOpen,
}: {
  slots: PickerItem<number>[];
  defaultValue: number;
  setSelectedSlots: Dispatch<SetStateAction<number>>;
  dropdownLabel: TransKey;
  onOpen: () => void;
  onClose: () => void;
  isDropdownOpen: boolean;
}) => {
  return (
    <DropdownPicker<number>
      items={slots}
      defaultValue={defaultValue}
      onSelectItem={({ value }) => setSelectedSlots(value)}
      dropdownLabelText={dropdownLabel}
      onOpen={onOpen}
      onClose={onClose}
      dropDownDirection={isANDROID ? "DEFAULT" : "TOP"}
      isDropdownOpen={isDropdownOpen}
      multiDropdownContent
    />
  );
};

export default SlotsDropdownPicker;
