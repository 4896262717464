import {
  ConversationsListResponse,
  ConversationsMessagesResponse,
  CreateCustomMessageTemplateType,
  CustomMessageTemplateType,
} from "@screens/MessageCenter/Conversation/conversation.types";
import { api } from "./api";

import { endpoints } from "./endpoints";

export const getAllUnreadMessages = async (params?: { user?: number }) => {
  const { data } = await api.get<{ unreadMessageCount: number }>(
    endpoints.ALL_UNREAD_MESSAGES,
    {
      params,
    },
  );
  return data;
};

export const getConversationMessages = async (id: number) => {
  const { data } = await api.get<ConversationsMessagesResponse>(
    endpoints.CONVERSATION(id),
  );
  return data;
};

export const markMessagesAsRead = async (id: number) =>
  await api.patch(endpoints.CONVERSATION_MARK_AS_READ(id));

export const sendConversationMessage = (id: number, message: string) =>
  api.post<ConversationsMessagesResponse>(endpoints.CONVERSATION(id), {
    message,
  });

export const getConversations = async () => {
  const { data } = await api.get<ConversationsListResponse[]>(
    endpoints.CONVERSATIONS,
  );
  return data;
};

export const getCustomMessageTemplates = async () => {
  const { data } = await api.get<CustomMessageTemplateType[]>(
    endpoints.CUSTOM_MESSAGE_TEMPLATES,
  );
  return data;
};

export const createCustomMessageTemplate = async (
  data: CreateCustomMessageTemplateType,
) =>
  api.post<CreateCustomMessageTemplateType>(
    endpoints.CUSTOM_MESSAGE_TEMPLATES,
    data,
  );

export const editCustomMessageTemplate = async (
  data: CustomMessageTemplateType,
) =>
  api.patch<CustomMessageTemplateType>(
    endpoints.CUSTOM_MESSAGE_TEMPLATES_DETAILS(data.id),
    data,
  );

export const deleteCustomMessageTemplate = (id: number) =>
  api.delete(endpoints.CUSTOM_MESSAGE_TEMPLATES_DETAILS(id));
