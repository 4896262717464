import { FetchError } from "@components/errors";
import { getCustomMessageTemplates } from "@services/ApiService/conversations";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, IconButton } from "react-native-paper";
import { queryKeysConversations } from "../queryKeysMessageCenter";
import TemplateChipList from "./TemplateChipList";
import { spacing16, spacing8 } from "@styles/spacing";
import { RootStackParamList } from "@navigators/navigation.types";
import { useNavigation, NavigationProp } from "@react-navigation/native";

type InputTopBarProps = {
  onCustomMessageTemplateSelect?: (value: string) => void;
};

const InputTopBar: FC<InputTopBarProps> = ({
  onCustomMessageTemplateSelect,
}) => {
  const { isLoading, data, isError, refetch } = useQuery({
    queryFn: getCustomMessageTemplates,
    queryKey: queryKeysConversations.templatesList(),
  });

  const { navigate } =
    useNavigation<
      NavigationProp<RootStackParamList, "CustomMessageTemplates">
    >();

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={styles.container}>
      <IconButton
        icon="dots-vertical"
        onPress={() => navigate("CustomMessageTemplates")}
      />
      <TemplateChipList
        customMessageTemplateData={data}
        onCustomMessageTemplateSelect={onCustomMessageTemplateSelect}
      />
    </View>
  );
};

export default InputTopBar;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: spacing8,
    paddingRight: spacing16,
  },
});
