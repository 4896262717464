import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Divider } from "react-native-paper";

import { UpcomingAppointmentCard } from "@components/cards/UpcomingAppointmentCard";
import { FetchError } from "@components/errors";

import MultiColorText from "@components/Text/MultiColorText";
import { useUpcomingAppointment } from "@hooks/queryHooks/useUpcomingAppointment";
import { globalStyles } from "@styles/global";
import { getDate } from "@utils/date";
import { useFocusEffect } from "@react-navigation/native";

type Props = {
  interlocutorId: number;
  nav: (id: number) => void;
};

const UpcomingConsultations: FC<PropsWithChildren<Props>> = ({
  interlocutorId,
  nav,
}) => {
  const { t } = useTranslation();
  const { gapMedium } = globalStyles;
  const { data, isLoading, isError, refetch } = useUpcomingAppointment({
    interlocutorId,
    nearestCount: 1,
  });

  useFocusEffect(() => void refetch());

  if (isLoading) return <ActivityIndicator />;

  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View>
      {data?.length > 0 ? (
        <View style={gapMedium}>
          <Divider bold horizontalInset />
          <MultiColorText
            leftText={`${t("T00047")}:`}
            rightText={getDate(data?.[0].dateFrom)}
            variant="titleMedium"
            rightTextProps={{ variant: "titleMedium" }}
          />
          <UpcomingAppointmentCard
            upcomingAppointmentData={data[0]}
            onPress={() => nav(data[0].id)}
          />
        </View>
      ) : null}
    </View>
  );
};

export default UpcomingConsultations;
