import { useAuth } from "@contexts/AuthContext/auth";
import { useFlags } from "@hooks/useFlags";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { VerificationStatus } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { getPhone } from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";

const useGetPhone = () => {
  const { updateUser } = useAuth();
  const {
    flags: { FEATURE_SMS_VERIFICATION_ENABLED },
  } = useFlags();

  const { data, ...rest } = useQuery({
    queryKey: queryKeysVerficiations.phone(),
    queryFn: getPhone,
    onSuccess: updateUser,
    enabled: FEATURE_SMS_VERIFICATION_ENABLED,
    select: data => data?.[0],
  });

  const isVerified = data?.verificationStatus === VerificationStatus.VERIFIED;
  const previousPhoneNumberWithoutCountryCode = data?.phoneNumber.slice(3);

  return {
    data,
    previousPhoneNumberWithoutCountryCode,
    isVerified,
    ...rest,
  };
};

export default useGetPhone;
