import { ScheduleAppointmentStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { PhysiotherapistAppointmentCalendar } from "./PhysiotherapistAppointmentCalendar";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { OnHourButtonPress } from "./appointment.types";

const PhysiotherapistCalendarForPatient: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ScheduleAppointmentStackParamsList,
      "PhysiotherapistCalendarForPatient"
    >
  >
> = ({ route, navigation: { navigate } }) => {
  const {
    physiotherapistId,
    serviceType: initialServiceType,
    serviceId,
  } = route.params;

  const onHourPress = ({
    dateFrom,
    serviceType,
    serviceId,
    selectedItem,
  }: OnHourButtonPress) => {
    const resultServiceType =
      serviceType === AppointmentSlots.ONLINE ? "Online" : "Stationary";
    navigate("ScheduleAppointment", {
      date: dateFrom,
      physiotherapistId,
      serviceType: resultServiceType,
      serviceId,
      appointmentLanguage: selectedItem?.value,
      languageLabel: selectedItem?.label,
    });
  };

  return (
    <PhysiotherapistAppointmentCalendar
      physiotherapistId={physiotherapistId}
      serviceType={initialServiceType}
      initialServiceId={serviceId}
      onHourPress={onHourPress}
    />
  );
};

export default PhysiotherapistCalendarForPatient;
