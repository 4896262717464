import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { View } from "react-native";
import CounterItem from "./CounterItem";
import { FrameContentParams } from "./inviteToTheApp.types";

const SmallFrameContent = ({
  allTherapists,
  verifiedTherapists,
  onPress,
}: FrameContentParams) => {
  const { gapMedium } = globalStyles;

  return (
    <>
      <View style={gapMedium}>
        <CounterItem countValue={allTherapists} counterType="registered" />
        <CounterItem
          countValue={verifiedTherapists}
          counterType="verified"
          highlighted
        />
      </View>
      <PrimaryButton label="T01551" onPress={onPress} />
    </>
  );
};

export default SmallFrameContent;
