import { currency } from "@utils/constants";
import { GenderType } from "@contexts/AuthContext/user.types";

export type CurrencyType = keyof typeof currency;

export type HumanModelType = {
  humanBones?: string[] | null;
  humanMuscles?: string[] | null;
  humanGeneral: string[] | null;
  gender: GenderType;
};

export type FileAttachment = {
  id: number;
  file: string;
  name: string;
  thumbnail?: string | null;
};

export type PaginationResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type ModalAnimationType = "none" | "slide" | "fade" | undefined;

export type InvitationCodeType = {
  invitationCode: string;
  accept?: boolean;
};

export type InvitationCodeResponse = Partial<InvitationCodeType> & {
  id?: number;
};

export enum PreviewType {
  IMG = "image",
  PDF = "pdf",
}

export enum PermissionType {
  calendar = "calendar",
  cameraAndMicrophone = "cameraAndMicrophone",
  location = "location",
  notifications = "notifications",
  galleryAndFiles = "galleryAndFiles",
}

export type PaginationParams = {
  page?: number;
  pageSize?: number;
};

export enum ProductName {
  FIXME = "Fixme",
  GYMSY = "Gymsy",
}

export type HealthCheckResponse = {
  newest_version: string;
  min_version: string;
  maintenance_mode: string;
  maintenance_reason: string;
};
