import { PermissionType } from "@globalTypes/common.types";
import { globalStyles } from "@styles/global";
import { spacing16, spacing32 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { getContentForPermissionModal } from "@utils/getContentForPermissionModal";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import PrimaryButton from "../buttons/PrimaryButton";
import { FullScreenModal } from "./FullScreenModal";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useUserDetails } from "@hooks/index";
import { isWeb } from "@utils/constants";

type PermissionModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  onPressConfirm: () => void;
  type: PermissionType;
  onPressNotNowCallback?: () => void;
  testID?: string;
};

const PermissionModal: FC<PermissionModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  onPressConfirm,
  type,
  onPressNotNowCallback,
  testID,
}) => {
  const { gapLarge, container: globalContainer } = globalStyles;
  const { container, topSection } = styles;
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { isPatient } = useUserDetails();

  const { image, title, subtitle, confirmButtonLabel } =
    getContentForPermissionModal(type, isPatient);

  const renderContent = () => (
    <View
      style={[
        container,
        globalContainer,
        {
          width,
          height,
          marginBottom: -spacing32,
        },
      ]}
      testID={testID}>
      <View style={topSection}>
        {image}
        <View style={[gapLarge, { alignItems: "center" }]}>
          <Text variant="titleMedium">{t(title)}</Text>
          <Text variant="bodyMedium" style={{ textAlign: "center" }}>
            {t(subtitle)}
          </Text>
        </View>
      </View>
      <View style={[gapLarge, { width: "100%" }]}>
        <PrimaryButton
          mode="outlined"
          label="T01487"
          onPress={() => {
            setIsModalVisible(false);
            onPressNotNowCallback && onPressNotNowCallback();
          }}
          testID="rejectNotificationPermissions"
        />
        <PrimaryButton
          mode="contained"
          label={confirmButtonLabel}
          onPress={onPressConfirm}
          style={{ marginBottom: spacing16 }}
        />
      </View>
    </View>
  );

  if (type === PermissionType.notifications && isWeb) return null;

  return (
    <FullScreenModal
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      renderContent={renderContent}
      withoutBackDrop
    />
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background,
  },
  topSection: {
    justifyContent: "center",
    alignItems: "center",
    gap: spacing32,
    flex: 1,
  },
});

export default PermissionModal;
