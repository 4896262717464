import {
  CreateTrainingStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ScrollView } from "react-native";

import { BottomModalWithButton } from "@components/BottomSheet";
import ExerciseTile from "@components/Tile/exercise/ExerciseTile";

import { getExercise } from "@api/exercises";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import AddSeriesForm, {
  AddSeriesFormType,
} from "@components/Forms/AddSeriesForm";
import SeriesTile from "@components/Tile/SeriesTile";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { CompositeScreenProps } from "@react-navigation/native";
import { CreateTrainingContext } from "@screens/TrainingsAndExercises/Physiotherapist/createTraining.context";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, SegmentedButtons } from "react-native-paper";
import {
  BaseSeries,
  CtxExerciseType,
  Series,
  SeriesType,
} from "../exercise.types";
import { queryKeysExercises } from "../queryKeysTrainingsAndExercises";
import SuggestedParametersTile from "@components/Tile/SuggestedParametersTile";
import SuggestedParametersSheetContent from "@components/BottomSheetContents/SuggestedParametersSheetContent";
import { getPredictedParameters } from "@services/ApiService/trainings";
import { parametersQueryKeys } from "./parametersQueryKeys";
import { customAlert } from "@utils/alert";
import { BottomModalWithButtonProps } from "@components/BottomSheet/BottomModalWithButton";
import { useFlags } from "@hooks/useFlags";

type EditParametersScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      CreateTrainingStackParamsList,
      "EditParameters",
      "SearchExercises"
    >,
    NativeStackScreenProps<
      Pick<RootStackParamList, "CreateTrainingStack" | "ExerciseDetails">
    >
  >
>;

const EditParameters: FC<EditParametersScreen> = ({
  route: {
    params: { exerciseIndex, exerciseId },
  },
  navigation: { navigate, setOptions, pop, goBack },
}) => {
  const { exercises, addExercise, editExerciseSeries, patientId, popCount } =
    useContext(CreateTrainingContext);
  const seriesData = exercises[exerciseIndex]?.series;

  const { setErrorsFromResponse } = useErrors();
  const [series, setSeries] = useState<BaseSeries[]>(seriesData || []);
  const [exercise, setExercise] = useState<CtxExerciseType>();
  const {
    flags: { FEATURE_PREDICTED_PARAMETERS },
  } = useFlags();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysExercises.detail(exerciseId),
    queryFn: async () => await getExercise(exerciseId),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });
  const exerciseIdForPredictedParams = data?.originalExerciseId || data?.id;
  const {
    data: predictedParameters,
    isLoading: predictedParametersLoading,
    isError: predictedParametersError,
    refetch: refetchPredictedParameters,
  } = useQuery({
    queryFn: async () =>
      await getPredictedParameters({
        patientId,
        exerciseId: exerciseIdForPredictedParams,
      }),
    queryKey: parametersQueryKeys.detail(
      exerciseIdForPredictedParams,
      patientId,
    ),
    enabled: !!patientId && !!exerciseIdForPredictedParams,
    retry: false,
  });

  const [seriesType, setSeriesType] = useState<SeriesType>(
    seriesData?.[0].seriesType || SeriesType.REPETITIONS,
  );
  const { t } = useTranslation();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [addSeriesModalVisible, setAddSeriesModalVisible] = useState(false);
  const [backModalVisible, setBackModalVisible] = useState(false);
  const [suggestedParamsModalVisible, setSuggestedParamsModalVisible] =
    useState(false);
  const [moreInfoModalVisible, setMoreInfoModalVisible] = useState(false);
  const hasSeries = series.length > 0;
  const onSeriesTypeChange = () =>
    hasSeries ? setConfirmModalVisible(true) : changeSeriesType();

  useEffect(() => {
    if (data) {
      const { exerciseVideo, instructionExerciseVideo, exerciseImage } = data;

      const resultData = {
        ...data,
        exerciseVideoId: exerciseVideo,
        id: exerciseId,
        series: [],
      };

      if (instructionExerciseVideo !== null) {
        resultData.instructionExerciseVideoId = instructionExerciseVideo;
      }

      if (exerciseImage !== null) {
        resultData.exerciseImageId = exerciseImage;
      }

      setExercise(resultData);
    }
  }, [data, exerciseId]);

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack, popToTop, pop } }) => (
        <AppbarTitleWithBackAction
          title={t("T00097")}
          onBack={() => (hasSeries ? setBackModalVisible(true) : goBack())}
          onClose={() => {
            popToTop();
            pop(popCount);
          }}
        />
      ),
    });
  }, [setOptions, t, hasSeries, popCount]);

  const changeSeriesType = useCallback(
    () =>
      setSeriesType(
        seriesType === SeriesType.REPETITIONS
          ? SeriesType.TIME
          : SeriesType.REPETITIONS,
      ),
    [seriesType],
  );

  const segmentedButtons = useMemo(
    () => [
      {
        value: SeriesType.REPETITIONS,
        label: t("T00414"),
        showSelectedCheck: true,
      },
      {
        value: SeriesType.TIME,
        label: t("T00415"),
        showSelectedCheck: true,
      },
    ],
    [t],
  );

  const onContinuePress = useCallback(() => {
    setSeries([]);
    changeSeriesType();
    setConfirmModalVisible(false);
  }, [changeSeriesType]);

  const confirmModalButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00472",
        onPress: onContinuePress,
        loading: false,
      },
    ],
    [onContinuePress],
  );

  const handleBackWithoutSave = useCallback(() => {
    setBackModalVisible(false);
    if (seriesData) {
      goBack();
    } else navigate("SearchExercises");
  }, [seriesData, goBack, navigate]);

  const addExerciseOrSaveChangesToContext = useCallback(() => {
    const newExercise = { ...exercise };
    newExercise.series = [...series];
    if (seriesData) {
      editExerciseSeries(newExercise, exerciseIndex);
    } else addExercise(newExercise);
    setBackModalVisible(false);
    pop(3);
  }, [
    exercise,
    series,
    seriesData,
    addExercise,
    pop,
    editExerciseSeries,
    exerciseIndex,
  ]);

  const backModalButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00460",
        onPress: handleBackWithoutSave,
        loading: false,
        mode: "outlined",
      },
      {
        label: "T00164",
        onPress: addExerciseOrSaveChangesToContext,
        loading: false,
      },
    ],
    [addExerciseOrSaveChangesToContext, handleBackWithoutSave],
  );

  const moreInfoModalButtons: BottomModalWithButtonProps["buttons"] = useMemo(
    () => [
      {
        label: "T00394",
        onPress: () => setMoreInfoModalVisible(false),
        loading: false,
      },
    ],
    [],
  );

  const handleAddSeries = ({
    value,
    seriesNumber,
    break: seriesBreak,
  }: AddSeriesFormType) => {
    setSeries(prevState => {
      const newSeries: Series[] = [];
      for (let i = 0; i < seriesNumber; i++) {
        newSeries.push({
          id: new Date().getTime() + i,
          exercise: exerciseId,
          seriesBreak,
          value,
          seriesType,
        });
      }
      return [...prevState, ...newSeries];
    });
    setAddSeriesModalVisible(false);
  };

  const removeSeries = useCallback(
    (id: number) =>
      setSeries(prevState => prevState.filter(series => series.id !== id)),
    [],
  );

  const confirmAlert = useCallback(
    (cb: () => void) =>
      customAlert("", t("T00769"), [
        {
          text: t("T00163"),
          onPress: cb,
        },
        {
          text: t("T00145"),
        },
      ]),
    [t],
  );

  const handleApplyParameters = useCallback(
    (suggestedSeries: Omit<BaseSeries, "id">[], seriesType: SeriesType) => {
      const setSeriesFromSuggestedParameters = () => {
        setSuggestedParamsModalVisible(false);
        const series = suggestedSeries.map((s, i) => ({
          ...s,
          id: new Date().getTime() + i,
        }));
        setSeries(series);
        setSeriesType(seriesType);
      };
      if (series.length) return confirmAlert(setSeriesFromSuggestedParameters);
      setSeriesFromSuggestedParameters();
    },
    [confirmAlert, series],
  );

  const refetchQueries = async () =>
    await Promise.all([refetch(), !!patientId && refetchPredictedParameters()]);

  const renderFixMeSuggestion = useCallback(() => {
    if (
      patientId &&
      (predictedParameters?.repetitions?.length > 0 ||
        predictedParameters?.time?.length > 0)
    ) {
      return (
        <SuggestedParametersTile
          onPress={() => setSuggestedParamsModalVisible(true)}
        />
      );
    }
  }, [patientId, predictedParameters]);

  const { scrollContainer, gapLarge, loading } = globalStyles;

  if (isLoading || (!!patientId && predictedParametersLoading))
    return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetchQueries} />;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <ExerciseTile
        exerciseId={exerciseId}
        onPress={() => navigate("ExerciseDetails", { id: exerciseId })}
        mode="outlined"
      />
      {predictedParametersError || !FEATURE_PREDICTED_PARAMETERS
        ? null
        : renderFixMeSuggestion()}
      <SegmentedButtons
        value={seriesType}
        onValueChange={onSeriesTypeChange}
        buttons={segmentedButtons}
      />
      {hasSeries &&
        series.map(({ id, value, seriesBreak }, i) => (
          <SeriesTile
            id={id}
            order={i + 1}
            exercise={exerciseId}
            key={`series-${id}-${i}`}
            seriesData={{
              value,
              seriesType,
              seriesBreak,
              exercise: exerciseId,
            }}
            onClose={() => removeSeries(id)}
          />
        ))}
      <PrimaryButton
        label="T00422"
        mode="outlined"
        onPress={() => setAddSeriesModalVisible(true)}
      />
      <PrimaryButton
        label="T00454"
        onPress={addExerciseOrSaveChangesToContext}
        disabled={!hasSeries}
      />
      <BottomModalWithButton
        modalVisible={confirmModalVisible}
        setModalVisible={setConfirmModalVisible}
        buttons={confirmModalButtons}
        title="T01319"
        subtitles={["T01320"]}
      />
      <BottomModalContainer
        modalVisible={addSeriesModalVisible}
        setModalVisible={setAddSeriesModalVisible}>
        <AddSeriesForm
          seriesType={seriesType}
          handleAddSeries={handleAddSeries}
        />
      </BottomModalContainer>
      <BottomModalWithButton
        modalVisible={backModalVisible}
        setModalVisible={setBackModalVisible}
        buttons={backModalButtons}
        title="T00461"
        subtitles={["T00462"]}
      />
      <BottomModalContainer
        modalVisible={suggestedParamsModalVisible}
        setModalVisible={setSuggestedParamsModalVisible}>
        <SuggestedParametersSheetContent
          onClosePress={() => setSuggestedParamsModalVisible(false)}
          onMoreInformationPress={() => {
            setSuggestedParamsModalVisible(false);
            setTimeout(() => setMoreInfoModalVisible(true), 500);
          }}
          onApplyPress={handleApplyParameters}
          data={predictedParameters}
        />
      </BottomModalContainer>
      <BottomModalWithButton
        modalVisible={moreInfoModalVisible}
        setModalVisible={setMoreInfoModalVisible}
        buttons={moreInfoModalButtons}
        title="T00759"
        subtitles={["T00767", "T00768"]}
      />
    </ScrollView>
  );
};

export default EditParameters;
