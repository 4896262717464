import { useAuth } from "@contexts/AuthContext/auth";
import { AccountType } from "@contexts/AuthContext/user.types";

export const useUserDetails = () => {
  const {
    user: {
      accountType,
      currency,
      gender,
      id,
      email,
      language,
      subscriptionExpires,
      invitationCode,
      createdAt,
      stripeActive,
    },
  } = useAuth();

  const isPhysiotherapist = accountType === AccountType.THERAPIST;
  const isPatient = accountType === AccountType.PATIENT;

  return {
    isPhysiotherapist,
    isPatient,
    currency,
    gender,
    id,
    email,
    language,
    subscriptionExpires,
    invitationCode,
    createdAt,
    stripeActive,
  };
};
