import DocumentListItem from "@components/ListItems/DocumentListItem";
import { useAuth } from "@contexts/AuthContext/auth";
import { useLocale } from "@hooks/useLocale";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing16, spacing8 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { getDate, getTime } from "@utils/date";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import { Card, HelperText, Text } from "react-native-paper";
import { MessageTileType, MessageType } from "./types/MessageTile.types";
import { getTileStyleByType } from "./utils/MessageTile.utils";

export const MessageTile: FC<MessageTileType> = ({
  content,
  attachments,
  type,
  date,
  elevation,
  index,
  readAt,
  disableExtraText,
  sender,
  ...restCardProps
}) => {
  const { container, tileWidth, whiteFont, listItem } = styles;
  const tileStyles = getTileStyleByType(type);
  const { locale } = useLocale();
  const dateAndTime = `${getDate(date)}, ${getTime(date, locale)}`;
  const isReporter = type === MessageType.REPORTER;
  const { t } = useTranslation();
  const {
    user: { id: userId },
  } = useAuth();
  const {
    colors: { primaryContainer },
  } = useAppTheme();

  return (
    <View style={[isReporter ? { alignItems: "flex-end" } : null, container]}>
      <Text variant="labelSmall">{dateAndTime}</Text>
      {attachments?.length ? (
        <DocumentListItem
          key={`${attachments[0].id}-${attachments[0].name}-${index}`}
          title={attachments[0].name}
          titleNumberOfLines={3}
          titleStyle={{ paddingVertical: spacing12 }}
          style={[tileWidth, listItem, { backgroundColor: primaryContainer }]}
          onEyePress={async () => {
            await Linking.openURL(attachments[0].file);
          }}
        />
      ) : (
        <Card
          {...restCardProps}
          mode="elevated"
          elevation={elevation as never}
          style={[tileStyles, tileWidth]}>
          <Card.Content>
            <Text variant="bodySmall" style={isReporter ? whiteFont : null}>
              {content}
            </Text>
          </Card.Content>
        </Card>
      )}
      {index === 0 && !disableExtraText && sender === userId && (
        <View style={{ alignItems: "flex-end", marginTop: -spacing8 }}>
          <HelperText type="info">{t(readAt ? "T01170" : "T01171")}</HelperText>
        </View>
      )}
    </View>
  );
};

export default MessageTile;

const styles = StyleSheet.create({
  container: {
    ...globalStyles.gapSmall,
    padding: spacing8,
  },
  listItem: {
    borderRadius: spacing12,
    paddingRight: spacing16,
    paddingVertical: 0,
  },
  whiteFont: {
    color: palettes.primary[100],
  },
  tileWidth: { width: "75%" },
});
