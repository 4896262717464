import { queryKeysRewards } from "@screens/Profiles/MyProfile/InviteToTheApp/queryKeysRewards";
import { getRewardStatus } from "@services/ApiService/users";
import { useQuery } from "@tanstack/react-query";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export enum rewardStatusType {
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
  UNDETERMINED = "undetermined",
}

const useRewardStatus = () => {
  const [rewardStatus, setRewardStatus] = useState<rewardStatusType>(
    rewardStatusType.UNDETERMINED,
  );
  const { t } = useTranslation();

  const { isLoading, error, isError, isSuccess } = useQuery<
    unknown,
    AxiosError<unknown>
  >({
    queryKey: queryKeysRewards.rewardStatus,
    queryFn: getRewardStatus,
    retry: 0,
  });

  useEffect(() => {
    if (isSuccess) {
      setRewardStatus(rewardStatusType.AVAILABLE);
    } else if (isError) {
      if (error.response.status === 400) {
        setRewardStatus(rewardStatusType.UNAVAILABLE);
      } else {
        showAlertWithCustomButtons({
          title: t("T00676"),
          message: t("T00677"),
          cancelButton: {
            text: t("T00394"),
          },
        });
        setRewardStatus(rewardStatusType.UNDETERMINED);
      }
    } else setRewardStatus(rewardStatusType.UNDETERMINED);
  }, [isLoading, isSuccess, isError, error?.response?.status, t]);

  return { rewardStatus, isLoading };
};

export default useRewardStatus;
