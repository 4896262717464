import SlotsDropdownPicker from "@components/DropdownPicker/SlotsDropdownPicker";
import { SegmentedButtonsWithController } from "@components/SegmentedButtonsWithController";
import { createSlots } from "@components/Status/helpers";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { palettes } from "@styles/colors";
import { spacing16, spacing24 } from "@styles/spacing";
import { SLOTS_COUNT } from "@utils/constants";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { Control, FieldError, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { HelperText } from "react-native-paper";

type ServiceTypeButtonAndSlotsPickerParams = {
  control: Control;
  watch: UseFormWatch<{
    serviceType?: string;
  }>;
  selectedSlots: number;
  setSelectedSlots: Dispatch<SetStateAction<number>>;
  setCurrencyDropdownOpen?: Dispatch<SetStateAction<boolean>>;
  setBreakSlotsDropdownOpen?: Dispatch<SetStateAction<boolean>>;
  slotsDropdownOpen: boolean;
  setSlotsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  errors?: FieldError[];
  serviceType?: ServiceType;
};

const ServiceTypeButtonAndSlotsPicker: FC<
  ServiceTypeButtonAndSlotsPickerParams
> = ({
  control,
  watch,
  selectedSlots,
  setSelectedSlots,
  slotsDropdownOpen,
  setSlotsDropdownOpen,
  setCurrencyDropdownOpen,
  setBreakSlotsDropdownOpen,
  errors,
  serviceType,
}) => {
  const { t } = useTranslation();
  const slots = createSlots(SLOTS_COUNT);

  const onSlotsDropdownOpen = useCallback(() => {
    setCurrencyDropdownOpen && setCurrencyDropdownOpen(false);
    setBreakSlotsDropdownOpen && setBreakSlotsDropdownOpen(false);
    setSlotsDropdownOpen(true);
  }, [
    setBreakSlotsDropdownOpen,
    setCurrencyDropdownOpen,
    setSlotsDropdownOpen,
  ]);

  return (
    <>
      <View style={{ gap: spacing24 }}>
        <SegmentedButtonsWithController
          control={control}
          name="serviceType"
          key="serviceType"
          buttons={[
            {
              value: "Stationary",
              label: `${t("T00546")}`,
              showSelectedCheck: true,
            },
            {
              value: "Online",
              label: `${t("T00483")}`,
              style: watch("serviceType") === "Online" && {
                backgroundColor: palettes.tertiary[90],
              },
              showSelectedCheck: true,
            },
          ]}
          errors={errors}
          density="small"
          defaultValue={serviceType}
        />
        <SlotsDropdownPicker
          slots={slots}
          defaultValue={selectedSlots}
          setSelectedSlots={setSelectedSlots}
          dropdownLabel="T00785"
          onOpen={onSlotsDropdownOpen}
          onClose={() => setSlotsDropdownOpen(false)}
          isDropdownOpen={slotsDropdownOpen}
        />
      </View>
      <HelperText type="info" style={{ marginTop: -spacing16 }}>
        {t("T01304")}
      </HelperText>
    </>
  );
};

export default ServiceTypeButtonAndSlotsPicker;
