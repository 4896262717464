import { IconButton, Text, IconButtonProps } from "react-native-paper";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { palettes } from "@styles/colors";
import { spacing4, spacing8 } from "@styles/spacing";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TransKey } from "@globalTypes/i18next";

type Props = Omit<IconButtonProps, "icon"> & {
  title: TransKey | string;
  iconName?: string;
  subContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconColor?: string;
  buttonInView?: boolean;
};

export const TextButton: FC<Props> = ({
  title,
  iconName,
  subContainerStyle,
  containerStyle,
  textStyle,
  iconColor,
  mode = "outlined",
  style,
  buttonInView = false,
  ...props
}) => {
  const { container, innerContainer, text } = styles;
  const { t } = useTranslation();

  return (
    <IconButton
      {...props}
      mode={mode}
      style={[
        !buttonInView && { height: "auto" },
        container,
        containerStyle,
        style,
      ]}
      icon={() => (
        <View style={[innerContainer, subContainerStyle]}>
          {iconName && (
            <IconButton
              iconColor={iconColor}
              icon={iconName}
              style={{
                marginHorizontal: 0,
              }}
            />
          )}
          <Text variant="labelLarge" style={[text, textStyle]}>
            {t(title as TransKey)}
          </Text>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderWidth: 0,
    alignSelf: "center",
    paddingRight: spacing8,
  },
  innerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing4,
  },
  text: {
    flex: 1,
    color: palettes.primary[40],
  },
});
