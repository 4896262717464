import { useRewardRedeemDates } from "@hooks/rewards/useRewardRedeemDates";
import { globalStyles } from "@styles/global";
import { Trans } from "react-i18next";
import { Text } from "react-native-paper";

const RewardsInfoTexts = ({ extendedFrame }: { extendedFrame: boolean }) => {
  const { countingEndDate, receivingRewardEndDate } = useRewardRedeemDates();

  return (
    <>
      {extendedFrame && (
        <Text variant="labelSmall">
          <Trans
            i18nKey="T01569"
            components={{
              1: (
                <Text variant="labelSmall" style={globalStyles.fontBolded}>
                  Note:
                </Text>
              ),
            }}
          />
        </Text>
      )}
      <Text variant="labelSmall">
        <Trans
          i18nKey="T01554"
          values={{ countingEndDate }}
          components={{
            1: (
              <Text variant="labelSmall" style={globalStyles.fontBolded}>
                {countingEndDate}
              </Text>
            ),
          }}
        />
      </Text>
      {extendedFrame && (
        <Text variant="labelSmall">
          <Trans
            i18nKey="T01570"
            values={{ receivingRewardEndDate }}
            components={{
              1: (
                <Text variant="labelSmall" style={globalStyles.fontBolded}>
                  {receivingRewardEndDate}
                </Text>
              ),
            }}
          />
        </Text>
      )}
    </>
  );
};

export default RewardsInfoTexts;
