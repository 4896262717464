import { FC } from "react";
import { Card, Text } from "react-native-paper";
import { PrimaryButton } from "@components/buttons";
import { StyleSheet, View } from "react-native";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { palettes } from "@styles/colors";
import { useTranslation } from "react-i18next";
import { TransKey } from "@globalTypes/i18next";
import { PaperCardProps } from "./types/RecordTile.types";

export type ActionTileProps = {
  tileColorTheme: "green" | "yellow";
  title: TransKey;
  subtitles?: TransKey[];
  buttonLabel: TransKey;
  onPress: () => void;
  additionalInfo?: TransKey;
  skipActionButtonLabel?: TransKey;
  onSkipActionPress?: () => void;
} & PaperCardProps;

const ActionTile: FC<ActionTileProps> = ({
  tileColorTheme,
  title,
  subtitles,
  buttonLabel,
  onPress,
  additionalInfo,
  skipActionButtonLabel,
  onSkipActionPress,
  ...cardProps
}) => {
  const { t } = useTranslation();
  const { subtitlesContainer, contentContainer } = styles;
  const { gapMedium } = globalStyles;
  const backgroundColor =
    tileColorTheme === "green" ? palettes.complete[5] : palettes.warning[90];
  const borderColor =
    tileColorTheme === "green" ? palettes.complete[10] : palettes.warning[91];
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Card
      style={{
        backgroundColor,
        borderColor,
        borderWidth: 1,
      }}
      mode="contained"
      {...cardProps}>
      <Card.Content style={[gapMedium, contentContainer]}>
        <Text variant="labelLarge">{t(title)}</Text>
        {subtitles && (
          <View style={[gapMedium, subtitlesContainer]}>
            {subtitles.map(s => (
              <Text variant="labelSmall" key={s}>
                {`${t(s)}`}
              </Text>
            ))}
          </View>
        )}
        {skipActionButtonLabel && onSkipActionPress && (
          <PrimaryButton
            label={skipActionButtonLabel}
            mode="text"
            onPress={onSkipActionPress}
          />
        )}
        <PrimaryButton label={buttonLabel} onPress={onPress} />
        {additionalInfo && (
          <Text variant="labelSmall">{t(additionalInfo)}</Text>
        )}
      </Card.Content>
    </Card>
  );
};

export default ActionTile;

const styles = StyleSheet.create({
  subtitlesContainer: {
    flexDirection: "row",
  },
  contentContainer: {
    padding: spacing16,
  },
});
