import { globalStyles } from "@styles/global";
import { useAppTheme } from "@styles/theme";
import { FC, ReactNode } from "react";
import { View } from "react-native";
import { MD3TypescaleKey, Text, TextProps } from "react-native-paper";

type PickedTextProps = Omit<TextProps<MD3TypescaleKey>, "children">;

type MultiColorTextProps = {
  leftText: string;
  rightText?: string;
  rightTextProps?: PickedTextProps;
  rightComponent?: ReactNode;
} & PickedTextProps;

const MultiColorText: FC<MultiColorTextProps> = ({
  leftText,
  rightText,
  style = globalStyles.gapSmall,
  rightTextProps,
  rightComponent,
  ...props
}) => {
  const {
    colors: { primary },
  } = useAppTheme();
  return (
    <View
      style={[
        {
          flexDirection: "row",
          flexWrap: "wrap",
        },
        style,
      ]}>
      <Text variant="bodyMedium" {...props}>
        {leftText}
      </Text>
      {rightComponent || (
        <Text
          variant="bodyMedium"
          style={{
            color: primary,
          }}
          {...rightTextProps}>
          {rightText}
        </Text>
      )}
    </View>
  );
};

export default MultiColorText;
