import DocumentListItem from "@components/ListItems/DocumentListItem";
import { PrimaryButton } from "@components/buttons";
import { FileAttachment } from "@globalTypes/common.types";
import { imageAndPdfTypes } from "@hooks/media/useResources/constants";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { FC } from "react";
import { FieldError, UseFieldArrayRemove } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import { HelperText, Text } from "react-native-paper";

type Props = {
  onSelectFileButtonPress: () => void;
  clearDocuments: () => void;
  documents: FileAttachment[];
  isVerified: boolean;
  isRejected: boolean;
  isPending: boolean;
  actionEnabled?: boolean;
  errors?: { [x: string]: FieldError[] };
  clearErrors?: () => void;
  removeDocument: UseFieldArrayRemove;
};

export const LicenceAttachments: FC<Props> = ({
  onSelectFileButtonPress,
  isVerified,
  isRejected,
  isPending,
  actionEnabled,
  errors = {},
  documents,
  removeDocument,
}) => {
  const { t } = useTranslation();
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00602")}</Text>
      {errors?.diploma?.[0] && (
        <HelperText type="error">{errors?.diploma?.[0].message}</HelperText>
      )}
      <Text variant="bodyMedium">{t("T00915")}</Text>
      {!!documents?.length &&
        documents.map(({ name, file }, i) => (
          <DocumentListItem
            key={`licence-document-${name}-${i}`}
            title={name}
            onRemove={() => removeDocument(i)}
            isVerified={isVerified}
            actionEnabled={actionEnabled}
            onPress={async () => {
              await Linking.openURL(file);
            }}
            style={styles.document}
            removeEnabled={!(isVerified || isPending)}
          />
        ))}
      {(!isVerified || isRejected) &&
        documents.length <= 5 &&
        !actionEnabled && (
          <>
            <PrimaryButton
              mode="outlined"
              label="T00870"
              disabled={documents.length > 4}
              onPress={onSelectFileButtonPress}
            />
            <Text variant="bodyMedium">
              {t("T00535")}
              {`${imageAndPdfTypes.map(t => ` .${t.toLowerCase()}`)}.`}
            </Text>
          </>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  document: {
    backgroundColor: palettes.primary["99"],
    padding: spacing16,
  },
});
