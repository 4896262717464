import { spacing12, spacing6 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { List, ListItemProps } from "react-native-paper";

type DocumentListItemProps = ListItemProps & {
  onRemove?: () => void;
  onEyePress?: () => void;
  isVerified?: boolean;
  actionEnabled?: boolean;
  removeEnabled?: boolean;
};

const DocumentListItem: FC<DocumentListItemProps> = ({
  onRemove,
  onEyePress,
  isVerified = true,
  actionEnabled = true,
  style,
  removeEnabled,
  ...rest
}) => {
  const {
    colors: { surface },
  } = useAppTheme();
  const { eyeIcon, removeIcon } = styles;
  const canBeRemoved = (!isVerified && !actionEnabled) || removeEnabled;
  return (
    <List.Item
      titleNumberOfLines={2}
      right={props =>
        onRemove && canBeRemoved ? (
          <TouchableOpacity onPress={onRemove} style={removeIcon}>
            <List.Icon icon="close" {...props} />
          </TouchableOpacity>
        ) : onEyePress ? (
          <TouchableOpacity onPress={onEyePress} style={eyeIcon}>
            <List.Icon icon="eye" />
          </TouchableOpacity>
        ) : (
          <List.Icon icon="eye" {...props} />
        )
      }
      style={[{ backgroundColor: surface }, style]}
      {...rest}
    />
  );
};

export default DocumentListItem;

const styles = StyleSheet.create({
  removeIcon: { flexDirection: "row", alignItems: "center" },
  eyeIcon: {
    padding: spacing6,
    marginLeft: spacing12,
    alignSelf: "center",
  },
});
