import { GetAvailabilityResponse } from "@components/PhysioAvailability/PhysioAvailability.types";
import {
  Locale,
  add,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatDistance,
  formatISO,
  isToday,
  isValid,
  parse,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  addDays,
  addMinutes,
  isTomorrow,
} from "date-fns";
import * as dateFnsLocales from "date-fns/locale";
import { getLocales } from "expo-localization";
import { capitalize } from "lodash";
import { MIN_AGE } from "./constants";
import { t } from "i18next";

const locales: { [key: string]: Locale } = dateFnsLocales;

export const getTime = (date: string | Date, userLocale?: Locale) => {
  const selectedDate = typeof date === "string" ? new Date(date) : date;
  const locale = userLocale || getLocale();

  return format(selectedDate, "p", { locale });
};

const recognizeDateFormat = (date: string): Date | null => {
  const formats = [
    "yyyy-MM-dd",
    "yyyy/MM/dd",
    "yyyy.MM.dd",
    "dd.MM.yyyy",
    "yyyyMMdd",
    "MM/dd/yyyy",
  ];

  for (const format of formats) {
    const parsedDate = parse(date.split("T")[0], format, new Date());
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return null;
};

export const getDateFromString = (date: string) => {
  const locale = getLocale();
  const tempDate = recognizeDateFormat(date);

  if (tempDate !== null) {
    return tempDate;
  } else if (locale.code === "en-US") {
    return parse(date, "MM/dd/yyyy", new Date());
  } else {
    return parse(date, "dd/MM/yyyy", new Date());
  }
};

export const getDateObject = (date: string | Date | null) => {
  let resultDate = new Date();

  switch (typeof date) {
    case "string":
      resultDate = getDateFromString(date);
      break;
    case "object":
      resultDate = date;
      break;
    default:
      break;
  }

  return resultDate;
};

export const getDate = (date: string | Date | null) => {
  const locale = getLocale();
  let resultDate = new Date();

  switch (typeof date) {
    case "string":
      resultDate = getDateFromString(date);
      break;
    case "object":
      resultDate = date;
      break;
    default:
      break;
  }
  return format(resultDate, "P", { locale });
};

export const getFormattedDayWithMonth = (date: Date) => format(date, "dd.MM");

export const getFormattedHourAndMinutes = (date: Date) => format(date, "HH:mm");

export const padZero = (value: number): string =>
  value.toString().padStart(2, "0");

export const formatMillisecondsToTime = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
};

export const getMonthAndYearAsNumber = (date: Date) => {
  const selectedMonthNumber = date.getMonth() + 1;
  const selectedYearNumber = date.getFullYear();
  return { selectedMonthNumber, selectedYearNumber };
};

export const getLocale = (userLanguage?: string) => {
  const locale = getLocales && getLocales();

  if (userLanguage) {
    return locales[userLanguage];
  }
  const userLocale = locale?.[0]?.languageTag || "pl-PL";
  return locales[userLocale.split("-")[0]] || locales["pl"];
};

export const today = new Date();
export const formatDateWithDayName = (
  languageCode: string,
  date?: Date | string,
) => {
  return `${getDate(date || today)} - ${getDayName(
    date || today,
    languageCode,
  )}`;
};

export const getDayName = (
  date: Date | string,
  languageCode: string,
  customFormat = "EEEE",
) =>
  capitalize(
    format(new Date(date), customFormat, {
      locale: getLocaleForWeekdayTranslation(languageCode),
    }),
  );

export const isValidDate = (date: unknown): boolean => {
  if (typeof date === "string") return !isNaN(Number(getDateFromString(date)));
  if (date instanceof Date) return !isNaN(date.getTime());
  return false;
};

export const formatDateForApi = (
  date: string | Date,
  type: "normal" | "iso" = "normal",
) => {
  if (!isValidDate(date)) return;
  const resultDate = typeof date === "string" ? getDateFromString(date) : date;
  return type === "normal"
    ? format(resultDate, "yyyy-MM-dd")
    : formatISO(resultDate);
};

export const getMonthWithYear = (date: Date, language?: string) => {
  const locale = language ? getLocale(language) : getLocale();
  const monthName = format(date, "LLLL", { locale });

  return `${capitalize(monthName)}, ${date.getFullYear()}`;
};

export const adjustHoursTo15MinutesSlots = (date: Date): Date => {
  if (!isToday(date)) {
    return date;
  }

  const minutes = date.getMinutes();
  let roundedMinutes: number;

  if (minutes >= 0 && minutes < 15) {
    roundedMinutes = 15;
  } else if (minutes >= 15 && minutes < 30) {
    roundedMinutes = 30;
  } else if (minutes >= 30 && minutes < 45) {
    roundedMinutes = 45;
  } else {
    roundedMinutes = 0;
  }

  const extraHours = roundedMinutes === 0 ? 1 : 0;

  date = set(date, { minutes: roundedMinutes });

  if (extraHours > 0) {
    date = add(date, { hours: extraHours });
  }

  return date;
};

export const getMinAvailableTime = (
  selectedDate: Date,
  fromFilters?: boolean,
) => {
  const date = new Date(selectedDate);
  if (isToday(date) && !fromFilters) {
    const adjustedDate = adjustHoursTo15MinutesSlots(new Date());
    date.setHours(adjustedDate.getHours(), adjustedDate.getMinutes(), 0, 0);
  } else if (fromFilters) {
    date.setHours(0, 0, 0, 0);
  } else {
    date.setHours(6, 0, 0, 0);
  }
  const normalDayHours = new Date(selectedDate);
  normalDayHours.setHours(0, 0, 0, 0);

  return { date, normalDayHours };
};

export const getMaxAvailableTime = (
  date: Date,
  wrongAlert: () => void,
  fromFilters?: boolean,
) => {
  const maxDateTo = new Date(date);
  maxDateTo.setHours(20, 0, 0, 0);

  if (isToday(date) && new Date().getTime() > maxDateTo.getTime()) {
    const tempDate = new Date();
    tempDate.setHours(23, 15, 0, 0);
    if (new Date().getTime() > tempDate.getTime()) {
      wrongAlert();
    } else {
      const adjustedDate = adjustHoursTo15MinutesSlots(new Date());
      maxDateTo.setHours(adjustedDate.getHours());
    }
  } else if (fromFilters) {
    maxDateTo.setHours(23, 45, 0, 0);
  }

  return maxDateTo;
};

export const roundTimeToMatchSlot = (hours: number, minutes: number) => {
  let roundedHours = hours;
  let roundedMinutes = minutes;
  if (minutes > 0 && minutes < 15) roundedMinutes = 15;
  if (minutes > 15 && minutes < 30) roundedMinutes = 30;
  if (minutes > 30 && minutes < 45) roundedMinutes = 45;
  if (minutes > 45 && minutes < 60) {
    roundedHours = roundedHours + 1;
    roundedMinutes = 0;
  }

  return { roundedHours, roundedMinutes };
};

export const roundTimeToMatchReminderTime = (
  hours: number,
  minutes: number,
) => {
  let roundedHours = hours;
  let roundedMinutes = minutes;
  if (minutes > 0 && minutes < 10) roundedMinutes = 10;
  if (minutes > 10 && minutes < 20) roundedMinutes = 20;
  if (minutes > 20 && minutes < 30) roundedMinutes = 30;
  if (minutes > 30 && minutes < 40) roundedMinutes = 40;
  if (minutes > 40 && minutes < 50) roundedMinutes = 50;
  if (minutes > 50 && minutes < 60) {
    roundedHours = roundedHours + 1;
    roundedMinutes = 0;
  }

  return { roundedHours, roundedMinutes };
};

const getLocaleForWeekdayTranslation = (languageCode: string): Locale => {
  try {
    return locales[languageCode];
  } catch {
    switch (languageCode) {
      case "en":
        return locales.enGB;
      case "no":
        return locales.nb;
      default:
        return locales.pl;
    }
  }
};

export const getFormatDateForAppointmentDetails = (
  date: string,
  language: string,
) => {
  const alarmClockUnicode = 0x23f0;
  const dayWithDate = `${getDayName(date, language)} ${getDate(date)}`;
  const time = `${String.fromCodePoint(alarmClockUnicode)} ${getTime(date)}`;
  return `${dayWithDate}, ${time}`;
};

export const getInitialDateForBirthdayPicker = (): Date => {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear() - MIN_AGE,
    currentDate.getMonth(),
    currentDate.getDate(),
  );
};

export const convertMinutesToMiliseconds = (minutesToConvert: number) =>
  minutesToConvert * 60 * 1000;

export const convertDaysToMiliseconds = (numberOfDaysToConvert: number) =>
  convertMinutesToMiliseconds(numberOfDaysToConvert * 24 * 60);

export const getDurationInDays = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): number => {
  const durationInDays = formatDistance(new Date(startDate), new Date(endDate));
  return +durationInDays.split(" ")[0];
};

export const getMinutesBeforeDate = (date: string, numberOfMinutes: number) =>
  new Date(
    new Date(date).getTime() + convertMinutesToMiliseconds(numberOfMinutes),
  );

export const getDaysAfterDate = (date: string, numberOfDays: number) =>
  new Date(new Date(date).getTime() + convertDaysToMiliseconds(numberOfDays));

export const getCurrentDatePlus30min = () =>
  formatDateForApi(addMinutes(new Date(), 30), "iso");

export const getDatesWithAvailability = (data: GetAvailabilityResponse) =>
  data?.availableAppointments
    ?.sort((a, b) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();

      return aDate - bDate;
    })
    .map(({ date }) => {
      return { date: new Date(date), eventType: "blue" };
    });

export const getDays = (date: Date) => {
  if (!(date instanceof Date))
    throw new Error("The provided argument is not a valid Date object.");
  const startOfDayDate = formatDateForApi(startOfDay(date), "iso");
  const endOfDayDate = formatDateForApi(endOfDay(date), "iso");
  const todayPlusTwoDaysDate = formatDateForApi(
    endOfDay(addDays(date, 2)),
    "iso",
  );
  const threeDaysAfterDate = formatDateForApi(
    startOfDay(addDays(date, 3)),
    "iso",
  );

  const startOfWeekDate = formatDateForApi(
    startOfWeek(date, { weekStartsOn: 1 }),
    "iso",
  );
  const endOfWeekDate = formatDateForApi(
    endOfWeek(date, { weekStartsOn: 1 }),
    "iso",
  );
  const startOfMonthDate = formatDateForApi(startOfMonth(date), "iso");
  const endOfMonthDate = formatDateForApi(endOfMonth(date), "iso");
  const startOfPrevMonthDate = formatDateForApi(
    addMonths(startOfMonthDate, -1),
    "iso",
  );
  const endOfTwoMonthsAheadDate = formatDateForApi(
    addMonths(endOfMonthDate, 2),
    "iso",
  );
  return {
    startOfDayDate,
    endOfDayDate,
    todayPlusTwoDaysDate,
    threeDaysAfterDate,
    startOfWeekDate,
    endOfWeekDate,
    startOfMonthDate,
    endOfMonthDate,
    startOfPrevMonthDate,
    endOfTwoMonthsAheadDate,
  };
};

export const shortNameAndDateOfDay = (date: string, language: string) => {
  const shortDay = isToday(date)
    ? t("T01004")
    : isTomorrow(date)
    ? t("T01005")
    : `${getDayName(new Date(date), language, "iii")}`;

  const shortDate = getFormattedDayWithMonth(new Date(date));

  return { shortDay, shortDate };
};
