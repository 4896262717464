import { SvgSend } from "@assets/svg";
import { Input } from "@components/Input";
import { useUserDetails } from "@hooks/index";
import { useKeyboard } from "@hooks/ui/useKeyboard";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing8 } from "@styles/spacing";
import { isIOS, isWeb } from "@utils/constants";
import { FC, useMemo, useState } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";
import { IconButton } from "react-native-paper";
import { MessageType } from "../ConversationScreen";
import InputTopBar from "./InputTopBar";

type Props = {
  isSendingMessage: boolean;
  onPress: () => void;
  isValidForm: boolean;
  control: Control<MessageType>;
  disabled?: boolean;
  onPressContainer?: () => void;
  autoFocus?: boolean;
  setValue?: UseFormSetValue<MessageType>;
  getValues?: UseFormGetValues<MessageType>;
  displayInputTopbar?: boolean;
  handleAddFilesIcon?: () => void;
};

export const ConversationInput: FC<Props> = ({
  isSendingMessage,
  onPress,
  isValidForm,
  control,
  disabled,
  onPressContainer,
  autoFocus,
  setValue,
  getValues,
  displayInputTopbar = false,
  handleAddFilesIcon,
}) => {
  const [inputHeight, setInputHeight] = useState(0);
  const { isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();
  const {
    icon,
    textInput,
    sendButtonContainer,
    inputContainer,
    onPressContainerStyle,
  } = styles;
  const keyboardHeight = useKeyboard();
  const message = useWatch<MessageType>({ control, name: "msg" })?.trim();
  const isDisabled = useMemo(
    () => !message || !isValidForm || disabled || isSendingMessage,
    [message, isValidForm, disabled, isSendingMessage],
  );

  const handleCustomMessageTemplateSelect = (value: string) => {
    const currentValue = getValues("msg") || "";
    const updatedValue = `${currentValue}${value}`;
    setValue("msg", updatedValue, { shouldValidate: true });
  };

  return (
    <>
      {isPhysiotherapist &&
        (!!keyboardHeight || isWeb) &&
        displayInputTopbar && (
          <InputTopBar
            onCustomMessageTemplateSelect={handleCustomMessageTemplateSelect}
          />
        )}
      <View style={[inputContainer, globalStyles.gapMedium]}>
        {handleAddFilesIcon && (
          <IconButton icon="plus" style={icon} onPress={handleAddFilesIcon} />
        )}
        <Input
          mode="flat"
          name="msg"
          label={undefined}
          placeholder={`${t("T00374")}...`}
          control={control as unknown as Control}
          style={[
            textInput,
            {
              maxHeight: inputHeight * (isIOS ? 3.7 : 1.75),
            },
          ]}
          underlineColor="transparent"
          activeUnderlineColor={isIOS ? "transparent" : palettes.primary[20]}
          dense
          multiline
          containerStyle={{ flex: 1 }}
          onContentSizeChange={event => {
            if (inputHeight === 0) {
              setInputHeight(event.nativeEvent.contentSize.height);
            }
          }}
          onKeyPress={event => {
            if (event.nativeEvent.key === "Enter" && !isDisabled) {
              event.preventDefault();
              return onPress();
            }
          }}
          autoFocus={autoFocus}
        />
        <Pressable
          style={sendButtonContainer}
          onPress={onPress}
          disabled={isDisabled}>
          <SvgSend style={isDisabled && { opacity: 0.5 }} />
        </Pressable>
        {onPressContainer && (
          <TouchableOpacity
            onPress={onPressContainer}
            style={onPressContainerStyle}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    marginHorizontal: spacing12,
    paddingTop: spacing8,
  },
  icon: {
    margin: 0,
    marginBottom: spacing8,
    marginHorizontal: -spacing8,
  },
  textInput: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderRadius: 20,
  },
  sendButtonContainer: {
    marginTop: -spacing8,
  },
  onPressContainerStyle: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
});
