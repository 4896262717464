import {
  configureFonts,
  MD3LightTheme,
  MD3Theme,
  useTheme,
} from "react-native-paper";
import { fontConfig } from "./fontConfig";
import { ProductName } from "@globalTypes/common.types";
import { flags } from "@utils/flags";

type CustomColors = MD3Theme["colors"] & {
  yellowContrast?: string;
  blue?: string;
  lightBlue?: string;
  warning?: string;
  surfaceTint?: string;
  white?: string;
  elevatedCardBackground?: string;
  infoBlue?: string;
};

type CustomThemeType = MD3Theme & {
  colors: CustomColors;
  opacity: {
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  };
};

export const theme: CustomThemeType = {
  ...MD3LightTheme,
  colors: {
    ...MD3LightTheme.colors,
    primary: "#4666FF",
    primaryContainer: "#DEE1FF",
    onPrimary: "#FFFFFF",
    onPrimaryContainer: "#001159",
    secondary: "#5A5D72",
    secondaryContainer: "#DFE1F9",
    onSecondary: "#FFFFFF",
    onSecondaryContainer: "#171A2C",
    tertiary: "#76546D",
    tertiaryContainer: "#FFD7F2",
    onTertiary: "#FFFFFF",
    onTertiaryContainer: "#2D1228",
    error: "#BA1A1A",
    errorContainer: "#FFDAD6",
    onError: "#FFFFFF",
    onErrorContainer: "#410002",
    warning: "#FFC22D",
    outline: "#767680",
    background: "#FFFFFF",
    onBackground: "#1B1B1F",
    surface: "#FEFBFF",
    onSurface: "#1B1B1F",
    surfaceVariant: "#E3E1EC",
    onSurfaceVariant: "#46464F",
    inverseSurface: "#303034",
    inverseOnSurface: "#F3F0F4",
    inversePrimary: "#BAC3FF",
    shadow: "#000000",
    surfaceTint: "#274BE6",
    outlineVariant: "#C6C5D0",
    scrim: "#000000",
    white: "#FFFFFF",
    elevatedCardBackground: "#274BE60D",
  },
  opacity: {
    level1: 0.08,
    level2: 0.12,
    level3: 0.16,
    level4: 0.38,
  },
  fonts: configureFonts({ config: fontConfig, isV3: true }),
};

if (flags.PRODUCT_NAME === ProductName.GYMSY) {
  theme.colors = {
    ...theme.colors,
    primary: "#FDC400",
    onPrimary: "#28282D",
    primaryContainer: "#FFEFC7",
    secondaryContainer: "#FFEFC7",
    yellowContrast: "#937200",
    blue: "#4666FF",
    lightBlue: "#D7EEFF",
    infoBlue: "#DEE1FF",
  };
}

export type AppTheme = typeof theme;

export const useAppTheme = () => useTheme<AppTheme>();
