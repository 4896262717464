import { roundTimeToMatchSlot } from "./date";

export const roundUpToNearest15MinutesWith1SecondBackOffset = (
  dateStr: string,
): string => {
  // Extract the time components and the timezone offset from the date string
  const [datePart, timePart] = dateStr.split("T");

  if (!timePart || !datePart) return dateStr;

  const [time, timezoneOffset] = timePart.split(/([+-]\d{2}:\d{2})$/);
  let [hours, minutes] = time.split(":").map(Number);

  // Format the rounded time back to the original format
  const pad = (n: number) => n.toString().padStart(2, "0");

  if (hours === 23 && minutes >= 45) {
    return `${datePart}T${pad(hours)}:59:59${timezoneOffset}`;
  }

  if (minutes % 15 === 0) minutes += 15;

  // Round using the provided function
  const { roundedHours, roundedMinutes } = roundTimeToMatchSlot(hours, minutes);

  // Adjust the hours and minutes
  hours = roundedHours;
  minutes = roundedMinutes;

  // Adjust the minutes and hours if seconds subtraction requires it
  if (minutes === 0) {
    minutes = 59;
    hours = hours === 0 ? 23 : hours - 1;
  } else {
    minutes -= 1;
  }

  const roundedTime = `${pad(hours)}:${pad(minutes)}:59`;

  return `${datePart}T${roundedTime}${timezoneOffset}`;
};
