import { FC, PropsWithChildren, useCallback, useRef } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { AnimationObject } from "lottie-react-native";

import { SliderAnimation } from "@components/Slider/SliderAnimation";

import { DataType } from "@frontendData/onboarding_slider";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { TransKey } from "@globalTypes/i18next";
import { useSharedValue } from "react-native-reanimated";
import Carousel, {
  ICarouselInstance,
  Pagination,
} from "react-native-reanimated-carousel";
import { spacing12, spacing8 } from "@styles/spacing";
import { palettes } from "@styles/colors";
import { Logo } from "@components/Logo";
import { useFlags } from "@hooks/useFlags";
import { theme } from "@styles/theme";

type Props = {
  carouselStyle?: StyleProp<ViewStyle>;
  data: DataType[];
  carouselContainerStyle?: StyleProp<ViewStyle>;
  withAppLogo?: boolean;
};

export const CustomCarousel: FC<PropsWithChildren<Props>> = ({
  carouselStyle,
  children,
  data,
  carouselContainerStyle,
  withAppLogo,
}) => {
  const ref = useRef<ICarouselInstance>(null);
  const progress = useSharedValue<number>(0);
  const { isGymsy } = useFlags();
  const dotColor = isGymsy ? theme.colors.primary : palettes.primary[60];

  const onPressPagination = (index: number) => {
    ref.current?.scrollTo({
      count: index - progress.value,
      animated: true,
    });
  };

  const { width } = useMediaStyle();
  const { height } = useWindowDimensions();

  const renderItem = useCallback(
    ({
      item: { animation, text },
      index,
    }: {
      item: { animation: AnimationObject; text: TransKey };
      index: number;
    }) => (
      <SliderAnimation
        key={`slider-animation-${index}`}
        animation={animation}
        text={text}
      />
    ),
    [],
  );

  return (
    <View style={[styles.carouselContainer, carouselContainerStyle]}>
      {withAppLogo && <Logo />}
      <Carousel
        loop
        data={data}
        autoPlay
        pagingEnabled
        width={width}
        autoPlayInterval={5000}
        onProgressChange={progress}
        style={[{ height: height / 1.75 }, carouselStyle]}
        renderItem={renderItem}
      />
      <Pagination.Basic
        progress={progress}
        data={data}
        dotStyle={{ borderColor: dotColor, ...styles.dot }}
        onPress={onPressPagination}
        activeDotStyle={{
          backgroundColor: dotColor,
        }}
      />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  carouselContainer: {
    flex: 4,
    alignItems: "center",
  },
  dot: {
    width: spacing12,
    height: spacing12,
    borderWidth: 2,
    borderRadius: 100,
    marginHorizontal: spacing8,
  },
});
