import { Dispatch, FC, SetStateAction } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";

import { DateArrayType } from "../Calendar";
import { spacing16, spacing2, spacing48, spacing8 } from "@styles/spacing";
import { palettes } from "@styles/colors";
import { isWeb, webScreenWidth } from "@utils/constants";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import shouldDateBeDisabled from "../helpers/shouldDateBeDisabled";

type Props = {
  dayHaveEvent: DateArrayType;
  isCorrectDay: boolean;
  data: Date;
  multiselect: boolean;
  selectedDays: DateArrayType[];
  setSelectedDays: (dates: DateArrayType[]) => void;
  setSelectedDate: Dispatch<SetStateAction<DateArrayType>>;
  setCurrentDate: (data: DateArrayType) => void;
  onSelectDate: (data: DateArrayType, dontClose?: boolean) => void;
  actualYear: number;
  actualMonth: number;
  actualDay: number;
  shouldDisableDays?: boolean;
  calculatedShouldDisableDays?: boolean;
  selectedDate: DateArrayType;
  setOwnCurrentDate: (date: Date) => void;
  disableOnPress?: boolean;
  type: "week" | "month";
  currentMonth: number;
  currentYear: number;
};

export const PickerItem: FC<Props> = ({
  dayHaveEvent,
  isCorrectDay,
  data,
  multiselect,
  selectedDays,
  setSelectedDate,
  setSelectedDays,
  onSelectDate,
  actualYear,
  actualMonth,
  actualDay,
  shouldDisableDays,
  calculatedShouldDisableDays,
  selectedDate,
  disableOnPress,
  type,
  currentMonth,
  currentYear,
}) => {
  const shouldBeDisabled = shouldDateBeDisabled({
    actualDay,
    actualMonth,
    actualYear,
    currentMonth,
    currentYear,
    el: data,
    multiselect,
    type,
  });
  const { width } = useWindowDimensions();
  const onPressItem = () => {
    if (multiselect) {
      const tempSelectedDays = [...selectedDays];

      const isSelected = tempSelectedDays.some(
        ({ date }) => date.getTime() === data?.getTime(),
      );

      if (isSelected) {
        const result = tempSelectedDays.filter(
          ({ date }) => date.getTime() !== data?.getTime(),
        );
        setSelectedDays(result);
      } else {
        tempSelectedDays.push({ date: data });
        setSelectedDays(tempSelectedDays);
      }
    } else {
      setSelectedDate({ date: data });
    }

    onSelectDate && onSelectDate({ date: data });
  };

  const shouldDisplayBorder =
    actualYear === data?.getFullYear() &&
    actualMonth === data?.getMonth() &&
    actualDay == data?.getDate();

  const handlePickColor = () => {
    return shouldBeDisabled
      ? "gray"
      : isCorrectDay
      ? "white"
      : shouldDisplayBorder
      ? palettes.primary["40"]
      : "black";
  };

  const spacings = spacing16 * 2 + spacing8 * 2;
  const tempItemSize = isWeb ? webScreenWidth - spacings : width - spacings;
  const itemSize = (isWeb ? tempItemSize / 2 : tempItemSize) / 7;

  const isSelectedDay = !multiselect
    ? data?.getTime() === selectedDate.date.getTime()
    : selectedDays.some(({ date }) => date.getTime() === data?.getTime());

  const handleDotColor = () => {
    switch (dayHaveEvent.eventType) {
      case "blue":
        return isSelectedDay ? palettes.primary["100"] : palettes.primary["40"];
      case "green":
        return palettes.complete["10"];
      case "red":
        return palettes.error["40"];
      default:
        return dayHaveEvent.eventType;
    }
  };

  return (
    <TouchableOpacity
      disabled={
        data === null ||
        calculatedShouldDisableDays ||
        shouldBeDisabled ||
        shouldDisableDays ||
        disableOnPress
      }
      onPress={!disableOnPress ? onPressItem : void 0}
      style={[
        styles.container,
        {
          borderWidth: shouldDisplayBorder ? 1 : 0,
          backgroundColor: isCorrectDay
            ? palettes.primary["40"]
            : "transparent",
        },
        {
          width: itemSize,
          height: itemSize,
        },
      ]}>
      <Text
        style={{
          textAlign: "center",
          color:
            calculatedShouldDisableDays || shouldDisableDays
              ? "gray"
              : handlePickColor(),
        }}>
        {data?.getDate()}
      </Text>
      {dayHaveEvent && (
        <View
          style={[
            styles.dot,
            {
              backgroundColor: handleDotColor(),
            },
          ]}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    borderColor: palettes.primary["40"],
    borderRadius: spacing48,
    marginVertical: spacing2,
  },
  dot: {
    position: "absolute",
    height: spacing8,
    width: spacing8,
    borderRadius: spacing8,
    bottom: spacing2,
  },
});
