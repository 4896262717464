import SpecializationDropDownPicker from "@components/DropdownPicker/SpecializationDropdownPicker";
import ServicesList from "@components/Lists/ServicesList";
import { SearchbarButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysVerificationServices } from "@screens/Common/queryKeysServices";
import {
  getLicence,
  getServices,
  getSpecializations,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing64 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { ServiceDataType, VerificationStatus } from "./verification.types";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import { useDisplayDefaultSpecialization } from "@hooks/useDisplayDefaultSpecialization";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import ServicesTutorialBottomSheet from "./ServicesTutorialBottomSheet";
import { CurrencyAmountResponse } from "@services/ApiService/api.types";
import { AddServiceContext } from "./AddServices/addService.context";

type ServicesScreenType = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "Services">,
    NativeStackScreenProps<ProfileStackParamsList, "Profile">
  >
>;

const Services: FC<ServicesScreenType> = ({
  navigation: { navigate, setOptions },
}) => {
  const { t } = useTranslation();
  const [specializationId, setSpecializationId] = useState<string>(null);
  const [specializationDropdownError, setSpecializationDropdownError] =
    useState(false);
  const [tutorialBottomSheetVisible, setTutorialBottomSheetVisible] =
    useState(false);
  const { setData } = useContext(AddServiceContext);

  const {
    data: verificationServices,
    isLoading: verificationServicesLoading,
    isError: verificationServicesError,
    refetch: refetchVerificationServices,
  } = useQuery({
    queryKey: queryKeysVerificationServices.list({
      specializationId: +specializationId,
    }),
    queryFn: async () => getServices({ specializationId: +specializationId }),
    enabled: !!specializationId,
  });
  const {
    data: specializationData,
    isLoading: isSpecializationDataLoading,
    isError: isSpecializationDataError,
    refetch: refetchSpecializationData,
    isSuccess,
  } = useQuery({
    queryKey: queryKeysVerficiations.licenceSpecialization(),
    queryFn: getLicence,
    select: data =>
      data.find(
        licence => licence.verificationStatus === VerificationStatus.VERIFIED,
      )?.specialization,
  });
  const {
    data: specializationOptions,
    refetch: specializationOptionsRefetch,
    isLoading: specializationOptionsLoading,
    isError: specializationOptionsError,
  } = useQuery({
    queryKey: queryKeysVerficiations.specializationOptions(),
    queryFn: getSpecializations,
  });

  const { setSpecId, defaultValueForDropdown } =
    useDisplayDefaultSpecialization({
      specializationData,
      onSelectSpecialization: setSpecializationId,
    });

  useEffect(() => {
    setOptions({
      header: ({ navigation }) => (
        <AppbarTitleWithDefaultBackAction
          navigation={navigation}
          title={t("T00240")}
          filledIcon={!!verificationServices?.length}
        />
      ),
    });
  }, [setOptions, t, verificationServices, navigate]);

  useEffect(() => {
    if (isSuccess && !!specializationData?.id) {
      setSpecializationId(`${specializationData.id}`);
    }
  }, [isSuccess, specializationData?.id]);

  useEffect(() => {
    if (
      specializationId &&
      specializationId !== "undefined" &&
      specializationDropdownError
    ) {
      setSpecializationDropdownError(false);
    }
  }, [specializationDropdownError, specializationId]);

  const refetchAll = async () =>
    await Promise.all([
      refetchVerificationServices(),
      refetchSpecializationData(),
      specializationOptionsRefetch(),
    ]);

  const onItemEditPress = (
    service: ServiceDataType,
    currencyAmount: CurrencyAmountResponse,
  ) => {
    setData({ service, currencyAmount });
    navigate("AddServicesStack", {
      screen: "AddPaidServiceStep1",
    });
  };

  const onAddServicesPress = () => {
    if (!specializationId || specializationId === "undefined") {
      return setSpecializationDropdownError(true);
    }
    setData({ specializationId: +specializationId });
    return navigate("AddServicesStack", {
      screen: "AddServices",
    });
  };

  if (isSpecializationDataLoading || specializationOptionsLoading)
    return <ActivityIndicator style={globalStyles.loading} />;
  if (
    verificationServicesError ||
    isSpecializationDataError ||
    specializationOptionsError
  )
    return <FetchError action={refetchAll} />;

  const selectedSpecialization = specializationOptions.find(
    val => `${val.id}` === specializationId,
  );

  return (
    <ScrollView
      refreshControl={
        <RefreshControl
          refreshing={isSpecializationDataLoading}
          onRefresh={refetchAll}
        />
      }
      style={globalStyles.container}
      contentContainerStyle={[
        globalStyles.gapLarge,
        { paddingBottom: spacing64 },
      ]}>
      <TutorialTextButton
        label="T01446"
        onPress={() => setTutorialBottomSheetVisible(true)}
        ignoreTextStyle
      />
      <ServicesTutorialBottomSheet
        modalVisible={tutorialBottomSheetVisible}
        setModalVisible={setTutorialBottomSheetVisible}
        specializationName={specializationData?.name}
      />
      <SpecializationDropDownPicker
        onValueChange={(value: string) => setSpecId(value)}
        placeholder="T00919"
        errorMessage={specializationDropdownError && t("T01181")}
        defaultValue={defaultValueForDropdown}
        isVerified={true}
      />
      <SearchbarButton label="T00249" onPress={onAddServicesPress} />
      <View style={globalStyles.gapMedium}>
        <Text variant="titleMedium">{`${t("T00244")} ${
          selectedSpecialization?.name ? `(${selectedSpecialization.name})` : ""
        }:`}</Text>
        {verificationServicesLoading && specializationId ? (
          <ActivityIndicator />
        ) : (
          <ServicesList
            services={verificationServices}
            emptyText="T00246"
            onItemEditPress={onItemEditPress}
          />
        )}
      </View>
    </ScrollView>
  );
};

export default Services;
