import { spacing6 } from "@styles/spacing";
import { PropsWithChildren, useCallback, useEffect } from "react";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";

type BounceWrapperProps = { shouldBounce: boolean } & PropsWithChildren;

export const BounceWrapper = ({
  children,
  shouldBounce,
}: BounceWrapperProps) => {
  const translateY = useSharedValue(0);

  const handleJump = useCallback(() => {
    translateY.value = withSpring(
      -spacing6,
      { damping: 50, stiffness: 850 },
      () => {
        translateY.value = withSpring(0, { damping: 10, stiffness: 850 });
      },
    );
  }, [translateY]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  });

  useEffect(() => {
    if (shouldBounce) handleJump();
  }, [handleJump, shouldBounce]);

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};
