/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import HumanModelWithController from "@components/HumanModel/HumanModelWithController";
import MediaWithButton from "@components/Image/MediaWithButton";
import { Input } from "@components/Input";
import { ConditionsModal } from "@components/Modals/ModalComponents";
import { EquipmentModal } from "@components/Modals/ModalComponents/EquipmentModal";
import PermissionModal from "@components/Modals/PermissionModal";
import { SearchModal } from "@components/Modals/SearchModal";
import { PrimaryButton, SearchbarButton } from "@components/buttons";
import { FetchError, GeneralError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { GenderType } from "@contexts/AuthContext/user.types";
import { PermissionType } from "@globalTypes/common.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHumanModelSchemas } from "@hooks/humanModel/useHumanModelSchemas";
import { useImageOrVideo } from "@hooks/media/useImageOrVideo";
import { useMediaPermissions } from "@hooks/media/useMediaPermissions";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  createLibraryExercise,
  deleteExerciseAttachment,
  editLibraryExercise,
  getExercise,
} from "@services/ApiService/exercises";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  MAX_THREAD_DESCRIPTION_INPUT_LENGTH,
  TITLE_INPUT_MAX_LENGTH,
} from "@utils/constants";
import { removeEmptyArraysFromObject } from "@utils/objectHelpers";
import { showAlert } from "@utils/showAlert";
import { AxiosError, AxiosProgressEvent } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Control, FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import {
  ActivityIndicator,
  HelperText,
  ProgressBar,
  Text,
} from "react-native-paper";
import { Condition, CreateAndEditExerciseType } from "../exercise.types";
import { queryKeysExercises } from "../queryKeysTrainingsAndExercises";
import SelectedConditionsListWithController from "./SelectedConditionsListWithController";
import SelectedEquipmentListWithController from "./SelectedEquipmentListWithController";
import InfoTile from "@components/Tile/InfoTile";
import { useFlags } from "@hooks/useFlags";

enum FilePickers {
  EXERCISE_VIDEO = "EXERCISE_VIDEO",
  INSTRUCTION_VIDEO = "INSTRUCTION_VIDEO",
  EXERCISE_IMAGE = "EXERCISE_IMAGE",
}

const CreateAndEditExercise: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "CreateAndEditExercise">
  >
> = ({ navigation: { goBack, setOptions }, route }) => {
  const exerciseId = route.params?.id;
  const editionMode = !!exerciseId;
  const { t } = useTranslation();
  const {
    flags: { FEATURE_CONDITIONS },
  } = useFlags();
  const [selectedEquipment, setSelectedEquipment] = useState<number[]>([]);
  const [selectedConditions, setSelectedConditions] =
    useState<Condition[]>(null);
  const [conditionsScrollY, setConditionsScrollY] = useState(0);
  const [humanModelScrollY, setHumanModelScrollY] = useState(0);
  const [instructionScrollY, setInstructionScrollY] = useState(0);
  const {
    user: { gender },
  } = useAuth();
  const { errors, generalError, setErrorsFromResponse } = useErrors();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConditionsModalVisible, setIsConditionsModalVisible] =
    useState(false);
  const [exerciseImageDelete, setExerciseImageDelete] = useState(false);
  const [instructionVideoDelete, setInstructionVideoDelete] = useState(false);
  const { gapLarge, gapMedium, loading, gapSmall, scrollContainer } =
    globalStyles;
  const [isPermissionModalVisible, setIsPermissionModalVisible] =
    useState(false);
  const [
    isLeaveExerciseBottomSheetVisible,
    setIsLeaveExerciseBottomSheetVisible,
  ] = useState(false);
  const [mutationProgressValue, setMutationProgressValue] = useState(0);
  const controllerRef = useRef<AbortController | null>(null);
  const createAbortController = () => {
    const controller = new AbortController();
    controllerRef.current = controller;
    return controller;
  };

  const {
    pickFileFromDevice: pickExerciseVideo,
    pickedFile: pickedExerciseVideo,
    isFilePickLoading: isPickExerciseVideoLoading,
    removeFile: removeExerciseVideo,
  } = useImageOrVideo({ fileType: "video" });

  const {
    pickFileFromDevice: pickExerciseInstructionVideo,
    pickedFile: pickedExerciseInstructionVideo,
    isFilePickLoading: isPickExerciseInstructionVideoLoading,
    removeFile: removeExerciseInstructionVideo,
  } = useImageOrVideo({ fileType: "video" });

  const {
    pickFileFromDevice: pickImage,
    pickedFile: pickedImage,
    isFilePickLoading: isImagePickLoading,
    removeFile: removeImage,
  } = useImageOrVideo();

  const { checkPermissions, onPressConfirm } = useMediaPermissions({
    isModalVisible: isPermissionModalVisible,
    setIsModalVisible: setIsPermissionModalVisible,
  });

  const queryClient = useQueryClient();

  const invalidateExercisesQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysExercises.list(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysExercises.detail(exerciseId),
      }),
    ]);
  };

  const refreshAndGoBack = async () => {
    await invalidateExercisesQueries();
    goBack();
    reset();
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setMutationProgressValue(progress);
    }
  };

  const timeoutErrorMessage = t("T00832");

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({
      instructionExerciseVideoUrl,
      exerciseImageUrl,
      exerciseVideoUrl,
      ...data
    }: CreateAndEditExerciseType) => {
      const { signal } = createAbortController();

      const resultData = {
        ...data,
        exerciseVideo: pickedExerciseVideo,
        exerciseImage: pickedImage,
        instructionExerciseVideo: pickedExerciseInstructionVideo,
      };

      const params = {
        data: resultData,
        onUploadProgress,
        timeoutErrorMessage,
        signal,
      };

      return await (editionMode
        ? editLibraryExercise({
            id: exerciseId,
            ...params,
          })
        : createLibraryExercise(params));
    },
    onSuccess: refreshAndGoBack,
    onError: async ({ response, code }: AxiosError) => {
      if (response) {
        setErrorsFromResponse(response);
      } else if (code === "ERR_CANCELED") await invalidateExercisesQueries();
      else {
        showAlert(timeoutErrorMessage);
      }
      return setMutationProgressValue(0);
    },
    onSettled: () => setMutationProgressValue(0),
  });

  const {
    mutateAsync: deleteExerciseOptionalAttachments,
    isLoading: isDeleteExerciseOptionalAttachmentsLoading,
  } = useMutation({
    mutationFn: async () => {
      const { signal } = createAbortController();

      await deleteExerciseAttachment({
        id: exerciseId,
        params: {
          exerciseImage: exerciseImageDelete,
          instructionVideo: instructionVideoDelete,
        },
        signal,
      });
    },
    onSuccess: () => {
      setExerciseImageDelete(false);
      setInstructionVideoDelete(false);
    },
    onError: ({ response, code }: AxiosError) => {
      if (code === "ERR_CANCELED") return;
      setErrorsFromResponse(response);
    },
  });

  const {
    isLoading: isGetExerciseLoading,
    isError: isGetExerciseError,
    refetch: getExerciseRefetch,
    data,
  } = useQuery({
    queryKey: queryKeysExercises.detail(exerciseId),
    queryFn: async () => await getExercise(exerciseId),
    enabled: editionMode,
  });

  const { exerciseSchema } = useHumanModelSchemas();

  const {
    control,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { errors: formErrors },
    setError,
  } = useForm({
    resolver: yupResolver(exerciseSchema),
    defaultValues: {
      humanGeneral: data?.humanGeneral || [],
      humanBones: data?.humanBones || [],
      humanMuscles: data?.humanMuscles || [],
      name: data?.name,
      equipments: data?.equipments,
      instruction: data?.instruction,
      exerciseVideoUrl: data?.exerciseVideoUrl || "",
      exerciseImageUrl: data?.exerciseImageUrl || "",
      instructionExerciseVideoUrl: data?.instructionExerciseVideoUrl || "",
      gender: data?.gender || gender,
      conditions: FEATURE_CONDITIONS
        ? data?.conditions.map(({ id }) => id) || []
        : null,
    },
  });
  const scrollRef = useRef<KeyboardAwareScrollView>(null);

  const apiVideoUrl = watch("exerciseVideoUrl");
  const apiImageUrl = watch("exerciseImageUrl");
  const apiInstructionVideoUrl = watch("instructionExerciseVideoUrl");
  const humanBonesField = watch("humanBones");
  const humanMusclesField = watch("humanMuscles");
  const humanGeneralField = watch("humanGeneral");

  const isAnyMediaLoading = useMemo(
    () =>
      isImagePickLoading ||
      isPickExerciseVideoLoading ||
      isPickExerciseInstructionVideoLoading,
    [
      isImagePickLoading,
      isPickExerciseInstructionVideoLoading,
      isPickExerciseVideoLoading,
    ],
  );
  const isAnyMutationLoading = useMemo(
    () => isLoading || isDeleteExerciseOptionalAttachmentsLoading,
    [isDeleteExerciseOptionalAttachmentsLoading, isLoading],
  );
  const scrollToY = (y: number) => scrollRef.current.scrollToPosition(0, y);

  const validateOnSubmit = useCallback(
    (data: CreateAndEditExerciseType) => {
      if (FEATURE_CONDITIONS && !data.conditions?.length) {
        showAlert(t("T01501"));
        setError("conditions", { message: t("T01501") });
        scrollToY(conditionsScrollY);
        return false;
      }
      if (
        !data.humanBones.length &&
        !data.humanGeneral.length &&
        !data.humanMuscles.length
      ) {
        showAlert(t("T00845"));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setError("", { message: t("T00845") });
        scrollToY(humanModelScrollY);
        return false;
      }
      if (!data.instruction) {
        setError("instruction", { message: t("T00014") });
        scrollToY(instructionScrollY);
        return false;
      }
      if (
        editionMode
          ? !pickedExerciseVideo && !apiVideoUrl.length
          : !pickedExerciseVideo
      ) {
        showAlert(t("T01145"));
        return false;
      }
      return true;
    },
    [
      FEATURE_CONDITIONS,
      apiVideoUrl.length,
      conditionsScrollY,
      editionMode,
      humanModelScrollY,
      instructionScrollY,
      pickedExerciseVideo,
      setError,
      t,
    ],
  );
  const onSubmit = useCallback(
    async (data: CreateAndEditExerciseType) => {
      const result = { ...data };
      removeEmptyArraysFromObject(result);
      const isValid = validateOnSubmit(data);
      if (!isValid) return;

      editionMode &&
        (exerciseImageDelete || instructionVideoDelete) &&
        (await deleteExerciseOptionalAttachments());

      mutate(result);
    },
    [
      deleteExerciseOptionalAttachments,
      editionMode,
      exerciseImageDelete,
      instructionVideoDelete,
      mutate,
      validateOnSubmit,
    ],
  );

  const cancelMutation = useCallback(() => controllerRef.current?.abort(), []);

  const leaveExerciseButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: editionMode ? "T01533" : "T01506",
        onPress: goBack,
        mode: "outlined",
        loading: false,
      },
      {
        label: "T01507",
        onPress: () => setIsLeaveExerciseBottomSheetVisible(false),
        loading: false,
      },
    ],
    [editionMode, goBack],
  );

  const questionAboutAbortProcess = useCallback(() => {
    if (isAnyMutationLoading) {
      cancelMutation();
      setMutationProgressValue(0);
    }
    setIsLeaveExerciseBottomSheetVisible(true);
  }, [cancelMutation, isAnyMutationLoading]);

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <View>
          <AppbarTitleWithBackAction
            title={t(editionMode ? "T00071" : "T00070")}
            onClose={
              isAnyMediaLoading || isAnyMutationLoading
                ? questionAboutAbortProcess
                : goBack
            }
          />
          {!!mutationProgressValue && (
            <ProgressBar progress={mutationProgressValue / 100} />
          )}
        </View>
      ),
    });
  }, [
    editionMode,
    isAnyMediaLoading,
    isAnyMutationLoading,
    mutationProgressValue,
    questionAboutAbortProcess,
    setOptions,
    t,
  ]);

  useEffect(() => {
    setSelectedEquipment(data?.equipments);
  }, [data?.equipments]);

  useEffect(() => {
    FEATURE_CONDITIONS &&
      !!data?.conditions.length &&
      setSelectedConditions(data?.conditions);
  }, [FEATURE_CONDITIONS, data?.conditions]);

  useEffect(() => {
    setValue("equipments", selectedEquipment);
  }, [setValue, selectedEquipment]);

  useEffect(() => {
    const conditions = selectedConditions?.map(({ id }) => id);
    setValue("conditions", conditions);
  }, [setValue, selectedConditions]);

  const removeEquipmentFromList = useCallback(
    (eqId: number) => {
      setSelectedEquipment(prevSelected =>
        prevSelected.filter(e => e !== eqId),
      );
      setValue(`equipments.${eqId}`, undefined);
    },
    [setValue],
  );

  const removeConditionFromList = useCallback(
    (condition: Condition) => {
      setSelectedConditions(prevSelected =>
        prevSelected.filter(e => e.id !== condition.id),
      );
      setValue(`conditions.${condition.id}`, undefined);
    },
    [setValue],
  );
  const saveButtonLabel = `${editionMode ? t("T00841") : t("T01201")} ${
    mutationProgressValue ? `${mutationProgressValue}%` : ""
  }`;

  if (editionMode && isGetExerciseLoading)
    return <ActivityIndicator style={loading} />;

  if (isGetExerciseError) return <FetchError action={getExerciseRefetch} />;

  const conditionsError =
    formErrors?.conditions?.message && !selectedConditions?.length;

  const onPickFilePress = async (selectedPicker: FilePickers) => {
    let cb = pickExerciseVideo;
    if (selectedPicker === FilePickers.EXERCISE_IMAGE) {
      cb = pickImage;
    } else if (selectedPicker === FilePickers.INSTRUCTION_VIDEO) {
      cb = pickExerciseInstructionVideo;
    }
    await checkPermissions(cb, true);
  };

  return (
    <KeyboardAwareScrollView
      ref={scrollRef}
      contentContainerStyle={[scrollContainer, gapLarge]}>
      <InfoTile
        status="info"
        content={<Text style={{ fontFamily: "Poppins" }}>{t("T01582")}</Text>}
      />
      {generalError ? <GeneralError error={generalError} /> : null}
      <Input
        label="T00179"
        name="name"
        key="name"
        control={control as unknown as Control}
        autoCapitalize="sentences"
        errors={errors?.name}
        maxLength={TITLE_INPUT_MAX_LENGTH}
      />
      {FEATURE_CONDITIONS && (
        <View
          style={gapLarge}
          onLayout={e => setConditionsScrollY(e.nativeEvent.layout.y)}>
          <Text variant="titleMedium">{t("T01335")}:</Text>
          <SearchbarButton
            label="T01338"
            onPress={() => setIsConditionsModalVisible(true)}
            isError={!!conditionsError}
          />
          {conditionsError && (
            <HelperText type="error">
              {formErrors?.conditions?.message}
            </HelperText>
          )}
          {!!selectedConditions?.length && (
            <SelectedConditionsListWithController
              selectedConditions={selectedConditions}
              onXIconPress={removeConditionFromList}
              control={control as unknown as Control}
            />
          )}
        </View>
      )}
      <View
        style={gapLarge}
        onLayout={e => {
          setHumanModelScrollY(e.nativeEvent?.layout.y);
        }}>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T00255")}:</Text>
          <HumanModelWithController
            gender={watch("gender")}
            onGenderChange={(value: GenderType) => setValue("gender", value)}
            humanBones={humanBonesField}
            humanMuscles={humanMusclesField}
            humanGeneral={humanGeneralField}
            errors={
              (formErrors as {
                [x: string]: FieldError[];
              }) || errors
            }
            control={control as unknown as Control}
          />
        </View>
        <View style={[gapMedium, { marginBottom: -spacing16 }]}>
          <Text variant="titleMedium">{t("T01337")}:</Text>
          <SearchbarButton
            label="T00555"
            onPress={() => setIsModalVisible(true)}
          />
          <SelectedEquipmentListWithController
            selectedEquipment={selectedEquipment}
            control={control as unknown as Control}
            onXIconPress={removeEquipmentFromList}
          />
        </View>
        <View
          style={{ marginTop: spacing16 }}
          onLayout={e => {
            setInstructionScrollY(e.nativeEvent?.layout.y);
          }}>
          <Input
            name="instruction"
            key="instruction"
            label="T00556"
            control={control as unknown as Control}
            multiline
            maxLength={MAX_THREAD_DESCRIPTION_INPUT_LENGTH}
            errors={errors?.instruction}
          />
        </View>
        <View style={gapMedium}>
          <Text variant="titleMedium">{t("T00742")}:</Text>
          <Text variant="bodyMedium">{t("T00744")}</Text>
          {errors?.exerciseVideo?.map(({ message }, i) => (
            <HelperText
              key={`error-exerciseVideo-${message}-${i}`}
              type="error">
              {message}
            </HelperText>
          ))}
          {apiVideoUrl || pickedExerciseVideo ? (
            <MediaWithButton
              onPressButton={() =>
                apiVideoUrl.length > 0
                  ? setValue("exerciseVideoUrl", "")
                  : removeExerciseVideo()
              }
              buttonLabel="T00146"
              videoUri={apiVideoUrl || pickedExerciseVideo.assets[0].uri}
            />
          ) : (
            <PrimaryButton
              label="T00534"
              mode="outlined"
              onPress={() => onPickFilePress(FilePickers.EXERCISE_VIDEO)}
              loading={isPickExerciseVideoLoading}
            />
          )}
        </View>
        <View style={gapMedium}>
          <Text variant="titleMedium">{t("T00745")}:</Text>
          <Text variant="bodyMedium">{t("T00752")}</Text>
          {errors?.instructionExerciseVideo?.map(({ message }, i) => (
            <HelperText
              key={`error-instructionExerciseVideo-${message}-${i}`}
              type="error">
              {message}
            </HelperText>
          ))}
          {apiInstructionVideoUrl || pickedExerciseInstructionVideo ? (
            <MediaWithButton
              onPressButton={
                apiInstructionVideoUrl.length > 0
                  ? () => {
                      setValue("instructionExerciseVideoUrl", "");
                      setInstructionVideoDelete(true);
                    }
                  : removeExerciseInstructionVideo
              }
              buttonLabel="T00146"
              videoUri={
                apiInstructionVideoUrl ||
                pickedExerciseInstructionVideo.assets[0].uri
              }
            />
          ) : (
            <PrimaryButton
              label="T00534"
              mode="outlined"
              onPress={() => onPickFilePress(FilePickers.INSTRUCTION_VIDEO)}
              loading={isPickExerciseInstructionVideoLoading}
            />
          )}
        </View>
        <View style={gapMedium}>
          <Text variant="titleMedium">{t("T00753")}:</Text>
          {errors?.exerciseImage?.map(({ message }, i) => (
            <HelperText
              key={`error-exerciseImage-${message}-${i}`}
              type="error">
              {message}
            </HelperText>
          ))}
          {apiImageUrl || pickedImage ? (
            <MediaWithButton
              onPressButton={
                apiImageUrl.length > 0
                  ? () => {
                      setValue("exerciseImageUrl", "");
                      setExerciseImageDelete(true);
                    }
                  : removeImage
              }
              buttonLabel="T00146"
              imgUri={apiImageUrl || pickedImage.assets[0].uri}
            />
          ) : (
            <PrimaryButton
              label="T00534"
              onPress={() => onPickFilePress(FilePickers.EXERCISE_IMAGE)}
              mode="outlined"
              loading={isImagePickLoading}
            />
          )}
        </View>
      </View>
      <PrimaryButton
        label={saveButtonLabel}
        disabled={isLoading}
        loading={isLoading}
        onPress={handleSubmit(onSubmit)}
        style={{ marginBottom: spacing16 }}
      />
      <SearchModal<number>
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        ListComponent={EquipmentModal}
        selectedItems={selectedEquipment}
        setSelectedItems={setSelectedEquipment}
        returnLabel={value =>
          t("T00585", {
            value,
          })
        }
        enableSearching
        footerHeightAndroidAPI35OrHigher={150}
      />
      <SearchModal<Condition>
        isModalVisible={isConditionsModalVisible}
        setIsModalVisible={setIsConditionsModalVisible}
        ListComponent={ConditionsModal}
        selectedItems={selectedConditions}
        setSelectedItems={setSelectedConditions}
        returnLabel={value =>
          t("T01339", {
            value,
          })
        }
        enableSearching
      />
      <PermissionModal
        isModalVisible={isPermissionModalVisible}
        setIsModalVisible={setIsPermissionModalVisible}
        onPressConfirm={onPressConfirm}
        type={PermissionType.galleryAndFiles}
      />
      <BottomModalWithButton
        modalVisible={isLeaveExerciseBottomSheetVisible}
        setModalVisible={setIsLeaveExerciseBottomSheetVisible}
        title={editionMode ? "T01531" : "T01504"}
        subtitles={[editionMode ? "T01532" : "T01505"]}
        buttons={leaveExerciseButtons}
      />
    </KeyboardAwareScrollView>
  );
};

export default CreateAndEditExercise;
