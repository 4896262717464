import { useErrors } from "@hooks/useErrors";
import { queryKeysAppointments } from "@screens/Appointments/queryKeysAppointments";
import { getAppointmentsListWithInterlocutor } from "@services/ApiService/appointments";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

type UseUpcomingAppointmentOptions = {
  interlocutorId: number;
  nearestCount: 1 | 2;
};

export const useUpcomingAppointment = ({
  interlocutorId,
  nearestCount,
}: UseUpcomingAppointmentOptions) => {
  const { setErrorsFromResponse } = useErrors();
  return useQuery({
    queryKey: queryKeysAppointments.listAppointmentsWithInterlocutor(
      interlocutorId,
      nearestCount,
    ),
    queryFn: async () =>
      await getAppointmentsListWithInterlocutor({
        interlocutor: interlocutorId,
        nearestCount,
      }),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    enabled: !!interlocutorId,
  });
};
