import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import {
  AddFreeService,
  AddPaidServiceStep1,
  AddPaidServiceStep2,
  AddServices,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { AddServicesStackParamsList } from "./navigation.types";
import { AppbarTitleWithBackAction } from "@components/Appbar";

const Stack = createNativeStackNavigator<AddServicesStackParamsList>();

const AddServicesStackNavigator: FC<
  PropsWithChildren<NativeStackScreenProps<AddServicesStackParamsList>>
> = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="AddServices"
        component={AddServices}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="AddFreeService"
        component={AddFreeService}
        options={{
          header: () => <AppbarTitleWithBackAction title={t("T00250")} />,
        }}
      />
      <Stack.Screen
        name="AddPaidServiceStep1"
        component={AddPaidServiceStep1}
        options={{
          header: () => <AppbarTitleWithBackAction title={t("T00250")} />,
        }}
      />
      <Stack.Screen
        name="AddPaidServiceStep2"
        component={AddPaidServiceStep2}
      />
    </Stack.Navigator>
  );
};

export default AddServicesStackNavigator;
