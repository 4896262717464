import {
  createMaterialTopTabNavigator,
  MaterialTopTabScreenProps,
} from "@react-navigation/material-top-tabs";
import { fontConfig } from "@styles/fontConfig";
import { theme } from "@styles/theme";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Host } from "react-native-portalize";
import { RootStackParamList } from "@navigators/navigation.types";
import { Files } from "./Files";
import { Information } from "./Information";
import { Survey } from "./Survey";
import { CompositeScreenProps, TabActions } from "@react-navigation/native";
import { useFlags } from "@hooks/useFlags";

export type AppointmentDetailsTabsParamList = {
  Information: { appointmentId: number; isIdFromNotification?: boolean };
  Survey: { appointmentId: number };
  Files: { appointmentId: number };
};

type AppointmentDetailsProps = PropsWithChildren<
  CompositeScreenProps<
    MaterialTopTabScreenProps<RootStackParamList, "AppointmentDetails">,
    MaterialTopTabScreenProps<AppointmentDetailsTabsParamList>
  >
>;

export enum TabName {
  INFORMATION = "information",
  SURVEY = "survey",
  FILES = "files",
}

const Tab = createMaterialTopTabNavigator<AppointmentDetailsTabsParamList>();

export const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  route,
  navigation,
}) => {
  const { id, idFromNotification } = route.params;
  const tabName = route?.params.tabName;
  const appointmentId = id || idFromNotification;
  const [screenMounted, setScreenMounted] = useState(false);
  const { t } = useTranslation();
  const {
    flags: { FEATURE_MEDICAL_RECORDS, FEATURE_SURVEY_ENABLED },
  } = useFlags();

  useEffect(() => {
    setScreenMounted(true);
    return () => setScreenMounted(false);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (screenMounted && tabName === TabName.SURVEY && FEATURE_SURVEY_ENABLED) {
      timeout = setTimeout(() => {
        navigation.dispatch(TabActions.jumpTo("Survey", { appointmentId }));
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [
    screenMounted,
    appointmentId,
    tabName,
    navigation,
    FEATURE_SURVEY_ENABLED,
  ]);

  return (
    <Host>
      <Tab.Navigator
        screenOptions={{
          tabBarStyle: { backgroundColor: theme.colors.surface },
          tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
        }}>
        <Tab.Screen
          name="Information"
          options={{ title: t("T00147") }}
          initialParams={{
            appointmentId,
            isIdFromNotification: !!idFromNotification,
          }}
          component={Information}
        />
        {FEATURE_SURVEY_ENABLED && (
          <Tab.Screen
            name="Survey"
            options={{ title: t("T00319") }}
            initialParams={{ appointmentId }}
            component={Survey}
          />
        )}
        {FEATURE_MEDICAL_RECORDS && (
          <Tab.Screen
            name="Files"
            options={{ title: t("T00155") }}
            initialParams={{ appointmentId }}
            component={Files}
          />
        )}
      </Tab.Navigator>
    </Host>
  );
};

export default AppointmentDetails;
