import { AntDesign } from "@expo/vector-icons";
import { queryKeysEquipments } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getEquipmentDetails } from "@services/ApiService/exercises";
import { globalStyles } from "@styles/global";
import { spacing16, spacing4 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Card, Text } from "react-native-paper";
import { EquipmentTileProps } from "../types/equipmentTile.types";
import RefetchIconButton from "../components/RefetchIconButton";
import { Image, ImageProps } from "expo-image";

const EquipmentTile: FC<EquipmentTileProps> = ({
  equipmentId,
  mode,
  elevation,
  onXIconPress,
  onPress,
  ...props
}) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysEquipments.detail(equipmentId),
    queryFn: async () => await getEquipmentDetails(equipmentId),
  });

  const img = data?.image
    ? { uri: data?.image }
    : (require("@assets/images/default_avatar.png") as ImageProps["source"]);

  const { borderWidth, borderColor, elevatedBackground, container, image } =
    styles;

  return (
    <Card
      elevation={elevation as never}
      mode={mode}
      style={[
        (mode === "contained" || "elevated") && borderWidth,
        borderColor,
        mode === "elevated" && elevatedBackground,
      ]}
      onPress={!isError && onPress}
      {...props}>
      <Card.Content>
        <View style={container}>
          <Image
            source={img}
            style={image}
            placeholder={
              require("@assets/images/default_avatar.png") as ImageProps["source"]
            }
          />
          <Text
            variant="titleMedium"
            numberOfLines={2}
            style={[globalStyles.gapSmall, { flex: 1 }]}>
            {data?.name}
          </Text>
          {isError ? (
            <RefetchIconButton onPress={refetch} />
          ) : isLoading ? (
            <ActivityIndicator style={{ marginRight: spacing16 }} />
          ) : onXIconPress ? (
            <TouchableOpacity
              onPress={() => onXIconPress(equipmentId)}
              hitSlop={15}>
              <AntDesign name="close" size={14} />
            </TouchableOpacity>
          ) : null}
        </View>
      </Card.Content>
    </Card>
  );
};

export default EquipmentTile;

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
  },
  borderWidth: {
    borderWidth: 0.5,
  },
  elevatedBackground: {
    backgroundColor: theme.colors.elevatedCardBackground,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
  image: { borderRadius: spacing4, width: 56, height: 56 },
});
