import { config } from "./config";
import * as Sentry from "@sentry/react-native";

const experimentalSentryConfig = {
  _experiments: {
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
  integrations: [Sentry.mobileReplayIntegration()],
};

let sentryOptions = {
  dsn: config.EXPO_PUBLIC_SENTRY_DSN,
  debug: false,
};

if (__DEV__) {
  sentryOptions = { ...sentryOptions, ...experimentalSentryConfig };
}

export default sentryOptions;
