import { useKeyboard } from "@hooks/ui/useKeyboard";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getCoordinatesFromAddress } from "@services/ApiService/geocoding";
import { spacing12, spacing24, spacing4 } from "@styles/spacing";
import { theme, useAppTheme } from "@styles/theme";
import { config } from "@utils/config";
import { isIOS } from "@utils/constants";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { showSnackbar } from "@utils/snackbarHelper";
import * as Location from "expo-location";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { AppState, StyleSheet } from "react-native";
import {
  GooglePlaceData,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
  Point,
} from "react-native-google-places-autocomplete";
import { IconButton, Text } from "react-native-paper";
import { openSettings } from "react-native-permissions";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";
import { defaultFilters } from "./FiltersComponents/utils";
import PermissionModal from "@components/Modals/PermissionModal";
import { PermissionType } from "@globalTypes/common.types";

type RowData = GooglePlaceData & {
  isPredefinedPlace: boolean;
};
type OnSelectPlace = {
  activeCity: string;
  lat?: number;
  lng?: number;
};

export const CitySearch: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "CitySearch">>
> = ({
  navigation: { goBack, navigate },
  route: {
    params: { setFilters },
  },
}) => {
  const appState = useRef(AppState.currentState);
  const [permissionsModalVisible, setPermissionsModalVisible] = useState(false);
  const { GRANTED, DENIED } = Location.PermissionStatus;
  const { t } = useTranslation();
  const { language } = useUserDetails();
  const { container, textInput, textInputContainer, row, separator } = styles;
  const autocompleteRef = useRef<GooglePlacesAutocompleteRef>(null);
  const {
    colors: { primary, onSurface },
  } = useAppTheme();
  const keyboardHeight = useKeyboard();

  const autocompleteSuggestions = useMemo(
    () => [
      {
        description: t("T01293"),
        geometry: { location: { lat: 0, lng: 0, latitude: 0, longitude: 0 } }, //this will be ignored for first option
      },
      {
        description: t("T01294"),
        geometry: {
          location: {
            lat: 52.22977,
            lng: 21.01178,
            latitude: 52.22977,
            longitude: 21.01178,
          },
        },
      },
      {
        description: t("T01295"),
        geometry: {
          location: {
            lat: 51.2465,
            lng: 22.5684,
            latitude: 51.2465,
            longitude: 22.5684,
          },
        },
      },
      {
        description: t("T01296"),
        geometry: {
          location: {
            lat: 50.0647,
            lng: 19.945,
            latitude: 50.0647,
            longitude: 19.945,
          },
        },
      },
      {
        description: t("T01297"),
        geometry: {
          location: {
            lat: 51.7592,
            lng: 19.4559,
            latitude: 51.7592,
            longitude: 19.4559,
          },
        },
      },
      {
        description: t("T01298"),
        geometry: {
          location: {
            lat: 51.1079,
            lng: 17.0385,
            latitude: 51.1079,
            longitude: 17.0385,
          },
        },
      },
      {
        description: t("T01299"),
        geometry: {
          location: {
            lat: 52.4064,
            lng: 16.9252,
            latitude: 52.4064,
            longitude: 16.9252,
          },
        },
      },
      {
        description: t("T01300"),
        geometry: {
          location: {
            lat: 54.352,
            lng: 18.6466,
            latitude: 54.352,
            longitude: 18.6466,
          },
        },
      },
      {
        description: t("T01301"),
        geometry: {
          location: {
            lat: 53.4285,
            lng: 14.5528,
            latitude: 53.4285,
            longitude: 14.5528,
          },
        },
      },
      {
        description: t("T01302"),
        geometry: {
          location: {
            lat: 53.1235,
            lng: 18.0076,
            latitude: 53.1235,
            longitude: 18.0076,
          },
        },
      },
    ],
    [t],
  );

  const myLocationOptionDescription = autocompleteSuggestions[0].description;

  const showLocationDeniedSnackbar = useCallback(
    () =>
      showSnackbar({
        message: t("T01305"),
      }),
    [t],
  );

  const handleDenyLocationPermission = useCallback(() => {
    setPermissionsModalVisible(false);
    setTimeout(showLocationDeniedSnackbar, 300);
  }, [showLocationDeniedSnackbar]);

  const showCustomAlert = useCallback(
    () =>
      showAlertWithCustomButtons({
        title: t("T01306"),
        message: t("T01498"),
        cancelButton: {
          text: t("T00145"),
          onPress: handleDenyLocationPermission,
        },
        confirmButton: { text: t("T00002"), onPress: openSettings },
      }),
    [handleDenyLocationPermission, t],
  );

  const onSelectPlace = useCallback(
    ({ activeCity, lat, lng }: OnSelectPlace) => {
      if (lat && lng) {
        setFilters(prev => {
          const filtersToSet = prev ? prev : defaultFilters;
          return { ...filtersToSet, lat, lng };
        });
      }
      navigate("Physiotherapists", { activeCity });
    },
    [navigate, setFilters],
  );

  const selectCloseToMeOption = useCallback(async () => {
    const lastKnownPosition = await Location.getLastKnownPositionAsync();
    const location =
      lastKnownPosition || (await Location.getCurrentPositionAsync());
    const {
      coords: { latitude, longitude },
    } = location;
    return onSelectPlace({
      activeCity: myLocationOptionDescription,
      lat: latitude,
      lng: longitude,
    });
  }, [myLocationOptionDescription, onSelectPlace]);

  const onClear = () => {
    autocompleteRef.current?.setAddressText("");
    autocompleteRef.current?.clear();
  };

  const handleLocationPermission = useCallback(async () => {
    const locationPermissions =
      await Location.requestForegroundPermissionsAsync();
    const { status, canAskAgain } = locationPermissions;
    if (status === DENIED && !canAskAgain) return showCustomAlert();
    if (status !== GRANTED) return onClear();

    await selectCloseToMeOption();
  }, [DENIED, GRANTED, selectCloseToMeOption, showCustomAlert]);

  const onPickMyLocation = useCallback(async () => {
    autocompleteRef.current.setAddressText("");
    const { status } = await Location.getForegroundPermissionsAsync();
    if (status !== GRANTED) {
      setPermissionsModalVisible(true);
    } else {
      await handleLocationPermission();
    }
  }, [GRANTED, handleLocationPermission]);

  const onItemPress = useCallback(
    async (activeCity: string, { lat, lng }: Point) => {
      if (activeCity === myLocationOptionDescription) await onPickMyLocation();
      else {
        onSelectPlace({
          activeCity,
          lat,
          lng,
        });
      }
    },
    [myLocationOptionDescription, onPickMyLocation, onSelectPlace],
  );

  const onSubmitEditing = async () => {
    const text = autocompleteRef.current?.getAddressText();
    const coords = await getCoordinatesFromAddress(text);
    onSelectPlace(
      coords
        ? {
            activeCity: text,
            lat: coords.lat,
            lng: coords.lng,
          }
        : { activeCity: text },
    );
  };

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current === "background" && nextAppState === "active") {
        setPermissionsModalVisible(false);
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => autocompleteRef.current?.focus(), 200);
    return () => clearTimeout(timeout);
  }, [autocompleteRef]);

  const renderRightButton = () =>
    autocompleteRef.current?.getAddressText() && (
      <IconButton icon="close" onPress={onClear} size={spacing24} />
    );

  const renderLeftButton = () => (
    <IconButton icon="arrow-left" onPress={goBack} size={spacing24} />
  );

  return (
    <Host>
      <SafeAreaView style={{ flex: 1 }} edges={["left", "right", "top"]}>
        <GooglePlacesAutocomplete
          isRowScrollable={false}
          ref={autocompleteRef}
          placeholder={t("T01292")}
          query={{
            key: config.EXPO_PUBLIC_GOOGLE_CLOUD_API_KEY,
            language,
            components: `country:${language}`,
            types: "locality",
          }}
          predefinedPlaces={autocompleteSuggestions}
          styles={{
            container,
            textInputContainer,
            textInput,
            row,
            separator,
            listView: { marginBottom: isIOS && keyboardHeight },
          }}
          renderLeftButton={renderLeftButton}
          renderRightButton={renderRightButton}
          renderRow={({ description, isPredefinedPlace }: RowData, index) => {
            const isMyLocationOption = isPredefinedPlace && index === 0;
            return (
              <>
                <IconButton
                  icon="map-marker-outline"
                  iconColor={isMyLocationOption ? primary : onSurface}
                />
                <Text>{description}</Text>
              </>
            );
          }}
          enablePoweredByContainer={false}
          onPress={(data, detail) =>
            onItemPress(data.description, detail.geometry.location)
          }
          fetchDetails
          minLength={3}
          textInputProps={{
            returnKeyType: "search",
            clearButtonMode: "never",
            onSubmitEditing,
            selectionColor: primary,
          }}
          keepResultsAfterBlur
        />
        <PermissionModal
          isModalVisible={permissionsModalVisible}
          setIsModalVisible={setPermissionsModalVisible}
          onPressConfirm={handleLocationPermission}
          type={PermissionType.location}
        />
      </SafeAreaView>
    </Host>
  );
};

const styles = StyleSheet.create({
  container: { marginTop: spacing4 },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginStart: -spacing12,
  },
  textInputContainer: {
    borderBottomWidth: 1,
  },
  textInput: { marginTop: 3 },
  separator: { height: 1, backgroundColor: theme.colors.outlineVariant },
});
