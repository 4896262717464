import CommitmentTile from "@components/Tile/CommitmentTile";
import { Appointment } from "@screens/Appointments/appointment.types";
import { FC } from "react";
import { Switch } from "react-native";

type Props = {
  appointmentDetails: Appointment;
  isPhysiotherapist: boolean;
  onChangeSwitch: () => void;
  onButtonPress: () => void;
  isCommitmentActive: boolean;
};

export const SpecialTrainingRequestTile: FC<Props> = ({
  appointmentDetails,
  isPhysiotherapist,
  onChangeSwitch,
  onButtonPress,
  isCommitmentActive,
}) => {
  const currentDate = new Date();
  const appointmentDateFrom =
    appointmentDetails?.dateFrom && new Date(appointmentDetails.dateFrom);

  if (
    !isPhysiotherapist ||
    appointmentDetails?.serviceType !== "Stationary" ||
    currentDate.getTime() <= appointmentDateFrom.getTime() ||
    appointmentDetails?.patientAbsent
  ) {
    return null;
  }

  return (
    <CommitmentTile
      title="T00370"
      content="T00371"
      action={
        <Switch value={isCommitmentActive} onValueChange={onChangeSwitch} />
      }
      onButtonPress={onButtonPress}
    />
  );
};
