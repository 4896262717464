import { yupResolver } from "@hookform/resolvers/yup";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { ActivityIndicator, List, Text } from "react-native-paper";
import { object, string } from "yup";

import { Input } from "@components/Input";
import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import { PrimaryButton } from "@components/buttons";

import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { getEducation, setEducation } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { Education as EducationType } from "./verification.types";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { EditEducationBottomSheetContent } from "@components/BottomSheetContents/EditEducationBottomSheetContent";
import { palettes } from "@styles/colors";
import { spacing16, spacing8 } from "@styles/spacing";
import { CompositeScreenProps } from "@react-navigation/native";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import { useInvalidateProfileDetails } from "@hooks/index";
import { useFlags } from "@hooks/useFlags";

type SetEducationForm = {
  description: string;
};

const EducationList = ({
  data,
  setModalVisible,
  setEducation,
}: {
  data: EducationType[];
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setEducation: Dispatch<SetStateAction<EducationType>>;
}) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00329")}:</Text>
      {!data.length ? (
        <Text variant="bodyMedium">{t("T00330")}</Text>
      ) : (
        data.map((education, i) => (
          <List.Item
            key={`educationData-item-${i}`}
            title={education.description}
            onPress={() => {
              setEducation(education);
              setModalVisible(prev => !prev);
            }}
            titleNumberOfLines={4}
            right={props => <List.Icon {...props} icon="pencil-outline" />}
            style={styles.itemContainer}
          />
        ))
      )}
    </View>
  );
};

type EducationScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "Education">,
    NativeStackScreenProps<ProfileStackParamsList, "Profile">
  >
>;

const Education: FC<EducationScreen> = ({
  navigation: { navigate, setOptions },
}) => {
  const [addEducationModalVisible, setAddEducationModalVisible] =
    useState(false);
  const [editEducationModalVisible, setEditEducationModalVisible] =
    useState(false);
  const [currentEducation, setCurrentEducation] = useState<EducationType>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { errors: mutationErrors, setErrorsFromResponse: setMutationError } =
    useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const {
    data: educationData,
    isLoading: isQueryLoading,
    isError: isQueryError,
    refetch: refetchQuery,
  } = useQuery({
    queryKey: queryKeysVerficiations.education(),
    queryFn: getEducation,
  });
  const { isGymsy } = useFlags();

  const schema = object().shape({
    description: string().required(t("T00014")),
  });

  const { control, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const refreshAndResetForm = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries({
        queryKey: queryKeysVerficiations.education(),
      }),
    ]);
    setAddEducationModalVisible(false);
    reset();
    setValue("description", "");
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { mutate, isLoading: isMutationLoading } = useMutation({
    mutationFn: setEducation,
    onError: ({ response }: AxiosError) => setMutationError(response),
    onSuccess: refreshAndResetForm,
  });

  const onSubmit = useCallback(
    ({ description }: SetEducationForm) => mutate(description),
    [mutate],
  );

  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00272",
        onPress: handleSubmit(onSubmit),
        loading: isMutationLoading,
      },
    ],
    [onSubmit, handleSubmit, isMutationLoading],
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation }) => (
        <AppbarTitleWithDefaultBackAction
          navigation={navigation}
          title={t("T00269")}
          filledIcon={!!educationData?.length}
        />
      ),
    });
  }, [setOptions, t, navigate, educationData?.length]);

  if (isQueryLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isQueryError) return <FetchError action={refetchQuery} />;

  return (
    <ScrollView
      style={globalStyles.container}
      contentContainerStyle={globalStyles.gapLarge}>
      <Text variant="bodyMedium">{t("T00271")}</Text>
      <PrimaryButton
        label="T00272"
        mode="outlined"
        onPress={() => setAddEducationModalVisible(true)}
      />
      <EducationList
        data={educationData}
        setModalVisible={setEditEducationModalVisible}
        setEducation={setCurrentEducation}
      />
      <BottomModalWithButton
        modalVisible={addEducationModalVisible}
        setModalVisible={setAddEducationModalVisible}
        buttons={buttons}
        title="T00272"
        subtitles={["T00273"]}>
        <Input
          name="description"
          label={isGymsy ? "T00488" : "T00269"}
          errors={mutationErrors?.description}
          control={control as unknown as Control}
          multiline
          isInBottomSheet
        />
      </BottomModalWithButton>
      <BottomModalContainer
        modalVisible={editEducationModalVisible}
        setModalVisible={setEditEducationModalVisible}>
        <EditEducationBottomSheetContent
          education={currentEducation}
          setEducation={setCurrentEducation}
          setModalVisible={setEditEducationModalVisible}
        />
      </BottomModalContainer>
    </ScrollView>
  );
};

export default Education;

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palettes.primary[99],
    paddingHorizontal: spacing16,
    paddingVertical: spacing8,
  },
});
