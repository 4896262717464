const isVersionFormat = (str: string) => {
  const versionRegex = /^\d+\.\d+\.\d+$/;
  return versionRegex.test(str);
};

export const compareVersions = (
  current: string,
  min: string,
): { isLower: boolean; isHigher: boolean } => {
  const currentParts = current.split(".").map(Number);
  const minParts = min.split(".").map(Number);

  let isLower = false;
  let isHigher = false;

  for (let i = 0; i < Math.max(currentParts.length, minParts.length); i++) {
    const currentPart = currentParts[i] || 0;
    const minPart = minParts[i] || 0;

    if (currentPart < minPart) {
      isLower = true;
      break;
    } else if (currentPart > minPart) {
      isHigher = true;
      break;
    }
  }

  return { isLower, isHigher };
};

export const isVersionNotSupported = (current: string, min: string) => {
  if (!isVersionFormat(current) || !isVersionFormat(min)) {
    return false;
  }
  if (current === min) return false;
  const result = compareVersions(current, min);
  return result.isLower;
};

export const UPDATE_ALERT_KEY = "update_alert";
