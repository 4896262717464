import {
  CurrencyAmountResponse,
  CurrencyChoices,
} from "@services/ApiService/api.types";
import { createContext, PropsWithChildren, useState } from "react";
import { ServiceType } from "../services.types";
import { ServiceDataType } from "../verification.types";

type AddServicesStackParams = {
  currencyAmount?: CurrencyAmountResponse;
  restOfCurrencies?: CurrencyChoices[];
  serviceType?: ServiceType;
  specializationId?: number;
  service?: ServiceDataType;
  serviceItemId?: number;
  serviceName?: string;
  clientSlots?: number;
  breakSlots?: number;
  price?: number;
  selectedCurrency?: string;
};

type AddServiceContextProps = AddServicesStackParams & {
  setData: (data: AddServicesStackParams) => void;
  clearData: () => void;
};

export const AddServiceContext = createContext<AddServiceContextProps>(
  {} as AddServiceContextProps,
);

const AddServiceContextProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<AddServicesStackParams>({});

  const updateData = (newData: AddServicesStackParams) => {
    setData(prevData => ({
      ...prevData,
      ...newData,
    }));
  };

  const clearData = () => setData({});

  const value = { ...data, setData: updateData, clearData };

  return (
    <AddServiceContext.Provider value={value}>
      {children}
    </AddServiceContext.Provider>
  );
};

export default AddServiceContextProvider;
