import { PrimaryButton } from "@components/buttons";
import ErrorComponent from "@components/Tile/components/ErrorComponent";
import useApiHealthCheck from "@hooks/healthCheck/useApiHealthCheck";
import { updateApp } from "@hooks/healthCheck/useUpdateAppAlert";
import { useAppStateChange } from "@hooks/useAppStateChange";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing16, spacing32 } from "@styles/spacing";
import { restartApp } from "@utils/restart";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

const MaintenanceScreen: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "Maintenance">>
> = () => {
  const { isMaintenance, isNotSupported, maintenanceReason, isRefetching } =
    useApiHealthCheck({
      isMaintenanceScreen: true,
    });
  const { t } = useTranslation();
  useAppStateChange(restartApp);

  if (isRefetching) return <ActivityIndicator style={globalStyles.loading} />;

  return (
    <View
      style={[
        { justifyContent: "center", alignItems: "center" },
        globalStyles.container,
      ]}>
      <ErrorComponent hideAllText />
      <View style={[globalStyles.gapMedium, { marginTop: spacing32 }]}>
        {isNotSupported ? (
          <View style={[globalStyles.gapSmall, { marginBottom: spacing16 }]}>
            <Text variant="labelLarge" style={{ textAlign: "center" }}>
              {t("T01545")}
            </Text>
            <PrimaryButton
              label={t("T01546")}
              onPress={updateApp}
              mode="text"
              icon="open-in-new"
            />
          </View>
        ) : isMaintenance ? (
          <View style={globalStyles.gapSmall}>
            <View style={[globalStyles.gapSmall, { alignItems: "center" }]}>
              <Text variant="labelLarge">{t("T01547")}</Text>
              {maintenanceReason && (
                <Text variant="labelLarge">{maintenanceReason}</Text>
              )}
              <Text variant="labelLarge">{t("T01548")}</Text>
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
};

export default MaintenanceScreen;
