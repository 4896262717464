import { palettes, white } from "@styles/colors";
import { spacing4, spacing8 } from "@styles/spacing";
import { isANDROID } from "@utils/constants";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

type CounterItemParams = {
  countValue: number;
  highlighted?: boolean;
  counterType: "registered" | "verified";
};

const extraPaddingIfAndroid: number = isANDROID ? 1 : 0;

const CounterItem = ({
  countValue,
  highlighted = false,
  counterType,
}: CounterItemParams) => {
  const { t } = useTranslation();
  const { item, value, extraStyle } = styles;

  return (
    <View style={item}>
      <Text variant="labelSmall">
        {t(counterType === "registered" ? "T01552" : "T01553")}:
      </Text>
      <Text variant="labelMedium" style={[value, highlighted && extraStyle]}>
        {countValue}
      </Text>
    </View>
  );
};
const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  value: {
    fontFamily: "Poppins",
    marginLeft: spacing8,
    backgroundColor: white,
    borderRadius: spacing4,
    borderWidth: 1,
    borderColor: white,
    paddingLeft: spacing8 + extraPaddingIfAndroid,
    paddingTop: spacing4 + extraPaddingIfAndroid,
    paddingRight: spacing8 - 1,
    paddingBottom: spacing4 - 1,
    overflow: "hidden",
  },
  extraStyle: {
    borderColor: palettes.complete[10],
    fontFamily: "Poppins",
    fontWeight: "700",
  },
});

export default CounterItem;
