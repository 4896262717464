import { api } from "./api";
import {
  Appointment,
  BaseAppointment,
  FillAppointmentSurvey,
  PostCreatePhysiotherapistAppointment,
  ScheduleAppointmentType,
  SetAppointmentDetailsType,
} from "@screens/Appointments/appointment.types";
import { endpoints } from "./endpoints";
import { ResponseOptions } from "./api.types";
import {
  GetServicesParams,
  GetServiceType,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";

type GetAppointmentsListParams = {
  dateFrom?: string;
  dateTo?: string;
  nearestCount?: 1 | 2;
};

type GetAppointmentsListWithInterlocutorParams = {
  nearestCount: 1 | 2;
  interlocutor: number;
};

export const getAppointmentsList = async (
  params: GetAppointmentsListParams,
) => {
  const { data } = await api.get<BaseAppointment[]>(endpoints.APPOINTMENTS, {
    params,
  });
  return data;
};

export const getAppointmentsListWithInterlocutor = async (
  params: GetAppointmentsListWithInterlocutorParams,
) => {
  const { data } = await api.get<BaseAppointment[]>(endpoints.APPOINTMENTS, {
    params,
  });
  return data;
};

export const createAppointment = (data: ScheduleAppointmentType) =>
  api.post<Appointment>(endpoints.APPOINTMENTS, data);

export const getAppointmentDetails = async (id: number) => {
  const { data } = await api.get<Appointment>(
    endpoints.APPOINTMENT_DETAILS(id),
  );
  return data;
};

export const checkIfPromoCodeAvailable = async (params: { physioId: number }) =>
  await api.get(endpoints.CHECK_PROMO_CODE, {
    params,
  });

export const setAppointmentDetails = (
  id: number,
  data: SetAppointmentDetailsType,
) =>
  api.patch<SetAppointmentDetailsType>(endpoints.APPOINTMENT_DETAILS(id), data);

export const joinAppointment = async (id: number) => {
  const { data } = await api.get<{ token: string }>(
    endpoints.APPOINTMENTS_JOIN(id),
  );
  return data;
};

export const deleteCustomAppointment = async (id: number) =>
  await api.patch(endpoints.DELETE_CUSTOM_APPOINTMENT(id));

export const cancelAppointment = async (id: number) =>
  await api.patch(endpoints.APPOINTMENT_CANCEL(id));

export const fillAppointmentSurvey = async (
  id: number,
  data: FillAppointmentSurvey,
) => await api.patch(endpoints.APPOINTMENT_SURVEY(id), data);

export const getAppointmentOptions = async () =>
  await api.options<ResponseOptions>(endpoints.APPOINTMENTS);

export const getPhysiotherapistServices = async (
  id: number,
  params: GetServicesParams,
) => {
  const { data } = await api.get<GetServiceType[]>(
    endpoints.PHYSIOTHERAPIST_SERVICES(id),
    { params },
  );
  return data;
};

export const createPhysiotherapistAppointment = (
  data: PostCreatePhysiotherapistAppointment,
) => api.post<Appointment>(endpoints.APPOINTMENTS_PHYSIOTHERAPIST, data);
