import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

import { BottomModalWithButton } from "@components/BottomSheet";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";

import { ButtonBottomSheet } from "@components/Button/Button.types";

import {
  deleteMedicalRecord,
  getMedicalRecord,
  getSharedMedicalRecord,
  updateSharedMedicalRecord,
} from "@api/medicalRecords";
import ShareRecordForm from "@components/Forms/ShareRecordForm";
import HumanModel from "@components/HumanModel/HumanModel";
import { RecordTitleWithReportWrapper } from "@components/MedicalRecord/RecordTitle";
import InfoTile from "@components/Tile/InfoTile";
import { PhysiotherapistCardWithRating, UserCard } from "@components/cards";
import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { getDate } from "@utils/date";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";
import { queryKeysMedicalRecord } from "./queryKeysMedicalRecord";
import MedicalRecordAttachmentsList from "@components/Lists/MedicalRecordAttachmentsList";
import DescriptionSection from "./TextSection";
import { isFuture } from "date-fns";
import { PainTile } from "@components/Tile/PainTile";
import { getNumberOfShares } from "@components/MedicalRecord/helpers";
import { SystemMessage } from "@components/Report/report.types";

const RecordPreview: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "RecordPreview">>
> = ({
  navigation: { navigate, goBack },
  route: {
    params: { id },
  },
}) => {
  const { scrollContainer, loading, gapLarge, gapMedium } = globalStyles;
  const [shareFormModalVisible, setShareFormModalVisible] = useState(false);
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);
  const { setErrorsFromResponse } = useErrors();
  const { setErrorsFromResponse: setStopSharingErrorsFromResponse } =
    useErrors();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const {
    recordDetails,
    createdByMeList,
    sharedToOtherUserDetails,
    sharedToMeList,
    sharedBetweenMeAndOtherUserList,
  } = queryKeysMedicalRecord;
  const { isPatient, id: userId } = useUserDetails();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: recordDetails(id),
    queryFn: async () => await getMedicalRecord(id),
  });

  const sharedRecordId = data?.sharedMedicalRecords?.find(({ asignee }) =>
    userId !== data?.owner ? asignee === userId : asignee,
  )?.id;

  const {
    data: sharedRecord,
    isLoading: isSharedRecordLoading,
    isError: isSharedRecordError,
    refetch: refetchSharedRecord,
  } = useQuery({
    queryKey: sharedToOtherUserDetails(sharedRecordId),
    queryFn: async () => await getSharedMedicalRecord(sharedRecordId),
    enabled: !!data?.sharedMedicalRecords?.length,
  });

  const onStopSharingSuccess = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries(recordDetails(id)),
      queryClient.invalidateQueries(sharedToMeList()),
      queryClient.invalidateQueries(
        sharedBetweenMeAndOtherUserList(sharedRecordId),
      ),
      queryClient.invalidateQueries(sharedToOtherUserDetails(sharedRecordId)),
    ]);
  }, [
    id,
    queryClient,
    recordDetails,
    sharedBetweenMeAndOtherUserList,
    sharedRecordId,
    sharedToMeList,
    sharedToOtherUserDetails,
  ]);

  const { mutate: stopSharingRecord, isLoading: isStopSharingRecordLoading } =
    useMutation({
      mutationFn: async (dueDate: string | null) =>
        await updateSharedMedicalRecord(sharedRecordId, dueDate),
      onSuccess: onStopSharingSuccess,
      onError: ({ response }: AxiosError) =>
        setStopSharingErrorsFromResponse(response),
    });

  const onDeleteSuccess = async () => {
    await queryClient.invalidateQueries({
      queryKey: createdByMeList(),
    });
    if (data?.sharedMedicalRecords?.length) {
      const sharedListToInvalidate = data.sharedMedicalRecords.map(
        ({ asignee }) =>
          queryClient.invalidateQueries(
            sharedBetweenMeAndOtherUserList(asignee),
          ),
      );
      await Promise.all(sharedListToInvalidate);
    }
    setModalVisible(false);
    goBack();
  };

  const { isLoading: isDeleting, mutate: deleteRecord } = useMutation({
    mutationFn: deleteMedicalRecord,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onDeleteSuccess,
  });

  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00145",
        onPress: () => setModalVisible(false),
        loading: false,
        mode: "outlined",
      },
      {
        label: "T00146",
        onPress: () => deleteRecord(id),
        loading: isDeleting,
      },
    ],
    [isDeleting, deleteRecord, id],
  );

  const recordData = useMemo(() => {
    const recordDueDate = sharedRecord?.data?.dueDate;
    if (!recordDueDate) return;
    return {
      isShared: isFuture(new Date(recordDueDate)),
      dueDate: getDate(recordDueDate),
    };
  }, [sharedRecord?.data?.dueDate]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  const {
    title,
    description,
    owner,
    updatedAt,
    painLevel,
    hadBeenCured,
    attachments,
    sharedMedicalRecords,
    asignee,
    humanBones,
    humanMuscles,
    humanGeneral,
    gender,
  } = data;

  const isMyRecord = user.id === owner;

  const numberOfShares = isMyRecord && getNumberOfShares(sharedMedicalRecords);

  const lastModifyDate = getDate(updatedAt);

  const detailsItems = [
    {
      title: `${t("T00280")}:`,
      extras: () => (
        <HumanModel
          humanGeneral={humanGeneral}
          gender={gender}
          humanBones={humanBones}
          humanMuscles={humanMuscles}
        />
      ),
    },
    {
      title: `${t("T00285")}:`,
      subtitle: description,
      withReport: !isMyRecord,
    },
    {
      extras: () => <PainTile disableSliding initialValue={painLevel} />,
    },
    {
      title: `${t("T00861")}`,
      subtitle: hadBeenCured ? t("T00576") : t("T00574"),
    },
  ];

  return (
    <Host>
      <SafeAreaView edges={["bottom", "left", "right"]}>
        <ScrollView contentContainerStyle={[gapLarge, scrollContainer]}>
          <RecordTitleWithReportWrapper
            text={title}
            isMyRecord={isMyRecord}
            systemMessage={SystemMessage.REPORT_MEDICAL_RECORD_TITLE}
            item={id}
          />
          <Divider />
          {isMyRecord ? (
            <>
              <PrimaryButton
                label="T00146"
                onPress={() => setModalVisible(true)}
                mode="outlined"
              />
              <PrimaryButton
                label="T00335"
                onPress={() =>
                  navigate("CreateAndEditRecord", {
                    id,
                    patientId: !isPatient && asignee[0],
                  })
                }
                mode="outlined"
              />
              {!isPatient && !!sharedMedicalRecords.length ? (
                <View style={globalStyles.gapMedium}>
                  <Text variant="titleMedium">{t("T00275")}:</Text>
                  <UserCard
                    initialData={{ id: asignee[0] }}
                    mode="outlined"
                    disableHeightAndWeight
                    onPress={() =>
                      navigate("PatientProfile", {
                        id: asignee[0],
                      })
                    }
                  />
                  <InfoTile
                    status="info"
                    content={
                      <Text>
                        {t(recordData?.isShared ? "T00865" : "T00864")}
                      </Text>
                    }
                  />
                  <PrimaryButton
                    label={recordData?.isShared ? "T00866" : "T00867"}
                    onPress={() => {
                      recordData?.isShared && sharedRecordId
                        ? stopSharingRecord(null)
                        : setShareFormModalVisible(true);
                    }}
                    loading={isStopSharingRecordLoading}
                  />
                </View>
              ) : (
                <PrimaryButton
                  label="T00344"
                  onPress={() => navigate("ShareRecord", { id })}
                />
              )}
              {isPatient && numberOfShares > 0 && (
                <InfoTile
                  content={
                    <Text variant="bodyMedium">
                      {t("T00322", {
                        value: numberOfShares,
                      })}
                    </Text>
                  }
                  status="info"
                />
              )}
              <View style={gapMedium}>
                <Text variant="bodyMedium">{`${t(
                  "T00353",
                )} ${lastModifyDate}`}</Text>
                {!!sharedMedicalRecords?.length && isSharedRecordLoading ? (
                  <ActivityIndicator style={loading} />
                ) : isSharedRecordError ? (
                  <FetchError action={refetchSharedRecord} />
                ) : (
                  !isPatient &&
                  !!sharedMedicalRecords?.length &&
                  recordData?.isShared && (
                    <Text variant="bodyMedium">{`${t("T00354")}: ${
                      recordData?.dueDate
                    }`}</Text>
                  )
                )}
              </View>
            </>
          ) : (
            <View style={gapMedium}>
              <Text variant="titleMedium">
                {t(`${isPatient ? "T00351" : "T00352"}`)}:
              </Text>
              {isPatient ? (
                <PhysiotherapistCardWithRating initialData={{ id: owner }} />
              ) : (
                <UserCard initialData={{ id: owner }} mode="outlined" flat />
              )}
              <Text variant="bodyMedium">{`${t(
                "T00353",
              )} ${lastModifyDate}`}</Text>
              {!!sharedMedicalRecords.length && isSharedRecordLoading ? (
                <ActivityIndicator style={loading} />
              ) : isSharedRecordError ? (
                <FetchError action={refetchSharedRecord} />
              ) : (
                !!sharedMedicalRecords?.length &&
                recordData?.isShared && (
                  <Text variant="bodyMedium">{`${t("T00354")}: ${
                    recordData?.dueDate
                  }`}</Text>
                )
              )}
            </View>
          )}
          <Divider bold />
          <View style={gapMedium}>
            <DescriptionSection
              detailsItems={detailsItems}
              systemMessage={SystemMessage.REPORT_MEDICAL_RECORD_DESCRIPTION}
              item={id}
            />
            <MedicalRecordAttachmentsList
              systemMessage={SystemMessage.REPORT_MEDICAL_RECORD_ATTACHMENT}
              attachments={attachments}
              isMyRecord={isMyRecord}
              item={id}
            />
          </View>
          <BottomModalWithButton
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            buttons={buttons}
            title="T00343"
            subtitles={[isPatient ? "T00342" : "T01204"]}
          />
          {!isPatient && (
            <ShareRecordForm
              asignee={asignee[0]}
              medicalRecord={id}
              sharedRecordId={sharedRecordId}
              shareFormModalVisible={shareFormModalVisible}
              setShareFormModalVisible={setShareFormModalVisible}
              calendarModalVisible={calendarModalVisible}
              setCalendarModalVisible={setCalendarModalVisible}
            />
          )}
        </ScrollView>
      </SafeAreaView>
    </Host>
  );
};

export default RecordPreview;
