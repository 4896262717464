import { deleteService } from "@api/verifications";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Button } from "@components/Button";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import ServiceTile from "@components/Tile/service/ServiceTile";
import { TranslationKey } from "@globalTypes/i18next";
import { useInvalidateProfileDetails } from "@hooks/index";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import {
  queryKeysServices,
  queryKeysVerificationServices,
} from "@screens/Common/queryKeysServices";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { ServiceDataType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { HandleScheduleAppointmentType } from "@screens/Profiles/Physiotherapist/PhysiotherapistProfile";
import { CurrencyAmountResponse } from "@services/ApiService/api.types";
import { getCurrencyAmount } from "@services/ApiService/common";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

type ServicesListProps = {
  services: ServiceDataType[];
  emptyText: TranslationKey;
  handleScheduleAppointment?: HandleScheduleAppointmentType;
  onItemEditPress?: (
    params: ServiceDataType,
    currencyAmount: CurrencyAmountResponse,
  ) => void;
};

const ServicesList: FC<ServicesListProps> = ({
  services,
  emptyText,
  handleScheduleAppointment,
  onItemEditPress,
}) => {
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const [currentlySelectedService, setCurrentlySelectedService] =
    useState<ServiceDataType>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOrRemoveModalVisible, setIsEditOrRemoveModalVisible] =
    useState(false);

  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const {
    data: currencyAmount,
    isLoading: currencyAmountLoading,
    isError: currencyAmountError,
    refetch: refetchCurrencyAmount,
  } = useQuery({
    queryFn: getCurrencyAmount,
    queryKey: queryKeysVerficiations.currencyOptions(),
    enabled: isPhysiotherapist,
  });

  const onDeleteServiceSuccess = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries({
        queryKey: queryKeysServices.all,
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysVerificationServices.all,
      }),
      queryClient.invalidateQueries({
        queryKey:
          queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      }),
    ]);
    setIsDeleteModalVisible(false);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: deleteService,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onDeleteServiceSuccess,
  });

  const handleServiceEditPress = (service: ServiceDataType) => {
    setCurrentlySelectedService(service);
    setIsEditOrRemoveModalVisible(true);
  };

  const handleRemoveServicePress = () => {
    setIsEditOrRemoveModalVisible(false);
    setTimeout(() => setIsDeleteModalVisible(true), 500);
  };

  const handleOpenServiceEditionMode = useCallback(() => {
    setIsEditOrRemoveModalVisible(false);
    onItemEditPress &&
      onItemEditPress(currentlySelectedService, currencyAmount);
  }, [currencyAmount, currentlySelectedService, onItemEditPress]);

  if (currencyAmountLoading && isPhysiotherapist)
    return <ActivityIndicator style={globalStyles.loading} />;

  if (currencyAmountError) return <FetchError action={refetchCurrencyAmount} />;
  return (
    <>
      {services?.length ? (
        services.map((item, index) => {
          const { serviceType, id, serviceItem } = item;
          return (
            <ServiceTile
              service={item}
              key={`${serviceItem.name}-${index}`}
              onPress={
                isPhysiotherapist
                  ? () => handleServiceEditPress(item)
                  : () =>
                      handleScheduleAppointment(
                        serviceType === "Online"
                          ? AppointmentSlots.ONLINE
                          : AppointmentSlots.STATIONARY,
                        id,
                      )
              }
              showAllPrices={isPhysiotherapist}
            />
          );
        })
      ) : (
        <Text variant="bodyMedium">{t(emptyText)}</Text>
      )}
      {isPhysiotherapist && currentlySelectedService && (
        <>
          <BottomModalContainer
            modalVisible={isDeleteModalVisible}
            setModalVisible={setIsDeleteModalVisible}>
            <View style={globalStyles.gapLarge}>
              <Text variant="bodyLarge">{t("T00853")}</Text>
              <Text variant="bodyMedium">{t("T00854")}</Text>
              <Text variant="bodyMedium">{t("T00856")}</Text>
              <Button
                label="T00852"
                onPress={() => mutate(currentlySelectedService.id)}
                mode="contained"
                loading={isLoading}
              />
            </View>
          </BottomModalContainer>
          <BottomModalContainer
            modalVisible={isEditOrRemoveModalVisible}
            setModalVisible={setIsEditOrRemoveModalVisible}>
            <View style={globalStyles.gapLarge}>
              <View style={{ marginTop: spacing16 }}>
                <ServiceTile service={currentlySelectedService} showAllPrices />
              </View>
              <PrimaryButton
                label="T01250"
                onPress={handleOpenServiceEditionMode}
                mode="outlined"
              />
              <PrimaryButton
                label="T00852"
                onPress={handleRemoveServicePress}
                mode="outlined"
              />
            </View>
          </BottomModalContainer>
        </>
      )}
    </>
  );
};

export default ServicesList;
