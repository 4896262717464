import { VerificationStatus } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import {
  useDataForVerificationActionTileProfile,
  useSubscriptionStatus,
} from "..";
import {
  VerificationActionTileType,
  VerificationInfoTileType,
} from "./useVerification";
import { useActionTileType } from "./useActionTileType";

export const useVerificationStatus = () => {
  const { actionTileTypeProfileVisible, licenceData } =
    useDataForVerificationActionTileProfile();

  const {
    keyForAppleBillingGracePeriod,
    keyForGoogleBillingGracePeriod,
    appleSubscriptionStatus,
    googleSubscriptionStatus,
  } = useSubscriptionStatus();

  const {
    actionTileTypeAvailabilityVisible,
    actionTileTypeStationaryServiceVisible,
    actionTileTypeOnlineServiceVisible,
    actionTileTypeOnlinePaymentsVisible,
    actionTileTypeStationaryPaymentsVisible,
  } = useActionTileType();

  const getVerificationStatus = () => {
    switch (true) {
      case licenceData?.[0]?.verificationStatus === VerificationStatus.PENDING:
        return VerificationActionTileType.VERIFICATION_WAITING;
      case actionTileTypeProfileVisible:
        return VerificationActionTileType.PROFILE;
      case appleSubscriptionStatus?.length &&
        appleSubscriptionStatus?.[0]?.subscriptionStatus ===
          +keyForAppleBillingGracePeriod:
      case googleSubscriptionStatus?.length &&
        googleSubscriptionStatus?.[0]?.subscriptionStatus ===
          keyForGoogleBillingGracePeriod:
        return VerificationInfoTileType.SUBSCRIPTION_GRACE_PERIOD;
      case actionTileTypeAvailabilityVisible:
        return VerificationActionTileType.AVAILABILITY;
      case actionTileTypeStationaryServiceVisible:
        return VerificationActionTileType.SERVICE_STATIONARY;
      case actionTileTypeOnlineServiceVisible:
        return VerificationActionTileType.SERVICE_ONLINE;
      case actionTileTypeOnlinePaymentsVisible:
        return VerificationActionTileType.NO_ONLINE_PAYMENTS;
      case actionTileTypeStationaryPaymentsVisible:
        return VerificationActionTileType.NO_STATIONARY_PAYMENTS;
      default:
        return VerificationInfoTileType.COMPLETED;
    }
  };
  return getVerificationStatus();
};
