import ActionTile from "@components/Tile/ActionTile";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { getDataForProfileCompletionActionTile } from "@utils/getDataForVerificationTiles";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Chip } from "react-native-paper";
import { RehabVerificationProgressResponse } from "./verification.types";
import useProfileCompletionActionsSkippedByUser from "@hooks/verification/useProfileCompletionActionsSkippedByUser";

type ProfileCompletionSectionProps = {
  data: RehabVerificationProgressResponse;
};

export const ProfileCompletionSection = ({
  data,
}: ProfileCompletionSectionProps) => {
  const { navigate } = useNavigation<NavigationProp<ProfileStackParamsList>>();
  const navigateTo = (screen: keyof ProfileCompletionStackParamList) =>
    navigate("ProfileCompletionStack", {
      screen,
    });
  const { t } = useTranslation();
  const {
    getProfileCompletionActionsSkippedByUser,
    profileCompletionActionsSkippedByUser,
  } = useProfileCompletionActionsSkippedByUser();

  const { chipContainer, chipText } = styles;

  return (
    !!getDataForProfileCompletionActionTile(
      data,
      navigateTo,
      profileCompletionActionsSkippedByUser,
      getProfileCompletionActionsSkippedByUser,
    ) && (
      <View style={globalStyles.gapMedium}>
        <Chip style={chipContainer} textStyle={chipText}>
          {t("T00204")}:
        </Chip>
        <ActionTile
          tileColorTheme="green"
          {...getDataForProfileCompletionActionTile(
            data,
            navigateTo,
            profileCompletionActionsSkippedByUser,
            getProfileCompletionActionsSkippedByUser,
          )}
        />
      </View>
    )
  );
};

const styles = StyleSheet.create({
  chipContainer: {
    alignSelf: "flex-start",
    borderRadius: spacing16,
    backgroundColor: theme.colors.secondaryContainer,
  },
  chipText: { color: theme.colors.onSecondaryContainer },
});
