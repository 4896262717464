import { ProductName } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import { flags } from "@utils/flags";
import { AnimationObject } from "lottie-react-native";

export type DataType = {
  animation: AnimationObject;
  text: TransKey;
};

const sliders: DataType[] = [
  {
    animation:
      require("@assets/animations/sliders/Slider_01_Online.json") as AnimationObject,
    text: "T00445",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_02_Statistics.json") as AnimationObject,
    text: "T00446",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_04_Documents.json") as AnimationObject,
    text: "T00447",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_05_Trainings.json") as AnimationObject,
    text: "T00448",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_03_Specialists.json") as AnimationObject,
    text: "T00449",
  },
];

if (
  !flags.FEATURE_MEDICAL_RECORDS &&
  flags.PRODUCT_NAME !== ProductName.GYMSY
) {
  sliders.splice(2, 1);
}

export const onBoardingData: DataType[] = sliders;
