import {
  AvailabilityParams,
  GetAvailabilityGroupsResponse,
  GetAvailabilityResponse,
  GetFutureAvailabilityParams,
  GetFutureAvailabilityResponse,
  PhysiotherapistAvailabilityParams,
} from "@components/PhysioAvailability/PhysioAvailability.types";
import {
  GetPhysiotherapistsResponse,
  OnlyIdProfile,
  PublicPhysiotherapistFullProfile,
  PublicPhysiotherapistProfile,
  PublicUserProfile,
  UserReport,
  UserReportResponse,
} from "@contexts/AuthContext/user.types";
import {
  GetAchievementResponse,
  GetUserAchievementsResponse,
} from "@screens/Profiles/MyProfile/Achievements/achievements.types";
import {
  UserProfileDetails,
  UserProfileDetailsResponse,
} from "@screens/Profiles/MyProfile/PatientCompletion/patientCompletion.types";
import {
  PhysiotherapistSettingsPatch,
  PhysiotherapistSettingsResponse,
} from "@screens/Profiles/MyProfile/settings.types";

import { PaginationResponse } from "@globalTypes/common.types";
import { GetPhysiotherapistParams } from "@screens/Appointments/filters.types";
import { PostAvailabilityHoursRangeType } from "@screens/Calendar/availability.types";
import {
  BlacklistData,
  BlacklistResponse,
} from "@screens/MessageCenter/Conversation/conversation.types";
import { InvitedTherapistData } from "@screens/Profiles/MyProfile/InviteToTheApp/inviteToTheApp.types";
import { api } from "./api";
import { MyPhysiotherapistsParams } from "./api.types";
import { endpoints } from "./endpoints";

export const searchPhysiotherapists = async (
  params: Pick<GetPhysiotherapistParams, "search">,
) => {
  const { data } = await api.get<GetPhysiotherapistsResponse[]>(
    endpoints.PHYSIOTHERAPISTS_LIST,
    {
      params,
    },
  );
  return data;
};

export const getPhysiotherapists = async (
  params: Omit<GetPhysiotherapistParams, "search">,
  page: number,
  pageSize: number,
) => {
  let selectedFilters = {};
  const { lat, lng } = params;
  const {
    available,
    dateTo,
    dateFrom,
    spokenLanguage,
    serviceName,
    country,
    availabilityType,
    hourFrom,
    hourTo,
  } = params;
  if (spokenLanguage?.length) {
    const spokenLanguageString = spokenLanguage
      ?.map(({ value }) => value)
      .join("&");
    Object.assign(selectedFilters, { spokenLanguage: spokenLanguageString });
  }

  if (serviceName?.length) {
    const serviceNameString = serviceName?.join("&");
    Object.assign(selectedFilters, { serviceName: serviceNameString });
  }

  if (hourFrom) {
    const hh = hourFrom.getUTCHours().toString().padStart(2, "0");
    const mm = hourFrom.getUTCMinutes().toString().padStart(2, "0");
    Object.assign(selectedFilters, {
      hourFrom: `${hh}:${mm}`,
    });
  }

  if (hourTo) {
    const hh = hourTo.getUTCHours().toString().padStart(2, "0");
    const mm = hourTo.getUTCMinutes().toString().padStart(2, "0");
    Object.assign(selectedFilters, {
      hourTo: `${hh}:${mm}`,
    });
  }

  selectedFilters = {
    ...selectedFilters,
    available,
    dateFrom,
    dateTo,
    country,
    availabilityType,
    lat,
    lng,
    page,
    pageSize,
  };

  const { data } = await api.get<
    PaginationResponse<GetPhysiotherapistsResponse>
  >(endpoints.PHYSIOTHERAPISTS_LIST, {
    params: selectedFilters,
  });
  return data;
};

export const getPhysiotherapistBasicData = async (id: number) => {
  const { data } = await api.get<PublicPhysiotherapistProfile>(
    endpoints.PHYSIOTHERAPIST_DETAILS(id),
  );
  return data;
};

export const getPhysiotherapistFullData = async (id: number) => {
  const { data } = await api.get<PublicPhysiotherapistFullProfile>(
    endpoints.PHYSIOTHERAPIST_DETAILS(id),
  );
  return data;
};

export const getPatientBasicData = async (id: number) => {
  const { data } = await api.get<PublicUserProfile>(
    endpoints.MY_PATIENT_DETAILS(id),
  );
  return data;
};

export const getPhysiotherapistAvailability = async (
  params: PhysiotherapistAvailabilityParams,
) => {
  const { data } = await api.get<GetAvailabilityResponse>(
    endpoints.APPOINTMENTS_AVAILABILITY,
    {
      params,
    },
  );
  return data;
};

export const getPhysiotherapistAvailabilityGroups = async (
  params: AvailabilityParams,
) => {
  const { data } = await api.get<GetAvailabilityGroupsResponse>(
    endpoints.APPOINTMENTS_GROUPS,
    { params },
  );
  return data;
};

export const deletePhysiotherapistAvailabilityGroup = (
  slotsToDelete: number[],
) => api.patch(endpoints.APPOINTMENTS_GROUPS, { slotsToDelete });

export const getMyPatients = async (search?: string) => {
  const { data } = await api.get<OnlyIdProfile[]>(endpoints.MY_PATIENTS_LIST, {
    params: { search },
  });
  return data;
};

export const getMyPhysiotherapists = async (
  params?: MyPhysiotherapistsParams,
) => {
  const { data } = await api.get<OnlyIdProfile[]>(
    endpoints.MY_PHYSIOTHERAPISTS_LIST,
    { params },
  );
  return data;
};

export const getMyLatestPhysiotherapist = async () => {
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.MY_LATEST_PHYSIOTHERAPISTS);
  return data;
};

export const getUserDetails = async () => {
  const { data } = await api.get<UserProfileDetailsResponse[]>(
    endpoints.USER_DETAILS,
  );
  return data;
};

export const addUserDetails = (data: UserProfileDetails) =>
  api.post<UserProfileDetailsResponse>(endpoints.USER_DETAILS, data);

export const updateUserDetails = (id: number, data: UserProfileDetails) =>
  api.patch<UserProfileDetailsResponse>(
    endpoints.USER_DETAILS_UPDATE(id),
    data,
  );

export const getAchievements = async () => {
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.ACHIEVEMENTS);
  return data;
};

export const getAchievement = async (id: number) => {
  const { data } = await api.get<GetAchievementResponse>(
    endpoints.ACHIEVEMENT(id),
  );
  return data;
};

export const getUserAchievements = async () => {
  const { data } = await api.get<GetUserAchievementsResponse[]>(
    endpoints.USER_ACHIEVEMENTS,
  );
  return data;
};

export const deleteAllAvailabilityHoursFromSelectedDays = (dates: string[]) =>
  api.post(endpoints.APPOINTMENTS_AVAILABILITY_DELETE_DAYS, { dates });

export const setAvailabilityHoursRangeForSelectedDate = (
  data: PostAvailabilityHoursRangeType,
) => api.post(endpoints.APPOINTMENTS_AVAILABILITY, data);

export const getFutureAvailability = async (
  params: GetFutureAvailabilityParams,
) => {
  const { data } = await api.get<GetFutureAvailabilityResponse>(
    endpoints.FUTURE_AVAILABILITY,
    {
      params,
    },
  );
  return data;
};

export const checkFutureSlots = async (dateFrom: string) =>
  await api.get(endpoints.FUTURE_SLOTS_CHECK, { params: { dateFrom } });

export const getPhysiotherapistSettings = async () => {
  const { data } = await api.get<PhysiotherapistSettingsResponse[]>(
    endpoints.PHYSIOTHERAPIST_SETTINGS,
  );
  return data;
};

export const changePhysiotherapistSettings = (
  id: number,
  data: PhysiotherapistSettingsPatch,
) =>
  api.patch<PhysiotherapistSettingsResponse>(
    endpoints.PHYSIOTHERAPIST_SETTINGS_CHANGE(id),
    data,
  );

export const copyAvailabilityFromSelectedDay = (
  dateCopyFrom: string,
  dateCopyTo: string,
) =>
  api.post(endpoints.COPY_APPOINTMENTS_AVAILABILITY, {
    dateCopyFrom,
    dateCopyTo,
  });

export const getMessagesBlacklist = (userId: number) =>
  api.get(endpoints.BLACKLIST, { params: { blockedId: userId } });

export const addUserToBlacklist = (data: BlacklistData) =>
  api.post<BlacklistResponse>(endpoints.BLACKLIST, data);

export const removeUserFromBlacklist = (data: BlacklistData) =>
  api.post<BlacklistResponse>(endpoints.REMOVE_FROM_BLACKLIST, data);

export const reportPhysiotherapist = (data: UserReport) =>
  api.post<UserReportResponse>(endpoints.REPORT_PHYSIOTHERAPIST, data);

export const reportPatient = (data: UserReport) =>
  api.post<UserReportResponse>(endpoints.REPORT_PATIENT, data);

export const getInvitedTherapists = async () => {
  const { data } = await api.get<InvitedTherapistData[]>(
    endpoints.INVITED_THERAPISTS,
  );
  return data;
};

export const getReward = async () => await api.get(endpoints.REDEEM_REWARD);

export const getRewardStatus = async () =>
  await api.get(endpoints.REWARD_STATUS);
