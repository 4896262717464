import { config } from "./config";
import { ProductName } from "@globalTypes/common.types";

type Flags = {
  PRODUCT_NAME: ProductName;
  FEATURE_APPLE_LOGIN: boolean;
  FEATURE_SMS_VERIFICATION_ENABLED: boolean;
  FEATURE_FREE_SERVICE_ENABLED: boolean;
  FEATURE_FACEBOOK_LOGIN: boolean;
  FEATURE_MEDICAL_RECORDS: boolean;
  FEATURE_BONE_MODEL: boolean;
  FEATURE_GENERAL_MODEL: boolean;
  FEATURE_CONDITIONS: boolean;
  FEATURE_ABOUT_APPLICATION: boolean;
  FEATURE_PERSONAL_NOTES: boolean;
  FEATURE_PREDICTED_PARAMETERS: boolean;
  FEATURE_SURVEY_ENABLED: boolean;
  FEATURE_TRAINING_SELECTION_ASSISTANT: boolean;
  FEATURE_FORUM: boolean;
  FEATURE_GENERAL_WORKOUT_SETS: boolean;
};

export const flags: Flags = {
  PRODUCT_NAME:
    (config.EXPO_PUBLIC_PRODUCT_NAME as ProductName) || ProductName.FIXME,
  FEATURE_APPLE_LOGIN: config.EXPO_PUBLIC_FEATURE_APPLE_LOGIN === "true",
  FEATURE_SMS_VERIFICATION_ENABLED:
    config.EXPO_PUBLIC_FEATURE_SMS_VERIFICATION_ENABLED === "true",
  FEATURE_FREE_SERVICE_ENABLED:
    config.EXPO_PUBLIC_FEATURE_FREE_SERVICE_ENABLED === "true",
  FEATURE_FACEBOOK_LOGIN: config.EXPO_PUBLIC_FEATURE_FACEBOOK_LOGIN === "true",
  FEATURE_MEDICAL_RECORDS:
    config.EXPO_PUBLIC_FEATURE_MEDICAL_RECORDS === "true",
  FEATURE_BONE_MODEL: config.EXPO_PUBLIC_FEATURE_BONE_MODEL_ENABLED === "true",
  FEATURE_GENERAL_MODEL:
    config.EXPO_PUBLIC_FEATURE_GENERAL_MODEL_ENABLED === "true",
  FEATURE_CONDITIONS: config.EXPO_PUBLIC_FEATURE_CONDITIONS_ENABLED === "true",
  FEATURE_ABOUT_APPLICATION:
    config.EXPO_PUBLIC_FEATURE_ABOUT_APPLICATION === "true",
  FEATURE_PERSONAL_NOTES: config.EXPO_PUBLIC_FEATURE_PERSONAL_NOTES === "true",
  FEATURE_PREDICTED_PARAMETERS:
    config.EXPO_PUBLIC_FEATURE_PREDICTED_PARAMETERS === "true",
  FEATURE_SURVEY_ENABLED: config.EXPO_PUBLIC_FEATURE_SURVEY_ENABLED === "true",
  FEATURE_TRAINING_SELECTION_ASSISTANT:
    config.EXPO_PUBLIC_FEATURE_TRAINING_SELECTION_ASSISTANT === "true",
  FEATURE_FORUM: config.EXPO_PUBLIC_FEATURE_FORUM === "true",
  FEATURE_GENERAL_WORKOUT_SETS:
    config.EXPO_PUBLIC_FEATURE_GENERAL_WORKOUT_SETS === "true",
};
