import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  AppbarTitleWithBackAction,
  AppbarTitleWithLogo,
} from "@components/Appbar";
import MyProfilePatient from "@screens/Profiles/MyProfile/MyProfilePatient";
import MyProfilePhysiotherapist from "@screens/Profiles/MyProfile/MyProfilePhysiotherapist";

import { ProfileStackParamsList } from "./navigation.types";

import {
  BuySubscriptionScreen,
  CalendarExamples,
  HumanModelDevSceen,
  TestingComponents,
} from "@screens/DEV";

import AppbarTitleWithLogoForPhysiotherapist from "@components/Appbar/AppbarTitleWithLogoForPhysiotherapist";
import { useUserDetails } from "@hooks/user/useUserDetails";
import RehabStatistics from "@screens/Profiles/MyProfile/RehabStatistics";
import ForumStackNavigator from "./ForumStackNavigator";
import HelpCenterStackNavigator from "./HelpCenterStackNavigator";
import ProfileCompletionStackNavigator from "./ProfileCompletionStackNavigator";
import TransactionHistoryStackNavigator from "./TransactionHistoryStackNavigator";
import RewardsStackNavigator from "./RewardsStackNavigator";
import { useFlags } from "@hooks/useFlags";

const Stack = createNativeStackNavigator<ProfileStackParamsList>();

const MyProfileStackNavigator: FC = () => {
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const {
    flags: { FEATURE_FORUM },
  } = useFlags();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
      }}>
      <Stack.Screen
        name="Profile"
        component={
          isPhysiotherapist ? MyProfilePhysiotherapist : MyProfilePatient
        }
        options={{
          header: () =>
            isPhysiotherapist ? (
              <AppbarTitleWithLogoForPhysiotherapist title={t("T00168")} />
            ) : (
              <AppbarTitleWithLogo title={t("T00168")} />
            ),
        }}
      />
      <Stack.Group
        screenOptions={{
          presentation: "card",
          animation: "slide_from_bottom",
        }}>
        <Stack.Screen
          name="ProfileCompletionStack"
          component={ProfileCompletionStackNavigator}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="HelpCenterStack"
          component={HelpCenterStackNavigator}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TransactionHistoryStack"
          component={TransactionHistoryStackNavigator}
          options={{
            headerShown: false,
          }}
        />
        {isPhysiotherapist && (
          <>
            <Stack.Screen
              name="RehabStatistics"
              component={RehabStatistics}
              options={{
                header: ({ navigation: { goBack } }) => (
                  <AppbarTitleWithBackAction
                    onClose={goBack}
                    title={t("T00678")}
                  />
                ),
              }}
            />
            {FEATURE_FORUM && (
              <Stack.Screen
                name="ForumStack"
                component={ForumStackNavigator}
                options={{
                  headerShown: false,
                }}
              />
            )}
            <Stack.Screen
              name="RewardsStack"
              component={RewardsStackNavigator}
              options={{
                headerShown: false,
              }}
            />
          </>
        )}
      </Stack.Group>
      {__DEV__ && (
        <Stack.Group>
          <Stack.Screen
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name="CalendarExamples"
            component={CalendarExamples}
            options={{
              header: ({ navigation: { goBack } }) => (
                <AppbarTitleWithBackAction
                  onBack={goBack}
                  title="Calendar Examples"
                />
              ),
            }}
          />
          <Stack.Screen
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name="TestingComponents"
            component={TestingComponents}
            options={{
              header: ({ navigation: { goBack } }) => (
                <AppbarTitleWithBackAction
                  onBack={goBack}
                  title="TestingComponents"
                />
              ),
            }}
          />
          <Stack.Screen
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name="HumanModelDevScreen"
            component={HumanModelDevSceen}
            options={{
              header: ({ navigation: { goBack } }) => (
                <AppbarTitleWithBackAction
                  onBack={goBack}
                  title="Human Model"
                />
              ),
            }}
          />
          <Stack.Screen
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name="BuySubscriptionScreen"
            component={BuySubscriptionScreen}
            options={{
              header: ({ navigation: { goBack } }) => (
                <AppbarTitleWithBackAction
                  onBack={goBack}
                  title="Purchase Screen"
                />
              ),
            }}
          />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};

export default MyProfileStackNavigator;
