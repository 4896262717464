import { SvgAssignWorkoutToPatient } from "@assets/svg/tutorials";
import {
  BottomModalWithButton,
  BottomTutorialComponent,
} from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import HumanModel from "@components/HumanModel/HumanModel";
import EquipmentsInTrainingList from "@components/Lists/EquipmentsInTrainingList";
import ExercisesInTrainingList from "@components/Lists/ExercisesInTrainingList";
import ConditionChipsSection from "@components/Training/ConditionChipsSection";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { TransKey } from "@globalTypes/i18next";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  copyLibraryTraining,
  deleteLibraryTraining,
  getLibraryTraining,
} from "@services/ApiService/trainings";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  queryKeysLibraryTrainings,
  queryKeysTraining,
} from "../queryKeysTrainingsAndExercises";
import { TrainingFullDataType } from "../training.types";

type Actions = "delete" | "copy" | "edit";

const LibraryTrainingDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "LibraryTrainingDetails">
  >
> = ({
  route: {
    params: { id },
  },
  navigation: { goBack, navigate },
}) => {
  const { loading, gapLarge, gapMedium, gapSmall } = globalStyles;
  const { t } = useTranslation();
  const [title, setTitle] = useState<TransKey>();
  const [subtitles, setSubtitles] = useState<TransKey[]>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [tutorialModalVisible, setTutorialModalVisible] =
    useState<boolean>(false);
  const [buttons, setButtons] = useState<ButtonBottomSheet[]>([]);
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const { isLoading, isError, refetch, data } = useQuery({
    queryKey: queryKeysTraining.detail(id, { fullData: true }),
    queryFn: async () =>
      await getLibraryTraining<TrainingFullDataType>(id, true),
  });

  const { gender } = useUserDetails();

  const refreshAndGoBack = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysLibraryTrainings.list(),
    });
    goBack();
  };

  const { mutate: deleteTraining, isLoading: isDeleting } = useMutation({
    mutationFn: async () => await deleteLibraryTraining(id),
    onSuccess: refreshAndGoBack,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { mutate: copyTraining, isLoading: isCopying } = useMutation({
    mutationFn: async () => await copyLibraryTraining(id),
    onSuccess: refreshAndGoBack,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const openModal = useCallback(
    (action: Actions) => {
      switch (action) {
        case "delete":
          setTitle("T00283");
          setSubtitles(["T00284"]);
          setButtons([
            {
              label: "T00281",
              onPress: () => deleteTraining(),
              loading: false,
            },
          ]);
          break;

        case "copy":
          setTitle("T00296");
          setSubtitles(["T00297"]);
          setButtons([
            {
              label: "T00282",
              onPress: () => copyTraining(),
              loading: false,
            },
          ]);
          break;

        case "edit":
          setTitle("T00287");
          setSubtitles(["T00288"]);
          setButtons([
            {
              label: "T00286",
              onPress: () => {
                setModalVisible(false);
                setTimeout(
                  () =>
                    navigate("CreateTrainingStack", {
                      screen: "CreateAndEditTraining",
                      params: { id },
                    }),
                  500,
                );
              },
              loading: false,
            },
          ]);
          break;
      }
      setModalVisible(true);
    },
    [deleteTraining, copyTraining, navigate, id],
  );

  useEffect(() => {
    setButtons(prevButtons =>
      prevButtons.map(button => ({
        ...button,
        loading: isDeleting || isCopying,
      })),
    );
  }, [isCopying, isDeleting]);

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  const exercisesIds = data?.exercises.map(({ id }) => id);

  const { name, description, conditionsTotal } = data;
  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
        contentContainerStyle={[{ padding: spacing16 }, gapLarge]}>
        <Text variant="titleLarge">{name}</Text>
        <Divider bold />
        <TutorialTextButton
          label="T00670"
          onPress={() => setTutorialModalVisible(true)}
        />
        <View style={gapMedium}>
          <PrimaryButton
            label="T00281"
            mode="outlined"
            onPress={() => openModal("delete")}
          />
          <PrimaryButton
            label="T00282"
            mode="outlined"
            onPress={() => openModal("copy")}
          />
          <PrimaryButton label="T00069" onPress={() => openModal("edit")} />
        </View>
        <Divider bold horizontalInset />
        {description && (
          <>
            <View style={gapSmall}>
              <Text variant="titleMedium">{t("T00777")}:</Text>
              <Text variant="bodyMedium">{description}</Text>
            </View>
            <Divider bold />
          </>
        )}
        <ConditionChipsSection title="T01482" conditions={conditionsTotal} />
        <Text variant="titleMedium">{t("T00916")}:</Text>
        <HumanModel
          humanBones={data?.humanBonesTotal}
          humanGeneral={data?.humanGeneralTotal}
          humanMuscles={data?.humanMusclesTotal}
          gender={gender}
        />
        <ExercisesInTrainingList
          exercises={exercisesIds}
          onExercisePress={(exerciseId: number) =>
            navigate("ExerciseDetails", { id: exerciseId })
          }
        />
        <EquipmentsInTrainingList selectedEquipment={data?.equipments} />
        <BottomModalWithButton
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          title={title}
          subtitles={subtitles}
          buttons={buttons}
        />
      </ScrollView>
      <BottomModalContainer
        modalVisible={tutorialModalVisible}
        setModalVisible={setTutorialModalVisible}>
        <BottomTutorialComponent
          title="T00673"
          content={[
            {
              text: "T00674",
              svgComponent: () => <SvgAssignWorkoutToPatient />,
            },
          ]}
          onPress={() => setTutorialModalVisible(false)}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default LibraryTrainingDetails;
