import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { TextSectionProps } from "./inviteToTheApp.types";
const TextSection: FC<TextSectionProps> = ({
  title,
  subtitle,
  content,
  additionalText,
  style,
}) => {
  const { t } = useTranslation();
  const { gapMedium, gapLarge } = globalStyles;
  return (
    <View style={[gapLarge]}>
      <View style={[gapMedium, style]}>
        <Text variant="titleLarge">{t(title)}</Text>
        <Text variant="labelMedium">{t(subtitle)}</Text>
        {additionalText}
      </View>
      {content && <Text variant="bodySmall">{t(content)}</Text>}
    </View>
  );
};

export default TextSection;
