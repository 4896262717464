import { LIST } from "@utils/constants";

const keys = {
  therapistsInvited: "therapistsInvited",
  redeemReward: "redeemReward",
  rewardStatus: "rewardStatus",
};

export const queryKeysTherapistsInvited = {
  all: [keys.therapistsInvited] as const,
  list: () => [...queryKeysTherapistsInvited.all, LIST] as const,
};
export const queryKeysRewards = {
  all: [keys.redeemReward] as const,
  rewardStatus: [keys.rewardStatus] as const,
};
