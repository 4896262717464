import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { Trans } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import CounterItem from "./CounterItem";
import { FrameContentParams } from "./inviteToTheApp.types";
import { getSubscriptionExtensionInfo, getNextRewardText } from "./utils";
import useRewardStatus, {
  rewardStatusType,
} from "@hooks/rewards/useRewardStatus";

const ExtendedFrameContent = ({
  allTherapists,
  verifiedTherapists,
  onPress,
}: FrameContentParams) => {
  const { gapLarge } = globalStyles;
  const { countersContainer } = styles;
  const {
    currentlyGrantedPeriod,
    missingVerifiedTherapists,
    nextExpectedPeriod,
  } = getSubscriptionExtensionInfo(verifiedTherapists);
  const { rewardStatus, isLoading } = useRewardStatus();

  return (
    <View style={[gapLarge, { width: "100%" }]}>
      <View style={countersContainer}>
        <CounterItem countValue={allTherapists} counterType="registered" />
        <CounterItem
          countValue={verifiedTherapists}
          counterType="verified"
          highlighted
        />
      </View>
      {!!verifiedTherapists && (
        <Text variant="bodySmall">
          <Trans
            i18nKey="T01556"
            values={{ value: currentlyGrantedPeriod }}
            components={{
              1: (
                <Text style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  {currentlyGrantedPeriod}
                </Text>
              ),
            }}
          />
        </Text>
      )}
      {verifiedTherapists < 7 && (
        <Text
          variant="labelSmall"
          style={verifiedTherapists === 0 && { textAlign: "center" }}>
          {getNextRewardText({
            missingVerifiedTherapists,
            verifiedTherapists,
            nextExpectedPeriod,
          })}
        </Text>
      )}
      <PrimaryButton
        label="T01555"
        onPress={onPress}
        disabled={rewardStatus !== rewardStatusType.AVAILABLE}
        loading={rewardStatus === rewardStatusType.UNDETERMINED && isLoading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  countersContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

export default ExtendedFrameContent;
