import {
  SvgCalendar,
  SvgChat,
  SvgStars,
  SvgStatistics,
  SvgTraining,
  SvgVisit,
} from "@assets/svg/multicolor";
import { Logo } from "@components/Logo";
import InfoTile from "@components/Tile/InfoTile";
import { useAuth } from "@contexts/AuthContext/auth";
import { TransKey } from "@globalTypes/i18next";
import useInvalidateProfileDetailsAndSubscriptionStatus from "@hooks/queryHooks/useInvalidateProfileDetailsAndSubscriptionStatus";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, StyleSheet, View } from "react-native";
import { List, ListItemProps, Text } from "react-native-paper";

const subscriptionItems: Partial<ListItemProps>[] = [
  {
    title: "T00938",
    description: "T00939",
    left: () => <SvgCalendar />,
  },
  {
    title: "T00940",
    description: "T00941",
    left: () => <SvgVisit />,
  },
  {
    title: "T00942",
    description: "T00943",
    left: () => <SvgChat />,
  },
  {
    title: "T00944",
    description: "T00945",
    left: () => <SvgStatistics />,
  },
  {
    title: "T00946",
    description: "T00947",
    left: () => <SvgTraining />,
  },
  {
    title: "T00948",
    description: "T00949",
    left: () => <SvgStars />,
  },
];

type SubscriptionItemsProps = {
  footerHeight?: number;
  isSubActive: boolean;
};

const SubscriptionScreenContent: FC<SubscriptionItemsProps> = ({
  footerHeight,
  isSubActive,
}) => {
  const { scrollContainer, container } = globalStyles;
  const { titleText } = styles;
  const { t } = useTranslation();
  const { updateUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const { invalidateData } = useInvalidateProfileDetailsAndSubscriptionStatus();

  const onRefresh = () => {
    setIsLoading(true);
    try {
      updateUser();
      void invalidateData();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScrollView
      contentContainerStyle={[
        scrollContainer,
        { paddingBottom: footerHeight || spacing16 },
      ]}
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={onRefresh} />
      }>
      <Logo />
      <Text variant="titleLarge" style={titleText}>
        {t("T00937")}
      </Text>
      <InfoTile
        status={isSubActive ? "success" : "info"}
        content={
          <Text>
            {isSubActive
              ? t("T00951")
              : `${t("T01232")} ${isWeb ? `${t("T00964")}` : ""}`}
          </Text>
        }
      />
      <View style={container}>
        {subscriptionItems.map(({ title, description, left }) => (
          <List.Item
            key={`subscription-item-${title}`}
            title={<Text variant="bodyLarge">{t(title as TransKey)}</Text>}
            description={
              <Text variant="bodyMedium">{t(description as TransKey)}</Text>
            }
            titleNumberOfLines={0}
            descriptionNumberOfLines={0}
            left={left}
          />
        ))}
      </View>
    </ScrollView>
  );
};

export default SubscriptionScreenContent;

const styles = StyleSheet.create({
  titleText: {
    textAlign: "center",
    alignSelf: "center",
    width: "75%",
    marginVertical: spacing16,
  },
});
