import { ModelType } from "@components/HumanModel/humanModel.types";
import { GenderType } from "@contexts/AuthContext/user.types";
import { useFlags } from "@hooks/useFlags";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

type UseHumanModelButtonsOptions = {
  humanBones: string[];
  humanMuscles: string[];
  humanGeneral: string[];
};

export const useHumanModelButtons = ({
  humanBones,
  humanGeneral,
  humanMuscles,
}: UseHumanModelButtonsOptions) => {
  const { t } = useTranslation();
  const {
    flags: { FEATURE_BONE_MODEL, FEATURE_GENERAL_MODEL },
  } = useFlags();
  const modelTypeButtons: {
    value: ModelType;
    label: string;
    style?: {
      flexGrow: number;
    };
  }[] = [];

  if (FEATURE_GENERAL_MODEL) {
    modelTypeButtons.push({
      value: ModelType.GENERAL,
      label: `${t("T00388")} ${humanGeneral?.length || ""}`,
    });
  }

  modelTypeButtons.push({
    value: ModelType.MUSCLE,
    label: `${t("T00844")} ${humanMuscles?.length || ""}`,
    style: { flexGrow: humanMuscles?.length > 1 ? 2 : 1 },
  });

  if (FEATURE_BONE_MODEL) {
    modelTypeButtons.push({
      value: ModelType.BONE,
      label: `${t("T00843")} ${humanBones?.length || ""}`,
    });
  }

  const genderButtons = [
    {
      value: GenderType.MALE,
      label: capitalize(t("T00054")),
    },
    {
      value: GenderType.FEMALE,
      label: capitalize(t("T00055")),
    },
  ];
  return { modelTypeButtons, genderButtons };
};
