import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, RefreshControl, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { FetchError } from "@components/errors";
import { globalStyles } from "@styles/global";

import { ProphylacticTrainingCategoryType } from "../training.types";
import ProphylacticCategoryTile from "@components/Tile/training/ProphylacticCategoryTile";
import {
  queryKeysProphylacticWorkoutsSetCategories,
  queryKeysWorkoutCategories,
} from "../queryKeysTrainingsAndExercises";
import {
  getWorkoutCategories,
  getWorkoutSetCategories,
} from "@services/ApiService/trainings";
import { AllPatientTrainingsTabsProps } from "@navigators/navigation.types";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { spacing32 } from "@styles/spacing";
import { Tile } from "@components/Tile";
import { palettes } from "@styles/colors";
import { theme } from "@styles/theme";
import { WorkoutSetCategory } from "@components/Tile/training/prophylacticSets.type";
import { WorkoutSetTile } from "@components/Tile/training/WorkoutSetTile";
import { useFlags } from "@hooks/useFlags";

const GeneralWorkoutCategories: FC<
  PropsWithChildren<AllPatientTrainingsTabsProps>
> = ({ navigation: { navigate } }) => {
  const { t } = useTranslation();
  const {
    flags: { FEATURE_GENERAL_WORKOUT_SETS },
  } = useFlags();
  const {
    data: workoutCategories,
    isLoading: workoutCategoriesLoading,
    isError: workoutCategoriesError,
    refetch: refetchWorkoutCategories,
  } = useQuery({
    queryKey: queryKeysWorkoutCategories.list(),
    queryFn: getWorkoutCategories,
  });
  const {
    data: workoutSetCategories,
    isLoading: workoutSetsLoading,
    isError: workoutSetsError,
    refetch: refetchWorkoutSets,
  } = useQuery({
    queryKey: queryKeysProphylacticWorkoutsSetCategories.list(),
    queryFn: async () => await getWorkoutSetCategories(),
  });
  const {
    flags: { FEATURE_TRAINING_SELECTION_ASSISTANT },
  } = useFlags();

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "first", title: t("T00295") },
    { key: "second", title: t("T01059") },
  ]);
  const { width } = useWindowDimensions();
  const { loading, scrollContainer, gapLarge } = globalStyles;

  const renderTraining: ListRenderItem<ProphylacticTrainingCategoryType> =
    useCallback(
      ({ item: { id } }) => (
        <ProphylacticCategoryTile
          trainingId={id}
          onPress={() => navigate("GeneralWorkoutCategory", { id })}
        />
      ),
      [navigate],
    );

  const renderTrainingSet = useCallback(
    ({ item: { id } }: { item: { id: number } }) => (
      <WorkoutSetTile
        id={id}
        onPress={(data: WorkoutSetCategory) =>
          navigate("GeneralWorkoutSets", { data })
        }
      />
    ),
    [navigate],
  );

  const commonFlatListProps = useMemo(
    () => ({
      ListEmptyComponent: <Text variant="bodyMedium">{t("T00193")}</Text>,
      ListHeaderComponent: FEATURE_TRAINING_SELECTION_ASSISTANT ? (
        <Tile
          tileType="assistant"
          mode="outlined"
          onPress={() => navigate("TrainingSelectionAssistantStack")}
        />
      ) : null,
      contentContainerStyle: [
        scrollContainer,
        gapLarge,
        { paddingBottom: spacing32 },
      ],
    }),
    [
      FEATURE_TRAINING_SELECTION_ASSISTANT,
      gapLarge,
      navigate,
      scrollContainer,
      t,
    ],
  );

  const FirstRoute = useCallback(
    () => (
      <View style={{ flex: 1 }}>
        <FlatList
          {...commonFlatListProps}
          refreshControl={
            <RefreshControl
              refreshing={workoutCategoriesLoading}
              onRefresh={refetchWorkoutCategories}
            />
          }
          data={workoutCategories}
          keyExtractor={({ id }) => `training-${id}`}
          renderItem={renderTraining}
        />
      </View>
    ),
    [
      commonFlatListProps,
      refetchWorkoutCategories,
      renderTraining,
      workoutCategories,
      workoutCategoriesLoading,
    ],
  );

  const SecondRoute = useCallback(
    () => (
      <View style={{ flex: 1 }}>
        <FlatList
          {...commonFlatListProps}
          refreshControl={
            <RefreshControl
              refreshing={workoutSetsLoading}
              onRefresh={refetchWorkoutSets}
            />
          }
          data={workoutSetCategories}
          keyExtractor={({ id }) => `training-set-${id}`}
          renderItem={renderTrainingSet}
        />
      </View>
    ),
    [
      commonFlatListProps,
      refetchWorkoutSets,
      renderTrainingSet,
      workoutSetCategories,
      workoutSetsLoading,
    ],
  );

  const renderScene = useMemo(
    () =>
      SceneMap({
        first: FirstRoute,
        second: SecondRoute,
      }),
    [FirstRoute, SecondRoute],
  );

  const renderTabBar = props => {
    return (
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: palettes.primary[40] }}
        style={{ backgroundColor: theme.colors.surface }}
        renderLabel={({ route, focused }) => (
          <Text
            style={{ color: palettes.primary[focused ? 40 : 0] }}
            variant="titleSmall">
            {route.title}
          </Text>
        )}
      />
    );
  };

  const isAnyError = useMemo(
    () => workoutSetsError || workoutCategoriesError,
    [workoutSetsError, workoutCategoriesError],
  );
  const isAnyLoading = useMemo(
    () => workoutCategoriesLoading || workoutSetsLoading,
    [workoutCategoriesLoading, workoutSetsLoading],
  );

  const refetchEverything = useCallback(async () => {
    await Promise.all([refetchWorkoutSets(), refetchWorkoutCategories()]);
  }, [refetchWorkoutSets, refetchWorkoutCategories]);

  if (isAnyLoading) return <ActivityIndicator style={loading} />;
  if (isAnyError) return <FetchError action={refetchEverything} />;

  return (
    <View
      style={{
        flex: 1,
      }}>
      {!FEATURE_GENERAL_WORKOUT_SETS ? (
        <FirstRoute />
      ) : (
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={{ width }}
          renderTabBar={renderTabBar}
        />
      )}
    </View>
  );
};

export default GeneralWorkoutCategories;
