import { PrimaryButton } from "@components/buttons";
import { Input } from "@components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import {
  createCustomMessageTemplate,
  editCustomMessageTemplate,
} from "@services/ApiService/conversations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  MESSAGE_TEMPLATE_NAME_MAX_LENGTH,
  MULTILINE_INPUT_MAX_LENGTH,
} from "@utils/constants";
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { queryKeysConversations } from "../queryKeysMessageCenter";
import { AxiosError } from "axios";
import {
  CreateCustomMessageTemplateType,
  CustomMessageTemplateType,
} from "./conversation.types";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { useUserDetails } from "@hooks/index";

type CreateOrEditTemplateBottomSheetProps = {
  bottomSheetVisible: boolean;
  setBottomSheetVisible: Dispatch<SetStateAction<boolean>>;
  customMessageTemplateData?: CustomMessageTemplateType;
};

const CreateOrEditTemplateBottomSheet: FC<
  CreateOrEditTemplateBottomSheetProps
> = ({
  bottomSheetVisible,
  setBottomSheetVisible,
  customMessageTemplateData,
}) => {
  const { t } = useTranslation();
  const schema = object().shape({
    name: string()
      .max(MESSAGE_TEMPLATE_NAME_MAX_LENGTH)
      .required(t("T00014"))
      .trim(),
    description: string()
      .max(MULTILINE_INPUT_MAX_LENGTH)
      .required(t("T00014"))
      .trim(),
  });

  const { id: physioId } = useUserDetails();

  const { setErrorsFromResponse, errors } = useErrors();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (customMessageTemplateData) {
      setValue("name", customMessageTemplateData?.name);
      setValue("description", customMessageTemplateData?.description);
    }
  }, [customMessageTemplateData, setValue]);

  const queryClient = useQueryClient();

  const onCreateOrEditSuccess = useCallback(async () => {
    setBottomSheetVisible(false);
    await queryClient.invalidateQueries(queryKeysConversations.templatesList());
  }, [queryClient, setBottomSheetVisible]);

  const { mutate: editTemplate, isLoading: isEditTemplateLoading } =
    useMutation({
      mutationFn: editCustomMessageTemplate,
      onSuccess: onCreateOrEditSuccess,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const { mutate: createTemplate, isLoading: isCreateTemplateLoading } =
    useMutation({
      mutationFn: createCustomMessageTemplate,
      onSuccess: onCreateOrEditSuccess,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const onSubmit = useCallback(
    (data: CreateCustomMessageTemplateType) => {
      if (customMessageTemplateData) {
        const editData = {
          ...data,
          id: customMessageTemplateData?.id,
          author: physioId,
        };
        editTemplate(editData);
      } else {
        createTemplate(data);
      }
    },
    [createTemplate, customMessageTemplateData, editTemplate, physioId],
  );

  return (
    <BottomModalContainer
      modalVisible={bottomSheetVisible}
      setModalVisible={setBottomSheetVisible}>
      <>
        <Input
          name="name"
          label="T01601"
          control={control as unknown as Control<FieldValues>}
          errors={errors?.name}
          maxLength={MESSAGE_TEMPLATE_NAME_MAX_LENGTH}
          isInBottomSheet
        />
        <Input
          name="description"
          label="T01602"
          control={control as unknown as Control<FieldValues>}
          multiline
          errors={errors?.description}
          maxLength={MULTILINE_INPUT_MAX_LENGTH}
          isInBottomSheet
        />
        <PrimaryButton
          label="T00164"
          onPress={handleSubmit(onSubmit)}
          loading={isCreateTemplateLoading || isEditTemplateLoading}
          disabled={isCreateTemplateLoading || isEditTemplateLoading}
        />
      </>
    </BottomModalContainer>
  );
};

export default CreateOrEditTemplateBottomSheet;
