import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "@styles/theme";
import Exercises from "./Physiotherapist/Exercises";
import Trainings from "./Physiotherapist/Trainings";
import { TrainingsAndExercisesTabsParamList } from "./tabsParamsList.types";
import { fontConfig } from "@styles/fontConfig";

const Tab = createMaterialTopTabNavigator<TrainingsAndExercisesTabsParamList>();

type TrainingsAndExercisesCommonContentProps = {
  patientId?: number;
  resetProcess?: boolean;
};

const TrainingsAndExercisesCommonContent: FC<
  PropsWithChildren<TrainingsAndExercisesCommonContentProps>
> = ({ patientId, resetProcess }) => {
  const { t } = useTranslation();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.surface,
        },
        tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
      }}>
      <Tab.Screen
        initialParams={{ patientId, resetProcess }}
        name="Trainings"
        component={Trainings}
        options={{ title: t("T00167") }}
      />
      <Tab.Screen
        name="Exercises"
        component={Exercises}
        options={{ title: t("T00169") }}
      />
    </Tab.Navigator>
  );
};

export default TrainingsAndExercisesCommonContent;
