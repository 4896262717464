import { yupResolver } from "@hookform/resolvers/yup";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ActivityIndicator, Text } from "react-native-paper";

import { AppbarTitleWithBackAction } from "@components/Appbar";
import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import { AttachedFilesSection } from "@components/Files";
import AddAttachmentBottomSheet from "@components/Files/AddAttachmentBottomSheet";
import { RadioButtonsGroup } from "@components/FormElements";
import HumanModelWithController from "@components/HumanModel/HumanModelWithController";
import { Input } from "@components/Input";
import PainTileWithController from "@components/Tile/PainTileWithController";
import { PrimaryButton } from "@components/buttons";
import { UserCard } from "@components/cards";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { GenderType } from "@contexts/AuthContext/user.types";
import { booleanButtons } from "@frontendData/boolean_buttons";
import { FileAttachment } from "@globalTypes/common.types";
import { useHumanModelSchemas } from "@hooks/humanModel/useHumanModelSchemas";
import { useElementHeight } from "@hooks/index";
import { useResources } from "@hooks/media/useResources/useResources";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { postFile } from "@services/ApiService/common";
import { endpoints } from "@services/ApiService/endpoints";
import {
  createMedicalRecordAsPatient,
  createMedicalRecordAsPhysio,
  editMedicalRecord,
  getMedicalRecord,
} from "@services/ApiService/medicalRecords";
import { getPatientBasicData } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { INPUT_MAX_LENGTH, MULTILINE_INPUT_MAX_LENGTH } from "@utils/constants";
import { AxiosError } from "axios";
import {
  Control,
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PostMedicalRecords } from "./MedicalRecords.types";
import { queryKeysMedicalRecord } from "./queryKeysMedicalRecord";

const maxNumberOfFiles = 5;

const CreateAndEditRecord: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "CreateAndEditRecord">
  >
> = ({ navigation: { goBack, setOptions, pop }, route: { params } }) => {
  const {
    user: { gender },
  } = useAuth();
  const recordId = params?.id;
  const patientId = params?.patientId;
  const editionMode = !!recordId;
  const [modalVisible, setModalVisible] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);

  const { setErrorsFromResponse, errors, clearErrors } = useErrors();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isPhysiotherapist } = useUserDetails();
  const [createWithPatientContext, setCreateWithPatientContext] =
    useState(false);
  const [addAttachmentBottomSheetVisible, setAddAttachmentBottomSheetVisible] =
    useState(false);

  const { onElementLayout: onInputLayout, elementHeight: inputHeight } =
    useElementHeight();

  const {
    pickFilesFromDevice,
    isLoading: isPickedFileLoading,
    removeFile,
    pickedFiles,
    maxNumberOfFilesAlert,
  } = useResources({
    multiselect: true,
  });

  const { createdByMeList, recordDetails } = queryKeysMedicalRecord;
  const { loading, scrollContainer, gapLarge } = globalStyles;

  const {
    data: medicalRecordData,
    isLoading: isGetMedicalRecordLoading,
    isError: isGetMedicalRecordError,
    refetch: getMedicalRecordRefetch,
  } = useQuery({
    queryKey: recordDetails(recordId),
    queryFn: async () => await getMedicalRecord(recordId),
    enabled: editionMode,
  });

  const {
    data: patientData,
    isLoading: patientDataLoading,
    isError: patientDataError,
    refetch: refetchPatientData,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(patientId),
    queryFn: async () => await getPatientBasicData(patientId),
    enabled: createWithPatientContext,
  });

  const { medicalRecordSchema } = useHumanModelSchemas();

  const scrollRef = useRef<KeyboardAwareScrollView>(null);
  const scrollToY = (y: number) => scrollRef.current.scrollToPosition(0, y);

  const [recordTitleScrollY, setRecordTitleScrollY] = useState(0);
  const [recordHumanModelScrollY, setRecordHumanModelScrollY] = useState(0);
  const [recordDescriptionScrollY, setRecordDescriptionScrollY] = useState(0);
  const [recordProcessingCheckboxScrollY, setRecordProcessingCheckboxScrollY] =
    useState(0);

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(medicalRecordSchema),
    defaultValues: {
      title: medicalRecordData?.title,
      description: medicalRecordData?.description,
      painLevel: medicalRecordData ? medicalRecordData.painLevel : 0,
      previouslyTreated: medicalRecordData?.hadBeenCured ? "Yes" : "No",
      gender: medicalRecordData?.gender,
      humanBones: medicalRecordData?.humanBones || [],
      humanMuscles: medicalRecordData?.humanMuscles || [],
      humanGeneral: medicalRecordData?.humanGeneral || [],
      attachments: medicalRecordData?.attachments || [],
    },
    shouldFocusError: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attachments",
  });

  const humanBonesField = watch("humanBones");
  const humanMusclesField = watch("humanMuscles");
  const humanGeneralField = watch("humanGeneral");

  const createWithPatientCondition = useCallback(
    () => (createWithPatientContext ? pop(2) : goBack()),
    [createWithPatientContext, goBack, pop],
  );

  const refreshQuery = async () => {
    await Promise.all([
      queryClient.invalidateQueries(recordDetails(recordId)),
      queryClient.invalidateQueries(createdByMeList()),
    ]);
  };

  const refreshAndGoBack = async () => {
    await refreshQuery();
    createWithPatientCondition();
  };

  const { mutate: addAttachment, isLoading: isAddAttachmentLoading } =
    useMutation({
      mutationFn: postFile,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: ({ id, name, file }) => {
        if (fields.length >= maxNumberOfFiles) return;
        append({ name, id, file });
      },
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: PostMedicalRecords<number>) =>
      await (editionMode
        ? editMedicalRecord(recordId, data)
        : createWithPatientContext
        ? createMedicalRecordAsPhysio(data, patientId)
        : createMedicalRecordAsPatient(data)),
    onSuccess: refreshAndGoBack,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  useEffect(() => {
    if (pickedFiles?.length) {
      setAddAttachmentBottomSheetVisible(false);
      const howManyCanBeAdded = maxNumberOfFiles - fields.length;
      pickedFiles.length >= howManyCanBeAdded && maxNumberOfFilesAlert();
      pickedFiles.forEach(({ name, uri }, index) => {
        if (index < howManyCanBeAdded) {
          addAttachment({
            file: { name, uri },
            endpoint: endpoints.MEDICAL_RECORDS_FILES,
          });
        }
        removeFile(uri);
      });
    }
  }, [
    addAttachment,
    fields.length,
    maxNumberOfFilesAlert,
    pickedFiles,
    removeFile,
  ]);

  useEffect(
    () =>
      setOptions({
        header: () => (
          <AppbarTitleWithBackAction
            title={editionMode ? t("T00082") : t("T00081")}
            onClose={() => setModalVisible(true)}
          />
        ),
      }),
    [editionMode, setOptions, t],
  );

  useEffect(() => {
    setValue(
      "gender",
      editionMode ? medicalRecordData?.gender : patientData?.gender || gender,
    );
  }, [
    gender,
    setValue,
    editionMode,
    medicalRecordData?.gender,
    patientData?.gender,
  ]);

  useEffect(() => {
    setCreateWithPatientContext(isPhysiotherapist && !!patientId);
  }, [isPhysiotherapist, patientId]);

  const isAnyLoading = useMemo(
    () =>
      (isGetMedicalRecordLoading && editionMode) ||
      (patientDataLoading && createWithPatientContext),
    [
      isGetMedicalRecordLoading,
      editionMode,
      patientDataLoading,
      createWithPatientContext,
    ],
  );

  const selectFileDisabled = useMemo(
    () =>
      isLoading || isPickedFileLoading || fields?.length >= maxNumberOfFiles,
    [fields?.length, isLoading, isPickedFileLoading],
  );

  const isAnyError = useMemo(
    () => isGetMedicalRecordError || patientDataError,
    [isGetMedicalRecordError, patientDataError],
  );
  const refetchEverything = useCallback(
    async () =>
      await Promise.all([refetchPatientData(), getMedicalRecordRefetch()]),
    [refetchPatientData, getMedicalRecordRefetch],
  );

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      if (formErrors?.title?.message) {
        return scrollToY(recordTitleScrollY);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (formErrors?.[""]?.message) {
        return scrollToY(recordHumanModelScrollY);
      }
      if (formErrors?.description?.message) {
        return scrollToY(recordDescriptionScrollY);
      }
      if (formErrors?.processingCheckbox?.message) {
        return scrollToY(recordProcessingCheckboxScrollY);
      }
    }
  }, [
    formErrors,
    recordHumanModelScrollY,
    recordDescriptionScrollY,
    recordProcessingCheckboxScrollY,
    recordTitleScrollY,
  ]);

  const onSubmit = useCallback(
    (data: PostMedicalRecords<FileAttachment>) => {
      const attachments = data?.attachments?.map(({ id }) => id);
      mutate({ ...data, attachments });
    },
    [mutate],
  );
  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00347",
        onPress: createWithPatientCondition,
        mode: "outlined",
        loading: false,
      },
      {
        label: "T00986",
        onPress: handleSubmit(onSubmit),
        loading: isLoading,
      },
    ],
    [createWithPatientCondition, handleSubmit, isLoading, onSubmit],
  );

  if (isAnyLoading) return <ActivityIndicator style={loading} />;
  if (isAnyError) return <FetchError action={refetchEverything} />;

  return (
    <KeyboardAwareScrollView
      ref={scrollRef}
      contentContainerStyle={[
        scrollContainer,
        gapLarge,
        { justifyContent: "space-between" },
      ]}
      scrollEnabled={scrollEnabled}
      enableOnAndroid
      extraHeight={inputHeight}>
      <View style={gapLarge}>
        {createWithPatientContext && (
          <UserCard
            initialData={{ id: patientId }}
            mode="outlined"
            disableHeightAndWeight
          />
        )}
        <View onLayout={e => setRecordTitleScrollY(e.nativeEvent.layout.y)}>
          <Input
            name="title"
            key="title"
            label="T00320"
            control={control as unknown as Control}
            errors={errors?.title}
            maxLength={INPUT_MAX_LENGTH}
            multiline
          />
        </View>
        <View
          onLayout={e => {
            setRecordHumanModelScrollY(e.nativeEvent?.layout.y);
          }}>
          <Text variant="titleMedium">{t("T00294")}:</Text>
          <HumanModelWithController
            gender={watch("gender") as GenderType}
            onGenderChange={(value: GenderType) => setValue("gender", value)}
            humanBones={humanBonesField}
            humanMuscles={humanMusclesField}
            humanGeneral={humanGeneralField}
            control={control as unknown as Control}
            errors={
              (formErrors as {
                [x: string]: FieldError[];
              }) || errors
            }
            clearErrors={clearErrors}
          />
        </View>
        <View
          onLayout={e => {
            setRecordDescriptionScrollY(e.nativeEvent?.layout.y);
            onInputLayout(e);
          }}>
          <Input
            name="description"
            label="T00285"
            control={control as unknown as Control}
            multiline
            errors={errors?.description}
            maxLength={MULTILINE_INPUT_MAX_LENGTH}
          />
          <Text variant="bodyMedium">{t("T00855")}</Text>
        </View>
        <View>
          <PainTileWithController
            name="painLevel"
            control={control as unknown as Control}
            setScrollEnabled={setScrollEnabled}
            errors={errors?.painLevel}
          />
        </View>
        <Controller
          control={control}
          name="previouslyTreated"
          render={({ field: { onChange, value } }) => (
            <RadioButtonsGroup
              errorMessage={formErrors?.previouslyTreated?.message}
              onChange={onChange}
              value={value}
              data={booleanButtons}
              title="T00573"
            />
          )}
        />
        <AttachedFilesSection
          title="T00596"
          emptyListInfo="T00597"
          listData={fields as FileAttachment[]}
          onPressButton={() => setAddAttachmentBottomSheetVisible(true)}
          onListItemRemove={remove}
          loading={isAddAttachmentLoading}
          disabled={selectFileDisabled}
        />
        <View
          onLayout={e =>
            setRecordProcessingCheckboxScrollY(e.nativeEvent.layout.y)
          }>
          <CheckboxSimpleText
            name="processingCheckbox"
            control={control as never as Control}
            text={isPhysiotherapist ? "T01390" : "T01391"}
          />
        </View>
        <PrimaryButton
          label="T00985"
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={isLoading || isAddAttachmentLoading}
        />
      </View>
      <BottomModalWithButton
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        buttons={buttons}
        title="T00345"
        subtitles={["T00346"]}
      />
      <AddAttachmentBottomSheet
        visible={addAttachmentBottomSheetVisible}
        setVisible={setAddAttachmentBottomSheetVisible}
        pickFilesFromDevice={pickFilesFromDevice}
      />
    </KeyboardAwareScrollView>
  );
};

export default CreateAndEditRecord;
