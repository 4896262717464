import { TransKey } from "@globalTypes/i18next";
import { t } from "i18next";

type PeriodsParams = {
  currentlyGrantedPeriod: TransKey;
  missingVerifiedTherapists: number;
  nextExpectedPeriod: TransKey;
};

export const getSubscriptionExtensionInfo = (
  numberOfVerifiedTherapists: number,
) => {
  const periods: PeriodsParams[] = [
    {
      currentlyGrantedPeriod: "T01557",
      missingVerifiedTherapists: 1,
      nextExpectedPeriod: "T01558",
    },
    {
      currentlyGrantedPeriod: "T01558",
      missingVerifiedTherapists: 1,
      nextExpectedPeriod: "T01559",
    },
    {
      currentlyGrantedPeriod: "T01559",
      missingVerifiedTherapists: 1,
      nextExpectedPeriod: "T01560",
    },
    {
      currentlyGrantedPeriod: "T01560",
      missingVerifiedTherapists: 1,
      nextExpectedPeriod: "T01561",
    },
    {
      currentlyGrantedPeriod: "T01561",
      missingVerifiedTherapists: 3,
      nextExpectedPeriod: "T01562",
    },
    {
      currentlyGrantedPeriod: "T01561",
      missingVerifiedTherapists: 2,
      nextExpectedPeriod: "T01562",
    },
    {
      currentlyGrantedPeriod: "T01561",
      missingVerifiedTherapists: 1,
      nextExpectedPeriod: "T01562",
    },
    {
      currentlyGrantedPeriod: "T01562",
      missingVerifiedTherapists: 0,
      nextExpectedPeriod: undefined,
    },
  ];

  const index = Math.min(numberOfVerifiedTherapists, periods.length - 1);
  const {
    currentlyGrantedPeriod,
    missingVerifiedTherapists,
    nextExpectedPeriod,
  } = periods[index];

  return {
    currentlyGrantedPeriod: t(currentlyGrantedPeriod),
    missingVerifiedTherapists,
    nextExpectedPeriod: nextExpectedPeriod ? t(nextExpectedPeriod) : undefined,
  };
};

type GetNextRewardTextType = {
  verifiedTherapists: number;
  missingVerifiedTherapists: number;
  nextExpectedPeriod: string;
};

export const getNextRewardText = ({
  verifiedTherapists,
  missingVerifiedTherapists,
  nextExpectedPeriod,
}: GetNextRewardTextType) => {
  if (verifiedTherapists > 7) return null;
  if (verifiedTherapists === 0) return t("T01563");

  if (missingVerifiedTherapists === 1)
    return t("T01578", {
      nextExpectedPeriod,
    });
  return t("T01564", {
    missingVerifiedTherapists,
    nextExpectedPeriod,
  });
};
