import { SvgLogoSmall } from "@assets/svg";
import { spacing4 } from "@styles/spacing";
import { AVATAR_SIZE } from "@utils/constants";
import { Image, ImageProps } from "expo-image";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { AvatarTextProps } from "react-native-paper";

type ImageForTileProps = Partial<AvatarTextProps> & {
  image?: string;
  displayExtraIcon?: boolean;
};

const ImageForTile: FC<ImageForTileProps> = ({
  image,
  displayExtraIcon = false,
}) => (
  <View>
    <Image
      source={
        image
          ? {
              uri: image,
            }
          : (require("@assets/images/default_avatar.png") as ImageProps["source"])
      }
      style={{
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        borderRadius: spacing4,
      }}
    />
    {displayExtraIcon && <SvgLogoSmall style={styles.logo} />}
  </View>
);

export default ImageForTile;

const styles = StyleSheet.create({
  logo: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});
