import { Dispatch, SetStateAction } from "react";
import { ZoomClient, MediaStream } from "zoom/utils/types.web";

export type DataForContextType = {
  sessionEnabled?: boolean;
  displayInFloatingView?: boolean;
  containerSizes?: {
    height: number;
    width: number;
  };
  navigateTo?: string;
  appointmentId?: number;
  navigateData?: {
    id?: number;
  };
  cameraBlocked?: boolean;
  sessionToken?: string;
};

export type MediaContext = {
  audio: {
    encode: boolean;
    decode: boolean;
  };
  video: {
    encode: boolean;
    decode: boolean;
  };
  share: {
    encode: boolean;
    decode: boolean;
  };
  mediaStream: MediaStream | null;
};

export type AppointmentContextType = {
  data: DataForContextType;
  updateData: (data: DataForContextType) => void;
  setData: Dispatch<SetStateAction<DataForContextType>>;
  zmClient?: ZoomClient;
  mediaContext?: MediaContext;
  setMediaContext?: Dispatch<SetStateAction<MediaContext>>;
  isTabsVisible?: boolean;
  setIsTabsVisible?: Dispatch<SetStateAction<boolean>>;
};

export const initialSessionData: DataForContextType = {
  sessionEnabled: false,
  displayInFloatingView: false,
  containerSizes: { height: 0, width: 0 },
  navigateTo: null,
  appointmentId: null,
  navigateData: null,
  cameraBlocked: null,
};
