import { FC } from "react";
import { View } from "react-native";

import { PickerItem } from "./Item";

import { DateArrayType } from "../Calendar";

type Props = {
  data: Date[];
  multiselect: boolean;
  currentYear: number;
  currentMonth: number;
  selectedDays: DateArrayType[];
  datesWithEvents?: DateArrayType[];
  setSelectedDays: (dates: DateArrayType[]) => void;
  setCurrentDate: (data: DateArrayType) => void;
  setOwnCurrentDate: (date: Date) => void;
  onSelectDate: (data: DateArrayType, dontClose?: boolean) => void;
  selectedDate: DateArrayType;
  setSelectedDate: (data: DateArrayType) => void;
  shouldDisableDays?: boolean;
  type: "week" | "month";
  blockPast?: boolean;
  enableOnPressOnlyWithEvents?: boolean;
};

export const PickerWeek: FC<Props> = ({
  data,
  multiselect,
  currentMonth,
  selectedDays,
  datesWithEvents,
  selectedDate,
  blockPast,
  enableOnPressOnlyWithEvents = false,
  ...props
}) => {
  const actualDate = new Date();
  actualDate.setHours(0, 0, 0, 0);

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
      {data.map((el, it) => {
        const actualMonth = actualDate.getMonth();
        const actualDay = actualDate.getDate();
        const actualYear = actualDate.getFullYear();

        const elDate = el?.getDate() || null;

        let isCorrectDay: boolean = null;
        let localShouldDisableDays: boolean = null;

        if (multiselect && elDate !== null) {
          isCorrectDay = selectedDays.some(({ date }) => {
            const firstDay = new Date(date);
            firstDay.setHours(0, 0, 0, 0);

            return firstDay.getTime() === el?.getTime();
          });
        } else {
          isCorrectDay = selectedDate.date
            ? selectedDate.date.getTime() === el?.getTime()
            : actualDate.getTime() === el?.getTime();
        }

        const thisDayMonth = el?.getMonth();
        const thisDayYear = el?.getFullYear();
        const thisDayYearIsActualYear = thisDayYear === actualYear;

        if (blockPast) {
          if (
            thisDayYear < actualYear ||
            (thisDayYearIsActualYear && thisDayMonth < actualMonth) ||
            (thisDayYearIsActualYear &&
              thisDayMonth === actualMonth &&
              el?.getDate() < actualDay)
          ) {
            localShouldDisableDays = true;
          }
        }

        let dayHaveEvent: DateArrayType = null;

        if (elDate !== null) {
          datesWithEvents?.some(element => {
            const eventDate = new Date(element.date);
            eventDate.setHours(0, 0, 0, 0);

            if (eventDate.getTime() === el?.getTime()) {
              dayHaveEvent = element;
            }
          });
        }

        return (
          <PickerItem
            key={`month-${currentMonth}-day-${el}-${it}`}
            currentMonth={currentMonth}
            dayHaveEvent={dayHaveEvent}
            isCorrectDay={isCorrectDay}
            data={el}
            multiselect={multiselect}
            selectedDays={selectedDays}
            actualYear={actualYear}
            actualMonth={actualMonth}
            actualDay={actualDay}
            selectedDate={selectedDate}
            calculatedShouldDisableDays={localShouldDisableDays}
            disableOnPress={enableOnPressOnlyWithEvents && !dayHaveEvent}
            {...props}
          />
        );
      })}
    </View>
  );
};
