import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { CustomCarousel } from "./CustomCarousel";
import { onBoardingData } from "@frontendData/onboarding_slider";
import { isWeb } from "@utils/constants";

export const OnBoardingSlider = () => {
  const { height } = useWindowDimensions();
  return (
    <CustomCarousel
      data={onBoardingData}
      carouselStyle={{ height: isWeb ? height / 1.8 : height / 1.75 }}
    />
  );
};
