import {
  SvgAddCircleOutline,
  SvgCalendar,
  SvgCancel,
  SvgCancel2,
  SvgCheckCircleSmall,
  SvgClock,
  SvgError,
  SvgErrorOutline,
  SvgLocation,
  SvgLocationInProgress,
  SvgVideoChat,
  SvgVideoChatInProgress,
} from "@assets/svg";
import { TransKey } from "@globalTypes/i18next";
import {
  RehabVerificationProgressResponse,
  VerificationStatus,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { TransactionStatus } from "@screens/Profiles/Transactions/transaction.types";
import { theme } from "@styles/theme";
import { flags } from "@utils/flags";
import i18n from "i18next";
import { ReactElement } from "react";
import { StatusType, TrainingDayStatus } from "./status.types";
import { PickerItem } from "@components/DropdownPicker/DropdownPicker";
import { SERVICE_SLOT_TIME } from "@utils/constants";

export const getStatusIconAndText = (
  status:
    | StatusType
    | VerificationStatus
    | TransactionStatus
    | TrainingDayStatus,
) => {
  if (!status || typeof status !== "string")
    return { icon: null, text: "" } as { icon: JSX.Element; text: string };
  let icon: JSX.Element, text: TransKey;
  const { t } = i18n;

  switch (status.toLowerCase()) {
    case StatusType.CANCELED:
      icon = <SvgCancel2 />;
      text = "T01174";
      break;
    case StatusType.PATIENT_ABSENT:
      icon = <SvgCancel />;
      text = "T01151";
      break;
    case VerificationStatus.PENDING.toLowerCase():
      icon = <SvgClock />;
      text = "T00132";
      break;
    case VerificationStatus.REJECTED.toLowerCase():
    case TrainingDayStatus.NOT_FINISHED:
      icon = <SvgCancel />;
      text = "T00124";
      break;
    case VerificationStatus.VERIFIED.toLowerCase():
    case TransactionStatus.SUCCEEDED.toLowerCase():
    case TransactionStatus.AVAILABLE.toLowerCase():
    case TrainingDayStatus.FINISHED:
      icon = <SvgCheckCircleSmall />;
      text = "T00128";
      break;
    case StatusType.STATIONARY:
      icon = <SvgLocation />;
      text = "T00127";
      break;
    case StatusType.DURING_THE_DURATION_STATIONARY:
      icon = <SvgLocationInProgress />;
      text = "T00134";
      break;
    case StatusType.STATIONARY_ENDED:
      icon = <SvgLocation />;
      text = "T00135";
      break;
    case StatusType.ONLINE:
      icon = <SvgVideoChat />;
      text = "T00126";
      break;
    case StatusType.DURING_THE_DURATION_ONLINE:
      icon = <SvgVideoChatInProgress />;
      text = "T00136";
      break;
    case StatusType.NOT_PAID:
      icon = <SvgError fill={theme.colors.warning} />;
      text = "T00810";
      break;
    case StatusType.ONLINE_ENDED:
      icon = <SvgVideoChat />;
      text = "T00137";
      break;
    case (StatusType.SCHEDULED, TrainingDayStatus.SCHEDULED):
      icon = <SvgCalendar />;
      text = "T00125";
      break;
    case TransactionStatus.PENDING.toLowerCase():
      icon = <SvgClock />;
      text = "T00133";
      break;
    case TransactionStatus.CANCELLED.toLowerCase():
      icon = <SvgCancel />;
      text = "T00129";
      break;
    default:
      throw new Error(t("T00157", { status }));
  }
  return { icon, text };
};

export const getStatusIconAndTextForPatientProfileCompletion = (
  status: VerificationStatus | null,
) => {
  let icon: ReactElement, text: TransKey;
  switch (status) {
    case VerificationStatus.VERIFIED:
      icon = <SvgCheckCircleSmall />;
      text = "T00930";
      break;
    case VerificationStatus.REJECTED:
      icon = <SvgErrorOutline width={20} height={20} />;
      text = "T00131";
      break;
    case VerificationStatus.PENDING:
      icon = <SvgClock />;
      text = "T00132";
      break;
    case null:
    default:
      icon = <SvgAddCircleOutline />;
      text = "T00138";
  }
  return { icon, text };
};

export const getParentStatus = (
  statuses: Partial<RehabVerificationProgressResponse>,
) => {
  const anyChildRequiresAction = Object.keys(statuses).some(
    k => statuses[k] === VerificationStatus.REJECTED,
  );
  const anyChildIsWaitingForVerification = Object.keys(statuses).some(
    k => statuses[k] === VerificationStatus.PENDING,
  );
  const anyChildStatusIsToDo = Object.keys(statuses).some(
    k => statuses[k] === null,
  );
  const everyChildStatusIsVerified = Object.keys(statuses).every(
    k => statuses[k] === VerificationStatus.VERIFIED,
  );
  if (anyChildRequiresAction) return VerificationStatus.REJECTED;
  if (anyChildStatusIsToDo) return null;
  if (anyChildIsWaitingForVerification) return VerificationStatus.PENDING;
  if (everyChildStatusIsVerified) return VerificationStatus.VERIFIED;
};

export const getPhysioParentVerificationStatuses = (
  data: RehabVerificationProgressResponse,
) => {
  const {
    licenceVerification,
    addressVerification,
    subscriptionVerification,
    stationaryPaymentVerification,
    languageVerification,
    servicesVerification,
    aboutMeVerification,
    profilePhotoVerification,
    certificatesVerification,
    educationVerification,
    experienceVerification,
    onlinePaymentVerification,
    phoneNumberVerification,
  } = data;

  const licenceAndAddress = {
    licenceVerification,
    addressVerification,
  };

  const licenceAndAddressVerificationStatus = getParentStatus(
    flags.FEATURE_SMS_VERIFICATION_ENABLED
      ? { ...licenceAndAddress, phoneNumberVerification }
      : { ...licenceAndAddress },
  );

  const languageAndServicesVerificationStatus = getParentStatus({
    languageVerification,
    servicesVerification,
  });

  const profileInfoVerificationStatus = getParentStatus({
    aboutMeVerification,
    profilePhotoVerification,
    certificatesVerification,
    educationVerification,
    experienceVerification,
  });

  return {
    licenceAndAddressVerificationStatus,
    languageAndServicesVerificationStatus,
    stationaryPaymentVerification,
    profileInfoVerificationStatus,
    subscriptionVerification,
    onlinePaymentVerification,
    phoneNumberVerification,
  };
};

export const convertBooleanToVerificationStatus = (
  booleanStatus: boolean,
): VerificationStatus => (booleanStatus ? VerificationStatus.VERIFIED : null);

export const getStatusIconAndTextForInvitedUsers = (
  status: VerificationStatus | null,
): { icon: JSX.Element; text: TransKey } => {
  if (status === VerificationStatus.VERIFIED)
    return {
      icon: <SvgCheckCircleSmall />,
      text: "T01565",
    };
  return {
    icon: <SvgClock />,
    text: "T00132",
  };
};

export const createSlots = (
  n: number,
  firstSlotIsZero?: boolean,
): PickerItem<number>[] => {
  const arr: PickerItem<number>[] = [];
  for (let i = 0; i < n; i++) {
    const obj: PickerItem<number> = {
      value: i,
      label: `${
        (firstSlotIsZero ? 0 : SERVICE_SLOT_TIME) + i * SERVICE_SLOT_TIME
      }`,
    };
    arr.push(obj);
  }
  return arr;
};
