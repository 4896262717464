import { useEffect, useRef } from "react";
import { AppState } from "react-native";

export const useAppStateChange = (cb?: () => void) => {
  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current === "background" && nextAppState === "active") cb();

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [cb]);
};
