import {
  findMaxKey,
  setInitialOrientation,
} from "@components/HumanModel/helpers";
import {
  ModelType,
  Orientation,
} from "@components/HumanModel/humanModel.types";
import { useFlags } from "@hooks/useFlags";
import { useMemo } from "react";

type HumanModelObject = {
  [key in ModelType]: string[];
};

export const useInitialParametersForHumanModel = (
  obj: HumanModelObject,
): {
  modelType: ModelType;
  orientation: Orientation;
  anyLengthGreaterThanZero?: boolean;
} => {
  const {
    flags: { FEATURE_GENERAL_MODEL, FEATURE_BONE_MODEL },
  } = useFlags();
  const lengths: Record<ModelType, number> = useMemo(
    () => ({
      [ModelType.GENERAL]: obj?.general?.length || 0,
      [ModelType.MUSCLE]: obj?.muscle?.length || 0,
      [ModelType.BONE]: obj?.bone?.length || 0,
    }),
    [obj?.bone?.length, obj?.general?.length, obj?.muscle?.length],
  );

  const maxKey = findMaxKey(lengths, FEATURE_GENERAL_MODEL, FEATURE_BONE_MODEL);

  const anyLengthGreaterThanZero = useMemo(
    () => Object.values(lengths).some(length => length > 0),
    [lengths],
  );

  let orientation: Orientation;
  let longestPartArrayLength: number;

  const calculatedOrientation = setInitialOrientation({
    array: obj?.[maxKey]?.length ? [...obj[maxKey]] : [],
    key: maxKey,
    orientation,
    longestPartArrayLength,
  });

  if (!anyLengthGreaterThanZero && FEATURE_GENERAL_MODEL)
    return {
      modelType: ModelType.GENERAL,
      orientation: "front",
      anyLengthGreaterThanZero,
    };

  if (!FEATURE_GENERAL_MODEL && !FEATURE_BONE_MODEL)
    return {
      modelType: ModelType.MUSCLE,
      orientation: calculatedOrientation,
      anyLengthGreaterThanZero,
    };

  return {
    modelType: maxKey,
    orientation: calculatedOrientation,
    anyLengthGreaterThanZero,
  };
};
