import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
  View,
} from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { TrainingTile } from "../../../components/Tile";
import { PrimaryButton } from "../../../components/buttons";
import { FetchError } from "../../../components/errors";
import { useErrors } from "@hooks/useErrors";
import { getLibraryTrainingsList } from "@api/trainings";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { queryKeysLibraryTrainings } from "../queryKeysTrainingsAndExercises";
import {
  PhysiotherapistTrainingsTabProps,
  TrainingType,
} from "../training.types";
import { SvgProphylacticWorkout1 } from "@assets/svg/tutorials";
import { TransKey } from "@globalTypes/i18next";
import { UserCard } from "@components/cards";
import InfoTile from "@components/Tile/InfoTile";
import { useFlags } from "@hooks/useFlags";

const Trainings: FC<PropsWithChildren<PhysiotherapistTrainingsTabProps>> = ({
  navigation: { navigate },
  route,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { isGymsy } = useFlags();
  const subtitles: TransKey[] = isGymsy
    ? ["T00659", "T00661", "T00662"]
    : ["T00659", "T00660", "T00661", "T00662"];

  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const patientId = route.params?.patientId;
  const resetProcess = route.params?.resetProcess;
  const { data, refetch, isLoading, isError } = useQuery({
    queryKey: queryKeysLibraryTrainings.list(),
    queryFn: getLibraryTrainingsList,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const popCount = resetProcess ? 3 : 2;

  const renderItem: ListRenderItem<TrainingType> = ({ item: { id } }) => (
    <TrainingTile
      trainingId={id}
      onPress={() => {
        patientId
          ? navigate("CreateTrainingStack", {
              screen: "CreateAndEditTraining",
              params: {
                id,
                patientId,
                popCount,
              },
            })
          : navigate("LibraryTrainingDetails", {
              id,
            });
      }}
    />
  );

  const { gapMedium, flex, loading } = globalStyles;

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <View style={[gapMedium, flex]}>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id.toString()}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
        ListEmptyComponent={<Text variant="bodyMedium">{t("T00182")}</Text>}
        ListHeaderComponent={
          <View style={gapMedium}>
            {!patientId ? (
              <>
                <TutorialTextButton
                  label="T00656"
                  onPress={() => setModalVisible(true)}
                />
                <PrimaryButton
                  label="T01530"
                  mode="outlined"
                  onPress={() => navigate("PatientsList")}
                />
              </>
            ) : (
              <>
                <UserCard initialData={{ id: patientId }} mode="outlined" />
                <InfoTile
                  status="warning"
                  content={
                    <Text style={{ fontFamily: "Poppins" }}>{t("T01534")}</Text>
                  }
                />
              </>
            )}

            <Text variant="titleMedium">{t("T00184")}:</Text>
            <PrimaryButton
              label="T00658"
              onPress={() =>
                navigate("CreateTrainingStack", {
                  screen: "CreateAndEditTraining",
                  params: { patientId, popCount },
                })
              }
            />
          </View>
        }
        contentContainerStyle={[styles.flatListStyles, gapMedium]}
      />
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <BottomTutorialComponent
          title="T00657"
          content={[
            {
              text: subtitles,
              svgComponent: () => <SvgProphylacticWorkout1 />,
            },
          ]}
          onPress={() => setModalVisible(false)}
        />
      </BottomModalContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  flatListStyles: {
    padding: spacing16,
  },
});

export default Trainings;
