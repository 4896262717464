import { RootStackParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { healthCheck } from "@services/ApiService/common";
import { useQuery } from "@tanstack/react-query";
import { isWeb } from "@utils/constants";
import { useEffect, useState } from "react";
import { useAppVersion } from "../useAppVersion";
import { compareVersions, isVersionNotSupported } from "./healthCheckHelpers";

type UseApiHealthCheckOptions = {
  isMaintenanceScreen?: boolean;
};

const useApiHealthCheck = ({
  isMaintenanceScreen,
}: UseApiHealthCheckOptions = {}) => {
  const appVersion = useAppVersion();
  const { data, isLoading, isError, refetch, isRefetching } = useQuery({
    queryKey: ["apiHealthCheck"],
    queryFn: healthCheck,
    cacheTime: 0,
  });
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isNotSupported, setIsNotSupported] = useState(false);
  const [canBeUpdated, setCanBeUpdated] = useState(false);

  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  useEffect(() => {
    if ((isMaintenance || isNotSupported) && !isMaintenanceScreen)
      navigate("Maintenance");
  }, [isMaintenance, isMaintenanceScreen, isNotSupported, navigate]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isLoading && !isError) {
      if (data?.newest_version) {
        const isHigherThanNewest = compareVersions(
          data?.newest_version,
          appVersion,
        ).isHigher;

        if (isHigherThanNewest) {
          __DEV__ && console.error("dev - update app version in cms");
        }
      }

      if (data.maintenance_mode) {
        setIsMaintenance(true);
      } else if (
        data?.min_version &&
        isVersionNotSupported(appVersion, data?.min_version)
      ) {
        setIsNotSupported(true);
      } else if (
        data?.newest_version &&
        appVersion !== data?.newest_version &&
        !isWeb
      ) {
        const { isLower } = compareVersions(appVersion, data?.newest_version);
        setCanBeUpdated(isLower);
      }
    }

    return () => {
      setIsNotSupported(false);
      setIsMaintenance(false);
      clearTimeout(timeout);
    };
  }, [
    appVersion,
    data?.maintenance_mode,
    data?.maintenance_reason,
    data?.min_version,
    data?.newest_version,
    isError,
    isLoading,
  ]);

  return {
    isMaintenance,
    isNotSupported,
    maintenanceReason: data?.maintenance_reason,
    refetch,
    isLoading,
    isError,
    isRefetching,
    canBeUpdated,
  };
};

export default useApiHealthCheck;
