import { FC } from "react";
import { TextButton } from "./TextButton";
import { StyleProp, ViewStyle } from "react-native";
import { TransKey } from "@globalTypes/i18next";

type TutorialTextButtonProps = {
  label: TransKey;
  onPress: () => void;
  subContainerStyle?: StyleProp<ViewStyle>;
  ignoreTextStyle?: boolean;
  buttonInView?: boolean;
};

const TutorialTextButton: FC<TutorialTextButtonProps> = ({
  label,
  subContainerStyle,
  ignoreTextStyle,
  buttonInView = false,
  ...props
}) => (
  <TextButton
    iconName="information-outline"
    title={label}
    subContainerStyle={[{ padding: 0 }, subContainerStyle]}
    textStyle={!ignoreTextStyle && { flex: 1 }}
    buttonInView={buttonInView}
    {...props}
  />
);

export default TutorialTextButton;
