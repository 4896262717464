import { AppbarTitleWithBackAction } from "@components/Appbar";
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { ProfileCompletionStackParamList } from "./navigation.types";

import { AddPhoto } from "@screens/Profiles/MyProfile/CommonProfile";
import {
  PatientDetails,
  WeightAndHeight,
} from "@screens/Profiles/MyProfile/PatientCompletion";
import {
  AboutMe,
  AddressAndVerification,
  Certificates,
  Education,
  Experience,
  Payments,
  ProfileInformation,
  Services,
  ServicesAndLanguages,
  VerifyAddress,
  VerifyLicence,
  VerifyPhone,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification";
import Languages from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Languages";

import ProfileCompletion from "@screens/Profiles/MyProfile/PhysiotherapistVerification/ProfileCompletion";
import SpecializationRequirements from "@screens/Profiles/MyProfile/PhysiotherapistVerification/SpecializationRequirements";

import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import { useFlags } from "@hooks/useFlags";
import ConfirmSpecialization from "@screens/Profiles/MyProfile/PhysiotherapistVerification/ConfirmSpecialization";
import OnlinePayments from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Payments/OnlinePayments";
import StationaryPayments from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Payments/StationaryPayments";
import SuccessStripeModal from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Payments/SuccessStripeModal";
import Subscription from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Subscription/Subscription";
import SubscriptionWeb from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Subscription/SubscriptionWeb";
import { isWeb } from "@utils/constants";
import AddServicesStackNavigator from "./AddServicesStackNavigator";
import AddServiceContextProvider from "@screens/Profiles/MyProfile/PhysiotherapistVerification/AddServices/addService.context";

const Stack = createNativeStackNavigator<ProfileCompletionStackParamList>();

const ProfileCompletionStackNavigator: FC<
  PropsWithChildren<NativeStackScreenProps<ProfileCompletionStackParamList>>
> = () => {
  const { t } = useTranslation();
  const { isGymsy } = useFlags();

  return (
    <AddServiceContextProvider>
      <Stack.Navigator>
        <Stack.Screen
          name="ProfileCompletion"
          component={ProfileCompletion}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction onClose={goBack} title={t("T00197")} />
            ),
          }}
        />
        <Stack.Screen
          name="AddressAndVerification"
          component={AddressAndVerification}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00234")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="ProfileInformation"
          component={ProfileInformation}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00236")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="ServicesAndLanguages"
          component={ServicesAndLanguages}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00238")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="Payments"
          component={Payments}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00710")} />,
          }}
        />
        <Stack.Screen
          name="OnlinePayments"
          component={OnlinePayments}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T01089")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="SuccessStripeModal"
          component={SuccessStripeModal}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction onClose={goBack} title={t("T00161")} />
            ),
            animation: "slide_from_bottom",
          }}
        />
        <Stack.Screen
          name="StationaryPayments"
          component={StationaryPayments}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T01090")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="VerifyAddress"
          component={VerifyAddress}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00139")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="VerifyLicence"
          component={isGymsy ? ConfirmSpecialization : VerifyLicence}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00148")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="VerifyPhone"
          component={VerifyPhone}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T01508")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="AboutMe"
          component={AboutMe}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00258")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="Experience"
          component={Experience}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00265")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="Education"
          component={Education}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00269")} />,
          }}
        />
        <Stack.Screen
          name="Certificates"
          component={Certificates}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00274")} />,
          }}
        />
        <Stack.Screen
          name="Services"
          component={Services}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00240")} />,
          }}
        />
        <Stack.Screen
          name="AddServicesStack"
          component={AddServicesStackNavigator}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SpecializationRequirements"
          component={SpecializationRequirements}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction title={t("T00103")} onClose={goBack} />
            ),
            animation: "slide_from_bottom",
          }}
        />
        <Stack.Screen
          name="AddPhoto"
          component={AddPhoto}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00528")} />,
          }}
        />
        <Stack.Screen
          name="Languages"
          component={Languages}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00604")} />,
          }}
        />
        <Stack.Screen
          name="WeightAndHeight"
          component={WeightAndHeight}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T00356")}
              />
            ),
          }}
        />
        <Stack.Screen
          name="Subscription"
          component={isWeb ? SubscriptionWeb : Subscription}
          options={{
            header: () => <AppbarTitleWithBackAction title={t("T00014")} />,
          }}
        />
        <Stack.Screen
          name="PatientDetails"
          component={PatientDetails}
          options={{
            header: ({ navigation }) => (
              <AppbarTitleWithDefaultBackAction
                navigation={navigation}
                title={t("T01023")}
              />
            ),
          }}
        />
      </Stack.Navigator>
    </AddServiceContextProvider>
  );
};

export default ProfileCompletionStackNavigator;
