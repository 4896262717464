import { getPatientBasicData, getPhysiotherapistBasicData } from "@api/users";
import { setStatus } from "@components/Status/setStatus";
import { useLocale } from "@hooks/useLocale";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { palettes } from "@styles/colors";
import { theme } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { getTime } from "@utils/date";
import { FC } from "react";
import { StyleSheet } from "react-native";
import { ActivityIndicator, Card } from "react-native-paper";
import { FetchError } from "../../errors";
import UpcomingAppointmentContent from "./CardContent";
import { CardProps } from "./UpcomingAppointmentCard.types";

export const UpcomingAppointmentCard: FC<CardProps> = ({
  upcomingAppointmentData,
  mode = "outlined",
  elevation,
  onPress,
  ...props
}) => {
  const { isPatient } = useUserDetails();
  const { dateFrom, patient, physiotherapist, serviceType, promoCodeUsed } =
    upcomingAppointmentData;
  const { containerOutlined, onlineBackground } = styles;
  const userId = isPatient ? physiotherapist : patient;
  const isOnline = serviceType === "Online";
  const { locale } = useLocale();

  const {
    data: userBasicData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(userId),
    queryFn: async () => {
      if (isPatient) return await getPhysiotherapistBasicData(userId);
      return await getPatientBasicData(userId);
    },
  });

  return (
    <Card
      {...props}
      elevation={elevation as never}
      mode={mode}
      style={[
        mode === "outlined" && containerOutlined,
        isOnline && onlineBackground,
      ]}
      onPress={!isError && onPress}>
      <Card.Content>
        {isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : isLoading ? (
          <ActivityIndicator />
        ) : (
          <UpcomingAppointmentContent
            data={userBasicData}
            discountInfo={promoCodeUsed}
            subtitle={getTime(dateFrom, locale)}
            status={setStatus(upcomingAppointmentData)}
          />
        )}
      </Card.Content>
    </Card>
  );
};

export const styles = StyleSheet.create({
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
    backgroundColor: palettes.primary[90],
  },
  onlineBackground: {
    backgroundColor: theme.colors.tertiaryContainer,
  },
});

export default UpcomingAppointmentCard;
